import { createSelector } from "reselect";
import { ProjectSelector } from "../projects/selector";
import { RootState } from "../store";
import { ActivationCode } from "./entity";
import { ActivationCodeAdapter } from "./reducer";

const AdapterSelector = ActivationCodeAdapter.getSelectors(
  (state: RootState) => state.activationCodes
);

export const getState = createSelector(
  (state: RootState) => state,
  state => state.activationCodes
);

export const getActivationCode = AdapterSelector.selectAll;

export const getActivationCodeById = (id: string) => {
  createSelector(getActivationCode, activationCode =>
    activationCode.find(ac => ac.id === id)
  );
};

const getProjects = (activationCode: ActivationCode) =>
  createSelector(ProjectSelector.selectAll, projects => {
    projects.filter(project => project.codeId === activationCode.id);
  });

export const ActivationCodeSelector = {
  ...AdapterSelector,
  getState,
  getProjects
};
