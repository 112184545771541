import {
  EntityState,
  createEntityAdapter,
  PayloadAction
} from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { Domain } from "./entity";
import * as Api from "./api";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { goBack } from "connected-react-router";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { DomainActionsTypes } from "./action";
import { CompanySetting } from "../../entities/company-setting";
import { Watermark } from "../../entities/watermark";
import merge from "lodash/merge";

export interface DomainState extends EntityState<Domain> {
  selected?: Domain;
  settings?: CompanySetting;
  watermark?: Watermark;
  readOneStatus: FetchingStatus;
}

export const DomainInitialState: DomainState = {
  ids: [],
  entities: {},
  readOneStatus: FetchingStatus.NULL
};

export const DomainAdapter = createEntityAdapter<Domain>();

const DomainAdapterState = DomainAdapter.getInitialState(DomainInitialState);

const readOneDomain = createMyAsyncThunk<Domain, string>(
  DomainActionsTypes.DOMAIN_READ_ONE,
  Api.readOneDomain,
  {
    onFailed: ({ thunkApi: { dispatch } }) => {
      dispatch(goBack());
    }
  }
);

export const DomainSlice = createMySlice({
  name: "domain",
  initialState: DomainAdapterState,
  adapter: DomainAdapter,
  asyncActions: [
    {
      action: readOneDomain,
      statusName: "readOneStatus",
      onSuccess: (state: DomainState, action: PayloadAction<Domain>) => {
        state.selected = merge({}, state.selected, action.payload);
      }
    }
  ],
  reducers: {}
});

export const DomainReducer = DomainSlice.reducer;
export const DomainActions = {
  ...DomainSlice.actions,
  readOneDomain
};
