import { EntityId } from "@reduxjs/toolkit";
import { schema } from "normalizr";
import { Asset } from "../../entities/asset";
import { ActivationCode } from "../activation-code/entity";

/** 3 for sRGB, 4 for CMYK */
type Channels = 3 | 4;

export interface ImageGrouped {
  owned: Image[];
  companies: Image[];
  shared: Image[];
  catalogue: Image[];
}
export class Image extends Asset {
  id!: string;
  legacyId?: string;
  name!: string;
  url!: string;
  size!: number;
  width!: number;
  height!: number;
  format!: string;
  public!: boolean;
  space?: string;
  channels?: Channels;
  depth?: string;
  density?: number;
  chromaSubsampling?: string;
  isProgressive?: boolean;
  pages?: number;
  pageHeight?: number;
  loop?: number;
  delay?: number[];
  pagePrimary?: number;
  hasProfile?: boolean;
  hasAlpha?: boolean;
  enabled!: boolean;
  createdAt!: Date;
  updatedAt!: Date;
  asset!: Asset;
}

export interface ImageCreateDTO {}

export interface ImageUpdateDTO {
  id: string;
}

export interface ImageNormalized {
  images: Record<EntityId, Image>;
  activationCodes: Record<EntityId, ActivationCode>;
}
export const ImageEntity = new schema.Entity<Image>("images", {
  activationCodes: [new schema.Entity<ActivationCode[]>("activationCodes")]
});
