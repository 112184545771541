import React from "react";
import { useSelector } from "react-redux";
import { AudioActions } from "../../../reducers/audios/reducer";
import { AudioSelector } from "../../../reducers/audios/selector";
import { Company } from "../../../reducers/companies/entity";
import { CompanyActions } from "../../../reducers/companies/reducer";
import { CompanySelector } from "../../../reducers/companies/selector";
import { ImageActions } from "../../../reducers/images/reducer";
import { ImageSelector } from "../../../reducers/images/selector";
import { ScenarioDTO } from "../../../reducers/scenarios/entity";
import { ShotCategoryActions } from "../../../reducers/shot-category/reducer";
import { ShotCategorySelector } from "../../../reducers/shot-category/selector";
import { VideoEffectActions } from "../../../reducers/video-effect/reducer";
import { VideoEffectSelector } from "../../../reducers/video-effect/selector";
import { VideoActions } from "../../../reducers/videos/reducer";
import { VideoSelector } from "../../../reducers/videos/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { DynamicElement } from "../DynamicElement";
import { Page } from "../MyPage";
import GtvForm from "./Form";

interface GtvCompanyProps {
  company: Company;
  onSubmit: (scenario: ScenarioDTO) => void;
}

export const GtvCompany: React.FC<GtvCompanyProps> = ({
  company,
  onSubmit
}) => {
  const companyState = useSelector(CompanySelector.getState);
  const audios = useSelector(CompanySelector.getAllAudios(company));
  const videos = useSelector(CompanySelector.getAllVideos(company));
  const images = useSelector(CompanySelector.getAllImages(company));

  const readAudiosStatus = companyState.readAudiosStatus;
  const readImagesStatus = companyState.readImagesStatus;
  const readVideosStatus = companyState.readVideosStatus;
  const readAudiosAction = CompanyActions.readAudios(company);
  const readImagesAction = CompanyActions.readImages(company);
  const readVideosAction = CompanyActions.readVideos(company);

  const { readPremiumStatus: readAudioPremiumStatus } = useSelector(
    AudioSelector.getState
  );
  const { readPremiumStatus: readImagePremiumStatus } = useSelector(
    ImageSelector.getState
  );
  const { readPremiumStatus: readVideoPremiumStatus } = useSelector(
    VideoSelector.getState
  );
  const { readStatus: readVideoEffectStatus } = useSelector(
    VideoEffectSelector.getState
  );
  const { readStatus: readShotCategoriesStatus } = useSelector(
    ShotCategorySelector.getState
  );

  return (
    <Page>
      <DynamicElement
        actions={[
          {
            action: readImagesAction,
            status: readImagesStatus
          },
          {
            action: readAudiosAction,
            status: readAudiosStatus
          },
          {
            action: readVideosAction,
            status: readVideosStatus
          },
          {
            action: ImageActions.readPremium(),
            status: readImagePremiumStatus
          },
          {
            action: AudioActions.readPremium(),
            status: readAudioPremiumStatus
          },
          {
            action: VideoActions.readPremium(),
            status: readVideoPremiumStatus
          },
          {
            action: VideoEffectActions.read(),
            status: readVideoEffectStatus
          },
          {
            action: ShotCategoryActions.read(),
            status: readShotCategoriesStatus
          }
        ]}
        data={true}
        showCondition={[
          readImagePremiumStatus,
          readAudioPremiumStatus,
          readVideoPremiumStatus,
          readAudiosStatus,
          readImagesStatus,
          readVideosStatus,
          readShotCategoriesStatus
        ].every(status => status === FetchingStatus.SUCCESS)}
      >
        {() => (
          <GtvForm
            audios={audios}
            images={images}
            videos={videos}
            onSubmit={onSubmit}
          />
        )}
      </DynamicElement>
    </Page>
  );
};
