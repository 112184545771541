import {
  Avatar,
  Box,
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper
} from "@material-ui/core";
import UserIcon from "@material-ui/icons/AccountCircle";
import SendIcon from "@material-ui/icons/Send";
import { Form, FormikProps } from "formik";
import debounce from "lodash/debounce";
import differenceBy from "lodash/differenceBy";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyDialog } from "../../../components/common/MyDialog";
import { MyTextField } from "../../../components/common/MyForm/MyTextField";
import { MyFormik } from "../../../components/common/MyFormik";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { User } from "../../../entities/user";
import { Company } from "../../../reducers/companies/entity";
import { CompanyActions } from "../../../reducers/companies/reducer";
import { ItemSelector } from "../../../reducers/items/selector";
import { Request } from "../../../utils/request";
import { searchCollaboratorSchema } from "../schema/add-collaborator.schema";
import { CompanySelector } from "../../../reducers/companies/selector";

export interface CompanySearchCollaboratorProps extends DialogProps {
  company: Company;
}

const useStyles = makeStyles(() =>
  createStyles({
    sendIcon: {
      transform: "rotate(-45deg)"
    }
  })
);

export const CompanySearchCollaborator: React.FC<CompanySearchCollaboratorProps> = ({
  company,
  ...props
}) => {
  const [collaborators, setCollaborators] = useState<User[]>([]);
  const [search, setSearch] = useState<string>();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation("company");
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyAddCollaboratorPending = useSelector(
    ItemSelector.getItemsPendingByAction(
      CompanyActions.addCollaborator.typePrefix
    )
  );
  const companyCollaborators = useSelector(
    CompanySelector.getCollaborators(company)
  );

  const onSubmit = () => {
    return;
  };

  const onChange = useCallback(
    debounce(async ({ search }: { search: string }) => {
      setSearch(search);
      setPending(true);
      const { data } = await Request({
        withToken: true
      }).post("/users/collaborators", { search });
      setPending(false);
      setCollaborators(data);
    }, 200),
    []
  );

  const onAddCollaborator = (user: User) => {
    dispatch(CompanyActions.addCollaborator({ user, company }));
  };

  return (
    <MyDialog {...props} keepMounted={false}>
      <DialogTitle>{t("add-collaborator.title")}</DialogTitle>
      <DialogContent style={{ minHeight: 400, minWidth: 400 }}>
        <DialogContentText></DialogContentText>
        <MyFormik
          initialValues={{
            search: ""
          }}
          validationSchema={searchCollaboratorSchema}
          validateOnChange
          onSubmit={onSubmit}
        >
          {(props: FormikProps<{ search: string }>) => {
            if (search !== props.values.search) {
              onChange(props.values);
            }
            return (
              <Form>
                <MyTextField
                  fullWidth
                  label={t("add-collaborator.title")}
                  name="search"
                  InputProps={{
                    endAdornment: pending && <CircularProgress size={18} />
                  }}
                />
              </Form>
            );
          }}
        </MyFormik>
        <List>
          {differenceBy(collaborators, companyCollaborators || [], "id").map(
            c => {
              const loading = companyAddCollaboratorPending.includes(c.id);
              return (
                <Box clone mt={1} key={c.id}>
                  <Paper elevation={2}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <UserIcon fontSize="large" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={c.email}
                        secondary={
                          c.profile
                            ? `${c.profile.lastname} ${c.profile.firstname}`
                            : ""
                        }
                      />
                      <ListItemSecondaryAction>
                        <MyIconButton
                          edge="end"
                          onClick={() => onAddCollaborator(c)}
                          loading={loading}
                        >
                          <SendIcon className={classes.sendIcon} />
                        </MyIconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Paper>
                </Box>
              );
            }
          )}
        </List>
      </DialogContent>
      <DialogActions></DialogActions>
    </MyDialog>
  );
};
