import {
  createEntityAdapter,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ScenarioActionsTypes } from "./action";
import * as Api from "./api";
import { Scenario, ScenarioDTO } from "./entity";

export interface ScenarioState extends EntityState<Scenario> {
  selected?: Scenario;
  readOneStatus: FetchingStatus;
  updateOneStatus: FetchingStatus;
  removeOneStatus: FetchingStatus;
  transferScenarioStatus: FetchingStatus;
}

const ScenarioInitialState: ScenarioState = {
  ids: [],
  entities: {},
  selected: undefined,
  readOneStatus: FetchingStatus.NULL,
  updateOneStatus: FetchingStatus.NULL,
  removeOneStatus: FetchingStatus.NULL,
  transferScenarioStatus: FetchingStatus.NULL
};

export const ScenarioAdapter = createEntityAdapter<Scenario>();

const ScenarioAdapterState = ScenarioAdapter.getInitialState(
  ScenarioInitialState
);

const readOneScenario = createMyAsyncThunk<Scenario, string>(
  ScenarioActionsTypes.SCENARIO_READ_ONE,
  Api.readOneScenario
);
const removeOneScenario = createMyAsyncThunk(
  ScenarioActionsTypes.SCENARIO_REMOVE_ONE,
  Api.removeOneScenario,
  { onSuccess: ({ values }) => values, onSuccessMessage: "saga:delete-success" }
);

// TODO set the scenario selected id to update not the gtv values scenario id
const updateScenario = createMyAsyncThunk<Scenario, ScenarioDTO>(
  ScenarioActionsTypes.UPDATE,
  (scenario, { getState }) => {
    console.log('*** scenario ', scenario);
    const id = getState().gtv.scenario.values.id as string; 
    return Api.updateScenario(id, scenario);
  },
  {
    onSuccessMessage: "saga:update-success"
  }
);

const setScenarioOwnerToCompany = createMyAsyncThunk(
  ScenarioActionsTypes.SCENARIO_SET_TO_COMPANY,
  Api.setScenarioOwnerToCompany,
  {
    onSuccess: ({ values }) => values,
    onSuccessMessage: "saga:transfer-sucess"
  }
);

const ScenarioSlice = createMySlice({
  name: "scenarios",
  initialState: ScenarioAdapterState,
  adapter: ScenarioAdapter,
  asyncActions: [
    {
      action: readOneScenario,
      statusName: "readOneStatus",
      onSuccess: (state, action) => {
        ScenarioAdapter.upsertOne(state, action.payload);
        state.selected = action.payload;
      }
    },
    {
      action: updateScenario,
      statusName: "updateOneStatus",
      onSuccess: (state, action) => {
        if (state.selected?.id === action.payload.id) {
          state.selected = action.payload;
        }
        ScenarioAdapter.upsertOne(state, action.payload);
      }
    },
    {
      action: removeOneScenario,
      statusName: "removeOneStatus",
      onSuccess: (state, action) => {
        if (state.selected?.id === action.payload.id) {
          state.selected = action.payload;
        }
        ScenarioAdapter.removeOne(state, action.payload);
      }
    },
    {
      action: setScenarioOwnerToCompany,
      statusName: "transferScenarioStatus",
      onSuccess: (state, action) => {
        if (state.selected?.id === action.payload.id) {
          state.selected = action.payload;
        }
        ScenarioAdapter.removeOne(state, action.payload.id);
      }
    }
  ],
  reducers: {
    selected: (state, action: PayloadAction<Scenario | undefined>) => {
      state.selected = action.payload;
    }
  }
});

export const ScenarioReducer = ScenarioSlice.reducer;
export const ScenarioActions = {
  readOneScenario,
  updateScenario,
  async: {
    removeOneScenario,
    setScenarioOwnerToCompany
  },
  ...ScenarioSlice.actions
};
