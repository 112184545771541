import React from "react";
import { MyDrawer } from "../../components/common/MyDrawer";
import { MyPanel } from "../../components/common/MyPanel";
import { MyTopBar } from "../../components/common/MyTopBar";
import { MySpaceRoutes } from "../../routes/myspace.route";
import { MySpaceMenus } from "./components/Menus";
import { MySpaceTopBarMenus } from "./components/TopbarMenus";

export interface MySpaceProps {}

export const MySpace: React.FC<MySpaceProps> = () => {
  return (
    <MyPanel routes={MySpaceRoutes} prefix="/my-space">
      <MyTopBar rightActions={<MySpaceTopBarMenus />} applyMarginLeft />
      <MyDrawer>
        <MySpaceMenus />
      </MyDrawer>
    </MyPanel>
  );
};
