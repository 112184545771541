import { Request } from "../../utils/request";
import { Company } from "../companies/entity";
import { User } from "../../entities/user";
import { FontConfig } from "../fonts-config/entity";
import { ForgetPasswordValues } from "../../pages/Authentication/components/ForgetPassword.schema";
import { ChangePasswordValues } from "../../pages/Authentication/components/ChangePassword.schema";

export const readCompanies = (user: User) =>
  Request({ withToken: true }).get<Company[]>(`/users/${user.id}/companies`);

export const readCollaborations = (userId: string) =>
  Request({ withToken: true }).get<User[]>(`/users/${userId}/collaborations`);

export const readFontConfigs = (user: User) =>
  Request({ withToken: true }).get(`/users/${user.id}/font-configs`);

// TODO WRONG API END POINT IT SHOULD BE DELETE /font-configs/{id} DIRECTLY
export const removeFontConfig = ({
  user,
  fontConfig
}: {
  user: User;
  fontConfig: FontConfig;
}) =>
  Request({ withToken: true }).delete(
    `/users/${user.id}/font-configs/${fontConfig.id}`
  );

export const forgetPassword = (data: ForgetPasswordValues) =>
  Request().post(`/forget-password`, data);

export const changePassword = ({
  password,
  token
}: ChangePasswordValues & { token: string }) =>
  Request().post(`/change-password?token=${token}`, { password });
