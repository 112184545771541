import axios, { AxiosRequestConfig, AxiosInstance } from "axios";

export interface RequestProps {
  withToken: boolean;
  token?: string;
}

export const HOST = process.env.REACT_APP_ENDPOINT || "";
// export const HOST = "http://localhost:3004";
export const baseURL = `${HOST}/api`;

export type MyRequest = (
  options?: RequestProps,
  config?: AxiosRequestConfig
) => AxiosInstance;

export const Request: MyRequest = (options, config) => {
  const headers = config ? config.headers : {};
  if (options) {
    try {
      headers["Authorization"] = options.withToken
        ? `Bearer ${localStorage.getItem("token")}`
        : "";
    } catch (error) {
      console.error(error);
      throw new Error("Can not get the session token");
    }
  }
  try {
    headers["Accept-Language"] = localStorage.getItem("i18nextLng");
  } catch (error) {
    throw new Error("Can not get the current language from i18n");
  }

  return axios.create({
    ...config,
    baseURL,
    headers
  });
};
