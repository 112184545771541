import { CircularProgress, TextField, TextFieldProps } from "@material-ui/core";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import get from "lodash/get";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";

export interface SelectOption {
  value: any;
  label: string;
  // menuItemProps?: MenuItemProps;
}
interface MyAutocompleteFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>
  extends FormProps,
    Omit<
      AutocompleteProps<SelectOption, Multiple, DisableClearable, FreeSolo>,
      "renderInput" | "options"
    > {
  inputProps?: TextFieldProps;
  options?: SelectOption[];
  name: string;
  async?: (
    inputValue?: string
  ) => {
    url: string;
    label: string;
    value: string;
    method?: any;
    data?: any;
    withToken?: boolean;
    handleOptions?: (data: any) => Array<any>;
  };
}

export function MyAutocompleteField<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: MyAutocompleteFieldProps<Multiple, DisableClearable, FreeSolo>) {
  const {
    name,
    label,
    async,
    inputProps,
    helperText,
    formControlProps,
    formLabelProps,
    formHelperTextProps,
    multiple,
    ...autocompleteProps
  } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>(
    autocompleteProps.options || []
  );
  const { token } = useSelector(getCurrentUser);
  const { register, errors, control, getValues } = useFormContext();

  const loadOptions = useCallback(
    (inputValue: string): void => {
      if (!async) {
        return;
      }
      setLoading(true);
      const {
        url,
        label,
        value,
        method,
        data,
        withToken,
        handleOptions
      } = async(inputValue);
      Axios({
        method,
        data,
        url,
        headers: {
          Authorization: withToken ? "Bearer " + token : ""
        }
      })
        .then(({ data }) => {
          setLoading(false);
          if (!Array.isArray(data)) {
            throw new Error(
              "Async autocomplete component resolve data are not of type Array"
            );
          }
          const options = handleOptions ? handleOptions(data) : data;
          setOptions(
            (options as any[]).map(d => {
              const labelFound = get(d, `${label}`);
              const valueFound = get(d, `${value}`);
              return {
                label: labelFound,
                value: valueFound
              };
            })
          );
        })
        .catch(err => {
          setLoading(false);
          console.warn(err);
        });
    },
    [async, token]
  );

  useEffect(() => {
    if (!options.length && (!!getValues()[name] || open)) loadOptions("");
  }, [getValues, loadOptions, name, open, options.length]);

  const labelRef = React.useRef<HTMLLabelElement>(null);
  const errorField = get(errors, name)?.message;
  const myFormControlProps: MyFormControlProps = {
    errorField,
    label,
    labelRef,
    helperText,
    formLabelProps,
    formHelperTextProps,
    ...formControlProps
  };

  return (
    <MyFormControl
      {...myFormControlProps}
      disabled={autocompleteProps.disabled}
      disableLabel
    >
      <Controller
        name={name}
        control={control}
        render={({ onChange, onBlur, value }) => {
          const initialValue = value || (multiple ? [] : "");
          const autoCompleteOptionValues = multiple
            ? (options.filter(o => initialValue.includes(o.value)) as any)
            : options.find(o => o.value === initialValue) || null;

          return (
            <Autocomplete
              value={autoCompleteOptionValues}
              onChange={(_, value) => {
                onChange(
                  multiple
                    ? ((value as SelectOption[]) || []).map(v => v?.value)
                    : (value as SelectOption)?.value
                );
              }}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onBlur={onBlur}
              multiple={multiple}
              loading={loading}
              options={options}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              getOptionLabel={o => o.label}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errorField}
                  inputRef={register}
                  variant="standard"
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  {...inputProps}
                />
              )}
              {...autocompleteProps}
            />
          );
        }}
      />
    </MyFormControl>
  );
}
