import { Box, Grid } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { CategoryNames } from "../../entities/category";
import { Video } from "../../reducers/videos/entity";
import {
  MyGalleryAppBar,
  MyGalleryAppBarProps
} from "./gallery/MyGalleryAppBar";
import { MyVideo } from "./MyVideo";

export interface MyVideoListProps {
  title?: string;
  videos: Video[];
  category?: CategoryNames;
  multiple?: boolean;
  onVideosSelected?: (audios: Video | Video[]) => void;
  videosSelected?: Video | Video[];
  myGalleryAppBarProps?: MyGalleryAppBarProps;
}
export const MyVideoList: React.FC<MyVideoListProps> = ({
  videos,
  onVideosSelected,
  videosSelected,
  multiple,
  myGalleryAppBarProps,
  category
}) => {
  const [searchVideo, setSearchVideo] = useState(
    category ? videos.filter(v => v.category?.name === category) : videos
  );
  const [search, setSearch] = useState("");

  const filterVideo = useCallback(
    debounce((search: string) => {
      if (!search) {
        return setSearchVideo(videos);
      }
      setSearchVideo(
        videos.filter(i => i.name.toLowerCase().includes(search.toLowerCase()))
      );
    }, 200),
    [videos]
  );

  const onSearchChange = (search: string) => {
    setSearch(search);
  };

  const onSelection = (video: Video) => {
    if (onVideosSelected) {
      if (!videosSelected) {
        return onVideosSelected(multiple ? [video] : video);
      }
      if (multiple && Array.isArray(videosSelected)) {
        const videoExistIndex = (videosSelected || []).findIndex(
          i => i.id === video.id
        );
        if (videoExistIndex === -1)
          onVideosSelected([...(videosSelected || []), video]);
        else
          onVideosSelected(
            (videosSelected || []).filter(i => i.id !== video.id)
          );
      } else {
        onVideosSelected(video);
      }
    }
  };

  useEffect(() => {
    if (!search) {
      setSearchVideo(
        category ? videos.filter(v => v.category?.name === category) : videos
      );
    } else {
      filterVideo(search);
    }
  }, [category, filterVideo, search, videos]);

  return (
    <Box>
      <MyGalleryAppBar
        mySearchInputProps={{ onChange: onSearchChange, value: search }}
        toolbarProps={{ style: { marginRight: 28 } }}
        {...myGalleryAppBarProps}
      />
      <Grid container>
        {searchVideo.map(video => {
          const isSelected =
            multiple && Array.isArray(videosSelected)
              ? videosSelected.map(i => i.id).includes(video.id)
              : videosSelected
              ? (videosSelected as Video).id === video.id
              : false;

          return (
            <Grid item key={video.id} xs={12} sm={4} md={3}>
              <MyVideo
                video={video}
                isSelected={isSelected}
                onClick={() => onSelection(video)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
