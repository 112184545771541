import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { Font } from "./entity";
import { FontActionsTypes } from "./action";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import * as Api from "./api";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export interface FontState extends EntityState<Font> {
  readPublicStatus: FetchingStatus;
}

export const FontInitialState: FontState = {
  ids: [],
  entities: {},
  readPublicStatus: FetchingStatus.NULL
};

export const FontAdapter = createEntityAdapter<Font>();

const FontAdapterState = FontAdapter.getInitialState(FontInitialState);

export const readPublic = createMyAsyncThunk<Font[], void>(
  FontActionsTypes.FONT_READ_PUBLIC,
  Api.readPublic,
  {
    onFailedMessage: "fonts:fetchFailed",
    onSuccess: ({ result }) => {
      for (const font of result) {
        const fontFace = new FontFace(font.name, `url(${font.url})`).load();
        fontFace.then(
          fontFace =>
            fontFace.status === "loaded" && document.fonts.add(fontFace)
        );
      }
      return result;
    }
  }
);

const FontSlice = createMySlice({
  name: "fonts",
  initialState: FontAdapterState,
  adapter: FontAdapter,
  asyncActions: [
    {
      action: readPublic,
      statusName: "readPublicStatus",
      onSuccess: FontAdapter.addMany
    }
  ],
  reducers: {}
});

export const FontReducer = FontSlice.reducer;
export const FontActions = { ...FontSlice.actions, readPublic };
