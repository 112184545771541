import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../../../reducers/app/reducer";
import { AppSelector } from "../../../../reducers/app/selector";
import { Languages } from "../../../../utils/function/jsonTranslator";
import { MyButton } from "../../MyButton";
import { MyFlag } from "../../MyFlag";

export const Language: React.FC<{
  onLanguageSet?: (lang: Languages) => void;
}> = ({ onLanguageSet, ...props }) => {
  const { language } = useSelector(AppSelector.getState);
  const { t } = useTranslation("components");
  const dispatch = useDispatch();
  const setLanguage = (lang: Languages) => {
    dispatch(AppActions.setLanguage(lang));
  };

  useEffect(() => {
    const langStorage = localStorage.getItem("i18nextLng");
    dispatch(AppActions.setLanguage(langStorage as Languages || language));
  }, [])
  return (
    <MyButton
      color="inherit"
      {...props}
      leftIcon={<MyFlag code={language} />}
      menus={({ onClose }) => [
        <ListItem
          button
          key="fr"
          onClick={() => {
            setLanguage(Languages.FR);
            onLanguageSet && onLanguageSet(Languages.FR);
            onClose();
          }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <MyFlag code={Languages.FR} />
          </ListItemIcon>
          <ListItemText>{t("my-topbar.language-options.fr")}</ListItemText>
        </ListItem>,
        <ListItem
          button
          key="en"
          onClick={() => {
            setLanguage(Languages.EN);
            onLanguageSet && onLanguageSet(Languages.EN);
            onClose();
          }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <MyFlag code={Languages.EN} />
          </ListItemIcon>
          <ListItemText>{t("my-topbar.language-options.en")}</ListItemText>
        </ListItem>
      ]}
    >
      <Box display="flex" alignItems="center">
        <Typography>
          {language === Languages.FR
            ? t("my-topbar.language-options.fr")
            : t("my-topbar.language-options.en")}
        </Typography>
      </Box>
    </MyButton>
  );
};
