import { EntityId } from "@reduxjs/toolkit";
import { schema } from "normalizr";
import { Asset } from "../../entities/asset";
import { AssetMeta } from "../../entities/asset-meta";
import { ActivationCode } from "../activation-code/entity";

export class Audio extends Asset {
  id!: string;
  legacyId?: string;
  name!: string;
  url!: string;
  format!: string;
  size!: number;
  codec?: string;
  duration!: number;
  sampleRate!: number;
  channels!: number;
  channelLayout!: string;
  bitRate!: number;
  startTime?: number;
  durationTs?: number;
  maxBitRate?: number;
  nbFrames?: number;
  meta?: AssetMeta;
  createdAt!: Date;
  updatedAt!: Date;
  asset!: Asset;
}

export interface AudioCreateDTO {}

export interface AudioUpdateDTO {
  id: string;
}

export interface AudioNormalized {
  audios: Record<EntityId, Audio>;
  activationCodes: Record<EntityId, ActivationCode>;
}
export const AudioEntity = new schema.Entity<Audio>("audios", {
  activationCodes: [new schema.Entity<ActivationCode[]>("activationCodes")]
});
