import { yupResolver } from "@hookform/resolvers";
import { Box, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyButton } from "../../../components/common/MyButton";
import { MyForm } from "../../../components/common/MyForm2";
import { MyTextField } from "../../../components/common/MyForm2/MyTextField";
import { AuthenticationActions } from "../../../reducers/authentication/reducer";
import { getAuthenticationState } from "../../../reducers/authentication/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { SignInSchema } from "./SignIn.schema";
import { AppSelector } from "../../../reducers/app/selector";
import { Languages } from "../../../utils/function/jsonTranslator";
import { AppActions } from "../../../reducers/app/reducer";

export interface SignInProps {
  onTabAuthenticationChange: (
    e: React.ChangeEvent<{}> | null,
    newValue: number
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typographySpacer: { 
      marginTop: theme.spacing(4),
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  })
);

export const SignIn: React.FC<SignInProps> = ({
  onTabAuthenticationChange
}) => {
  const { t } = useTranslation("authentication");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { signInStatus } = useSelector(getAuthenticationState);
  const { language: lang } = useSelector(AppSelector.getState);

  const methods = useForm({
    defaultValues: {
      username: "",
      password: ""
    },
    mode: "all",
    context: { lang },
    resolver: yupResolver(SignInSchema)
  });

  const onSubmit = (values: any) => {
    dispatch(AuthenticationActions.signIn(values));
  };

  useEffect(() => {
    const langStorage = localStorage.getItem("i18nextLng");
    dispatch(AppActions.setLanguage(langStorage as Languages || lang));
  }, []);

  const onNoAccountClick = (e: React.MouseEvent<HTMLElement>) =>
    onTabAuthenticationChange(e, 1);

  return (
    <>
      <MyForm onSubmit={onSubmit} methods={methods}>
        <MyTextField
          required
          autoComplete="username"
          label={t("sign-in.form.email")}
          autoFocus
          name="username"
        />
        <MyTextField
          required
          autoComplete="current-password"
          label={t("sign-in.form.password")}
          name="password"
          type="password"
        />
        <MyButton
          fullWidth
          color="primary"
          onClick={() => onTabAuthenticationChange(null, 2)}
        >
          <Typography align="center" color="primary">
            {t("sign-in.forget-password")}
          </Typography>
        </MyButton>
        <Box clone mt={1}>
          <MyButton
            capitalize
            loading={signInStatus === FetchingStatus.PENDING}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            {t("sign-in.signin-submit")}
          </MyButton>
        </Box>
      </MyForm>

      <Typography
        align="center"
        paragraph
        color="textSecondary"
        className={classes.typographySpacer}
        onClick={onNoAccountClick}
      >
        {t("sign-in.no-account")}
      </Typography>
    </>
  );
};
