//Saga
import saga from "./saga.json";
//Context
import authentication from "./pages/authentication.json";
import common from "./common.json";
//Components
import components from "./components.json";
//Pages
import onBoarding from "./pages/on-boarding.json";
import home from "./pages/home.json";
import font from "./pages/font.json";
import reconnection from "./pages/reconnection.json";
import backoffice from "./pages/back-office.json";
import profile from "./pages/profile.json";
import company from "./pages/company.json";
import gtv from "./pages/gtv.json";
import project from "./pages/project.json";
//Routes
import routes from "./routes.json";
import yup from "./yup.json";

export default {
  yup,
  home,
  font,
  saga,
  backoffice,
  profile,
  routes,
  reconnection,
  authentication,
  company,
  components,
  common,
  gtv,
  onBoarding,
  project
};
