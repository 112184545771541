import React, { forwardRef } from "react";
import { BoxProps, Box } from "@material-ui/core";
import { motion, MotionProps } from "framer-motion";

export type BoxAnimatedProps = BoxProps & MotionProps;

export const BoxAnimated: React.FC<BoxAnimatedProps> = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <Box {...props} clone>
        <motion.div ref={ref as any}>{children}</motion.div>
      </Box>
    );
  }
);
