import { AssetMeta } from "../../entities/asset-meta";
import { Asset } from "../../entities/asset";
import { ActivationCode } from "../activation-code/entity";
import { schema } from "normalizr";
import { EntityId } from "@reduxjs/toolkit";

export class VideoGrouped {
  owned!: Video[];
  companies!: Video[];
  shared!: Video[];
  catalogue!: Video[];
}

export class Video extends Asset {
  activationCodes!: ActivationCode[];
  legacyId?: string;
  name!: string;
  format!: string;
  url!: string;
  size!: number;
  width!: number;
  height!: number;
  codec?: string;
  startTime!: number;
  durationTs!: number;
  duration!: number;
  bitRate!: number;
  maxBitRate!: number;
  nbFrames!: number;
  fps!: number;
  nbStream!: number;
  meta!: AssetMeta;
  createdAt!: Date;
  updatedAt!: Date;
  streams!: VideoStream[];
  asset!: Asset;
  projectId?: string;
}

export class VideoStream {
  id!: string;
  index!: string;
  codec_name?: string;
  codec_long_name?: string;
  profile?: string;
  codec_type?: string;
  codec_time_base?: string;
  codec_tag_string?: string;
  codec_tag?: string;
  width?: string;
  height?: string;
  coded_width?: string;
  coded_height?: string;
  has_b_frames?: string;
  sample_aspect_ratio?: string;
  display_aspect_ratio?: string;
  pix_fmt?: string;
  level?: string;
  color_range?: string;
  color_space?: string;
  color_transfer?: string;
  color_primaries?: string;
  chroma_location?: string;
  field_order?: string;
  timecode?: string;
  refs?: string;
  r_frame_rate?: string;
  avg_frame_rate?: string;
  time_base?: string;
  start_pts?: string;
  start_time?: string;
  duration_ts?: string;
  duration?: string;
  bit_rate?: string;
  max_bit_rate?: string;
  bits_per_raw_sample?: string;
  nb_frames?: string;
  nb_read_frames?: string;
  nb_read_packets?: string;
  sample_fmt?: string;
  sample_rate?: string;
  channels?: string;
  channel_layout?: string;
  bits_per_sample?: string;
  disposition?: any;
  tags?: AssetMeta;
  videos!: Video[];
}

export interface VideoCreateDTO {}

export interface VideoUpdateDTO {
  id: string;
}

export interface VideoNormalized {
  videos: Record<EntityId, Video>;
  activationCodes: Record<EntityId, ActivationCode>;
}
export const VideoEntity = new schema.Entity<Video>("videos", {
  activationCodes: [new schema.Entity<ActivationCode[]>("activationCodes")]
});
