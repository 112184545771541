import { Box, Grid } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined";
import LockCloseIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { Controller } from "react-hook-form";
import { MyIconButton } from "../MyIconButton";

interface MyLockFieldProps {
  name: string;
  lockProperty: string;
  defaultValue?: unknown;
}

export const MyLockField: React.FC<MyLockFieldProps> = ({
  name,
  defaultValue,
  children,
  lockProperty
}) => {
  const getLockProperties = (value: string[]) => {
    const lockFound = (value || []).find(lock => lock === lockProperty);
    return lockFound
      ? (value || []).filter(lock => lock !== lockProperty)
      : [...(value || []), lockProperty];
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value }) => (
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <Box clone mr={1}>
              <MyIconButton
                tabIndex={-1}
                onClick={() => {
                  onChange(getLockProperties(value));
                  onBlur();
                }}
                size="small"
              >
                {((value as string[]) || []).includes(lockProperty) ? (
                  <LockCloseIcon color="primary" />
                ) : (
                  <LockOpenIcon color="action" />
                )}
              </MyIconButton>
            </Box>
          </Grid>
          <Box clone width="100%">
            <Grid item>{children}</Grid>
          </Box>
        </Grid>
      )}
    />
  );
};
