import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { VideoEffect } from "./entity";
import * as Api from "./api";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { VideoEffectActionsTypes } from "./action";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export interface VideoEffectState extends EntityState<VideoEffect> {
  readStatus: FetchingStatus;
}

export const VideoEffectInitialState: VideoEffectState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL
};

export const VideoEffectAdapter = createEntityAdapter<VideoEffect>();

const VideoEffectAdapterState = VideoEffectAdapter.getInitialState(
  VideoEffectInitialState
);

export const read = createMyAsyncThunk(
  VideoEffectActionsTypes.VIDEO_EFFECT_READ,
  Api.read
);

const VideoEffectSlice = createMySlice({
  name: "video-effects",
  initialState: VideoEffectAdapterState,
  adapter: VideoEffectAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: VideoEffectAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const VideoEffectReducer = VideoEffectSlice.reducer;
export const VideoEffectActions = { ...VideoEffectSlice.actions, read };
