import { JsonTranslator } from "../../utils/function/jsonTranslator";
import { VideoTransitionEffect } from "../../entities/video-effect-transition";
import { Story } from "../../entities/story";

export enum VideoEffectNames {
  FADE_IN = "FADE_IN",
  FADE_OUT = "FADE_OUT"
}

export enum VideoEffectType {
  IN = "IN",
  OUT = "OUT"
}

export interface VideoEffect {
  filter(arg0: (n: any) => any): any;
  id: string;
  name: VideoEffectNames;
  label: JsonTranslator;
  description?: JsonTranslator;
  videoTransitionStartEffect?: VideoTransitionEffect;
  videoTransitionPreviousEndEffect?: VideoTransitionEffect;
  storyTransitionStart?: Story;
  storyTransitionEnd?: Story;
}

export interface VideoEffectCreateDTO {}

export interface VideoEffectUpdateDTO {
  id: string;
}
