import {
  Avatar,
  createStyles,
  Hidden,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Theme,
  Typography
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BackMySpaceIcon from "@material-ui/icons/KeyboardBackspace";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DefaultAvatar from "../../../../assets/avatars/default_avatar.png";
import { AuthenticationActions } from "../../../../reducers/authentication/reducer";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { MyButton } from "../../MyButton";
import { Language } from "./Language";
export interface UserMenuProps {
  menus?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      border: `2px solid ${theme.palette.common.white}`,
      width: 80,
      height: 80,
      [theme.breakpoints.down("sm")]: {
        width: 60,
        height: 60
      }
    }
  })
);

export const UserMenu: React.FC<UserMenuProps> = () => {
  const classes = useStyles();
  const user = useSelector(getCurrentUser);
  const { t } = useTranslation("company");
  const { t: tComponent } = useTranslation("components");
  const history = useHistory();
  const dispatch = useDispatch();
  const userName =
    user.profile.firstname && user.profile.lastname
      ? `${user.profile.firstname} ${user.profile.lastname}`
      : user.email;
  return (
    <MyButton
      leftIcon={
        <Avatar
          alt="avatar"
          src={(user.profile && user.profile.avatarUrl) || DefaultAvatar}
          className={classes.avatar}
        />
      }
      color="inherit"
      menus={() => (
        <MenuList>
          <div>
            <Hidden smUp>
              <MenuItem>
                <Language />
              </MenuItem>
            </Hidden>
          </div>
          <MenuItem onClick={() => history.push("/my-space")}>
            <ListItemIcon>
              <BackMySpaceIcon />
            </ListItemIcon>
            <ListItemText primary={t("menus.back-to-myspace")} />
          </MenuItem>
          <MenuItem onClick={() => dispatch(AuthenticationActions.logOut())}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={tComponent("my-topbar.logout")} />
          </MenuItem>
        </MenuList>
      )}
    >
      <Hidden smDown>
        <Typography>{userName}</Typography>
      </Hidden>
    </MyButton>
  );
};
