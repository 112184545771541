import { Image } from "../reducers/images/entity";
import { Company } from "../reducers/companies/entity";

export enum WatermarkPosition {
  TOP_RIGHT = "TOP_RIGHT",
  TOP_LEFT = "TOP_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT"
}

export class Watermark {
  id!: string;
  position!: WatermarkPosition;
  companyId!: string;
  company?: Company;
  imageId!: string;
  image!: Image;
}
