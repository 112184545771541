import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { ImageItem } from "../../../components/common/ImageItem";
import { Page } from "../../../components/common/MyPage";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { Image } from "../../../reducers/images/entity";
import { ImageSelector } from "../../../reducers/images/selector";
import { UserActions } from "../../../reducers/users/reducer";
import { UserSelector } from "../../../reducers/users/selector";

export const MySpaceImageList = () => {
  const { t } = useTranslation("common");
  const currentUser = useSelector(getCurrentUser);
  const { readImagesStatus } = useSelector(UserSelector.getState);
  const { owned, activationCode } = useSelector(
    ImageSelector.getUserImagesByGroup(currentUser)
  );

  return (
    <DynamicElement<Image[]>
      actions={{
        action: UserActions.readImages(currentUser),
        status: readImagesStatus
      }}
      data={[...owned, ...activationCode]}
    >
      {() => (
        <Page>
          {owned.length > 0 && (
            <Typography variant="subtitle1">{t("owned")}</Typography>
          )}
          <Box display="flex" flexWrap="wrap">
            {owned.map(image => (
              <ImageItem maxWidth={400} m={1} key={image.id} image={image} />
            ))}
          </Box>
          {activationCode.length > 0 && (
            <Typography variant="subtitle1">
              {t("from-activation-code")}
            </Typography>
          )}
          <Box display="flex" flexWrap="wrap">
            {activationCode.map(image => (
              <ImageItem maxWidth={400} m={1} key={image.id} image={image} />
            ))}
          </Box>
        </Page>
      )}
    </DynamicElement>
  );
};
