import { PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";
import { OptionsObject, SnackbarMessage, VariantType } from "notistack";
import { Languages } from "../../utils/function/jsonTranslator";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { AppActionTypes } from "./action";
import * as Api from "./api";

export interface AppState {
  theme: "default";
  isMobile: boolean;
  notifications: {
    message: SnackbarMessage;
    options?: OptionsObject;
    key?: number;
    dismissed?: boolean;
  }[];
  language: Languages;
  openMenu: boolean;
  isOnBackOffice: boolean;
  isOnBoarding: boolean;
  useLegacy: boolean;
  setUseLegacyStatus: FetchingStatus;
  getUseLegacyStatus: FetchingStatus;
}

export const AppInitialState: AppState = {
  theme: "default",
  isMobile: false,
  notifications: [],
  language: Languages.FR,
  openMenu: false,
  isOnBackOffice: false,
  isOnBoarding: false,
  useLegacy: true,
  setUseLegacyStatus: FetchingStatus.NULL,
  getUseLegacyStatus: FetchingStatus.NULL
};

export const getUseLegacy = createMyAsyncThunk<{ useLegacy: boolean }, void>(
  AppActionTypes.GET_USE_LEGACY_FETCH,
  Api.getUseLegacy
);

export const setUseLegacy = createMyAsyncThunk<{ useLegacy: boolean }, boolean>(
  AppActionTypes.SET_USE_LEGACY_FETCH,
  Api.setUseLegacy
);

const AppSlice = createMySlice({
  name: "app",
  initialState: AppInitialState,
  asyncActions: [
    {
      action: getUseLegacy,
      statusName: "getUseLegacyStatus",
      onSuccess: (state, action) => {
        state.useLegacy = action.payload.useLegacy;
      }
    },
    {
      action: setUseLegacy,
      statusName: "setUseLegacyStatus",
      onSuccess: (state, action) => {
        state.useLegacy = action.payload.useLegacy;
      }
    }
  ],
  reducers: {
    setLanguage: (state, action: PayloadAction<Languages>) => {
      state.language = action.payload;
      i18next.changeLanguage(action.payload);
    },
    openMenu: (state, action: PayloadAction<boolean>) => {
      state.openMenu = action.payload;
    },
    isOnBackOffice: (state, action: PayloadAction<boolean>) => {
      state.isOnBackOffice = action.payload;
    },
    isOnBoarding: (state, action: PayloadAction<boolean>) => {
      state.isOnBoarding = action.payload;
    },
    enqueueSnacbar: (
      state,
      action: PayloadAction<{
        message: string | React.ReactNode;
        variant: VariantType;
        options?: OptionsObject;
      }>
    ) => {
      const notification = {
        message: action.payload.message,
        options: {
          variant: action.payload.variant,
          ...action.payload.options
        },
        key: new Date().getTime() + Math.random()
      };
      state.notifications.push(notification);
    },
    closeSnackbar: (state, action: PayloadAction<string>) => {
      const dismiss = !action.payload;
      state.notifications = state.notifications.map(notification =>
        dismiss || notification.key === (action.payload as any)
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(
        not => not.key !== action.payload
      );
    }
  }
});

export const AppReducer = AppSlice.reducer;
export const AppActions = { ...AppSlice.actions, getUseLegacy, setUseLegacy };

//       case AppActionTypes.ENQUEUE_SNACKBAR:
//         const notification = {
//           message: action.message,
//           options: {
//             variant: action.variant,
//             ...action.options
//           },
//           key: new Date().getTime() + Math.random()
//         };
//         return {
//           ...state,
//           notifications: [...state.notifications, notification]
//         };

//       case AppActionTypes.CLOSE_SNACKBAR:
//         const dismiss = !action.key;
//         return {
//           ...state,
//           notifications: state.notifications.map(notification =>
//             dismiss || notification.key === (action.key as any)
//               ? { ...notification, dismissed: true }
//               : { ...notification }
//           )
//         };

//       case AppActionTypes.REMOVE_SNACKBAR:
//         return {
//           ...state,
//           notifications: state.notifications.filter(
//             not => not.key !== (action.key as any)
//           )
//         };
