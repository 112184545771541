import {
  Avatar,
  Box,
  ButtonBase,
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  useTheme
} from "@material-ui/core";
import ProfilIcon from "@material-ui/icons/AccountCircleOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import SubscriptionIcon from "@material-ui/icons/CardMembershipOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
//import FontIcon from "@material-ui/icons/FontDownloadOutlined";
//import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
//import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
//import AboutIcon from "@material-ui/icons/InfoOutlined";
//import MovieIcon from "@material-ui/icons/MovieOutlined";
//import MusicIcon from "@material-ui/icons/MusicNote";
//import PaletteIcon from "@material-ui/icons/PaletteOutlined";
//import ImageIcon from "@material-ui/icons/PanoramaOutlined";
//import RushIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
//import ScenarioIcon from "@material-ui/icons/TheatersOutlined";
import { makeStyles } from "@material-ui/styles";
//import { mdiHandshake } from "@mdi/js";
//import Icon from "@mdi/react";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../../assets/avatars/default_avatar.png";
import { ArrowTooltip } from "../../../components/common/MyTooltip";
import { AppActions } from "../../../reducers/app/reducer";
// import { getLanguage } from "../../../reducers/app/selector";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { SubscriptionNames } from "../../../utils/constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    avatarBox: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      position: "relative",
      overflow: "hidden"
    },
    avatarBoxBackground: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    avatar: {
      border: `6px solid ${theme.palette.common.white}`,
      width: 80,
      height: 80,
      [theme.breakpoints.down("sm")]: {
        width: 60,
        height: 60
      }
    },
    menuActive: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    menuSubscriptionNeeded: {
      color: theme.palette.action.disabled
    },
    nestedMenu: {
      paddingLeft: theme.spacing(4)
    }
  })
);
export interface DrawerMenuProps {
  icon: React.ReactElement;
  label: string;
  path?: string;
  inProgress?: boolean;
  needValidSubscription?: boolean;
  requiredPremiumSubscription?: boolean;
  children?: DrawerMenuProps[];
}
export interface MenusProps {}

export const MySpaceMenus: React.FC<MenusProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation("backoffice");
  const { url } = useRouteMatch();
  const user = useSelector(getCurrentUser);
  const [collapseMenu, setCollapseMenu] = useState<{
    [name: string]: boolean;
  }>({});
  const theme = useTheme();
  // const lang = useSelector(getLanguage);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menus: DrawerMenuProps[] = [
    // {
    //   icon: <HomeOutlinedIcon />,
    //   label: t("menus.home"),
    //   path: url,
    //   inProgress: true
    // },
    /*{
      icon: <MovieIcon />,
      label: t("menus.onboarding"),
      path: `/on-boarding`
    },*/
    {
      icon: <ProfilIcon />,
      label: t("menus.profil"),
      path: `${url}/profile`
    },
    //{
    //  icon: <FontIcon />,
    //  label: t("menus.font"),
    //  path: `${url}/fonts`
    //},
    // {
    //   icon: <PaletteIcon />,
    //   label: t("menus.assets"),
    //   path: `${url}/assets`,
    //   children: [
    //     {
    //       icon: <MovieIcon />,
    //       label: t("menus.videos"),
    //       path: `${url}/assets/videos`
    //     },
    //     {
    //       icon: <MusicIcon />,
    //       label: t("menus.musics"),
    //       path: `${url}/assets/musics`
    //     },
    //     {
    //       icon: <ImageIcon />,
    //       label: t("menus.images"),
    //       path: `${url}/assets/images`
    //     }
    //   ]
    // },
    {
      icon: <BusinessIcon />,
      label: t("menus.my-companies"),
      path: `${url}/my-companies`,
      needValidSubscription: true,
      requiredPremiumSubscription: true
    }
    //{
    //  icon: (
    //    <Icon path={mdiHandshake} size={1} fill={theme.palette.primary.main} />
    //  ),
    //  label: t("menus.collaborations"),
    //  path: `${url}/my-collaborations`,
    //  needValidSubscription: true
    //}
    // {
    //   icon: <ScenarioIcon fontSize="default" />,
    //   label: t("menus.scenarios"),
    //   path: `${url}/scenarios`,
    //   needValidSubscription: true
    // },
    //{
    //  icon: <SubscriptionIcon />,
    //  label: t("menus.subscription"),
    //  path: `${url}/subscription`
    //}
    // {
    //   icon: <RushIcon />,
    //   label: t("menus.rush"),
    //   path: `${url}/rush`,
    //   inProgress: true
    // },
    // {
    //   icon: <HelpIcon />,
    //   label: t("menus.help"),
    //   path: `${url}/help`,
    //   inProgress: true
    // },
    // {
    //   icon: <AboutIcon />,
    //   label: t("menus.about"),
    //   path: `${url}/about`,
    //   inProgress: true
    // }
  ];

  const onMenuClick = (path: string) => () => {
    history.push(path);
    dispatch(AppActions.openMenu(false));
  };

  const onCollapseMenuClick = (name: string) =>
    setCollapseMenu({ ...collapseMenu, [name]: !collapseMenu[name] });

  const generateMenus = (menus: DrawerMenuProps[], isNested = false) => {
    return menus.map((menu: DrawerMenuProps) => {
      const isActive = menu.path === location.pathname;

      const hasNoOrExpiredSubscription =
        menu.needValidSubscription &&
        (!user.userSubscription || !user.hasValidSubscription);

      const hasNotAPremiumSubscription =
        menu.requiredPremiumSubscription &&
        user.subscriptionName !== SubscriptionNames.PREMIUM;

      const hasInvalidSubscription =
        hasNoOrExpiredSubscription || hasNotAPremiumSubscription;

      if (menu.children) {
        return (
          <React.Fragment key={menu.path}>
            <ListItem button onClick={() => onCollapseMenuClick(menu.label)}>
              <ListItemIcon>
                {React.cloneElement(menu.icon, {
                  color: "primary"
                })}
              </ListItemIcon>
              <ListItemText primary={menu.label} />
              {collapseMenu[menu.label] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={collapseMenu[menu.label]}
              timeout="auto"
              unmountOnExit
            >
              <List>{generateMenus(menu.children, true)}</List>
            </Collapse>
          </React.Fragment>
        );
      }
      return (
        <ArrowTooltip
          key={menu.path}
          disableHoverListener={!hasInvalidSubscription}
          title={
            hasNotAPremiumSubscription ? (
              <Typography>{t("menus.subscription-required")}</Typography>
            ) : menu.needValidSubscription ? (
              <Typography>{t("menus.subscription-valid")}</Typography>
            ) : (
              false
            )
          }
          placement="right"
        >
          <ListItem
            button
            onClick={onMenuClick(
              hasInvalidSubscription
                ? "/my-space/subscription"
                : (menu.path as string)
            )}
            className={clsx({
              [classes.menuActive]: isActive,
              [classes.menuSubscriptionNeeded]: hasInvalidSubscription,
              [classes.nestedMenu]: isNested
            })}
          >
            <ListItemIcon>
              {React.cloneElement(menu.icon, {
                color: hasInvalidSubscription ? "disabled" : "primary",
                fill: hasInvalidSubscription
                  ? theme.palette.action.disabled
                  : theme.palette.primary.main
              })}
            </ListItemIcon>
            <ListItemText primary={menu.label} />
            {hasInvalidSubscription && (
              <SubscriptionIcon style={{ color: theme.palette.success.main }} />
            )}
            {menu.inProgress && (
              <ListItemSecondaryAction>
                <Box fontStyle="italic">
                  <Typography variant="caption">
                    {t("menus.in-progress")}
                  </Typography>
                </Box>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </ArrowTooltip>
      );
    });
  };

  return (
    <>
      <Box clone p={3} display="block">
        <ButtonBase
          onClick={() => history.push("/my-space/profile")}
          className={classes.avatarBox}
        >
          <Avatar
            alt="avatar"
            src={(user.profile && user.profile.avatarUrl) || DefaultAvatar}
            className={classes.avatar}
          />
          {user.profile && (
            <>
              <Typography align="left">
                <b>
                  {user.profile.lastname} {user.profile.firstname}
                </b>
              </Typography>
              <Typography variant="body2" align="left">
                {user.email}
              </Typography>
            </>
          )}
          {/* {user.roles && user.roles.length && (
          <Typography align="center">
            {jsonTranslator(user.roles[0].label, lang)}
          </Typography>
        )} */}
        </ButtonBase>
      </Box>

      <List>{generateMenus(menus)}</List>
    </>
  );
};
