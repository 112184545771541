import { Box, CircularProgress, Theme } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { MyMenu } from "./MyMenu";

export interface MyButtonProps extends ButtonProps {
  boxProps?: BoxProps;
  to?: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  loading?: boolean;
  capitalize?: boolean;
  menus?: (props: { onClose: Function }) => ReactNode;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none"
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  })
);

export const MyButton: React.FC<MyButtonProps> = ({
  to,
  capitalize,
  rightIcon,
  leftIcon,
  children,
  style,
  loading,
  menus,
  boxProps,
  ...rest
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const buttonStyle: React.CSSProperties = {
    ...style,
    textTransform: capitalize ? "uppercase" : "none",
    marginRight: 5
  };

  let button = (
    <Box clone {...boxProps}>
      <Button
        style={buttonStyle}
        {...rest}
        disabled={rest.disabled || loading}
        onClick={e =>
          to ? dispatch(push(to)) : rest.onClick ? rest.onClick(e) : null
        }
      >
        {leftIcon && (
          <Box mr={1}>
            {(props: BoxProps) => React.cloneElement(leftIcon, props)}
          </Box>
        )}
        {children}
        {rightIcon && (
          <Box ml={1}>
            {(props: BoxProps) => React.cloneElement(rightIcon, props)}
          </Box>
        )}
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>
    </Box>
  );
  if (menus) {
    button = (
      <MyMenu component={button} id="button-menu">
        {menus}
      </MyMenu>
    );
  }
  return button;
};
