import { InputLabel } from "@material-ui/core";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { FormHelperTextProps } from "@material-ui/core/FormHelperText";
import FormLabel, { FormLabelProps } from "@material-ui/core/FormLabel";
import { InputLabelProps } from "@material-ui/core/InputLabel";
import React from "react";
import { MyFormHelperText } from "./MyFormHelperText";

export interface FormProps {
  label: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  formLabelProps?: InputLabelProps | FormLabelProps;
  formHelperTextProps?: FormHelperTextProps;
}

export interface MyFormControlProps extends FormProps, FormControlProps {
  labelRef?: React.RefObject<HTMLLabelElement>;
  labelWidth?: number;
  disableLabel?: boolean;
  isAnInputLabel?: boolean;
  errorField?: any;
}

export const MyFormControl: React.FC<MyFormControlProps> = ({
  label,
  helperText,
  labelRef,
  children,
  disableLabel,
  isAnInputLabel,
  formLabelProps,
  formHelperTextProps,
  errorField,
  hidden,
  lang,
  ...formControlProps
}) => {
  return (
    <FormControl
      fullWidth={true}
      margin="dense"
      {...formControlProps}
      style={{
        display: hidden ? "none" : "inline-flex",
        ...formControlProps.style
      }}
    >
      {!disableLabel ? (
        isAnInputLabel ? (
          <InputLabel error={!!errorField} ref={labelRef} {...formLabelProps}>
            {label}
          </InputLabel>
        ) : (
          <FormLabel error={!!errorField} {...formLabelProps}>
            {label}
          </FormLabel>
        )
      ) : null}
      {children}
      <MyFormHelperText errorField={errorField} {...formHelperTextProps}>
        {helperText}
      </MyFormHelperText>
    </FormControl>
  );
};
