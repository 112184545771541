import { yupResolver } from "@hookform/resolvers";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid
} from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyButton } from "../../../../components/common/MyButton";
import { MyDialog } from "../../../../components/common/MyDialog";
import { MyForm } from "../../../../components/common/MyForm2";
import { MyTextField } from "../../../../components/common/MyForm2/MyTextField";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { CompanyCreateDTO } from "../../../../reducers/companies/entity";
import { UserSelector } from "../../../../reducers/users/selector";
import { FetchingStatus } from "../../../../utils/reducers/fetchingStatus";
import { createCompanySchema } from "../schema/create-company.schema";
import { UserActions } from "../../../../reducers/users/reducer";
export interface CreateCompanyProps extends DialogProps {}

export const CreateCompany: React.FC<CreateCompanyProps> = ({ ...props }) => {
  const { t } = useTranslation("backoffice");
  const dispatch = useDispatch();
  const { createCompanyStatus } = useSelector(UserSelector.getState);
  const user = useSelector(getCurrentUser);
  const onSubmit = (company: CompanyCreateDTO) => {
    dispatch(UserActions.createCompany({ user, company }));
  };

  const methods = useForm({ resolver: yupResolver(createCompanySchema) });
  return (
    <MyDialog {...props}>
      <MyForm methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t("my-companies.create-company")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("my-companies.form.tips")}</DialogContentText>

          <MyTextField name="name" label={t("my-companies.form.name")} />
          <Grid container>
            <Grid item>
              <MyTextField
                name="setting.primaryColor"
                type="color"
                label={t("my-companies.form.primaryColor")}
              />
            </Grid>
            <Grid item>
              <MyTextField
                name="setting.secondaryColor"
                type="color"
                label={t("my-companies.form.secondaryColor")}
              />
            </Grid>
            <Grid item>
              <MyTextField
                name="setting.tertiaryColor"
                type="color"
                label={t("my-companies.form.tertiaryColor")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MyButton
            loading={createCompanyStatus === FetchingStatus.PENDING}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {t("my-companies.create-company")}
          </MyButton>
        </DialogActions>
      </MyForm>
    </MyDialog>
  );
};
