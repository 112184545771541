import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  TypographyProps,
  ToolbarProps,
  AppBarProps
} from "@material-ui/core";
import { MySearchInput, MySearchInputProps } from "../MySearchInput";

export interface MyGalleryAppBarProps {
  title?: string;
  titleProps?: TypographyProps;
  mySearchInputProps?: MySearchInputProps;
  toolbarProps?: Partial<ToolbarProps>;
  appbarProps?: Partial<AppBarProps>;
}

export const MyGalleryAppBar: React.FC<MyGalleryAppBarProps> = ({
  title,
  mySearchInputProps,
  titleProps,
  appbarProps,
  toolbarProps
}) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <AppBar
      position="fixed"
      color="inherit"
      variant="elevation"
      {...appbarProps}
    >
      <Toolbar variant="dense" style={{ minHeight: 46 }} {...toolbarProps}>
        <Typography
          {...titleProps}
          style={{ whiteSpace: "nowrap", flexGrow: onMobile ? 1 : 0 }}
        >
          {title || ""}
        </Typography>
        {mySearchInputProps && (
          <MySearchInput
            widthEffect={onMobile ? 100 : 400}
            disabledWidthEffect={!onMobile}
            {...mySearchInputProps}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};
