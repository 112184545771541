import {
  Action,
  configureStore,
  ThunkAction,
  ThunkDispatch
} from "@reduxjs/toolkit";
import { routerMiddleware, RouterState } from "connected-react-router";
import { createBrowserHistory, History } from "history";
import { useDispatch } from "react-redux";
import { AnyAction, Middleware } from "redux";
import { actionToPlainObject } from "../utils/middlewares/action_object";
import { RootReducer } from "./";
import { ActivationCodeState } from "./activation-code/reducer";
import { AppState } from "./app/reducer";
import { AppBarState } from "./appbar/reducer";
import { AudioState } from "./audios/reducer";
import { AuthenticationState } from "./authentication/reducer";
import { CompanyState } from "./companies/reducer";
import { DomainState } from "./domains/reducer";
import { FontConfigState } from "./fonts-config/reducer";
import { FontState } from "./fonts/reducer";
import { GtvState } from "./gtv/reducer";
import { ImageState } from "./images/reducer";
import { ItemState } from "./items/reducer";
import { OnBoardingState } from "./on-boarding/reducer";
import { ProjectState } from "./projects/reducer";
import { RoleState } from "./roles/reducer";
import { ScenarioCompanySharedState } from "./scenarios-companies-shared/reducer";
import { ScenarioUserSharedState } from "./scenarios-users-shared/reducer";
import { ScenarioState } from "./scenarios/reducer";
import { ShotCategoryState } from "./shot-category/reducer";
import { UserState } from "./users/reducer";
import { VideoEffectState } from "./video-effect/reducer";
import { VideoState } from "./videos/reducer";

export const history = createBrowserHistory();

export const middlewares = (initialHistory?: History): Middleware[] => [
  routerMiddleware(initialHistory || history),
  actionToPlainObject
];

// Inject state from any source with this global window variable
const preloadedState = (window as any).__PRELOADED_STATE__;
delete (window as any).__PRELOADED_STATE__;

export const createMyStore = (initialStore?: any, initialHistory?: History) => {
  // let createdStore;
  // process.env.NODE_ENV === "production"
  //   ? (createdStore = createStore(
  //       RootReducer(initialHistory || history),
  //       { ...preloadedState, ...initialStore },
  //       composeOnProd(applyMiddlewares(initialHistory))
  //     ))
  //   : (createdStore = createStore(
  //       RootReducer(initialHistory || history),
  //       { ...preloadedState, ...initialStore },
  //       composeOnDev(applyMiddlewares(initialHistory))
  //     ));
  // return createdStore;
  return configureStore({
    reducer: RootReducer(initialHistory || history),
    preloadedState: { ...preloadedState, ...initialStore },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().prepend(middlewares(initialHistory))
  });
};

export const store = createMyStore(preloadedState);
/* export type RootState = ReturnType<typeof RootReducer>; */
export type AppDispatch = ThunkDispatch<RootState, null, AnyAction>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = {
  router: RouterState;
  items: ItemState;
  app: AppState;
  appbar: AppBarState;
  authentication: AuthenticationState;
  users: UserState;
  roles: RoleState;
  fonts: FontState;
  fontConfigs: FontConfigState;
  companies: CompanyState;
  scenarios: ScenarioState;
  scenarioUserShared: ScenarioUserSharedState;
  scenarioCompanyShared: ScenarioCompanySharedState;
  activationCodes: ActivationCodeState;
  images: ImageState;
  videos: VideoState;
  audios: AudioState;
  videoEffects: VideoEffectState;
  shotCategories: ShotCategoryState;
  onBoarding: OnBoardingState;
  gtv: GtvState;
  projects: ProjectState;
  domains: DomainState;
};
