import { Box, BoxProps, Typography, TypographyProps } from "@material-ui/core";
import React, { ElementType, ReactNode } from "react";

export interface MyTypographyProps extends TypographyProps {
  component?: ElementType;
  boxProps?: BoxProps;
  leftIcon?: ReactNode;
  IconBoxProps?: BoxProps;
}

export const MyTypography: React.FC<MyTypographyProps> = ({
  boxProps,
  leftIcon,
  IconBoxProps,
  ...rest
}) => {
  let typography = <Typography {...rest} />;
  if (leftIcon) {
    typography = (
      <Box display="flex" alignItems="center" {...boxProps}>
        <Box mr={2} lineHeight="12px" {...IconBoxProps}>
          {leftIcon}
        </Box>
        <Box>{typography}</Box>
      </Box>
    );
  }
  return typography;
};
