import {
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { VideoItem, VideoItemProps } from "./VideoItem";
import { Video } from "../../reducers/videos/entity";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 240,
      position: "relative"
    },
    label: {
      background: `rgba(0,0,0,0.7)`,
      borderRadius: 4,
      color: "white",
      position: "absolute",
      bottom: 0,
      lineHeight: "24px",
      height: 24,
      width: "100%"
    },
    labelSelected: {
      background: theme.palette.success.main
    },
    selected: {
      borderLeft: `4px solid ${theme.palette.success.main}`,
      borderRight: `4px solid ${theme.palette.success.main}`,
      borderTop: `4px solid ${theme.palette.success.main}`
    },
    pngAudios: {
      // backgroundColor: lightGreen[400]
    }
  })
);

export interface MyVideoProps {
  video: Video;
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  height?: React.CSSProperties["height"];
  width?: React.CSSProperties["width"];
  videoItemProps?: VideoItemProps;
  disableLabel?: boolean;
}
export const MyVideo: React.FC<MyVideoProps> = ({
  video,
  height,
  width,
  isSelected,
  style,
  className,
  onClick,
  disableLabel,
  videoItemProps
}) => {
  const classes = useStyles();
  return (
    <Card
      onClick={onClick}
      style={{ height, width, ...style }}
      className={clsx(classes.root, className, {
        [classes.selected]: isSelected
      })}
    >
      <VideoItem
        height="100%"
        width="100%"
        preload="none"
        controls
        src={`${video.url}#t0.5s`}
        {...videoItemProps}
      />

      {!disableLabel && (
        <Typography
          align="center"
          className={clsx(classes.label, {
            [classes.labelSelected]: isSelected
          })}
        >
          {video.name}
        </Typography>
      )}
    </Card>
  );
};
