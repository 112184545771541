import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CompanyActions } from "../companies/reducer";
import { ScenarioCompanyShared } from "../scenarios/entity";
import { ScenarioActions } from "../scenarios/reducer";
import { ScenarioCompanySharedTypes } from "./action";
import * as Api from "./api";

export const ScenarioCompanySharedAdapter = createEntityAdapter<
  ScenarioCompanyShared
>();

export interface ScenarioCompanySharedState
  extends EntityState<ScenarioCompanyShared> {
  createOneStatus: FetchingStatus;
}

export const ScenarioCompanySharedInitialState: ScenarioCompanySharedState = {
  entities: {},
  ids: [],
  createOneStatus: FetchingStatus.NULL
};

const ScenarioCompanySharedState = ScenarioCompanySharedAdapter.getInitialState(
  ScenarioCompanySharedInitialState
);

export const createOne = createMyAsyncThunk(
  ScenarioCompanySharedTypes.CREATE_ONE,
  Api.createOne,
  {
    onFailedMessage: "saga:scenario-company-shared.create-one-failed",
    onSuccessMessage: "saga:scenario-company-shared.create-one-success",
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const { company, scenario, ...rest } = result;
      if (company) dispatch(CompanyActions.addOne(company));
      if (scenario) dispatch(ScenarioActions.addOne(scenario));
      return rest;
    }
  }
);

const ScenarioCompanySharedSlice = createMySlice({
  name: "scenario-company-share",
  initialState: ScenarioCompanySharedState,
  adapter: ScenarioCompanySharedAdapter,
  asyncActions: [
    {
      action: createOne,
      statusName: "createOneStatus",
      onSuccess: ScenarioCompanySharedAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const ScenarioCompanySharedReducer = ScenarioCompanySharedSlice.reducer;
export const ScenarioCompanySharedActions = {
  createOne,
  ...ScenarioCompanySharedSlice.actions
};
