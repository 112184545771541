import {
  AppBar,
  createStyles,
  Drawer,
  makeStyles,
  Theme,
  Toolbar
} from "@material-ui/core";
import LeftArrowIcon from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { MyGalleryScenario } from "../../../components/common/gallery/MyGalleryScenario";
import { MyButton } from "../../../components/common/MyButton";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { Page } from "../../../components/common/MyPage";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { Scenario } from "../../../reducers/scenarios/entity";
import { ScenarioActions } from "../../../reducers/scenarios/reducer";
import { ScenarioSelector } from "../../../reducers/scenarios/selector";
import { UserActions } from "../../../reducers/users/reducer";
import { UserSelector } from "../../../reducers/users/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { ScenarioDetail } from "../../Scenario/ScenarioDetail";

interface ScenarioPageProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appbar: {
      background: "transparent"
    },
    drawer: {
      maxWidth: theme.breakpoints.values["md"],
      width: "100%"
    }
  })
);

export const ScenarioPage: React.FC<ScenarioPageProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("backoffice");
  const user = useSelector(getCurrentUser);
  const { readScenarioStatus } = useSelector(UserSelector.getState);
  // const readScenarioPremiumStatus = useSelector(getScenariosPremiumStatus);
  const scenariosPremium = useSelector(ScenarioSelector.getScenariosPremium);
  const scenariosPublic = useSelector(ScenarioSelector.getScenariosPublic);
  const userScenarios = useSelector(ScenarioSelector.getUserScenarios(user));
  const userSharedScenarios = useSelector(
    ScenarioSelector.getUserSharedScenarios(user)
  );
  const userActivationCodeScenarios = useSelector(
    ScenarioSelector.getUserActivationCodeScenarios(user)
  );
  const scenarioSelected = useSelector(ScenarioSelector.getScenarioSelected);
  const [closeCategory, setCloseCategory] = useState<Function>();
  const [openScenarioDetail, setOpenScenarioDetail] = useState<boolean>(false);

  /* useEffect(() => { */
  /*   return () => { */
  /*     dispatch(ScenarioActions.clear()); */
  /*   }; */
  /* }, [dispatch]); */

  const onScenarioSelected = (scenario: Scenario, close: Function) => {
    dispatch(ScenarioActions.selected(scenario));
    setOpenScenarioDetail(true);
    setCloseCategory(() => close);
  };

  const onScenarioMoveToCompany = () => {
    setOpenScenarioDetail(false);
    closeCategory && closeCategory();
    if (scenarioSelected) {
      // TODO refactor it
      dispatch(
        UserActions.shareScenarioToUserCompanies({
          scenario: scenarioSelected,
          user
        })
      );
    }
  };

  return (
    <Page>
      <DynamicElement
        actions={[
          {
            action: UserActions.readScenarios(),
            status: readScenarioStatus
          }
        ]}
        data={[
          ...scenariosPremium,
          ...userScenarios,
          ...userSharedScenarios,
          ...userActivationCodeScenarios,
          ...scenariosPublic
        ]}
        showCondition={
          readScenarioStatus === FetchingStatus.SUCCESS &&
          [
            ...scenariosPremium,
            ...userScenarios,
            ...userActivationCodeScenarios,
            ...userSharedScenarios,
            ...scenariosPublic
          ].length > 0
        }
      >
        {() => (
          <>
            <MyGalleryScenario
              onScenarioSelected={onScenarioSelected}
              own={userScenarios}
              shared={userSharedScenarios}
              activationCode={userActivationCodeScenarios}
              others={[...scenariosPremium, ...scenariosPublic]}
            />
            <Drawer
              classes={{ paper: classes.drawer }}
              open={openScenarioDetail}
              anchor="right"
              variant="temporary"
              onClose={() => setOpenScenarioDetail(false)}
            >
              <AppBar
                position="absolute"
                className={classes.appbar}
                elevation={0}
              >
                <Toolbar variant="dense" disableGutters>
                  <MyIconButton
                    style={{ color: "white", background: "rgba(0,0,0,0.2)" }}
                    onMouseDown={() => setOpenScenarioDetail(false)}
                  >
                    <LeftArrowIcon />
                  </MyIconButton>
                </Toolbar>
              </AppBar>
              {scenarioSelected && (
                <ScenarioDetail
                  id={scenarioSelected.id}
                  actions={
                    <>
                      {/* <MyButton fullWidth color="primary" variant="contained"> */}
                      {/*   {t("scenarios.start-project")} */}
                      {/* </MyButton> */}
                      {userSharedScenarios
                        .map(s => s.id)
                        .includes(scenarioSelected.id) && (
                        <MyButton
                          fullWidth
                          color="secondary"
                          variant="contained"
                          onClick={onScenarioMoveToCompany}
                        >
                          {t("scenarios.share-to-company")}
                        </MyButton>
                      )}
                    </>
                  }
                />
              )}
            </Drawer>
          </>
        )}
      </DynamicElement>
    </Page>
  );
};
