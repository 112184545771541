import merge from "lodash/merge";

export class ReducerMethods {
  static pushUniqueBy = <T>(
    array: Array<T & { id: string }>,
    item: T & { id: string },
    by?: keyof T & "id"
  ): Array<T & { id: string }> => {
    const By = by || "id";
    if (!array.length) {
      return [item];
    }
    const findItem = array.find(a => a[By] === item[By]);
    if (findItem) {
      return array;
    }
    return [...array, item];
  };

  static pushUniqueByMutate = <T>(
    array: Array<T & { id: string }>,
    item: T & { id: string },
    by?: keyof T & "id"
  ) => {
    const By = by || "id";
    if (!array.length) {
      array.push(item);
    }
    const itemIndex = array.findIndex(a => a[By] === item[By]);
    if (itemIndex > -1) {
      return;
    }
    array.push(item);
  };

  static pushArrayUniqueBy = <T>(
    array: Array<T & { id: string }>,
    items: Array<T & { id: string }>,
    by?: keyof T & "id"
  ): Array<T & { id: string }> => {
    const By = by || "id";
    if (array.length === 0) {
      return array.concat(items);
    }
    for (const item of items) {
      const findItem = array.find(a => a[By] === item[By]);
      if (findItem) {
        continue;
      }
      array.push(item);
    }
    return array;
  };

  static pushArrayUniqueByMutate = <T>(
    array: Array<T & { id: string }>,
    items: Array<T & { id: string }>,
    by?: keyof T & "id"
  ) => {
    const By = by || "id";
    if (array.length === 0) {
      array.push(...items);
    }
    for (const item of items) {
      const itemIndex = array.findIndex(a => a[By] === item[By]);
      if (itemIndex > -1) {
        continue;
      }
      array.push(item);
    }
  };

  static removeById = <T>(
    array: Array<T & { id: string }>,
    removedItem: T & { id: string }
  ) => {
    return array.filter(a => a.id !== removedItem.id);
  };

  static removeByIdMutate = <T>(
    array: Array<T & { id: string }>,
    removedItem: T & { id: string }
  ) => {
    const itemIndex = array.findIndex(item => item.id === removedItem.id);
    if (itemIndex > -1) {
      array.splice(itemIndex, 1);
    }
  };

  static updateById = <T>(
    array: Array<T & { id: string }>,
    updatedItem: T & { id: string }
  ): Array<T & { id: string }> => {
    return array.map(item =>
      item.id === updatedItem.id ? merge({}, item, updatedItem) : item
    );
  };

  static updateByIdMutate = <T>(
    array: Array<T & { id: string }>,
    updatedItem: T & { id: string }
  ) => {
    const itemIndex = array.findIndex(item => item.id === updatedItem.id);
    if (itemIndex === -1) {
      array.push(array[itemIndex]);
    } else {
      array.splice(itemIndex, 1, updatedItem);
    }
  };

  static upsertById = <T>(
    array: Array<T & { id: string }>,
    upsertedItem: T & { id: string }
  ): Array<T & { id: string }> => {
    if (!array.find(a => a.id === upsertedItem.id)) {
      return [...array, upsertedItem];
    } else {
      return array.map(item =>
        item.id === upsertedItem.id ? merge(item, upsertedItem) : item
      );
    }
  };

  static upsertByIdMutate = <T extends { id: string }>(
    array: Array<T>,
    upsertedItem: T,
    callback?: (item: T) => T
  ): void => {
    const itemIndex = array.findIndex(a => a.id === upsertedItem.id);
    if (itemIndex === -1) {
      array.push(upsertedItem);
    } else {
      array.splice(
        itemIndex,
        1,
        callback
          ? callback(array[itemIndex])
          : merge(array[itemIndex], upsertedItem)
      );
    }
  };

  static upsertManyByIdMutate = <T extends { id: string }>(
    array: Array<T>,
    upsertedItems: Array<T>,
    callback?: (item: T) => T
  ): void => {
    for (const upsertedItem of upsertedItems) {
      const itemIndex = array.findIndex(a => a.id === upsertedItem.id);
      if (itemIndex === -1) {
        array.push(upsertedItem);
      } else {
        array.splice(
          itemIndex,
          1,
          callback
            ? callback(array[itemIndex])
            : merge(array[itemIndex], upsertedItem)
        );
      }
    }
  };
}
