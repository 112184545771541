import { DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { MyButton } from "../MyButton";
import { MyDialog, MyDialogProps } from "../MyDialog";

export interface MyGalleryFieldDialogProps extends MyDialogProps {
  value: any;
  onCancel: () => void;
}
export const MyGalleryFieldDialog: React.FC<MyGalleryFieldDialogProps> = ({
  children,
  value,
  ...myDialogProps
}) => {
  const { t: tCommon } = useTranslation("common");
  return (
    <MyDialog fullScreen {...myDialogProps}>
      <DialogContent style={{ padding: 0, marginTop: 48 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <MyButton
          variant="outlined"
          onClick={() => {
            myDialogProps.onCancel && myDialogProps.onCancel();
            myDialogProps.onClose && myDialogProps.onClose({}, "backdropClick");
          }}
        >
          {tCommon("cancel")}
        </MyButton>
        {value && (
          <MyButton
            variant="contained"
            color="primary"
            onClick={() => {
              myDialogProps.onClose &&
                myDialogProps.onClose({}, "backdropClick");
            }}
          >
            {tCommon("confirm")}
          </MyButton>
        )}
      </DialogActions>
    </MyDialog>
  );
};
