import { Hidden, MenuItem, MenuList } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { UseLegacy } from "../../../components/common/MyTopBar/components/UseLegacy";
import { Language } from "../../../components/common/MyTopBar/components/Language";
import { Logout } from "../../../components/common/MyTopBar/components/Logout";
import { RoleNames } from "../../../reducers/roles/entity";

export interface MySpaceTopBarMenusProps {
  onClose?: Function;
}

export const MySpaceTopBarMenus: React.FC<MySpaceTopBarMenusProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const showLogout = currentUser.token;

  return (
    <>
      <Hidden smUp>
        <MyIconButton
          data-testid="topbar-menu-icon-button"
          color="inherit"
          menus={({ onClose }) => (
            <MenuList>
              <MenuItem>
                <UseLegacy onLegacyChange={onClose} />
              </MenuItem>
              <MenuItem>
                <Language onLanguageSet={onClose} />
              </MenuItem>
              {showLogout && (
                <MenuItem>
                  <Logout onLogout={onClose} />
                </MenuItem>
              )}
            </MenuList>
          )}
        >
          <MoreVertIcon />
        </MyIconButton>
      </Hidden>
      <Hidden xsDown>
        {currentUser.role?.name === RoleNames.GOD && <UseLegacy />}
        <Language />
        {showLogout && <Logout />}
      </Hidden>
    </>
  );
};
