import { Box, createStyles, fade, makeStyles, Theme } from "@material-ui/core";
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  ToggleButtonGroupProps
} from "@material-ui/lab";
import get from "lodash/get";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";

export interface ToggleButtonOption {
  value: any;
  label: React.ReactNode;
  toggleButtonProps?: ToggleButtonProps;
}
interface MyToggleButtonFieldProps extends FormProps, ToggleButtonGroupProps {
  options: ToggleButtonOption[];
  disabled?: boolean;
  multiple?: boolean;
  name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      "&$selected": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
          backgroundColor: fade(theme.palette.primary.main, 0.85)
        }
      }
    },
    root: {
      minWidth: 60
    }
  })
);

export const MyToggleButtonField: React.FC<MyToggleButtonFieldProps> = props => {
  const {
    name,
    label,
    options,
    helperText,
    formControlProps,
    formLabelProps,
    formHelperTextProps,
    disabled,
    multiple,
    ...rest
  } = props;
  const { errors, control } = useFormContext();
  const classes = useStyles();

  const errorField = get(errors, name)?.message;
  const myFormControlProps: MyFormControlProps = {
    errorField,
    label,
    helperText,
    formLabelProps,
    formHelperTextProps,
    ...formControlProps
  };
  return (
    <MyFormControl {...myFormControlProps} disabled={disabled}>
      <Controller
        name={name}
        control={control}
        render={({ onChange, onBlur, value }) => (
          <Box clone mt={1}>
            <ToggleButtonGroup
              value={value}
              exclusive={!multiple}
              onBlur={onBlur}
              onChange={(_, value) => {
                onChange(value);
              }}
              {...rest}
            >
              {options.map(o => (
                <ToggleButton
                  classes={{
                    selected: classes.selected,
                    root: classes.root,
                    ...o.toggleButtonProps?.classes
                  }}
                  color="primary"
                  key={o.value}
                  value={o.value}
                >
                  {o.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        )}
      />
    </MyFormControl>
  );
};
