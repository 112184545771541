import React, { useContext } from "react";
import { CompanyContext } from ".";
import { GtvCompany } from "../../components/common/Gtv";
import { Page } from "../../components/common/MyPage";
import { ScenarioDTO } from "../../reducers/scenarios/entity";
import { useDispatch } from "react-redux";
import { CompanyActions } from "../../reducers/companies/reducer";
import { push } from "connected-react-router";

interface CompanyGtvProps {}
export const CompanyGtv: React.FC<CompanyGtvProps> = () => {
  const company = useContext(CompanyContext);
  const dispatch = useDispatch();
  const onCreateScenario = (scenario: ScenarioDTO) => {
    dispatch(CompanyActions.createScenario({ company, scenario }));
    dispatch(push(`/company/${company.id}/scenarios`));
  };

  return (
    <Page>
      <GtvCompany onSubmit={onCreateScenario} company={company} />
    </Page>
  );
};
