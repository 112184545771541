import { Box, BoxProps, createStyles, makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import HeightIcon from "@material-ui/icons/Height";
import { useAnimation } from "framer-motion";
import { Resizable } from "re-resizable";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BoxAnimated } from "../../../components/common/BoxAnimated";
import { OnBoardingActions } from "../../../reducers/on-boarding/reducer";

export interface VideoLogoPlacementProps extends BoxProps {
  videoIndexSelected?: number;
  logoPath?: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    logo: {},
    logoContainer: {
      transform: "translate(-50%, -50%)",
      border: `1px solid ${blue[400]}`
    },
    posterVideo: {}
  })
);

// const buttonVariants: Variants = {
//   visible: {
//     opacity: 1,
//     scale: 1
//   },
//   hidden: {
//     opacity: 0,
//     scale: 0.8
//   }
// };

export const OnBoardingVideoLogoPlacement: React.FC<VideoLogoPlacementProps> = ({
  videoIndexSelected,
  logoPath,
  ...props
}) => {
  const posterRef = createRef<HTMLImageElement>();
  const logoRef = createRef<HTMLImageElement>();
  const resizeControlButton = useAnimation();

  const [posterDimension, setPosterDimension] = useState<
    { width: number; height: number } | undefined
  >();

  const [ratio, setRatio] = useState<number>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const posterVideoPath = `${process.env.REACT_APP_HOST ||
    ""}/public/videos/onboarding-${videoIndexSelected}.png`;

  const updatePosterDimension = useCallback(() => {
    if (posterRef.current?.clientWidth && posterRef.current?.clientHeight) {
      const width = posterRef.current?.clientWidth;
      const height = posterRef.current?.clientHeight;
      setPosterDimension({
        width,
        height
      });

      setRatio(1920 / width);
    }
  }, [posterRef]);

  useEffect(() => {
    window.addEventListener("resize", updatePosterDimension);
    return () => {
      window.removeEventListener("resize", updatePosterDimension);
    };
  }, [updatePosterDimension]);

  // useEffect(() => {
  //   const lastPositionX = 0;
  //   const changeLogoWidth = (e: MouseEvent) => {
  //     const delta = lastPositionX
  //       ? e.clientX - lastPositionX
  //       : e.clientX - dragClientX;
  //     setLogoRealWidth(prev => (prev ? prev + delta : 0));
  //   };
  //   const cleanUp = () => {
  //     setDragClientX(undefined);
  //     window.removeEventListener("mousemove", changeLogoWidth);
  //   };
  //   if (dragClientX) {
  //     window.addEventListener("mousemove", changeLogoWidth);
  //     window.addEventListener("mouseup", cleanUp);
  //   }
  //   return () => {
  //     window.removeEventListener("mouseUp", cleanUp);
  //   };
  // }, [dragClientX]);
  useEffect(() => {
    resizeControlButton.start({ scale: 1.2 });
  }, [resizeControlButton]);
  // useEffect(() => {
  //   if (posterDimension) updatePosterDimension();
  // }, [posterDimension, updatePosterDimension]);

  return (
    <Box
      {...props}
      position="relative"
      width="100%"
      maxWidth={1024}
      justifyContent="center"
      alignItems="center"
    >
      <img
        alt="poster"
        ref={posterRef}
        onLoad={() => {
          !posterDimension && updatePosterDimension();
        }}
        src={posterVideoPath}
        width="100%"
        className={classes.posterVideo}
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        className={classes.logoContainer}
      >
        <Resizable
          lockAspectRatio
          defaultSize={{ width: 200, height: "auto" }}
          maxWidth={posterDimension?.width}
          maxHeight={posterDimension?.height}
          onResizeStop={(e, direction, el) => {
            resizeControlButton.stop();
            dispatch(OnBoardingActions.setLogoWidth(el.clientWidth * ratio!));
          }}
          handleComponent={{
            topRight: (
              <BoxAnimated
                bgcolor={blue[400]}
                color="white"
                height={24}
                width={24}
                transition={{ yoyo: Infinity }}
                initial={{ scale: 0.8 }}
                animate={resizeControlButton}
              >
                <HeightIcon style={{ transform: "rotate(45deg)" }} />
              </BoxAnimated>
            )
          }}
        >
          <img alt="logo" width="100%" ref={logoRef} src={logoPath} />
        </Resizable>
      </Box>
    </Box>
  );
};
