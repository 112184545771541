import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AudioItem } from "../../../components/common/AudioItem";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { Page } from "../../../components/common/MyPage";
import { Audio } from "../../../reducers/audios/entity";
import { AudioSelector } from "../../../reducers/audios/selector";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { UserActions } from "../../../reducers/users/reducer";
import { UserSelector } from "../../../reducers/users/selector";

export const MySpaceAudioList = () => {
  const { t } = useTranslation("common");
  const currentUser = useSelector(getCurrentUser);
  const { readAudiosStatus } = useSelector(UserSelector.getState);
  const { owned, activationCode } = useSelector(
    AudioSelector.getUserAudiosByGroup(currentUser)
  );

  return (
    <DynamicElement<Audio[]>
      actions={{
        action: UserActions.readAudios(currentUser),
        status: readAudiosStatus
      }}
      data={[...owned, ...activationCode]}
    >
      {() => (
        <Page>
          {owned.length > 0 && (
            <Typography variant="subtitle1">{t("owned")}</Typography>
          )}
          <Box display="flex" flexWrap="wrap">
            {owned.map(audio => (
              <AudioItem maxWidth={400} m={1} key={audio.id} audio={audio} />
            ))}
          </Box>
          {activationCode.length > 0 && (
            <Typography variant="subtitle1">
              {t("from-activation-code")}
            </Typography>
          )}
          <Box display="flex" flexWrap="wrap" flexGrow={1}>
            {activationCode.map(audio => (
              <AudioItem maxWidth={400} m={1} key={audio.id} audio={audio} />
            ))}
          </Box>
        </Page>
      )}
    </DynamicElement>
  );
};
