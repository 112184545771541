import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppSelector } from "../../reducers/app/selector";
import { Languages } from "../function/jsonTranslator";
import { getYupLocales, Yup } from "../yup";
import { usePrevious } from "./usePrevious";
import { UseFormMethods } from "react-hook-form";

export const useYup: Function = (validateForm: Function) => {
  const { language } = useSelector(AppSelector.getState);
  const previousLanguage = usePrevious<Languages>(language);
  useEffect(() => {
    if (previousLanguage !== language) {
      Yup.setLocale(getYupLocales());
      validateForm();
    }
  }, [language, previousLanguage, validateForm]);
};

export const useYupForm: Function = ({
  trigger,
  formState
}: UseFormMethods) => {
  const { language } = useSelector(AppSelector.getState);
  const previousLanguage = usePrevious(language);
  useEffect(() => {
    if (formState.isDirty && previousLanguage !== language) {
      Yup.setLocale(getYupLocales());
      trigger();
    }
  }, [formState.isDirty, language, previousLanguage, trigger]);
};
