import { Audio } from "../reducers/audios/entity";
import { Category } from "./category";
import { Company } from "../reducers/companies/entity";
import { Image } from "../reducers/images/entity";
import { User } from "./user";
import { Video } from "../reducers/videos/entity";
import { JsonTranslator } from "../utils/function/jsonTranslator";

export interface AssetGroup<T> {
  owned: T[];
  shared: T[];
  activationCode: T[];
}

export enum WatermarkPosition {
  CENTER = "CENTER",
  TOP = "TOP",
  TOP_RIGHT = "TOP_RIGHT",
  RIGHT = "RIGHT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  BOTTOM = "BOTTOM",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  LEFT = "LEFT",
  TOP_LEFT = "TOP_LEFT"
}

export enum AssetsType {
  VIDEO = "video",
  IMAGE = "image",
  AUDIO = "audio"
}

export enum AssetsStatus {
  VALIDATED = "VALIDATED",
  REFUSED = "REFUSED"
}

export class Asset {
  id!: string;
  name!: string;
  label!: JsonTranslator;
  description?: JsonTranslator;
  status?: AssetsStatus;
  public!: boolean;
  premium!: boolean;
  enabled!: boolean;
  createdAt!: Date;
  updatedAt!: Date;

  categoryId?: string;
  category?: Category;
  userId?: string;
  user?: User;
  companyId: string | undefined;
  company?: Company;

  // Set in app
  url?: string;
}

export type AssetClassType = Image | Video | Audio;
