import * as yup from "yup";
import i18n from "../i18n";

export const getYupLocales = () => ({
  mixed: {
    required: i18n.t("yup:mixed.required")
  },
  string: {
    email: i18n.t("yup:string.email")
  },
  object: {},
  number: {
    min: i18n.t("yup:number.min"),
    max: i18n.t("yup:number.max")
  },
  array: {
    min: i18n.t("yup:array.min"),
    max: i18n.t("yup:array.max")
  }
});
yup.setLocale(getYupLocales());
export const Yup = yup;
