import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Logo from "../../assets/logo.svg";
import Logo from "../../assets/logo-white.png";
import { Page } from "../../components/common/MyPage";
import { RootState } from "../../reducers/store";
import { getCurrentUser } from "../../reducers/authentication/selector";
import { Authentication } from "./components";

export interface HomeProps {}
type Props = HomeProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: 300,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 200
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 150
      }
    },
    authenticationSection: {
      maxWidth: 540
    }
  })
);

const AuthenticationComponent: React.FC<Props> = () => {
  const [authTab, setAuthTab] = useState(0);
  const location = useSelector((state: RootState) => state.router.location);
  const currentUser = useSelector(getCurrentUser);
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    if (currentUser && currentUser.isActive && currentUser.token) {
      history.push("/");
    }
  }, [currentUser, history]);
  useEffect(() => {
    setAuthTab(
      location && location.search
        ? (queryString.parse(location.search, { parseNumbers: true })
            .authTab as number | undefined) || 0
        : 0
    );
  }, [location]);

  return (
    <Page p={{ xs: 4, sm: 4 }}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <img alt="enterprise-logo" src={Logo} className={classes.logo} />
        </Grid>
        <Grid item className={classes.authenticationSection}>
          <Authentication
            authTab={authTab}
            onTabAuthenticationChange={value => setAuthTab(value)}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export const AuthenticationPage = AuthenticationComponent;
