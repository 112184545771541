import { Box } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import React from "react";
import { useSpring } from "react-spring";

export interface MyPageProps extends BoxProps {
  style?: React.CSSProperties;
  className?: string;
  center?: boolean;
}

export const Page: React.FC<MyPageProps> = props => {
  const { children, center, style, ...rest } = props;
  const pageAnimatedProps = useSpring({
    from: { opacity: 0 },
    opacity: 1
  });

  return (
    <Box
      p={{ xs: 0.2, sm: 0.5, md: 1 }}
      minHeight="100%"
      height={center ? "calc(100vh - 128px)" : "auto"}
      style={{ ...style, ...pageAnimatedProps }}
      {...rest}
    >
      {center ? (
        <Box
          height="100%"
          data-testid="center-content"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
