import { createSelector } from "reselect";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { RootState } from "../store";
import { ShotCategoryAdapter } from "./reducer";
import { AppSelector } from "../app/selector";

const AdapterSelector = ShotCategoryAdapter.getSelectors(
  (state: RootState) => state.shotCategories
);

const getState = createSelector(
  (state: RootState) => state.shotCategories,
  state => state
);

const getShotcategoryById = (id?: string) => {
  return createSelector(AdapterSelector.selectAll, shotCategories =>
    shotCategories.find(sc => sc.id === id)
  );
};

const getShotCategoriesOptions = createSelector(
  AdapterSelector.selectAll,
  AppSelector.getState,
  (shotCategories, { language }) =>
    shotCategories.map(shotCategory => ({
      value: shotCategory.id,
      label: jsonTranslator(shotCategory.label, language)
    }))
);

export const ShotCategorySelector = {
  ...AdapterSelector,
  getState,
  getShotCategoriesOptions,
  getShotcategoryById
};
