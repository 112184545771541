import {
  Box,
  BoxProps,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DoneIcon from "@material-ui/icons/Done";
import React, { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyForm } from "../../../components/common/MyForm2";
import { MyTextField } from "../../../components/common/MyForm2/MyTextField";
import { AppTheme } from "../../../components/theme/AppTheme";
import { AppActions } from "../../../reducers/app/reducer";
import { AppSelector } from "../../../reducers/app/selector";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { OnBoardingActions } from "../../../reducers/on-boarding/reducer";
import { getOnBoardingState } from "../../../reducers/on-boarding/selector";
import { OnBoardingValues } from "../schema";

interface LogoFrameProps extends BoxProps {
  onGenerate: (values: OnBoardingValues) => void;
  onLogoDrop: (logo: File) => void;
  formMethods: UseFormMethods<OnBoardingValues>;
  onBoardingIsNotValid: boolean;
}

export const LogoFrame: React.FC<LogoFrameProps> = ({
  onGenerate,
  onLogoDrop,
  formMethods,
  onBoardingIsNotValid,
  ...props
}) => {
  const { t } = useTranslation("onBoarding");
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const { theme } = useSelector(AppSelector.getState);
  const { videoNumber, logoWidth, logoPath, videoUrl } = useSelector(
    getOnBoardingState
  );
  const [logoName, setLogoName] = useState<string>("");

  const onDropLogo = useCallback(
    (files: File[]) => {
      if (!files || !files.length) {
        return;
      }
      const logo = files[0];
      setLogoName(logo.name);
      onLogoDrop(logo);
      // const readerDataUrl = new FileReader();
      // readerDataUrl.onloadend = () => {
      //   onLogoLoaded();
      // };
      // // readerDataUrl.readAsDataURL(logo);
    },
    [onLogoDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropLogo,
    multiple: false,
    maxSize: 2 * Math.pow(10, 6),
    accept: ["image/png", "image/jpeg"],
    onFileDialogCancel: () => console.log("file dialog cancel"),
    onDropRejected: (filesRejected: FileRejection[]) => {
      dispatch(
        AppActions.enqueueSnacbar({
          message: `${filesRejected.map(f => f.file.name).join(", ")} ${t(
            "drop"
          )}`,
          variant: "warning"
        })
      );
    }
  });

  useEffect(() => {
    if (!logoPath) {
      setLogoName("");
    }
  }, [logoPath]);

  return (
    <AppTheme theme={theme}>
      <Box clone borderRadius={12} bgcolor="white" color="black" {...props}>
        <Paper elevation={4}>
          <MyForm
            methods={formMethods}
            onSubmit={values => console.log(values)}
          >
            <List>
              <ListItem button {...getRootProps()}>
                <input name="logo" {...getInputProps()} />
                <ListItemIcon>
                  <Box clone fontSize={40} color="primary.light">
                    <AddCircleIcon />
                  </Box>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: "h6" }}>
                  {logoName || t("add-logo")}
                </ListItemText>
                {logoPath && (
                  <ListItemSecondaryAction>
                    <Box clone fontSize={40} color="success.main">
                      <DoneIcon />
                    </Box>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider />
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                {!user.id && (
                  <>
                    <ListItemText>
                      <MyTextField
                        required
                        inputProps={{ size: "small" }}
                        name="email"
                        label={t("your-email")}
                        fullWidth
                      />
                    </ListItemText>
                    {formMethods.formState.dirtyFields.email &&
                      !formMethods.errors.email && (
                        <ListItemSecondaryAction>
                          <Box clone fontSize={40} color="success.main">
                            <DoneIcon />
                          </Box>
                        </ListItemSecondaryAction>
                      )}
                  </>
                )}
              </ListItem>
              <Divider />
              <ListItem
                disabled={!logoPath}
                button
                onClick={() => dispatch(OnBoardingActions.setStep(1))}
              >
                <ListItemText primaryTypographyProps={{ variant: "h6" }}>
                  {t("select-background")}
                </ListItemText>
                {videoNumber && (
                  <ListItemSecondaryAction>
                    <Box clone fontSize={40} color="success.main">
                      <DoneIcon />
                    </Box>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider />
              <ListItem
                disabled={!videoNumber}
                button
                onClick={() => dispatch(OnBoardingActions.setStep(2))}
              >
                <ListItemText primaryTypographyProps={{ variant: "h6" }}>
                  Dimensions
                </ListItemText>
                {!!logoWidth && (
                  <ListItemSecondaryAction>
                    <Box clone fontSize={40} color="success.main">
                      <DoneIcon />
                    </Box>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider />
              <ListItem>
                <Box
                  clone
                  display="flex"
                  justifyContent={!videoUrl ? "center" : "space-between"}
                >
                  <ListItemText>
                    <Fab
                      disabled={onBoardingIsNotValid}
                      variant="extended"
                      size="large"
                      color="primary"
                      style={{ width: 200, margin: "0 auto" }}
                      onClick={() => {
                        !user.id
                          ? formMethods.handleSubmit(onGenerate)()
                          : onGenerate({ email: user.email });
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("generate")}
                      </Typography>
                    </Fab>
                  </ListItemText>
                </Box>
                {!!videoUrl && (
                  <ListItemSecondaryAction>
                    <Box clone fontSize={40} color="success.main">
                      <DoneIcon />
                    </Box>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </MyForm>
        </Paper>
      </Box>
    </AppTheme>
  );
};
