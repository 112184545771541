import {
  Drawer,
  DrawerProps,
  Hidden,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Theme
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../reducers/app/reducer";
import { AppSelector } from "../../reducers/app/selector";
import { DRAWER_WIDTH } from "../../utils/constant";

interface MyDrawerProps {
  drawerProps?: DrawerProps;
  swipeableDrawerProps?: SwipeableDrawerProps;
  className?: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    swipeableDrawer: {
      width: DRAWER_WIDTH
    },
    drawerPaper: {
      [theme.breakpoints.up("md")]: {
        width: DRAWER_WIDTH,
        flexShrink: 0
      }
    },
    toolbar: theme.mixins.toolbar
  })
);
export const MyDrawer: React.FC<MyDrawerProps> = ({
  children,
  drawerProps,
  swipeableDrawerProps,
  style,
  className
}) => {
  const classes = useStyles();
  const { isOnBackOffice, openMenu } = useSelector(AppSelector.getState);
  const dispatch = useDispatch();
  const iOS =
    (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleDrawerToggle = (open?: boolean) => {
    dispatch(AppActions.openMenu(open === undefined ? !openMenu : open));
  };
  if (!isOnBackOffice) {
    return null;
  }

  return (
    <>
      <Hidden mdUp>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={openMenu}
          variant="temporary"
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={() => handleDrawerToggle()}
          onOpen={() => handleDrawerToggle(true)}
          classes={{ paper: classes.swipeableDrawer }}
          style={style}
          className={className}
          {...swipeableDrawerProps}
        >
          {children}
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          PaperProps={{ elevation: 4 }}
          variant="permanent"
          open
          style={style}
          className={className}
          {...drawerProps}
        >
          {children}
        </Drawer>
      </Hidden>
    </>
  );
};
