import { Shot } from "../../entities/shot";
import { JsonTranslator } from "../../utils/function/jsonTranslator";

export enum ShotCategoryName {
  GENERIC = "GENERIC",
  INTERTITLE = "INTERTITLE",
  INTERVIEW = "INTERVIEW",
  CLIP = "CLIP",
  B_ROLL = "B_ROLL",
  B_ROLL_CHILD = "B_ROLL_CHILD"
}

export interface ShotCategory {
  id: string;
  name: ShotCategoryName;
  label: JsonTranslator;
  description?: JsonTranslator;
  defaultVideoVolume?: number;
  defaultMusicVolume?: number;
  defaultVideoVolumeWithAudioMix?: number;
  defaultMusicVolumeWithAudioMix?: number;
  defaultRecommendedDuration?: number;
  shots?: Shot[];
}

export interface ShotCategoryCreateDTO {}

export interface ShotCategoryUpdateDTO {
  id: string;
}
