import { createSelector } from "reselect";
import { User } from "../../entities/user";
import { ActivationCodeSelector } from "../activation-code/selector";
import { RootState } from "../store";
import { ImageAdapter } from "./reducer";

const AdapterSelector = ImageAdapter.getSelectors(
  (state: RootState) => state.images
);

const getState = createSelector(
  (state: RootState) => state,
  state => state.images
);

const getPremium = createSelector(AdapterSelector.selectAll, images =>
  images.filter(i => i.premium)
);

const getUserImages = (user: User) =>
  createSelector(AdapterSelector.selectAll, images =>
    images.filter(v => v.userId === user.id)
  );
const getUserImagesFromActivationCode = (user: User) =>
  createSelector(
    AdapterSelector.selectAll,
    ActivationCodeSelector.selectAll,
    (images, activationCodes) =>
      activationCodes
        .map(ac =>
          images.filter(
            i =>
              ac.imageIds.includes(i.id) &&
              ac.userIds.includes(user.id) &&
              new Date() < new Date(ac.end) &&
              new Date() > new Date(ac.start)
          )
        )
        .flat()
  );

const getUserImagesByGroup = (user: User) =>
  createSelector(
    getUserImages(user),
    getUserImagesFromActivationCode(user),
    (owned, activationCode) => ({ owned, activationCode })
  );

export const ImageSelector = {
  ...AdapterSelector,
  getState,
  getPremium,
  getUserImagesByGroup
};
