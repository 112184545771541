import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  createStyles,
  Hidden,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MovieIcon from "@material-ui/icons/Movie";
import SaveIcon from "@material-ui/icons/Save";
import { ThunkDispatch } from "@reduxjs/toolkit";
import clsx from "clsx";
import React, {
  createContext,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef
} from "react";
import { UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { AnyAction, DeepPartial } from "redux";
import { AudioMixCreateDto } from "../../../entities/audio-mix";
import { ShotDTO } from "../../../entities/shot";
import { StoryDTO } from "../../../entities/story";
import { AppSelector } from "../../../reducers/app/selector";
import { Audio } from "../../../reducers/audios/entity";
import { GtvActions } from "../../../reducers/gtv/reducer";
import { GtvSelector } from "../../../reducers/gtv/selector";
import { Image } from "../../../reducers/images/entity";
import { ScenarioDTO } from "../../../reducers/scenarios/entity";
import { ShotCategoryName } from "../../../reducers/shot-category/entity";
import { ShotCategorySelector } from "../../../reducers/shot-category/selector";
import { RootState } from "../../../reducers/store";
import { Video } from "../../../reducers/videos/entity";
import {
  jsonTranslator,
  JsonTranslator
} from "../../../utils/function/jsonTranslator";
import { DotStatus } from "../DotStatus";
import { MyButton } from "../MyButton";
import { MyIconButton } from "../MyIconButton";
import { MyTypography } from "../MyTypography";
import { AudioMixForm } from "./AudioMixForm";
import { ScenarioForm } from "./ScenarioForm";
import { GtvStepConnector, GtvStepIconButton } from "./StepIconButton";
import { StoryForm } from "./StoryForm";
import { Timeline } from "./Timeline";

export interface GtvFormProps {
  onSubmit: (values: ScenarioDTO) => void;
  audios: Audio[];
  images: Image[];
  videos: Video[];
}

interface MethodsRef {
  scenario: { methods: UseFormMethods };
  audioMix: { methods: UseFormMethods };
  stories: Array<{
    methods: UseFormMethods;
    shots: Array<{ methods: UseFormMethods }>;
  }>;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    swipeable: () => ({
      "&>.react-swipeable-view-container": {
        maxWidth: 800,
        margin: "0 auto"
        /* maxWidth: activeStep === 2 ? "initial" : 800, */
        /* width: activeStep === 2 ? "100%" : "auto" */
      }
    }),
    swipeableSlide: {
      /* width: "400px !important" */
      /* padding: "0 100px 0 0" */
    },
    stepper: {
      padding: 0,
      flex: 1,
      minWidth: 280
    },
    disabledStep: {
      opacity: 0.3,
      pointerEvents: "none"
    }
  })
);
export const GtvContext = createContext<{
  edit: boolean;
  isSubmitted: boolean;
  methodsRef: MutableRefObject<MethodsRef | undefined>;
  audios: Audio[];
  images: Image[];
  videos: Video[];
  handleStep: (step: number) => void;
  submit: () => void;
  onScenarioSubmit: (values: ScenarioDTO) => void;
  onScenarioUpdateIsValid: (isValid: boolean) => void;
  onAudioMixSubmit: (values: AudioMixCreateDto) => void;
  onAudioMixUpdateIsValid: (isValid: boolean) => void;
  onStorySubmit: (index: number, story: StoryDTO, close?: boolean) => void;
  onStoryRemove: (index: number) => void;
  onStoryUpdateIsValid: (index: number, isValid: boolean) => void;
  onStoryAdd: () => void;
  onStoryDuplicate: (index: number) => void;
  onStoryOpen: (index: number, open: boolean) => void;
  onShotSubmit: (
    storyIndex: number,
    index: number,
    shot: DeepPartial<ShotDTO>,
    close?: boolean
  ) => void;
  onShotRemove: (storyIndex: number, index: number) => void;
  onShotUpdateIsValid: (
    storyIndex: number,
    index: number,
    isValid: boolean
  ) => void;
  onShotAdd: (storyIndex: number) => void;
  onShotDuplicate: (storyIndex: number, index: number) => void;
  onShotOpen: (storyIndex: number, index: number, open: boolean) => void;
}>({
  edit: false,
  methodsRef: { current: undefined },
  isSubmitted: false,
  audios: [],
  images: [],
  videos: [],
  handleStep: () => null,
  submit: () => null,
  onScenarioSubmit: () => null,
  onScenarioUpdateIsValid: () => null,
  onAudioMixSubmit: () => null,
  onAudioMixUpdateIsValid: () => null,
  onStorySubmit: () => null,
  onStoryRemove: () => null,
  onStoryUpdateIsValid: () => null,
  onStoryAdd: () => null,
  onStoryDuplicate: () => null,
  onStoryOpen: () => null,
  onShotSubmit: () => null,
  onShotRemove: () => null,
  onShotUpdateIsValid: () => null,
  onShotAdd: () => null,
  onShotDuplicate: () => null,
  onShotOpen: () => null
});
export const GtvForm: React.FC<GtvFormProps> = ({
  onSubmit,
  audios,
  images,
  videos
}) => {
  const theme = useTheme();
  const isOnMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const { t } = useTranslation("gtv");
  const { language: lang } = useSelector(AppSelector.getState);
  const edit = useSelector(GtvSelector.getEdit);
  const isSubmitted = useSelector(GtvSelector.getIsSubmitted);
  const isValidating = useSelector(GtvSelector.getIsValidating);
  const stories = useSelector(GtvSelector.getStoriesState);
  const scenario = useSelector(GtvSelector.getScenarioState);
  const audioMix = useSelector(GtvSelector.getAudioMixState);
  const shotCategories = useSelector(ShotCategorySelector.selectAll);
  const methodsRef = useRef<MethodsRef>({} as any);
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles(activeStep);

  // To optimize to be computed one time
  const storyIsValid = stories.every(
    s => s.isValid && s.shots.every(s => s.isValid)
  );
  const stepCompleted = [scenario.isValid, audioMix.isValid, storyIsValid];

  const handleStep = useCallback(
    (step: number, lastStep?: number) => {
      if (lastStep && !stepCompleted[lastStep] && step > 0) {
        setActiveStep(step - 1);
        return;
      }
      setActiveStep(step);
    },
    [stepCompleted]
  );

  const submit = useCallback(async () => {
    const result = (await dispatch(GtvActions.submit())) as any;

    if (!result?.error && result?.payload?.isValid) {
      onSubmit(result.payload.values);

      // reset methods ref
      await dispatch(GtvActions.clear());
    }
  }, [dispatch, onSubmit]);

  // Scenario
  const onScenarioSubmit = useCallback(
    (values: Partial<ScenarioDTO>) => {
      dispatch(GtvActions.updateScenario(values));
      handleStep(1);
    },
    [dispatch, handleStep]
  );
  const onScenarioUpdateIsValid = useCallback(
    (isValid: boolean) => {
      dispatch(GtvActions.updateScenarioIsValid(isValid));
    },
    [dispatch]
  );

  // AudioMix
  const onAudioMixSubmit = useCallback(
    (values: AudioMixCreateDto) => {
      dispatch(GtvActions.updateAudioMix(values));
      handleStep(2);
    },
    [dispatch, handleStep]
  );
  const onAudioMixUpdateIsValid = useCallback(
    (isValid: boolean) => {
      dispatch(GtvActions.updateAudioMixIsValid(isValid));
    },
    [dispatch]
  );

  // Stories
  const onStorySubmit = useCallback(
    (index: number, story: DeepPartial<StoryDTO>, close = true) => {
      const firstShotIsBroll =
        shotCategories.find(
          sc => sc.id === stories[index].shots[0].values.shotCategoryId
        )?.name === ShotCategoryName.B_ROLL;
      const brollChildId = shotCategories.find(
        sc => sc.name === ShotCategoryName.B_ROLL_CHILD
      )?.id;
      dispatch(
        GtvActions.updateStory({
          index,
          story,
          firstShotIsBroll: firstShotIsBroll ? { brollChildId } : undefined,
          close
        })
      );
    },
    [dispatch, shotCategories, stories]
  );
  const onStoryAdd = useCallback(() => {
    dispatch(GtvActions.addStory());
  }, [dispatch]);
  const onStoryRemove = useCallback(
    (index: number) => {
      dispatch(GtvActions.removeStory(index));
    },
    [dispatch]
  );
  const onStoryUpdateIsValid = useCallback(
    (index: number, isValid: boolean) => {
      dispatch(GtvActions.updateStoryIsValid({ index, isValid }));
    },
    [dispatch]
  );
  const onStoryDuplicate = useCallback(
    (index: number) => {
      dispatch(GtvActions.duplicateStory({ index, context: { lang } }));
    },
    [dispatch, lang]
  );
  const onStoryOpen = useCallback(
    (index: number, open: boolean) =>
      dispatch(GtvActions.openStory({ index, open })),
    [dispatch]
  );

  // Shots
  const onShotSubmit = useCallback(
    (
      storyIndex: number,
      index: number,
      shot: DeepPartial<ShotDTO>,
      close = true
    ) => {
      const storyValues = methodsRef.current.stories[
        storyIndex
      ].methods.getValues();
      dispatch(
        GtvActions.updateStory({ index: storyIndex, story: storyValues })
      );
      dispatch(GtvActions.updateShot({ storyIndex, index, shot, close }));
    },
    [dispatch]
  );

  const onShotAdd = useCallback(
    (storyIndex: number) => {
      dispatch(GtvActions.addShot(storyIndex));
    },
    [dispatch]
  );

  const onShotRemove = useCallback(
    (storyIndex: number, index: number) => {
      dispatch(GtvActions.removeShot({ storyIndex, index }));
    },
    [dispatch]
  );
  const onShotUpdateIsValid = useCallback(
    (storyIndex: number, index: number, isValid: boolean) => {
      dispatch(GtvActions.updateShotIsValid({ storyIndex, index, isValid }));
    },
    [dispatch]
  );
  const onShotDuplicate = useCallback(
    (storyIndex: number, index: number) => {
      dispatch(
        GtvActions.duplicateShot({
          storyIndex,
          index,
          context: { lang }
        })
      );
    },
    [dispatch, lang]
  );
  const onShotOpen = useCallback(
    (storyIndex: number, index: number, open: boolean) =>
      dispatch(GtvActions.openShot({ storyIndex, index, open })),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      // dispatch(GtvActions.clear());
    };
  }, [dispatch]);

  return (
    <GtvContext.Provider
      value={{
        methodsRef,
        isSubmitted,
        edit,
        audios,
        images,
        videos,
        handleStep,
        submit,
        onScenarioSubmit,
        onScenarioUpdateIsValid,
        onAudioMixSubmit,
        onAudioMixUpdateIsValid,
        onStorySubmit,
        onStoryRemove,
        onStoryUpdateIsValid,
        onStoryDuplicate,
        onStoryAdd,
        onStoryOpen,
        onShotSubmit,
        onShotRemove,
        onShotUpdateIsValid,
        onShotDuplicate,
        onShotAdd,
        onShotOpen
      }}
    >
      <Box position="relative">
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box clone flex={1}>
            <Typography variant="h2" color="primary">
              Scenario
            </Typography>
          </Box>
          <Stepper
            className={classes.stepper}
            activeStep={activeStep}
            connector={<GtvStepConnector />}
          >
            <Step completed={edit || stepCompleted[0]}>
              <StepLabel
                StepIconComponent={GtvStepIconButton}
                StepIconProps={{
                  error: (edit || isSubmitted) && !stepCompleted[0]
                }}
                onClick={() => (edit || stepCompleted[0]) && handleStep(0)}
              />
            </Step>
            <Step completed={edit || stepCompleted[1]}>
              <StepLabel
                StepIconComponent={GtvStepIconButton}
                StepIconProps={{
                  error: (edit || isSubmitted) && !stepCompleted[1]
                }}
                onClick={() => (edit || stepCompleted[1]) && handleStep(1)}
              />
            </Step>
            <Step completed={edit || stepCompleted[2]}>
              <StepLabel
                StepIconComponent={GtvStepIconButton}
                StepIconProps={{
                  error: (edit || isSubmitted) && !stepCompleted[2]
                }}
                onClick={() => (edit || stepCompleted[2]) && handleStep(2)}
              />
            </Step>
            <Hidden smDown>
              <MyButton
                capitalize
                loading={isValidating}
                size={isOnMobile ? "small" : "large"}
                variant="contained"
                color={stepCompleted.every(Boolean) ? "primary" : "default"}
                disabled={!stepCompleted.every(Boolean)}
                onClick={() => {
                  if (edit || stepCompleted.every(Boolean)) submit();
                }}
              >
                {edit ? t("update-scenario") : t("create-scenario")}
              </MyButton>
            </Hidden>
          </Stepper>
        </Box>
        <Timeline position="sticky" top={64} zIndex={1} />
        <SwipeableViews
          // TODO issue when height increase on 3rd step so it will be false for now
          animateHeight={false}
          enableMouseEvents={isOnMobile}
          className={classes.swipeable}
          index={activeStep}
          onChangeIndex={handleStep}
        >
          <ScenarioForm
            component={Paper}
            overflow="hidden"
            p={{ xs: 2, md: 4, lg: 8 }}
            minHeight={{ xs: "auto", md: 700 }}
            maxWidth={theme.breakpoints.width("sm")}
            className={clsx({ [classes.disabledStep]: activeStep !== 0 })}
            // m={{ xs: 0, md: 4 }}
          />
          <AudioMixForm
            className={clsx({ [classes.disabledStep]: activeStep !== 1 })}
            component={Paper}
            overflow="hidden"
            p={{ xs: 2, md: 4, lg: 8 }}
            minHeight={{ xs: "auto", md: 700 }}
            maxWidth={theme.breakpoints.width("sm")}
            // m={{ xs: 0, md: 4 }}
          />
          <Box
            component={Paper}
            className={clsx({
              [classes.disabledStep]: activeStep !== 2
            })}
            p={{ xs: 1, md: 4, lg: 8 }}
            // m={{ xs: 0, md: 4 }}
          >
            <Box clone mb={{ xs: 2, md: 4 }}>
              <MyTypography
                color="primary"
                leftIcon={
                  <Avatar
                    style={{
                      background: theme.palette.primary.main,
                      color: "white",
                      height: 50,
                      width: 50
                    }}
                  >
                    <MovieIcon fontSize="large" />
                  </Avatar>
                }
                variant="h4"
              >
                {t("stories")}
              </MyTypography>
            </Box>
            {stories.map((story, index) => {
              const shotsOpenOrUnvalid = story.shots.some(
                shot => shot.open || !shot.isValid
              );
              return (
                <Box
                  key={story.values._key}
                  clone
                  my={{ xs: 1, sm: 2 }}
                  p={{ xs: 0, sm: 1 }}
                >
                  <Accordion
                    expanded={story.open}
                    TransitionProps={{
                      unmountOnExit: false,
                      mountOnEnter: true
                    }}
                    onChange={() => onStoryOpen(index, true)}
                  >
                    <AccordionSummary
                      IconButtonProps={{
                        onClick: e => {
                          if (
                            story.open &&
                            !shotsOpenOrUnvalid &&
                            methodsRef.current
                          ) {
                            methodsRef.current.stories[
                              index
                            ].methods.handleSubmit(values =>
                              onStorySubmit(index, values)
                            )(e);
                          }
                        }
                      }}
                      expandIcon={
                        story.open ? (
                          <Tooltip
                            arrow
                            title={
                              <Typography>
                                {shotsOpenOrUnvalid
                                  ? t("validate-shots-before-story")
                                  : t("save-story")}
                              </Typography>
                            }
                          >
                            <SaveIcon
                              style={{ transform: "rotate(180deg)" }}
                              color={
                                story.shots.some(
                                  shot => shot.open || !shot.isValid
                                )
                                  ? "disabled"
                                  : "secondary"
                              }
                            />
                          </Tooltip>
                        ) : (
                          <ExpandMoreIcon color="primary" />
                        )
                      }
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <Box
                          display="flex"
                          alignItems="center"
                          height="100%"
                          flex={1}
                        >
                          <Typography color="primary" variant="h4">
                            {t("story")} {index + 1}
                          </Typography>
                          <DotStatus
                            ml={2}
                            size={10}
                            isValid={
                              story.isValid && story.shots.every(s => s.isValid)
                            }
                          />
                          <Hidden xsDown>
                            {story.values.label && (
                              <>
                                <Box
                                  mx={{ xs: 1, sm: 2, md: 4 }}
                                  width={2}
                                  height="100%"
                                  bgcolor="primary.main"
                                />
                                <Typography variant="h5">
                                  {jsonTranslator(
                                    story.values.label as JsonTranslator,
                                    lang
                                  )}
                                </Typography>
                              </>
                            )}
                          </Hidden>
                        </Box>
                        <MyIconButton
                          tooltipProps={{
                            arrow: true,
                            title: (
                              <Typography>{t("duplicate-story")}</Typography>
                            )
                          }}
                          color="primary"
                          onClick={e => {
                            e.stopPropagation();
                            onStoryDuplicate(index);
                          }}
                        >
                          <FileCopyIcon fontSize="small" />
                        </MyIconButton>
                        {stories.length > 1 && (
                          <MyIconButton
                            tooltipProps={{
                              arrow: true,
                              title: (
                                <Typography>{t("remove-story")}</Typography>
                              )
                            }}
                            color="primary"
                            onClick={e => {
                              e.stopPropagation();
                              onStoryRemove(index);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </MyIconButton>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <StoryForm key={story.values._key} index={index} />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
            <MyButton
              boxProps={{
                height: { xs: 60, sm: 80 },
                justifyContent: "flex-start",
                border: `2px dashed ${theme.palette.primary.main}`
              }}
              fullWidth
              color="primary"
              leftIcon={<AddIcon color="primary" fontSize="large" />}
              onClick={onStoryAdd}
            >
              <Typography variant="h4">{t("add-story")}</Typography>
            </MyButton>
          </Box>
        </SwipeableViews>
        <Hidden mdUp>
          <MyButton
            capitalize
            fullWidth
            loading={isValidating}
            size="large"
            variant="contained"
            color={stepCompleted.every(Boolean) ? "primary" : "default"}
            disabled={!stepCompleted.every(Boolean)}
            onClick={() => {
              if (edit || stepCompleted.every(Boolean)) submit();
            }}
          >
            {edit ? t("update-scenario") : t("create-scenario")}
          </MyButton>
        </Hidden>
      </Box>
    </GtvContext.Provider>
  );
};

export default GtvForm;
