export enum Languages {
  "EN" = "en-US",
  "FR" = "fr-FR"
}
export type JsonTranslator = {
  [K in Languages | string]: string;
};

export const jsonTranslator = (
  json: JsonTranslator,
  lang: Languages
): string => {
  try {
    const languages = json;
    return languages[lang] || languages[Languages.EN];
  } catch (err) {
    throw new Error(`Cannot transform ${json} to the current translation`);
  }
};
