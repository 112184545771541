import { Yup } from "../../../utils/yup";
import { StringSchema } from "yup";
import i18n from "../../../utils/i18n";

export interface ProfilValues {
  username?: string;
  email?: string;
  password?: string;
  passwordConfirm?: string;
  firstname?: string;
  lastname?: string;
  companyName?: string;
  newsletter?: boolean;
}

export const ProfilSchema = Yup.object({
  username: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string(),
  passwordConfirm: Yup.string().when(
    "password",
    (value: string, schema: StringSchema) => {
      if (value) {
        return schema.required().test({
          message: i18n.t("profile:form.password-equality"),
          test: val => val === value
        });
      }
      return schema.notRequired();
    }
  ),
  firstname: Yup.string(),
  lastname: Yup.string(),
  companyName: Yup.string().required(),
  //newsletter: Yup.boolean()
  //   .default(false)
  //   .required()
});
