import { PayloadAction } from "@reduxjs/toolkit";
import Axios from "axios";
import i18n from "../../utils/i18n";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { Request } from "../../utils/request";
import { AppActions } from "../app/reducer";
import { UserActions } from "../users/reducer";
import { Video } from "../videos/entity";
import { OnBoardingActionTypes } from "./action";

export enum GenerateType {
  VIDEO = "video",
  IMAGE = "image",
  COLOR = "color"
}
export interface OnBoardingState {
  step: number;
  generateStatus: FetchingStatus;
  email?: string;
  logoPath?: string;
  logoWidth?: number;
  videoUrl?: string;
  videoNumber?: number;
}
export const onBoardingState: OnBoardingState = {
  step: 0,
  generateStatus: FetchingStatus.NULL,
  email: "",
  logoPath: undefined,
  logoWidth: undefined,
  videoUrl: undefined,
  videoNumber: undefined
};

const generateVideo = createMyAsyncThunk(
  OnBoardingActionTypes.GENERATE_VIDEO,
  async (email: string | undefined, { getState }) => {
    const formData = new FormData();
    const { logoPath, videoNumber, logoWidth } = getState().onBoarding;
    const userId = getState().authentication.user?.id || undefined;
    const lang = getState().app.language;
    const { data } = await Axios.get(logoPath as string, {
      responseType: "blob"
    });
    formData.append(
      "data",
      JSON.stringify({
        userId,
        lang,
        email: email || undefined,
        videoNumber,
        logo: { width: logoWidth }
      })
    );
    formData.append("logo", data, "test");
    return Request().post("/on-boarding", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  {
    onSuccess: ({ result, thunkApi: { dispatch, getState } }) => {
      const userId = getState().authentication.user?.id;
      if (userId) dispatch(UserActions.updateOnBoarding(userId));
      return result;
    },
    onFailed: ({ error, thunkApi: { dispatch } }) => {
      dispatch(
        AppActions.enqueueSnacbar({
          message: `${i18n.t("onBoarding:errors.video-creation")}: ${
            error.message
          } ${
            error.statusCode === 401
              ? i18n.t("onBoarding:errors.user-already-exist") +
                window.location.host
              : ""
          }`,
          variant: "error"
        })
      );
    }
  }
);

export const OnBoardingSlice = createMySlice({
  name: "on-boarding",
  initialState: onBoardingState,
  asyncActions: [
    {
      action: generateVideo,
      statusName: "generateStatus",
      onSuccess: (state, action: PayloadAction<Video>) => {
        state.videoUrl = action.payload.url;
      }
    }
  ],
  reducers: {
    reset: () => {
      return onBoardingState;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setLogoPath(state, action: PayloadAction<string | undefined>) {
      state.logoPath = action.payload;
    },
    setLogoWidth(state, action: PayloadAction<number | undefined>) {
      state.logoWidth = action.payload;
    },
    setVideoNumber(state, action: PayloadAction<number | undefined>) {
      state.videoNumber = action.payload;
    }
  }
});

export const OnBoardingActions = {
  ...OnBoardingSlice.actions,
  generateVideo
};
export const OnBoardingReducer = OnBoardingSlice.reducer;
