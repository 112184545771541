import { Yup } from "../../../utils/yup";

export interface SignInValues {
  username: string;
  password: string;
}

export const SignInSchema = Yup.object().shape({
  username: Yup.string()
    .required()
    .lowercase(),
  password: Yup.string().required()
});
