import { Formik, FormikConfig, yupToFormErrors } from "formik";
import React from "react";

export interface MyFormikProps<T> extends FormikConfig<T> {
  validationContext?: Record<string, any>;
}

export const MyFormik: React.FC<MyFormikProps<any>> = ({
  validationSchema,
  validationContext,
  ...props
}) => (
  <Formik
    validate={async values => {
      try {
        await validationSchema().validate(values, {
          context: validationContext,
          abortEarly: false
        });
      } catch (err) {
        return yupToFormErrors(err);
      }
    }}
    validateOnBlur={true}
    validateOnChange={false}
    {...props}
  />
);
