import { MyRouteProps } from ".";
import { MyConstructionPage } from "../components/common/MyConstructionPage";
import { Fonts } from "../pages/Font";
import { MyCollaborations } from "../pages/MySpace/MyCollaborations";
import { MyCompanies } from "../pages/MySpace/MyCompanies";
import { MySpaceHome } from "../pages/MySpace/Home";
import { Profil } from "../pages/MySpace/Profil";
import { SubscriptionPage } from "../pages/MySpace/Subscription";
import { ScenarioPage } from "../pages/MySpace/Scenario";
import { MySpaceVideoList } from "../pages/MySpace/components/VideoList";
import { MySpaceImageList } from "../pages/MySpace/components/ImageList";
import { MySpaceAudioList } from "../pages/MySpace/components/AudioList";

export const MySpaceRoutes: MyRouteProps[] = [
  { name: "myspace-home", exact: true, path: "/", component: MySpaceHome },
  {
    name: "profile",
    path: "/profile",
    exact: true,
    component: Profil,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "fonts",
    path: "/fonts",
    exact: true,
    component: Fonts,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "assets",
    path: "/assets",
    childRoutes: [
      {
        name: "myspace-assets-videos",
        path: "/videos",
        exact: true,
        component: MySpaceVideoList,
        options: {
          authenticated: true
        }
      },
      {
        name: "myspace-assets-images",
        path: "/images",
        exact: true,
        component: MySpaceImageList,
        options: {
          authenticated: true
        }
      },
      {
        name: "myspace-assets-musics",
        path: "/musics",
        exact: true,
        component: MySpaceAudioList,
        options: {
          authenticated: true
        }
      }
      /* { */
      /*   name: "company-assets-images", */
      /*   path: "/images", */
      /*   exact: true, */
      /* component: MySpaceImageList, */
      /*   options: { */
      /*     authenticated: true */
      /*   } */
      /* }, */
      /* { */
      /*   name: "company-assets-musics", */
      /*   path: "/musics", */
      /*   exact: true, */
      /* component: MySpaceMusicList, */
      /*   options: { */
      /*     authenticated: true */
      /*   } */
      /* } */
    ]
  },
  {
    name: "rush",
    path: "/rush",
    exact: true,
    component: MyConstructionPage,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "subscription",
    path: "/subscription",
    exact: true,
    component: SubscriptionPage,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "help",
    path: "/help",
    exact: true,
    component: MyConstructionPage,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "about",
    path: "/about",
    exact: true,
    component: MyConstructionPage,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "my-companies",
    path: "/my-companies",
    exact: true,
    component: MyCompanies,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "my-collaborations",
    path: "/my-collaborations",
    exact: true,
    component: MyCollaborations,
    options: {
      authenticated: true,
      redirect: "/authentication"
    }
  },
  {
    name: "scenarios",
    path: "/scenarios",
    exact: true,
    component: ScenarioPage,
    options: {
      authenticated: true
    }
  }
];
