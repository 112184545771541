import {
  Box,
  Card,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import VideoIcon from "@material-ui/icons/Movie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { Page } from "../../../components/common/MyPage";
import { VideoItem } from "../../../components/common/VideoItem";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { UserActions } from "../../../reducers/users/reducer";
import { UserSelector } from "../../../reducers/users/selector";
import { Video } from "../../../reducers/videos/entity";
import { VideoSelector } from "../../../reducers/videos/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
export interface MySpaceVideoListProps {}

export const MySpaceVideoList: React.FC<MySpaceVideoListProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const { t } = useTranslation("common");
  const { readVideosStatus } = useSelector(UserSelector.getState);
  const { owned, activationCode } = useSelector(
    VideoSelector.getUserVideosByGroup(currentUser)
  );

  return (
    <DynamicElement<Video[]>
      actions={{
        action: UserActions.readVideos(currentUser),
        status: readVideosStatus
      }}
      data={[...owned, ...activationCode]}
      showCondition={readVideosStatus === FetchingStatus.SUCCESS}
    >
      {() => (
        <Page>
          {owned.length > 0 && (
            <Typography variant="subtitle1">{t("owned")}</Typography>
          )}
          <Box display="flex" flexWrap="wrap">
            {owned.map(v => (
              <Box clone key={v.id} maxWidth={400} m={1} flex="auto">
                <Card>
                  <VideoItem controls width="100%">
                    <source src={v.url} type="video/mp4" />
                  </VideoItem>
                  <ListItem>
                    <ListItemIcon>
                      <VideoIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: "subtitle1" }}
                    >
                      {v.name}
                    </ListItemText>
                  </ListItem>
                </Card>
              </Box>
            ))}
          </Box>
          {activationCode.length > 0 && (
            <Typography variant="subtitle1">
              {t("from-activation-code")}
            </Typography>
          )}
          <Box display="flex" flexWrap="wrap">
            {activationCode.map(v => (
              <Box clone key={v.id} maxWidth={400} m={1} flex="auto">
                <Card>
                  <VideoItem controls width="100%">
                    <source src={v.url} type="video/mp4" />
                  </VideoItem>
                  <ListItem>
                    <ListItemIcon>
                      <VideoIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: "subtitle1" }}
                    >
                      {v.name}
                    </ListItemText>
                  </ListItem>
                </Card>
              </Box>
            ))}
          </Box>
        </Page>
      )}
    </DynamicElement>
  );
};
