import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Slider,
  SliderProps
} from "@material-ui/core";
import get from "lodash/get";
import React, { ReactElement } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { MyIconButton } from "../MyIconButton";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";

interface MySliderFieldProps extends SliderProps, FormProps {
  name: string;
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  percentageDisplay?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%"
    },
    grid: {
      width: "100%"
    },
    slider: {
      width: "100%"
    },
    rail: {
      height: 4
    },
    track: {
      height: 4
    },
    thumb: {
      height: 13
    }
  })
);

export const MySliderField = (props: MySliderFieldProps) => {
  const {
    leftIcon,
    defaultValue,
    rightIcon,
    percentageDisplay,
    label,
    helperText,
    formControlProps,
    formLabelProps,
    formHelperTextProps,
    name,
    ...rest
  } = props;
  const { errors, control } = useFormContext();
  const value = useWatch({ name });
  const errorField = get(errors, name)?.message;
  const classes = useStyles();

  const myFormControlProps: MyFormControlProps = {
    errorField,
    label,
    helperText,
    formLabelProps,
    formHelperTextProps,
    ...formControlProps
  };

  return (
    <MyFormControl {...myFormControlProps}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, onBlur }) => (
          <Grid
            container
            alignItems="center"
            className={classes.grid}
            wrap="nowrap"
          >
            <Grid item>
              <Box clone mr={1}>
                <MyIconButton
                  size="small"
                  tabIndex={-1}
                  onMouseDown={() =>
                    onChange(percentageDisplay ? 0 : rest.min || 0)
                  }
                >
                  {leftIcon}
                </MyIconButton>
              </Box>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Slider
                className={classes.slider}
                classes={{
                  rail: classes.rail,
                  track: classes.track,
                  thumb: classes.thumb
                }}
                max={percentageDisplay ? 1 : rest.max}
                min={percentageDisplay ? 0 : rest.min}
                step={percentageDisplay ? 0.01 : rest.step}
                valueLabelDisplay="auto"
                valueLabelFormat={(value, index) =>
                  percentageDisplay
                    ? `${((value || 0) * 100).toFixed(0)} %`
                    : (rest.valueLabelFormat as any)(value, index)
                }
                onChangeCommitted={(e, newValue) => onChange(newValue)}
                onChange={(_, value) => {
                  onChange(value);
                }}
                onBlur={onBlur}
                {...rest}
                value={typeof value === "number" ? value : 0}
              />
            </Grid>
            <Grid item>
              <Box clone ml={1}>
                <MyIconButton
                  tabIndex={-1}
                  size="small"
                  onMouseDown={() =>
                    onChange(percentageDisplay ? 1 : rest.max || 100)
                  }
                >
                  {rightIcon}
                </MyIconButton>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    </MyFormControl>
  );
};
