import React from "react";
import { Switch } from "react-router-dom";
import { MyRouteProps } from "../../routes";
import { RoutesManager } from "../../routes/RouterManager";

export interface MyPanelProps {
  routes: MyRouteProps[];
  className?: string;
  prefix?: string;
  extraProps?: any;
}

export const MyPanel: React.FC<MyPanelProps> = ({
  routes,
  prefix,
  children
}) => {
  return (
    <>
      {children}
      <Switch>
        <RoutesManager routes={routes} prefix={prefix} />
      </Switch>
    </>
  );
};
