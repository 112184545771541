import { Box, createStyles, TextField, Theme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchComponent: {
      display: "block",
      margin: "0 0 0 auto",
      width: 300,
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      }
    }
  })
);

export const MyTableToolbar: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const { search, localization, onSearchChanged } = props;
  return (
    <Box p={2}>
      {search && (
        <TextField
          InputProps={{
            startAdornment: (
              <Box mr={1} clone>
                <SearchIcon />
              </Box>
            )
          }}
          placeholder={localization.searchPlaceholder}
          fullWidth
          className={classes.searchComponent}
          margin="dense"
          name="search"
          onChange={e => onSearchChanged(e.target.value)}
        />
      )}
    </Box>
  );
};
