import { Yup } from "../../../utils/yup";
import { StringSchema } from "yup";
import i18n from "../../../utils/i18n";

export interface ChangePasswordValues {
  password: string;
  passwordConfirm: string;
}

export const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string(),
  passwordConfirm: Yup.string().when(
    "password",
    (value: string, schema: StringSchema) => {
      if (value) {
        return schema.required().test({
          message: i18n.t("profile:form.password-equality"),
          test: val => val === value
        });
      }
      return schema.notRequired();
    }
  )
});
