import {
  Box,
  createStyles,
  Grid,
  IconButton,
  InputBase,
  InputBaseProps,
  makeStyles,
  SvgIconProps
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import React, { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MyIconButton } from "./MyIconButton";

export interface MySearchInputProps {
  onChange: (value: string) => void;
  value?: string;
  widthEffect?: string | number;
  disabledWidthEffect?: boolean;
  inputBaseProps?: InputBaseProps;
  searchIconProps?: SvgIconProps;
}

const useStyles = makeStyles(() =>
  createStyles({
    inputRoot: {
      color: "inherit",
      transition: "width 0.2s ease-in-out"
    },
    inputInput: {
      color: "inherit"
    },
    searchIconRoot: { color: "inherit" }
  })
);

export const MySearchInput: React.FC<MySearchInputProps> = ({
  onChange,
  value,
  inputBaseProps,
  searchIconProps,
  widthEffect,
  disabledWidthEffect
}) => {
  const classes = useStyles();
  const inputRef = createRef<HTMLInputElement>();
  const [searchActive, setSearchActive] = useState(false);
  const { t } = useTranslation("components");

  const inputStyle = {
    width: !disabledWidthEffect
      ? searchActive
        ? widthEffect || 160
        : 0
      : "auto"
  };
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  const onSearchIconClick = () => {
    setSearchActive(!searchActive);
    if (!searchActive && inputRef.current) inputRef.current.focus();
  };
  const clearSearch = () => {
    onChange("");
  };

  return (
    <Box mx={1}>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item>
          <MyIconButton size="small" onClick={onSearchIconClick}>
            <SearchIcon
              classes={{ root: classes.searchIconRoot }}
              {...searchIconProps}
            />
          </MyIconButton>
        </Grid>
        <Grid item>
          <InputBase
            inputRef={inputRef}
            autoFocus={searchActive}
            value={value}
            onChange={onInputChange}
            placeholder={t("mySearchInput.placeholder")}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            endAdornment={
              (disabledWidthEffect || searchActive) &&
              value &&
              value.length > 0 && (
                <IconButton size="small" onClick={clearSearch}>
                  <CloseIcon color="inherit" fontSize="small" />
                </IconButton>
              )
            }
            style={inputStyle}
            {...inputBaseProps}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
