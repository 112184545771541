import {
  createMuiTheme,
  createStyles,
  Hidden,
  makeStyles,
  MuiThemeProvider,
  Theme,
  Typography
} from "@material-ui/core";
import React, { createContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DynamicElement } from "../../components/common/DynamicElement";
import { MyDrawer } from "../../components/common/MyDrawer";
import { Page } from "../../components/common/MyPage";
import { MyPanel } from "../../components/common/MyPanel";
import { MyTopBar } from "../../components/common/MyTopBar";
import { Language } from "../../components/common/MyTopBar/components/Language";
import { UserMenu } from "../../components/common/MyTopBar/components/UserMenu";
import { commonThemeOptions } from "../../components/theme/common";
import { getCurrentUser } from "../../reducers/authentication/selector";
import { Company, CompanyWithRelation } from "../../reducers/companies/entity";
import { CompanyActions } from "../../reducers/companies/reducer";
import { CompanySelector } from "../../reducers/companies/selector";
import { RootState } from "../../reducers/store";
import { CompaniesRoute } from "../../routes/companies.route";
import CompanyLogo from "./assets/default_logo.png";
import { CompanyMenus } from "./components/Menus";

export interface ExtraCompanyProps {
  company: CompanyWithRelation;
  isOwner: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {},
    appbar: {},
    toolbarSpacer: theme.mixins.toolbar,
    companyLogo: {
      height: 50,
      maxWidth: 130,
      objectFit: "contain",
      marginRight: theme.spacing()
    },
    title: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 180
      }
    }
  })
);

export const CompanyContext = createContext<Company>({} as Company);

interface CompanyPageProps {}
export const CompanyPage: React.FC<CompanyPageProps> = () => {
  const classes = useStyles();
  const { id: companyId } = useParams<{ id: string }>();
  const currentUser = useSelector(getCurrentUser);
  const companySelected = useSelector((state: RootState) =>
    CompanySelector.selectById(state, companyId)
  );

  const { readOneStatus } = useSelector(CompanySelector.getState);

  // const dispatch = useDispatch();

  //TODO CHECK IF IT WORKS
  // useEffect(() => {
  //   return () => {
  //     dispatch(new CompanySelected(undefined));
  //   };
  // }, [dispatch]);
  return (
    <Page>
      <DynamicElement<CompanyWithRelation>
        data={companySelected as CompanyWithRelation}
        actions={{
          action: CompanyActions.readOneCompany(companyId),
          status: readOneStatus
        }}
        showCondition={!!companySelected}
      >
        {company => {
          const isOwner = company.userId === currentUser.id;
          const theme = createMuiTheme(
            company.setting
              ? {
                  ...commonThemeOptions,
                  palette: {
                    primary: { main: company.setting.primaryColor },
                    secondary: { main: company.setting.secondaryColor }
                  }
                }
              : commonThemeOptions
          );
          return (
            <CompanyContext.Provider value={company}>
              <MuiThemeProvider theme={theme}>
                <MyPanel
                  routes={CompaniesRoute({ company, isOwner })}
                  prefix="/company/:id"
                  extraProps={{
                    company: companySelected,
                    isOwner
                  }}
                >
                  <MyTopBar
                    toolbarProps={{
                      style: {
                        background: `linear-gradient(121deg, ${theme.palette.primary.light} 190px, ${theme.palette.primary.main} 380px)`,
                        color: theme.palette.getContrastText(
                          theme.palette.primary.main
                        )
                      }
                    }}
                    leftActions={
                      <>
                        <Hidden xsDown>
                          <img
                            alt="logo-company"
                            src={
                              company.watermarks && company.watermarks.length
                                ? (company.watermarks[0].image &&
                                    company.watermarks[0].image.url) ||
                                  CompanyLogo
                                : CompanyLogo
                            }
                            className={classes.companyLogo}
                          />
                        </Hidden>
                        <Typography
                          className={classes.title}
                          variant="h6"
                          color="inherit"
                        >
                          {company.name}
                        </Typography>
                      </>
                    }
                    rightActions={
                      <>
                        <Hidden xsDown>
                          <Language />
                        </Hidden>
                        <UserMenu />
                      </>
                    }
                  />
                  <MyDrawer>
                    <Hidden smDown>
                      <div className={classes.toolbarSpacer} />
                    </Hidden>
                    <CompanyMenus company={company} isOwner={isOwner} />
                  </MyDrawer>
                </MyPanel>
              </MuiThemeProvider>
            </CompanyContext.Provider>
          );
        }}
      </DynamicElement>
    </Page>
  );
};
