export enum CompanyActionsTypes {
  COMPANY_DELETE = "@@COMPANY delete company",
  COMPANY_DELETE_STATUS = "@@COMPANY delete company status",
  COMPANY_READ_ONE = "@@COMPANY read one company",
  COMPANY_READ_ONE_STATUS = "@@COMPANY read one status company",
  COMPANY_ADD = "@@COMPANY add company",
  COMPANY_REMOVE = "@@COMPANY remove company",
  COMPANY_SELECTED = "@@COMPANY company selected",
  COMPANY_REMOVE_COLLABORATOR = "@@COMPANY remove collaborator",
  COMPANY_REMOVE_COLLABORATOR_STATUS = "@@COMPANY remove collaborator status",
  COMPANY_ADD_COLLABORATOR = "@@COMPANY add collaborator",
  COMPANY_ADD_COLLABORATOR_STATUS = "@@COMPANY add collaborator status",
  COMPANY_READ_COLLABORATORS = "@@COMPANY read collaborators",
  COMPANY_READ_COLLABORATORS_STATUS = "@@COMPANY read collaborators status",
  COMPANY_READ_ASSETS = "@@COMPANY read assets",
  COMPANY_READ_ASSETS_STATUS = "@@COMPANY read assets status",
  COMPANY_READ_ASSETS_AUDIOS = "@@COMPANY read assets audios",
  COMPANY_READ_ASSETS_AUDIOS_STATUS = "@@COMPANY read assets audios status",
  COMPANY_UPLOAD_ASSETS_AUDIO = "@@COMPANY upload assets audio",
  COMPANY_UPLOAD_ASSETS_AUDIO_STATUS = "@@COMPANY upload assets audio status",
  COMPANY_READ_ASSETS_IMAGE = "@@COMPANY read assets images",
  COMPANY_READ_ASSETS_IMAGES_STATUS = "@@COMPANY read assets image status",
  COMPANY_UPLOAD_ASSET_IMAGE = "@@COMPANY upload assets images",
  COMPANY_UPLOAD_ASSET_IMAGE_STATUS = "@@COMPANY upload assets image status",
  COMPANY_UPLOAD_WATERMARK_IMAGE = "@@COMPANY upload watermark image",
  COMPANY_UPLOAD_WATERMARK_IMAGE_STATUS = "@@COMPANY upload watermark image status",
  COMPANY_REMOVE_WATERMARK_IMAGE = "@@COMPANY remove watermark image",
  COMPANY_REMOVE_WATERMARK_IMAGE_STATUS = "@@COMPANY remove watermark image status",
  COMPANY_READ_ASSETS_VIDEO = "@@COMPANY read assets video",
  COMPANY_READ_ASSETS_VIDEOS_STATUS = "@@COMPANY read assets video status",
  COMPANY_UPLOAD_ASSET_VIDEO = "@@COMPANY upload assets video",
  COMPANY_UPLOAD_ASSET_VIDEO_STATUS = "@@COMPANY upload assets video status",
  COMPANY_READ_SCENARIOS = "@@COMPANY read scenarios",
  COMPANY_READ_SCENARIOS_STATUS = "@@COMPANY read scenarios status",
  COMPANY_CREATE_SCENARIOS = "@@COMPANY create scenario",
  COMPANY_CREATE_SCENARIOS_STATUS = "@@COMPANY create scenario status",
  COMPANY_UPDATE_SCENARIOS = "@@COMPANY update scenario",
  COMPANY_UPDATE_SCENARIOS_STATUS = "@@COMPANY update scenario status",
  COMPANY_READ_PROJECTS = "@@COMPANY read projects",
  COMPANY_CREATE_PROJECT = "@@COMPANY create project",
  COMPANY_ASSET_SET_BLOB = "@@COMPANY set buffer to video",
  COMPANY_READ_ASSETS_TRANSFERED_IMAGE = "@@COMPANY read assets transfered images",
  COMPANY_READ_ASSETS_TRANSFERED_VIDEO = "@@COMPANY read assets transfered video",
  COMPANY_READ_ASSETS_TRANSFERED_AUDIOS = "@@COMPANY read assets transfered audios"
}
