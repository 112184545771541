import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Axios from "axios";
import { push } from "connected-react-router";
import merge from "lodash/merge";
import { DeepPartial } from "redux";
import { RootClear } from "..";
import { User } from "../../entities/user";
import { SignInValues } from "../../pages/Authentication/components/SignIn.schema";
import { removeToken, saveToken } from "../../utils/reconnection";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { Request } from "../../utils/request";
import { getUseLegacy } from "../app/api";
import { Domain } from "../domains/entity";
import { DomainActions } from "../domains/reducer";
import { FontActions } from "../fonts/reducer";
import { AuthenticationActionsTypes } from "./action";
import * as Api from "./api";

export interface AuthenticationState {
  user?: User;
  domain?: Domain;
  signInStatus: FetchingStatus;
  signUpStatus: FetchingStatus;
  logOutStatus: FetchingStatus;
  retryConnectionStatus: FetchingStatus;
  updateAccountStatus: FetchingStatus;
  forgetPasswordStatus: FetchingStatus;
  changePasswordStatus: FetchingStatus;
}

export const AuthenticationInitialState: AuthenticationState = {
  user: undefined,
  retryConnectionStatus: FetchingStatus.NULL,
  signInStatus: FetchingStatus.NULL,
  signUpStatus: FetchingStatus.NULL,
  logOutStatus: FetchingStatus.NULL,
  updateAccountStatus: FetchingStatus.NULL,
  forgetPasswordStatus: FetchingStatus.NULL,
  changePasswordStatus: FetchingStatus.NULL
};

export const signIn = createMyAsyncThunk<User, SignInValues>(
  AuthenticationActionsTypes.SIGNIN_FETCH,
  values => Request().post("/sign-in", values),
  {
    onSuccess: ({ result: user, thunkApi: { dispatch } }) => {
      saveToken(user.token);
      dispatch(push(!user.onBoarding ? "/my-space/profile" : "/my-space")); //skip on boarding page
      //dispatch(push(!user.onBoarding ? "/on-boarding" : "/my-space"));
      dispatch(FontActions.readPublic());
      if (user.domains) dispatch(DomainActions.readOneDomain(user.domains.id));
      return user;
    },
    onSuccessMessage: "authentication:response.sign-in-success",
    onFailedMessage: "authentication:response.sign-in-failed"
  }
);

export const signUp = createMyAsyncThunk<DeepPartial<User>, DeepPartial<User>>(
  AuthenticationActionsTypes.SIGNUP_FETCH,
  values => Request().post("/sign-up", values),
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(push({ pathname: "/authentication", search: "?authTab=0" }));
      return result;
    },
    onSuccessMessage: "authentication:response.sign-up-success",
    onFailedMessage: "authentication:response.sign-in-failed"
  }
);

export const retryConnection = createMyAsyncThunk<User, string>(
  AuthenticationActionsTypes.RETRY_CONNECTION,
  token =>
    Axios.get("/sign-in-by-token", {
      baseURL: `https://api.izivisite.proprietes-privees.com.feature-platformsh-6fw3zbi-jo4sw2dkixjly.eu-5.platformsh.site/api`,
      headers: {
        Authorization: "Bearer " + token
      }
    }),
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(FontActions.readPublic());
      return result;
    },
    onFailed: ({ thunkApi: { dispatch } }) => {
      removeToken();
      dispatch(push("/authentication"));
    },
    onFailedMessage: "authentication:response.retry-connection"
  }
);

export const logOut = createAsyncThunk(
  AuthenticationActionsTypes.LOG_OUT_FETCH,
  (_, { dispatch }) => {
    removeToken();
    dispatch(new RootClear());
    dispatch(push("/"));
    dispatch(getUseLegacy);
  }
);

export const updateAccount = createMyAsyncThunk(
  AuthenticationActionsTypes.UPDATE_ACCOUNT_FETCH,
  (partialUser: DeepPartial<User>, { getState }) => {
    return Request({ withToken: true }).patch(
      `/users/${getState().authentication.user?.id}/account`,
      partialUser
    );
  },
  {
    onSuccessMessage: "authentication:response.update-account-success",
    onFailedMessage: "authentication:response.update-account-failed"
  }
);

export const forgetPassword = createMyAsyncThunk(
  AuthenticationActionsTypes.FORGET_PASSWORD,
  Api.forgetPassword,
  {
    onSuccessMessage: "saga:forget-password.success"
  }
);

export const changePassword = createMyAsyncThunk(
  AuthenticationActionsTypes.CHANGE_PASSWORD,
  Api.changePassword,
  {
    onSuccessMessage: "saga:change-password.success"
  }
);

const AuthenticationSlice = createMySlice({
  name: "authentication",
  initialState: AuthenticationInitialState,
  asyncActions: [
    {
      action: signIn,
      statusName: "signInStatus",
      onSuccess: (state: AuthenticationState, action: PayloadAction<User>) => {
        state.user = merge({}, state.user, action.payload);
      }
    },
    {
      action: retryConnection,
      statusName: "retryConnectionStatus",
      onSuccess: (state: AuthenticationState, action: PayloadAction<User>) => {
        state.user = merge({}, state.user, action.payload);
      }
    },
    { action: signUp, statusName: "signUpStatus" },
    {
      action: logOut,
      statusName: "logOutStatus",
      onSuccess: (state: AuthenticationState) => {
        removeToken();
        state.user = AuthenticationInitialState.user;
      }
    },
    {
      action: updateAccount,
      statusName: "updateAccountStatus",
      onSuccess: (state: AuthenticationState, action: PayloadAction<User>) => {
        state.user = merge({}, state.user, action.payload || {});
      }
    },
    { action: forgetPassword, statusName: "forgetPasswordStatus" },
    { action: changePassword, statusName: "changePasswordStatus" }
  ],
  reducers: {}
});

// export default AuthenticationSlice.reducer;

export const AuthenticationReducer = AuthenticationSlice.reducer;
export const AuthenticationActions = {
  signIn,
  retryConnection,
  signUp,
  logOut,
  updateAccount,
  forgetPassword,
  changePassword,
  ...AuthenticationSlice.actions
};

// case AuthenticationActionsTypes.LOG_OUT_STATUS:
//   draft.logOutStatus = action.status;
//   localStorage.removeItem("token");
//   draft.user = AuthenticationInitialState.user;
//   break;
// case AuthenticationActionsTypes.UPDATE_ACCOUNT_STATUS:
//   draft.updateAccountStatus = action.status;
//   draft.user = merge({}, state.user, action.newUserData || {});
//   break;
// case AuthenticationActionsTypes.LEAVE_COMPANY_STATUS:
//   draft.leaveCompanyStatus = action.status;
//   break;
// case AuthenticationActionsTypes.READ_SCENARIO_STATUS:
//   draft.readScenarioStatus = action.status;
//   break;
// case AuthenticationActionsTypes.READ_SHARED_SCENARIO_STATUS:
//   draft.readScenarioStatus = action.status;
//   break;
