import {
  Box,
  Card,
  CardHeader,
  createStyles,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from "@material-ui/core";
import { lightGreen } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ExtraCompanyProps } from "../..";
import { DynamicElement } from "../../../../components/common/DynamicElement";
import { MyIconButton } from "../../../../components/common/MyIconButton";
import { Page } from "../../../../components/common/MyPage";
import { UploadAssetButton } from "../../../../components/common/UploadAssetButton";
import { AssetsType } from "../../../../entities/asset";
import { AppSelector } from "../../../../reducers/app/selector";
import { Audio } from "../../../../reducers/audios/entity";
import { AudioActions } from "../../../../reducers/audios/reducer";
import { CompanyActions } from "../../../../reducers/companies/reducer";
import { CompanySelector } from "../../../../reducers/companies/selector";
import { useAppDispatch } from "../../../../reducers/store";
import { FetchingStatus } from "../../../../utils/reducers/fetchingStatus";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { RoleNames } from "../../../../reducers/roles/entity";
import { Company } from "../../../../reducers/companies/entity";
import { useSnackbar } from "notistack";

interface ListProps {
  audios: Audio[];
  company?: Company;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    musicCard: {
      margin: theme.spacing(2)
    },
    audio: {
      width: "100%"
    },
    listItem: {
      width: 160
    },
    pngImages: {
      backgroundColor: lightGreen[400]
    }
  })
);

export const MusicList: React.FC<ExtraCompanyProps> = ({ company }) => {
  const { readAudiosTransferedStatus } = useSelector(CompanySelector.getState);
  const { t } = useTranslation("company");
  const { t: gtv } = useTranslation("gtv");
  const dispatch = useAppDispatch();
  const { uploadAudioStatus } = useSelector(CompanySelector.getState);
  const audios = useSelector(CompanySelector.getAudios(company));
  const currentUser = useSelector(getCurrentUser);
  const { enqueueSnackbar } = useSnackbar();

  const uploadAudiosAssets = async (formData: FormData, filesName: string[]) => {
    // check if filesName list string is present on images list name
    const isStringPresent = filesName.some(filename => {
      const lastDotIndex = filename.lastIndexOf('.');
      const nameWithoutExtension = filename.substring(0, lastDotIndex);
      return audios.some(audio => audio.name === nameWithoutExtension);
    });
  
    if(isStringPresent) {
      enqueueSnackbar(gtv("form.name-exist"), { variant: "error" });
      return false;
    }

    await dispatch(CompanyActions.uploadAudio({ company, formData })); 
  }

  useEffect(() => {
    dispatch(CompanyActions.readAudiosTransfered(company));
  }, [company, dispatch]);

  return (
    <>
      {(company?.userId === currentUser.id ||
        (currentUser.role &&
          [RoleNames.ADMIN, RoleNames.GOD].includes(
            currentUser.role.name
          ))) && (
            <UploadAssetButton
            variant="contained"
            color="primary"
            assetType={AssetsType.AUDIO}
            loadingStatus={uploadAudioStatus}
            onSubmitAsset={async (formData: FormData, fileNames?: string[]) =>
              await uploadAudiosAssets(formData, fileNames || [])
            }
          >
            {t("assets.audio-upload")}
          </UploadAssetButton>
      )}

      <DynamicElement<Audio[]>
        actions={{
          action: CompanyActions.readAudios(company),
          status: readAudiosTransferedStatus
        }}
        data={audios}
        showCondition={
          !!audios &&
          audios.length > 0 &&
          readAudiosTransferedStatus === FetchingStatus.SUCCESS
        }
      >
        {audios => <List audios={audios} company={company} />}
      </DynamicElement>
    </>
  );
};
export const List: React.FC<ListProps> = ({ audios, company }) => {
  const classes = useStyles();
  const { language: lang } = useSelector(AppSelector.getState);
  const { t: tCommon } = useTranslation("common");
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const onDelete = (id: string) => {
    dispatch(AudioActions.async.removeOne(id));
  };
  return (
    <Page>
      <Box display="flex" flexWrap="wrap">
        {audios.map(a => (
          <Box clone key={a.id} maxWidth={400} m={1} flex="auto">
            <Card className={classes.musicCard}>
              <CardHeader
                avatar={<MusicNoteIcon />}
                action={(company?.userId === currentUser.id ||
                  (currentUser.role &&
                    [RoleNames.ADMIN, RoleNames.GOD].includes(
                      currentUser.role.name
                    ))) ?
                    <MyIconButton
                    menus={({ onClose }) => (
                      <MenuItem
                        dense={true}
                        onClick={() => {
                          onClose();
                          onDelete(a.id);
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 36 }}>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <Typography color="error">
                          {tCommon("delete")}
                        </Typography>
                      </MenuItem>
                    )}
                  >
                    <MoreVertIcon />
                  </MyIconButton> : null
                }
                title={a.name}
                subheader={`${tCommon("created-at")} ${new Date(
                  a.createdAt
                ).toLocaleDateString(lang)}`}
              />
              <audio controls src={a.url} className={classes.audio} />
            </Card>
          </Box>
        ))}
      </Box>
    </Page>
  );
};
