import { Yup } from "../../utils/yup";
import { ScenarioDTO } from "../../reducers/scenarios/entity";
import { JsonTranslatorSchema } from "../../utils/yup/common-schema";
import { MixedSchema } from "yup";

export interface GtvValues extends ScenarioDTO {}
export interface ScenarioValues extends ScenarioDTO {}
const { array, boolean, mixed, number, object, string } = Yup;
export type ScenarioSchemaValues = ScenarioValues;
export const ScenarioDefaultValues = {
  lockProperties: [],
  label: {
    "en-US": "",
    "fr-FR": ""
  },
  description: {
    "en-US": "",
    "fr-FR": ""
  },
  posterId: undefined,
  availableFormats: [],
  tagIds: [],
  enabled: true,
  wantAudioMix: false,
  audioMix: {
    playOnGenerics: false,
    audioId: undefined,
    lockProperties: []
  },
  stories: [
    {
      lockProperties: [],
      label: { "fr-FR": "", "en-US": "" },
      description: { "fr-FR": "", "en-US": "" },
      audioId: undefined,
      enabled: true,
      startEffectId: undefined,
      startEffectDuration: undefined,
      endEffectId: undefined,
      endEffectDuration: undefined,
      shots: [
        {
          lockProperties: [],
          label: { "fr-FR": "", "en-US": "" },
          description: { "fr-FR": "", "en-US": "" },
          instruction: { "fr-FR": "", "en-US": "" },
          videoId: undefined,
          recommendedDuration: undefined,
          type: undefined,
          musicVolumeWithAudioMix: undefined,
          videoVolumeWithAudioMix: undefined,
          musicVolume: undefined,
          videoVolume: undefined,
          shotCategoryId: undefined,
          audioId: undefined,
          enabled: true,
          posterId: undefined
        }
      ]
    }
  ]
};
export const AudioMixSchema = mixed().when(
  "$wantAudioMix",
  (val: boolean, schema: MixedSchema) => {
    if (!val) {
      return schema.transform(() => null);
    }
    return object({
      id: string(),
      scenarioId: string(),
      lockProperties: array()
        .of(string())
        .default([]),
      playOnGenerics: boolean().default(false),
      audioId: string().required()
    });
  }
);

export const ShotSchemaObject = {
  id: string(),
  storyId: string(),
  lockProperties: array()
    .of(string())
    .default([]),
  label: JsonTranslatorSchema(true),
  description: JsonTranslatorSchema().nullable(),
  instruction: JsonTranslatorSchema().nullable(),
  order: number()
    .integer()
    .required(),
  recommendedDuration: number()
    .transform((v, o) => (!o ? undefined : v))
    .positive()
    .required(),
  type: string()
    .default(null)
    .nullable()
    .oneOf(["WIDE", "MEDIUM", "CLOSE", null]),
  shotCategoryId: string().required(),
  posterId: string()
    .defined()
    .default(null)
    .nullable(),
  videoId: string()
    .defined()
    .default(null)
    .nullable(),
  enabled: boolean()
    .required()
    .default(true),
  musicVolumeWithAudioMix: number()
    .transform((v, o) => (!o ? undefined : v))
    .min(0)
    .max(1)
    .default(0),
  videoVolumeWithAudioMix: number()
    .transform((cv, ov) => (!ov ? 0 : cv))
    .min(0)
    .max(1)
    .default(0),
  musicVolume: number()
    .transform((cv, ov) => (!ov ? 0 : cv))
    .min(0)
    .max(1)
    .default(0),
  videoVolume: number()
    .transform((cv, ov) => (!ov ? 0 : cv))
    .min(0)
    .max(1)
    .default(0)
  // videoTutorialId: string(),
};
export const ShotSchema = object(ShotSchemaObject);

export const StorySchemaObject = {
  id: string(),
  scenarioId: string(),
  lockProperties: array()
    .of(string())
    .default([]),
  label: JsonTranslatorSchema(true),
  description: JsonTranslatorSchema()
    .nullable()
    .default(null),
  order: number()
    .integer()
    .required(),
  audioId: string()
    .defined()
    .default(null)
    .nullable(),
  enabled: boolean()
    .required()
    .default(true),
  startEffectId: string()
    .defined()
    .nullable()
    .default(null),
  startEffectDuration: number()
    .transform((cv, ov) => (!ov ? null : cv))
    .nullable()
    .default(null),
  endEffectId: string()
    .defined()
    .nullable()
    .default(null),
  endEffectDuration: number()
    .transform((cv, ov) => (!ov ? null : cv))
    .nullable()
    .default(null)
};
export const StorySchema = object(StorySchemaObject);

export const ScenarioSchemaObject = {
  lockProperties: array()
    .of(string())
    .default([]),
  label: JsonTranslatorSchema(true),
  description: JsonTranslatorSchema()
    .nullable()
    .default(null),
  availableFormats: array()
    .required()
    .default([])
    .min(1)
    .of(string().oneOf(["1/1", "9/16", "16/9", "16/10"])),
  tagIds: array()
    .of(string().required())
    .default([]),
  posterId: string().required(),
  enabled: boolean()
    .required()
    .default(true),
  wantAudioMix: boolean()
    .default(false)
    .required()
};

export const ScenarioSchema = object(ScenarioSchemaObject);

export const GtvSchema = object().shape({
  ...ScenarioSchemaObject,
  stories: array()
    .min(1)
    .default([])
    .of(
      object({
        ...StorySchemaObject
        // shots: array()
        //   .min(1)
        //   .default([])
        //   .of(ShotSchema)
      })
    )
});
