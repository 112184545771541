import { PayloadAction } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { CSSProperties } from "react";

export interface AppBarState {
  title?: string;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  style?: CSSProperties;
}

export const AppBarInitialState: AppBarState = {
  title: "",
  rightActions: undefined,
  leftActions: undefined,
  style: undefined
};

export const AppBarSlice = createMySlice({
  name: "appbar",
  initialState: AppBarInitialState,
  reducers: {
    setState(state, action: PayloadAction<AppBarState | undefined>) {
      return action.payload || AppBarInitialState;
    }
  }
});

export const AppBarReducer = AppBarSlice.reducer;
export const AppBarActions = AppBarSlice.actions;
