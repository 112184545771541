import {
  Card,
  makeStyles,
  Theme,
  createStyles,
  ButtonBase,
  Typography
} from "@material-ui/core";
import React from "react";
import { Image } from "../../reducers/images/entity";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 2,
      height: 210,
      position: "relative"
    },
    image: {
      width: "100%",
      height: 210,
      objectFit: "cover"
    },
    label: {
      background: `rgba(0,0,0,0.7)`,
      borderRadius: 4,
      color: "white",
      position: "absolute",
      bottom: 0,
      lineHeight: "24px",
      height: 24,
      width: "100%"
    },
    labelSelected: {
      background: theme.palette.success.main
    },
    selected: {
      borderLeft: `4px solid ${theme.palette.success.main}`,
      borderRight: `4px solid ${theme.palette.success.main}`,
      borderTop: `4px solid ${theme.palette.success.main}`
    },
    pngImages: {
      // backgroundColor: lightGreen[400]
    }
  })
);

export interface ImageItemProps {
  image: Image;
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  height?: React.CSSProperties["height"];
  width?: React.CSSProperties["width"];
}
export const MyImage: React.FC<ImageItemProps> = ({
  image,
  isSelected,
  onClick,
  className,
  style,
  height,
  width
}) => {
  const classes = useStyles();
  return (
    <Card
      onClick={onClick}
      style={{ height, width, ...style }}
      className={clsx(classes.root, className, {
        [classes.selected]: isSelected
      })}
    >
      <ButtonBase>
        <img
          className={clsx(classes.image, {
            [classes.pngImages]: image.format === "png"
          })}
          style={{ height, width }}
          src={image.url}
          alt={image.name}
        />
      </ButtonBase>
      <Typography
        align="center"
        className={clsx(classes.label, {
          [classes.labelSelected]: isSelected
        })}
      >
        {image.name}
      </Typography>
    </Card>
  );
};
