import { Request } from "../../utils/request";
import { Company, CompanyCreateDTO } from "../companies/entity";
import { User } from "../../entities/user";
import { FontConfig } from "../fonts-config/entity";
import { Project } from "../projects/entity";

export const readOneUser = (id: string) =>
  Request({ withToken: true }).get(`/users/${id}`);

export const readCompanies = (user: User) =>
  Request({ withToken: true }).get<Company[]>(`/users/${user.id}/companies`);

export const readCollaborations = (user: User) =>
  Request({ withToken: true }).get<User[]>(`/users/${user.id}/collaborations`);

export const readFontConfigs = (user: User) =>
  Request({ withToken: true }).get<FontConfig[]>(
    `/users/${user.id}/font-configs`
  );

export const readUserProjects = (user: User) =>
  Request({ withToken: true }).get<Project[]>(`/users/${user.id}/projects`);

export const removeFontConfig = (args: {
  user: User;
  fontConfig: FontConfig;
}) =>
  Request({ withToken: true }).delete(
    `/users/${args.user.id}/font-configs/${args.fontConfig.id}`
  );

export function createCompany(args: { user: User; company: CompanyCreateDTO }) {
  return Request({ withToken: true }).post<Company>(
    `/users/${args.user.id}/companies`,
    args.company
  );
}

export function readVideos(user: User) {
  return Request({ withToken: true }).get(`/users/${user.id}/videos`);
}

export function readImages(user: User) {
  return Request({ withToken: true }).get(`/users/${user.id}/images`);
}

export function readAudios(user: User) {
  return Request({ withToken: true }).get(`/users/${user.id}/audios`);
}
