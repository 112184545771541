import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TutorialsCard } from "./card";
import { baseURL } from "../../utils/request";

export interface TutorialsPageProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  })
);

export const TutorialsPage: React.FC<TutorialsPageProps> = () => {
  const classes = useStyles();

  const [tutos, setTutos] = useState<any[]>([]);

  useEffect(() => {
    fetch(baseURL + "/tutorials")
      .then(response => response.json())
      .then(data => setTutos(data));
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={5} alignItems="center">
        {tutos?.map(tuto => (
          <Grid
            item
            key={tuto.id}
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={12}
            sm={6}
          >
            <TutorialsCard tuto={tuto} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
