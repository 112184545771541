export enum ScenarioActionsTypes {
  SCENARIO_READ_ONE = "@@SCENARIO read one scenario",
  SCENARIO_READ_ONE_STATUS = "@@SCENARIO read one status scenario",
  SCENARIO_REMOVE_ONE = "@@SCENARIO remove one scenario",
  SCENARIO_REMOVE_ONE_STATUS = "@@SCENARIO remove one status scenario",
  SCENARIO_SHARED_FROM_USER_TO_COMPANIES = "@@SCENARIO shared from user to companies",
  SCENARIO_SHARED_FROM_USER_TO_COMPANIES_STATUS = "@@SCENARIO shared from user to companies status",
  UPDATE = "@@SCENARIO update scenario",
  UPDATE_STATUS = "@@SCENARIO update scenario status",
  SCENARIO_SET_TO_COMPANY = "@@SCENARIO set owner to company"
}
