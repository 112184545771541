import {
  IconButton,
  Theme,
  CircularProgress,
  CircularProgressProps,
  Box,
  BoxProps,
  TooltipProps,
  Tooltip
} from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { MyMenu, MyMenuProps } from "./MyMenu";

interface MyIconButtonProps extends IconButtonProps {
  boxProps?: BoxProps;
  to?: LinkProps["to"];
  linkProps?: Omit<LinkProps, "to">;
  tooltipProps?: Omit<TooltipProps, "children">;
  circularProgressProps?: CircularProgressProps;
  bgColorHover?: string;
  loading?: boolean;
  menus?: (props: { onClose: () => void }) => ReactNode;
  menuProps?: Omit<MyMenuProps, "component" | "id" | "children">;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ bgColorHover }: { bgColorHover: string }) => ({
      "&:hover": {
        backgroundColor: bgColorHover
      }
    }),
    link: {
      textDecoration: "none"
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -16,
      marginLeft: -16
    }
  })
);

export const MyIconButton: React.FC<MyIconButtonProps> = ({
  to,
  children,
  linkProps,
  loading,
  menus,
  menuProps,
  circularProgressProps,
  bgColorHover,
  boxProps,
  tooltipProps,
  ...rest
}) => {
  const classes = useStyle({ bgColorHover: bgColorHover || "inherit" });

  let button = (
    <Box clone {...boxProps}>
      <IconButton
        {...rest}
        disabled={rest.disabled || loading}
        className={classes.root}
      >
        {children}
        {loading && (
          <CircularProgress
            size={32}
            className={classes.buttonProgress}
            {...circularProgressProps}
          />
        )}
      </IconButton>
    </Box>
  );
  if (to) {
    button = (
      <Link to={to} className={classes.link} {...linkProps}>
        {button}
      </Link>
    );
  }
  if (menus) {
    button = (
      <MyMenu component={button} id="button-menu" {...menuProps}>
        {menus}
      </MyMenu>
    );
  }
  if (tooltipProps) {
    button = (
      <Tooltip {...tooltipProps}>
        <div>{button}</div>
      </Tooltip>
    );
  }
  return button;
};
