import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../reducers/app/reducer";
import { AppSelector } from "../../reducers/app/selector";

let displayed = [] as any[];

export const Notifier = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(AppSelector.getState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event: any, reason: any) => {
            if (options.onClose) {
              options.onClose(event, reason);
            }
          },
          onExited: (event: any, myKey: any) => {
            // removen this snackbar from redux store
            dispatch(AppActions.closeSnackbar(myKey));
            removeDisplayed(myKey);
          }
        } as any);

        // keep track of snackbars that we've displayed
        storeDisplayed(key as any);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};
