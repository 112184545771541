import { Request } from "../../utils/request";
import { ScenarioDTO } from "./entity";

export const readOneScenario = (id: string) =>
  Request({ withToken: true }).get(`/scenarios/${id}`);

export const updateScenario = (id: string, scenario: ScenarioDTO) => {
  return Request({ withToken: true }).put(`/scenarios/${id}`, scenario);
};

export const removeOneScenario = (id: string) => {
  return Request({ withToken: true }).delete(`/scenarios/${id}`);
};

export const setScenarioOwnerToCompany = ({
  id,
  companyId
}: {
  id: string;
  companyId: string;
}) => {
  return Request({ withToken: true }).put(
    `/scenarios/${id}/companies/${companyId}/owner`
  );
};
