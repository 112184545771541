export enum UserActionsTypes {
  READ_ONE_USER = "@@USER read one",
  UPDATE_ONBOARDING = "@@CURRENT_USER update on boarding",
  LEAVE_COMPANY = "@@CURRENT_USER leave company",
  LEAVE_COMPANY_STATUS = "@@CURRENT_USER leave company status",
  READ_SCENARIO = "@@CURRENT_USER read scenario",
  READ_SCENARIO_STATUS = "@@CURRENT_USER read scenario status",
  READ_SHARED_SCENARIO = "@@CURRENT_USER read shared scenario",
  READ_SHARED_SCENARIO_STATUS = "@@CURRENT_USER read shared scenario status",
  SHARE_SCENARIO_TO_USER_COMPANIES = "@@CURRENT_USER share scenarios to user's companies",
  READ_VIDEOS = "@@CURRENT_USER read videos",
  READ_IMAGES = "@@CURRENT_USER read images",
  READ_AUDIOS = "@@CURRENT_USER read audios",
  READ_FONTCONFIGS = "@@CURRENT_USER read font configs",
  REMOVE_FONTCONFIG = "@@CURRENT_USER remove font config",
  READ_COMPANIES = "@@CURRENT_USER read companies",
  READ_COLLABORATIONS = "@@CURRENT_USER read collaborations",
  READ_PROJECTS = "@@CURRENT_USER read projects",
  CREATE_COMPANY = "@@CURRENT_USER create company"
}
