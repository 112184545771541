import React from "react";
import { BoxProps, Paper, Box } from "@material-ui/core";

interface DotStatusProps extends BoxProps {
  size: number;
  isValid: boolean;
}

export const DotStatus: React.FC<DotStatusProps> = ({
  size,
  isValid,
  ...rest
}) => {
  return (
    <Box
      component={Paper}
      height={size}
      width={size}
      borderRadius="100%"
      border="1px solid white"
      bgcolor={!isValid ? "warning.main" : "success.main"}
      {...rest}
    />
  );
};
