import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { ImageActionsTypes } from "./action";
import { Image } from "./entity";
import * as Api from "./api";

export interface ImageState extends EntityState<Image> {
  readStatus: FetchingStatus;
  readPremiumStatus: FetchingStatus;
  removeOneStatus: FetchingStatus;
}

export const ImageInitialState: ImageState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  readPremiumStatus: FetchingStatus.NULL,
  removeOneStatus: FetchingStatus.NULL
};

export const ImageAdapter = createEntityAdapter<Image>();

const ImageAdapterState = ImageAdapter.getInitialState(ImageInitialState);

export const read = createMyAsyncThunk<Image[]>(
  ImageActionsTypes.IMAGE_READ,
  Api.read
);

export const readPremium = createMyAsyncThunk<Image[]>(
  ImageActionsTypes.IMAGE_READ_PREMIUM,
  Api.readPremium
);

export const removeOne = createMyAsyncThunk(
  ImageActionsTypes.IMAGE_REMOVE_ONE,
  Api.removeOne,
  { onSuccess: ({ values }) => values, onSuccessMessage: "saga:delete-success" }
);



const ImageSlice = createMySlice({
  name: "images",
  initialState: ImageAdapterState,
  adapter: ImageAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ImageAdapter.upsertMany
    },
    {
      action: readPremium,
      statusName: "readPremiumStatus",
      onSuccess: ImageAdapter.upsertMany
    },
    {
      action: removeOne,
      statusName: "removeOneStatus",
      onSuccess: ImageAdapter.removeOne
    }
  ],
  reducers: {}
});

export const ImageReducer = ImageSlice.reducer;
export const ImageActions = {
  ...ImageSlice.actions,
  read,
  readPremium,
  async: {
    readPremium,
    read,
    removeOne
  }
};
