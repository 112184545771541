import { createSelector } from "reselect";
import { RootState } from "../store";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export const getState = createSelector(
  (state: RootState) => state,
  state => state.items
);

export const getItemsStateByAction = (action: string) =>
  createSelector(getState, itemState => {
    return itemState[action];
  });

export const getItemsPendingByAction = (action: string) => {
  return createSelector(getItemsStateByAction(action), itemState => {
    if (!itemState) {
      return [];
    }
    return itemState[FetchingStatus.PENDING];
  });
};
export const ItemSelector = {
  getState,
  getItemsStateByAction,
  getItemsPendingByAction
};
