import {
  Box,
  BoxProps,
  Card,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import MusicIcon from "@material-ui/icons/MusicNote";
import React from "react";
import { Audio } from "../../reducers/audios/entity";

export interface AudioItemProps extends BoxProps {
  audio: Audio;
}

export const AudioItem: React.FC<AudioItemProps> = ({ audio, ...props }) => {
  return (
    <Box {...props}>
      <Card>
        <audio
          style={{ minWidth: 300 }}
          preload="none"
          controls
          src={audio.url}
        />
        <ListItem>
          <ListItemIcon>
            <MusicIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: "subtitle1" }}>
            {audio.name}
          </ListItemText>
        </ListItem>
      </Card>
    </Box>
  );
};
