import { Switch, Typography } from "@material-ui/core";
import FormControlLabel, {
  FormControlLabelProps
} from "@material-ui/core/FormControlLabel";
import { SwitchProps } from "@material-ui/core/Switch";
import { get } from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";

interface MySwitchFieldProps extends SwitchProps, FormProps {
  formControlLabelProps?: FormControlLabelProps;
  name: string;
}

export const MySwitchField: React.FC<MySwitchFieldProps> = ({
  name,
  defaultValue,
  label,
  helperText,
  formControlProps,
  formLabelProps,
  formHelperTextProps,
  formControlLabelProps,
  ...switchProps
}) => {
  const { errors, control } = useFormContext();
  const errorField = get(errors, name)?.message;
  const myFormControlProps: MyFormControlProps = {
    label,
    errorField,
    helperText,
    formLabelProps,
    formHelperTextProps,
    ...formControlProps
  };
  return (
    <MyFormControl disableLabel {...myFormControlProps}>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            defaultChecked={defaultValue || false}
            defaultValue={false}
            render={({ value, onChange, onBlur }) => (
              <Switch
                size="medium"
                color="primary"
                checked={value}
                value={value}
                onBlur={onBlur}
                onChange={e => onChange(e.target.checked)}
                {...switchProps}
              />
            )}
          />
        }
        label={<Typography>{label}</Typography>}
        {...formControlLabelProps}
      />
    </MyFormControl>
  );
};
