import uniqBy from "lodash/uniqBy";
import {
  createEntityAdapter,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { goBack } from "connected-react-router";
import { normalize } from "normalizr";
import { User } from "../../entities/user";
import { Watermark } from "../../entities/watermark";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { Audio } from "../audios/entity";
import { AudioActions } from "../audios/reducer";
import { ImageGrouped } from "../images/entity";
import { ImageActions } from "../images/reducer";
import { ScenarioCompanySharedActions } from "../scenarios-companies-shared/reducer";
import {
  Scenario,
  ScenarioDTO,
  ScenarioEntity,
  ScenarioNormalized
} from "../scenarios/entity";
import { ScenarioActions } from "../scenarios/reducer";
import { UserActions } from "../users/reducer";
import { VideoGrouped } from "../videos/entity";
import { VideoActions } from "../videos/reducer";
import { CompanyActionsTypes } from "./action";
import * as Api from "./api";
import { Company } from "./entity";
import {
  Project,
  ProjectEntity,
  ProjectNormalized,
  ProjectCompanyCreateDTO
} from "../projects/entity";
import { ProjectActions } from "../projects/reducer";

export interface CompanyState extends EntityState<Company> {
  selected?: Company;
  readOneStatus: FetchingStatus;
  removeOneStatus: FetchingStatus;
  readCollaboratorsStatus: FetchingStatus;
  readImagesStatus: FetchingStatus;
  readImagesTransferedStatus: FetchingStatus;
  uploadImageStatus: FetchingStatus;
  uploadWatermarkStatus: FetchingStatus;
  removeWatermarkStatus: FetchingStatus;
  readVideosStatus: FetchingStatus;
  readVideosTransferedStatus: FetchingStatus;
  uploadVideoStatus: FetchingStatus;
  readAudiosStatus: FetchingStatus;
  readAudiosTransferedStatus: FetchingStatus;
  uploadAudioStatus: FetchingStatus;
  readScenariosStatus: FetchingStatus;
  createScenarioStatus: FetchingStatus;
  updateScenarioStatus: FetchingStatus;
  readProjectsStatus: FetchingStatus;
  createProjectStatus: FetchingStatus;
}

export const CompanyInitialState: CompanyState = {
  ids: [],
  entities: {},
  selected: undefined,
  readOneStatus: FetchingStatus.NULL,
  removeOneStatus: FetchingStatus.NULL,
  readCollaboratorsStatus: FetchingStatus.NULL,
  readImagesStatus: FetchingStatus.NULL,
  readImagesTransferedStatus: FetchingStatus.NULL,
  uploadImageStatus: FetchingStatus.NULL,
  uploadWatermarkStatus: FetchingStatus.NULL,
  removeWatermarkStatus: FetchingStatus.NULL,
  readVideosStatus: FetchingStatus.NULL,
  readVideosTransferedStatus: FetchingStatus.NULL,
  uploadVideoStatus: FetchingStatus.NULL,
  readAudiosStatus: FetchingStatus.NULL,
  readAudiosTransferedStatus: FetchingStatus.NULL,
  uploadAudioStatus: FetchingStatus.NULL,
  readScenariosStatus: FetchingStatus.NULL,
  createScenarioStatus: FetchingStatus.NULL,
  updateScenarioStatus: FetchingStatus.NULL,
  readProjectsStatus: FetchingStatus.NULL,
  createProjectStatus: FetchingStatus.NULL
};

export const CompanyAdapter = createEntityAdapter<Company>();

const CompanyAdapterState = CompanyAdapter.getInitialState(CompanyInitialState);

const readOneCompany = createMyAsyncThunk<Company, string>(
  CompanyActionsTypes.COMPANY_READ_ONE,
  Api.readOneCompany,
  {
    onFailed: ({ thunkApi: { dispatch } }) => {
      dispatch(goBack());
    }
  }
);
const removeOne = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_DELETE,
  Api.removeOne,
  {
    onSuccess: ({ values }) => values.id,
    onSuccessMessage: "saga:delete-success"
  }
);

// Collaborators
const readCollaborators = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_READ_COLLABORATORS,
  Api.readCollaborators,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(UserActions.addMany(result));
    }
  }
);
const removeCollaborator = createMyAsyncThunk<
  { user: User; company: Company },
  { user: User; company: Company }
>(CompanyActionsTypes.COMPANY_REMOVE_COLLABORATOR, Api.removeCollaborator, {
  onSuccess: ({ values, thunkApi: { dispatch } }) => {
    dispatch(UserActions.removeOne(values.user.id));
    return values;
  }
});
const addCollaborator = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_ADD_COLLABORATOR,
  Api.addCollaborator,
  {
    onSuccess: ({ values, thunkApi: { dispatch } }) => {
      dispatch(UserActions.addOne(values.user));
      return values;
    }
  }
);

// Projects
const readCompanyProjects = createMyAsyncThunk<Project[], Company>(
  CompanyActionsTypes.COMPANY_READ_PROJECTS,
  Api.readProjects,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const { entities } = normalize<Project, ProjectNormalized>(result, [
        ProjectEntity
      ]);
      dispatch(VideoActions.upsertMany(entities.videos));
      dispatch(ScenarioActions.upsertMany(entities.scenarios));
      dispatch(ProjectActions.setAll(result || []));
    }
  }
);

const createCompanyProject = createMyAsyncThunk<
  Project,
  { company: Company; project: ProjectCompanyCreateDTO }
>(CompanyActionsTypes.COMPANY_CREATE_PROJECT, Api.createProject, {
  onSuccess: ({ result, thunkApi: { dispatch } }) => {
    dispatch(ProjectActions.upsertOne(result));
  },
  onSuccessMessage: "saga:project-created-success"
});

// Assets
const readImages = createMyAsyncThunk<ImageGrouped, Company>(
  CompanyActionsTypes.COMPANY_READ_ASSETS_IMAGE,
  Api.readImages,
  {
    onSuccess: async ({ result, thunkApi: { dispatch } }) => {
      const images = uniqBy(
        [
          ...result.owned,
          ...result.shared,
          ...result.companies,
          ...result.catalogue
        ],
        "id"
      );
      await dispatch(ImageActions.addMany(images));
    }
  }
);

const readImagesTransfered = createMyAsyncThunk<ImageGrouped, Company>(
  CompanyActionsTypes.COMPANY_READ_ASSETS_TRANSFERED_IMAGE,
  Api.readImages,
  {
    onSuccess: async ({ result, thunkApi: { dispatch } }) => {
      const images = uniqBy(
        [
          ...result.owned,
          ...result.shared,
          ...result.companies,
          ...result.catalogue
        ],
        "id"
      );
      await dispatch(ImageActions.clear());
      await dispatch(ImageActions.addMany(images));
    }
  }
);

const uploadImage = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_UPLOAD_ASSET_IMAGE,
  Api.uploadImage,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(ImageActions.addOne(result));
    },
    onSuccessMessage: "saga:upload-success",
    onFailedMessage: "saga:upload-failed",
  }
);

const uploadWatermark = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_UPLOAD_WATERMARK_IMAGE,
  Api.uploadWatermark,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(ImageActions.addOne(result.image));
    },
    onSuccessMessage: "saga:upload-success",
    onFailedMessage: "saga:upload-failed",
  }
);
const removeWatermark = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_REMOVE_WATERMARK_IMAGE,
  Api.removeWatermark,
  {
    onSuccess: ({ result, values, thunkApi: { dispatch } }) => {
      dispatch(ImageActions.removeOne(result.image));
      return values;
    },
    onSuccessMessage: "saga:delete-success"
  }
);

const readVideos = createMyAsyncThunk<VideoGrouped, Company>(
  CompanyActionsTypes.COMPANY_READ_ASSETS_VIDEO,
  Api.readVideos,
  {
    onSuccess: async ({ result, thunkApi: { dispatch } }) => {
      const videos = [
        ...result.owned,
        ...result.shared,
        ...result.companies,
        ...result.catalogue
      ];
      await dispatch(VideoActions.addMany(videos));
    }

    //onSuccess: ({ result, thunkApi: { dispatch } }) => {
    //dispatch(VideoActions.addMany(result));
    //}
  }
);

const readVideosTransfered = createMyAsyncThunk<VideoGrouped, Company>(
  CompanyActionsTypes.COMPANY_READ_ASSETS_TRANSFERED_VIDEO,
  Api.readVideos,
  {
    onSuccess: async ({ result, thunkApi: { dispatch } }) => {
      const videos = [
        ...result.owned,
        ...result.shared,
        ...result.companies,
        ...result.catalogue
      ];
      await dispatch(VideoActions.clear());
      await dispatch(VideoActions.addMany(videos));
    }
  }
);

const uploadVideo = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_UPLOAD_ASSET_VIDEO,
  Api.uploadVideo,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(VideoActions.addOne(result));
    },
    onSuccessMessage: "saga:upload-success",
    onFailedMessage: "saga:upload-failed",
  }
);

const readAudios = createMyAsyncThunk<Audio[], Company>(
  CompanyActionsTypes.COMPANY_READ_ASSETS_AUDIOS,
  Api.readAudios,
  {
    onSuccess: async ({ result, thunkApi: { dispatch } }) => {
      await dispatch(AudioActions.addMany(result));
    }
  }
);

const readAudiosTransfered = createMyAsyncThunk<Audio[], Company>(
  CompanyActionsTypes.COMPANY_READ_ASSETS_TRANSFERED_AUDIOS,
  Api.readAudios,
  {
    onSuccess: async ({ result, thunkApi: { dispatch } }) => {
      await dispatch(AudioActions.clear());
      await dispatch(AudioActions.addMany(result));
    }
  }
);

const uploadAudio = createMyAsyncThunk(
  CompanyActionsTypes.COMPANY_UPLOAD_ASSETS_AUDIO,
  Api.uploadAudio,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(AudioActions.addOne(result));
    },
    onSuccessMessage: "saga:upload-success",
    onFailedMessage: "saga:upload-failed"
  }
);

// Scenarios
const readScenarios = createMyAsyncThunk<Scenario[], Company>(
  CompanyActionsTypes.COMPANY_READ_SCENARIOS,
  Api.readScenarios,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const normalized = normalize<Scenario, ScenarioNormalized>(result, [
        ScenarioEntity
      ]);
      dispatch(
        ScenarioCompanySharedActions.upsertMany(
          normalized.entities.sharedCompanies || []
        )
      );
      dispatch(ScenarioActions.setAll(normalized.entities.scenarios || []));
    }
  }
);
const createScenario = createMyAsyncThunk<
  Scenario,
  { company: Company; scenario: ScenarioDTO }
>(CompanyActionsTypes.COMPANY_CREATE_SCENARIOS, Api.createScenario, {
  onSuccess: ({ result, thunkApi: { dispatch } }) => {
    dispatch(ScenarioActions.addOne(result));
  },
  onSuccessMessage: "saga:create-success",
  onFailedMessage: "saga:create-failed"
});

const updateScenario = createMyAsyncThunk<
  Scenario,
  { scenarioId: string; companyId: string; scenario: ScenarioDTO }
>(CompanyActionsTypes.COMPANY_UPDATE_SCENARIOS, Api.updateScenario, {
  onSuccess: ({ thunkApi: { dispatch }, result }) => {
    dispatch(ScenarioActions.upsertOne(result));
    dispatch(ScenarioActions.selected(result));
  },
  onSuccessMessage: "saga:update-success"
});

const CompanySlice = createMySlice({
  name: "companies",
  initialState: CompanyAdapterState,
  adapter: CompanyAdapter,
  asyncActions: [
    {
      action: readOneCompany,
      statusName: "readOneStatus",
      onSuccess: CompanyAdapter.upsertOne
    },
    {
      action: removeOne,
      statusName: "removeOneStatus",
      onSuccess: CompanyAdapter.removeOne
    },
    { action: readCollaborators, statusName: "readCollaboratorsStatus" },
    { action: readImages, statusName: "readImagesStatus" },
    { action: readImagesTransfered, statusName: "readImagesTransferedStatus" },
    { action: uploadImage, statusName: "uploadImageStatus" },
    { action: readVideos, statusName: "readVideosStatus" },
    { action: readVideosTransfered, statusName: "readVideosTransferedStatus" },
    { action: uploadVideo, statusName: "uploadVideoStatus" },
    { action: readAudios, statusName: "readAudiosStatus" },
    { action: readAudiosTransfered, statusName: "readAudiosTransferedStatus" },
    { action: uploadAudio, statusName: "uploadAudioStatus" },
    { action: readScenarios, statusName: "readScenariosStatus" },
    { action: createScenario, statusName: "createScenarioStatus" },
    { action: updateScenario, statusName: "updateScenarioStatus" },
    { action: readCompanyProjects, statusName: "readProjectsStatus" },
    { action: createCompanyProject, statusName: "createProjectStatus" },
    {
      action: uploadWatermark,
      statusName: "uploadWatermarkStatus",
      onSuccess: (state, action: PayloadAction<Watermark>) => {
        const { companyId } = action.payload;
        const company = state.entities[companyId];
        if (company)
          CompanyAdapter.upsertOne(state, {
            ...company,
            watermarks: [action.payload, ...(company.watermarks || [])]
          });
      }
    },
    {
      action: removeWatermark,
      statusName: "removeWatermarkStatus",
      onSuccess: (
        state,
        action: PayloadAction<{ companyId: string; id: string }>
      ) => {
        const company = state.entities[action.payload.companyId];
        if (company)
          CompanyAdapter.upsertOne(state, {
            ...company,
            watermarks: company.watermarks?.filter(
              w => w.id !== action.payload.id
            )
          });
      }
    }
  ],
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addCollaborator.fulfilled, (state, payload) => {
      const { user, company } = payload.meta.arg;
      const collaboratorsIds = state.entities[company.id]?.collaboratorsIds;
      if (collaboratorsIds) {
        const userIndex = collaboratorsIds.findIndex(c => c === user.id);
        if (userIndex > -1) collaboratorsIds.splice(userIndex, 1, user.id);
        else collaboratorsIds.push(user.id);
      }
    });
    builder.addCase(removeCollaborator.rejected, (state, payload) => {
      const { user, company } = payload.meta.arg;
      const collaboratorsIds = state.entities[company.id]?.collaboratorsIds;
      if (collaboratorsIds) {
        collaboratorsIds.splice(
          collaboratorsIds.findIndex(c => c === user.id),
          1
        );
      }
    });
  }
});

export const CompanyReducer = CompanySlice.reducer;
export const CompanyActions = {
  ...CompanySlice.actions,
  removeOne,
  readOneCompany,
  readCollaborators,
  addCollaborator,
  removeCollaborator,
  readImages,
  readImagesTransfered,
  uploadImage,
  readVideos,
  readVideosTransfered,
  uploadVideo,
  readAudios,
  readAudiosTransfered,
  uploadAudio,
  readScenarios,
  createScenario,
  updateScenario,
  uploadWatermark,
  removeWatermark,
  readCompanyProjects,
  createCompanyProject
};
