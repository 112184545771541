import React, { ComponentType, createElement, FocusEvent, Ref } from "react";
import { FormProvider, UseFormMethods } from "react-hook-form";
import { useYupForm } from "../../../utils/hooks/useYup";

export interface MyFormProps {
  onSubmit?: (values: any) => void;
  onBlur?: (e: FocusEvent<HTMLFormElement>) => void;
  formRef?: Ref<HTMLFormElement>;
  component?: ComponentType<any>;
  methods: UseFormMethods<any>;
}

export const MyForm: React.FC<MyFormProps> = ({
  onSubmit,
  children,
  formRef,
  onBlur,
  methods,
  component
}) => {
  useYupForm(methods);
  return (
    <FormProvider {...methods}>
      {!!component ? (
        createElement(component, {
          onSubmit,
          onBlur,
          children
        })
      ) : (
        <form
          ref={formRef}
          onSubmit={onSubmit && methods && methods.handleSubmit(onSubmit)}
          onBlur={onBlur}
          noValidate
        >
          {children}
        </form>
      )}
    </FormProvider>
  );
};
