import {
  CardActions,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import PlansCountIcon from "@material-ui/icons/MovieCreation";
import RecommendedDurationIcon from "@material-ui/icons/WatchLater";
import * as date from "date-fns";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DynamicElement } from "../../components/common/DynamicElement";
import { AppSelector } from "../../reducers/app/selector";
import { Scenario } from "../../reducers/scenarios/entity";
import { ScenarioActions } from "../../reducers/scenarios/reducer";
import {
  getScenarioById,
  ScenarioSelector
} from "../../reducers/scenarios/selector";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
interface ScenarioDetailProps {
  id: string;
  actions?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    poster: {
      height: 380,
      width: "100%",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        height: 200
      }
    }
  })
);
export const ScenarioDetail: React.FC<ScenarioDetailProps> = ({
  id: scenarioId,
  actions
}) => {
  const classes = useStyles();
  const { language: lang } = useSelector(AppSelector.getState);
  const { t } = useTranslation("components");
  const scenario = useSelector(getScenarioById(scenarioId));
  const { readOneStatus: scenarioReadOneStatus } = useSelector(
    ScenarioSelector.getState
  );

  return (
    <DynamicElement<Scenario>
      data={scenario}
      actions={{
        action: ScenarioActions.readOneScenario(scenarioId),
        status: scenarioReadOneStatus
      }}
      showCondition={!!scenario && !!scenario.stories}
    >
      {scenario => (
        <>
          <img
            src={scenario.poster?.url}
            alt={scenario.name}
            className={classes.poster}
          />
          <List>
            <ListItem>
              <ListItemIcon>
                <PlansCountIcon color="action" />
              </ListItemIcon>
              <ListItemText
                primary={scenario.stories?.reduce(
                  (acc, curr) => (acc += curr.shots.length),
                  0
                )}
                secondary={t("scenario-detail.shot-count")}
              />
            </ListItem>
            {scenario.stories && (
              <ListItem>
                <ListItemIcon>
                  <RecommendedDurationIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary={date.format(
                    scenario.stories?.reduce((acc, curr) => {
                      for (const shot of curr.shots) {
                        acc = date.addMilliseconds(
                          acc,
                          shot.recommendedDuration
                        );
                      }
                      return acc;
                    }, new Date(0)),
                    "mm:ss"
                  )}
                  secondary={t("scenario-detail.recommended-duration")}
                />
              </ListItem>
            )}
            <Divider variant="inset" />
            {scenario.description && (
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary={jsonTranslator(scenario.description, lang)}
                />
              </ListItem>
            )}
          </List>
          <CardActions>{actions}</CardActions>
        </>
      )}
    </DynamicElement>
  );
};
