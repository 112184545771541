import { object, string } from "yup";

export interface OnBoardingValues {
  email: string;
}

export const OnBoardingSchema = object().shape({
  email: string()
    .email()
    .required()
});
