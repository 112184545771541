import {
  ActivationCode,
  ActivationCodeCreateDTO,
  ActivationCodeUpdateDTO
} from "./entity";
import { Request } from "../../utils/request";
import { Project } from "../projects/entity";

export const read = () => Request({ withToken: true }).get("/activation-code");

export const readOneActivationCode = (id: string) =>
  Request({ withToken: true }).get(`/activation-code/${id}`);

export const createOne = (activationCode: ActivationCodeCreateDTO) =>
  Request({ withToken: true }).post("/activation-code", activationCode);

export const updateOne = ({ id, ...dto }: ActivationCodeUpdateDTO) =>
  Request({ withToken: true }).put(`/activation-code/${id}`, dto);

export const patchOne = ({ id, ...dto }: ActivationCodeUpdateDTO) =>
  Request({ withToken: true }).patch(`/activation-code/${id}`, dto);

export const deleteOne = (id: string) =>
  Request({ withToken: true }).delete(`/activation-code/${id}`);

export const readActivationCodeProjects = (activationCode: ActivationCode) =>
  Request({ withToken: true }).get<Project[]>(
    `/activation-code/${activationCode.id}/projects`
  );
