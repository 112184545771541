/* eslint-disable */
import TranslateIcon from "@material-ui/icons/Translate";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppSelector } from "../../../reducers/app/selector";
import {
  Languages,
  JsonTranslator
} from "../../../utils/function/jsonTranslator";
import { MyButton } from "../MyButton";
import { MyFlag } from "../MyFlag";
import { MyTextField, MyTextFieldProps } from "./MyTextField";
import { DeepPartial } from "redux";
interface MyJsonTranslatorField {
  defaultValue?: JsonTranslator | DeepPartial<JsonTranslator> | null;
  name: string;
  textFieldProps: Omit<MyTextFieldProps, "name">;
  multiline?: boolean;
  setValue?: any;
}

export const MyJsonTranslatorField: React.FC<MyJsonTranslatorField> = props => {
  const { textFieldProps, name, defaultValue, multiline, setValue } = props;
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("components");
  const [showTranslations, setShowTranslations] = useState(false);
  const { language: lang } = useSelector(AppSelector.getState);

  const frName = `${name}.fr-FR`;
  const enName = `${name}.en-US`;
  let defaultFrValue = defaultValue ? defaultValue["fr-FR"] : "";
  let defaultEnValue = defaultValue ? defaultValue["en-US"] : "";

  const [langFrValue, setLangFrValue] = useState(defaultFrValue);
  const [langEnValue, setLangEnValue] = useState(defaultEnValue);

  const [showEN, setShowEN] = useState(false);
  const [showFR, setShowFR] = useState(false);

  useEffect(() => {
    defaultFrValue = defaultValue ? defaultValue["fr-FR"] : "";
    defaultEnValue = defaultValue ? defaultValue["en-US"] : "";
    setLangFrValue(defaultFrValue);
    setLangEnValue(defaultEnValue);
  }, [defaultValue]);

  useEffect(() => {
    if (setValue) {
      if (lang === Languages.FR) {
        setValue(frName, langFrValue);
      }
      
      if (lang === Languages.EN) {
        setValue(enName, langEnValue);
      }
    }
  }, [lang]);

  useEffect(() => {
    if (showTranslations) {
      setShowEN(true);
      setShowFR(true);
    } else {
      setShowEN(false);
      setShowFR(false);
    }
    
    if (lang === Languages.EN) {
      setShowEN(true);
    } else if (lang === Languages.FR) {
      setShowFR(true);
    }
  }, [lang, showTranslations]);

  return (
    <>
      <div style={{ position: `${showFR ? "initial" : "absolute"}`, visibility: `${showFR ? "visible" : "hidden"}`, zIndex: parseInt(`${showFR ? 1 : -1}`)}}>
        <MyTextField
            name={frName}
            startAdornment={
              showTranslations ? (
                <MyFlag code={Languages.FR} />
              ) : (
                textFieldProps.startAdornment
              )
            }
            {...textFieldProps}
            label={`${showTranslations ? `${tCommon("french")} - ` : ""} ${
              textFieldProps.label
            }`}
            placeholder={tCommon("french-translation")}
            defaultValue={defaultFrValue}
            onTextChange={(text) => {
              setLangFrValue(text);
            }}
            value={langFrValue}
            multiline={multiline}
            onBlur={() => {
              if (!langEnValue || langEnValue === "") {
                setLangEnValue(langFrValue);
                setValue(enName, langFrValue);
              }
            }}
          />
      </div>

      <div style={{ position: `${showEN ? "initial" : "absolute"}`, visibility: `${showEN ? "visible" : "hidden"}`, zIndex: parseInt(`${showEN ? 1 : -1}`)}}>
        <MyTextField
            name={enName}
            startAdornment={
              showTranslations ? (
                <MyFlag code={Languages.EN} />
              ) : (
                textFieldProps.startAdornment
              )
            }
            {...textFieldProps}
            label={`${showTranslations ? `${tCommon("english")} - ` : ""} ${
              textFieldProps.label
            } `}
            defaultValue={defaultEnValue}
            placeholder={tCommon("english-translation")}
            onTextChange={(text) => {
              setLangEnValue(text);
            }}
            value={langEnValue}
            multiline={multiline}
            onBlur={() => {
              if (!langFrValue || langFrValue === "") {
                setLangFrValue(langEnValue);
                setValue(frName, langEnValue);
              }
            }}
          />
      </div>
      <MyButton
        tabIndex={-1}
        leftIcon={<TranslateIcon fontSize="small" color="primary" />}
        color="primary"
        size="small"
        onClick={() => setShowTranslations(prev => !prev)}
      >
        {showTranslations
          ? t("myJsonTranslatorField.hide-translations")
          : t("myJsonTranslatorField.show-translations")}
      </MyButton>
    </>
  );
};
