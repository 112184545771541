import React from "react";
import { MyRouteProps } from ".";
import { ExtraCompanyProps } from "../pages/Company";
import { ImageList } from "../pages/Company/assets/components/ImageList";
import { MusicList } from "../pages/Company/assets/components/MusicList";
import { VideoList } from "../pages/Company/assets/components/VideoList";
import { CompanyCollaborators } from "../pages/Company/components/Collaborators";
import { CompanyGtv } from "../pages/Company/Gtv";
import { CompanyHome } from "../pages/Company/Home";
import { ScenarioPage } from "../pages/Company/Scenario";
import { CompanyProjectList } from "../pages/Company/ProjectList";
import { CompanyProject } from "../pages/Company/Project";
import { CompanyProjectVideo } from "../pages/Company/Video";

export const CompaniesRoute = (props: ExtraCompanyProps): MyRouteProps[] => [
  {
    name: "home",
    path: "/",
    exact: true,
    component: CompanyHome,
    // render: () => <CompanyCollaborators {...props} />,
    options: {
      authenticated: true
    }
  },
  {
    name: "collaborators",
    path: "/collaborators",
    exact: true,
    render: () => <CompanyCollaborators {...props} />,
    options: {
      authenticated: true
    }
  },
  //{
  //  name: "projects",
  //  path: "/projects",
  //  exact: true,
  //  render: () => <CompanyProjects {...props} />,
  //  options: {
  //   authenticated: true
  // }
  // },
  {
    name: "assets",
    path: "/assets",
    childRoutes: [
      {
        name: "company-assets-videos",
        path: "/videos",
        exact: true,
        render: () => <VideoList {...props} />,
        options: {
          authenticated: true
        }
      },
      {
        name: "company-assets-images",
        path: "/images",
        exact: true,
        render: () => <ImageList {...props} />,
        options: {
          authenticated: true
        }
      },
      {
        name: "company-assets-musics",
        path: "/musics",
        exact: true,
        render: () => <MusicList {...props} />,
        options: {
          authenticated: true
        }
      }
    ]
  },
  {
    name: "gtv",
    path: "/gtv",
    exact: true,
    component: CompanyGtv,
    options: {
      authenticated: true
    }
  },
  {
    name: "scenarios",
    path: "/scenarios",
    exact: true,
    render: () => <ScenarioPage {...props} />,
    options: {
      authenticated: true
    }
  },
  {
    name: "projects",
    path: "/projects",
    exact: true,
    render: () => <CompanyProjectList {...props} />,
    options: {
      authenticated: true
    }
  },
  {
    name: "project",
    path: "/projects/:id",
    exact: true,
    render: () => <CompanyProject {...props} />,
    options: {
      authenticated: true
    }
  },
  {
    name: "video",
    path: "/projects/:id/video/:videoId",
    exact: true,
    render: () => <CompanyProjectVideo {...props} />,
    options: {
      authenticated: true
    }
  }
];
