import { createSelector } from "@reduxjs/toolkit";
import { CategoryNames } from "../../entities/category";
import { AudioSelector } from "../audios/selector";
import { ImageSelector } from "../images/selector";
import { ProjectSelector } from "../projects/selector";
import { RootState } from "../store";
import { UserSelector } from "../users/selector";
import { VideoSelector } from "../videos/selector";
import { Company } from "./entity";
import { CompanyAdapter } from "./reducer";

const AdapterSelector = CompanyAdapter.getSelectors(
  (state: RootState) => state.companies
);

export const getState = createSelector(
  (state: RootState) => state,
  state => state.companies
);

export const getCompany = AdapterSelector.selectAll;

export const getCompanyById = (id: string) =>
  createSelector(getCompany, company => company.find(c => c.id === id));

// Gallery
const getImages = (company: Company) =>
  createSelector(ImageSelector.selectAll, images =>
    images.filter(i => i.companyId === company.id)
  );

const getAllImages = (company: Company) =>
  createSelector(ImageSelector.selectAll, images =>
    images.filter(i => i.companyId === company.id || i.premium || i.public)
  );

const getImagesOfCategory = (company: Company) =>
  createSelector(getImages(company), images =>
    images.filter(i => i.category?.name === CategoryNames.THEMES)
  );

const getAudios = (company: Company) =>
  createSelector(AudioSelector.selectAll, audios =>
    audios.filter(a => a.companyId === company.id)
  );

const getAllAudios = (company: Company) =>
  createSelector(AudioSelector.selectAll, audios =>
    audios.filter(a => a.companyId === company.id || a.premium || a.public)
  );

const getVideos = (company: Company) =>
  createSelector(VideoSelector.selectAll, videos =>
    videos.filter(v => v.companyId === company.id)
  );

const getVideosById = (companyId?: string) =>
  createSelector(VideoSelector.selectAll, videos =>
    videos.filter(v => v.companyId === companyId)
  );

const getAllVideos = (company: Company) =>
  createSelector(VideoSelector.selectAll, videos =>
    videos.filter(v => v.companyId === company.id || v.premium || v.public)
  );

const getCollaborators = (company: Company) =>
  createSelector(UserSelector.selectAll, users =>
    users.filter(user => company.collaboratorsIds.includes(user.id))
  );

const getProjects = () => ProjectSelector.selectAll;

export const CompanySelector = {
  getState,
  getImages,
  getAllImages,
  getImagesOfCategory,
  getAudios,
  getAllAudios,
  getVideos,
  getVideosById,
  getAllVideos,
  getCollaborators,
  getCompanyById,
  getProjects,
  ...AdapterSelector
};
