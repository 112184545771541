import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import set from "lodash/set";
import sortBy from "lodash/sortBy";
import { DeepPartial } from "redux";
import * as uuid from "uuid";
import * as yup from "yup";
import {
  AudioMixSchema,
  ScenarioSchema,
  ShotSchema,
  StorySchema
} from "../../components/Gtv/schema";
import { AudioMixCreateDto } from "../../entities/audio-mix";
import { ShotDTO } from "../../entities/shot";
import { StoryDTO } from "../../entities/story";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { Scenario, ScenarioDTO } from "../scenarios/entity";
import { ShotCategory } from "../shot-category/entity";
import { ShotCategoryActions } from "../shot-category/reducer";
import { ShotCategorySelector } from "../shot-category/selector";
import { RootState, AppDispatch } from "../store";
import { GtvActionsTypes } from "./action";

export interface FormState<T> {
  isValid: boolean;
  values: T;
}

export interface ShotFormState
  extends FormState<DeepPartial<ShotDTO & { _key: string; id: string }>> {
  open: boolean;
  storyIndex: number;
}

export interface StoryFormState
  extends FormState<
    DeepPartial<
      StoryDTO & {
        _key: string;
        id: string;
        _storyCategoryId?: string;
      }
    >
  > {
  open: boolean;
  shots: Array<ShotFormState>;
}

export interface GtvState {
  edit: boolean;
  isSubmitted: boolean;
  isValidating: boolean;
  isDuplicated: boolean;
  scenario: FormState<DeepPartial<ScenarioDTO & { _key: string; id: string }>>;
  audioMix: FormState<
    | DeepPartial<
        (AudioMixCreateDto & { _key: string; id: string }) | undefined
      >
    | undefined
  >;
  stories: Array<StoryFormState>;
}

export interface GtvValidationResult {
  isValid: boolean;
  validation: {
    scenario: { isValid: boolean };
    audioMix: { isValid: boolean };
    stories: Array<{ isValid: boolean; shots: Array<{ isValid: boolean }> }>;
  };
  values: ScenarioDTO;
}

export const ScenarioInitialState: FormState<DeepPartial<
  ScenarioDTO & { id: string }
>> = {
  isValid: false,
  values: {
    lockProperties: [],
    availableFormats: [],
    enabled: true,
    label: {
      "fr-FR": "",
      "en-US": ""
    },
    description: {
      "fr-FR": "",
      "en-US": ""
    },
    posterId: "",
    wantAudioMix: true
  }
};

export const AudioMixInitialState: FormState<DeepPartial<
  AudioMixCreateDto & { id: string }
>> = {
  isValid: false,
  values: {}
};
export const ShotInitialState: ShotFormState = {
  storyIndex: 0,
  isValid: false,
  open: false,
  values: {
    _key: uuid.v4(),
    lockProperties: [],
    label: {
      "fr-FR": "",
      "en-US": ""
    },
    description: {
      "fr-FR": "",
      "en-US": ""
    },
    instruction: {
      "fr-FR": "",
      "en-US": ""
    },
    enabled: true,
    order: 0
  }
};

export const StoryInitialState: StoryFormState = {
  isValid: false,
  open: false,
  values: {
    _key: uuid.v4(),
    label: {
      "fr-FR": "",
      "en-US": ""
    },
    description: {
      "fr-FR": "",
      "en-US": ""
    },
    enabled: true,
    lockProperties: [],
    order: 0
  },
  shots: [ShotInitialState]
};

export const GtvInitialState: GtvState = {
  edit: false,
  isSubmitted: false,
  isValidating: false,
  isDuplicated: false,
  audioMix: AudioMixInitialState,
  scenario: ScenarioInitialState,
  stories: [StoryInitialState]
};

const validateSchema = <T extends object>(
  schema: yup.Schema<any>,
  initalValues: any,
  context?: object
): { isValid: boolean; values?: T } => {
  try {
    const values = schema.validateSync(initalValues, {
      abortEarly: false,
      stripUnknown: true,
      context
    });
    return { isValid: true, values };
  } catch (e) {
    console.warn("yup error", e);
    return { isValid: false };
  }
};

export const validateForm = createAsyncThunk<GtvValidationResult, void>(
  GtvActionsTypes.VALIDATING,
  (_, { getState }) => {
    const allValid: boolean[] = [];
    const validation: GtvValidationResult["validation"] = {
      scenario: { isValid: true },
      audioMix: { isValid: true },
      stories: []
    };
    let values = {} as ScenarioDTO;
    const state = getState() as RootState;
    const lang = state.app.language;
    const gtv = state.gtv;
    const shotCategories = ShotCategorySelector.selectAll(state);

    // Scenario
    const { isValid: scenarioIsValid, values: scenarioValues } = validateSchema<
      ScenarioDTO
    >(ScenarioSchema, gtv.scenario.values, {
      lang
    });
    validation.scenario.isValid = scenarioIsValid;
    allValid.push(scenarioIsValid);
    if (scenarioIsValid) values = Object.assign(values, scenarioValues);

    // AudioMix
    const { isValid: audioMixIsValid, values: audioMixValues } = validateSchema<
      AudioMixCreateDto
    >(AudioMixSchema, gtv.audioMix.values, {
      lang,
      wantAudioMix: gtv.scenario.values.wantAudioMix
    });
    validation.audioMix.isValid = audioMixIsValid;
    allValid.push(audioMixIsValid);
    if (audioMixIsValid) values.audioMix = audioMixValues;

    // Story
    gtv.stories.forEach((story, index) => {
      const lang = state.app.language;
      const { isValid: storyIsValid, values: storyValues } = validateSchema<
        StoryDTO
      >(StorySchema, story.values, {
        lang
      });
      set(validation, `stories[${index}].isValid`, storyIsValid);
      allValid.push(storyIsValid);
      if (storyIsValid && storyValues)
        values.stories = [
          ...(values.stories || []),
          { ...storyValues, order: index }
        ];

      // Shot
      story.shots.forEach((shot, shotIndex) => {
        const { isValid: shotIsValid, values: shotValues } = validateSchema<
          ShotDTO
        >(ShotSchema, shot.values, {
          lang,
          shotCategories
        });
        // Can be handle into component
        // if (!shotIsValid) {
        //   validation.stories[index].isValid = false;
        // }
        set(
          validation,
          `stories[${index}].shots[${shotIndex}].isValid`,
          shotIsValid
        );

        const recommendedDurationInMillisecond =
          shotValues?.recommendedDuration! * 1000;

        allValid.push(shotIsValid);
        if (shotIsValid && shotValues)
          values.stories[index].shots = [
            ...(values.stories[index].shots || []),
            {
              ...shotValues,
              order: shotIndex,
              recommendedDuration: recommendedDurationInMillisecond
            }
          ];
        console.log(
          "validateForm => values.stories[index].shots",
          values.stories[index].shots
        );
      });
    });
    console.log("validateForm => values", values);

    return { isValid: allValid.every(Boolean), validation, values };
  }
);

export const submit = createAsyncThunk(
  GtvActionsTypes.SUBMIT,
  async (_, { dispatch }) => {
    return new Promise<GtvValidationResult>(async res => {
      dispatch(GtvActions.setValidating(true));
      setTimeout(async () => {
        const result = (await dispatch(validateForm()))
          .payload as GtvValidationResult;
        res(result);
        console.log("submit => result", result);
      }, 300);
    });
  }
);

export const edit = createAsyncThunk<
  { scenario: Scenario; shotCategories: ShotCategory[]; context: object },
  Scenario,
  { dispatch: AppDispatch }
>(GtvActionsTypes.EDIT, async (scenario, { getState, dispatch }) => {
  const state = getState() as RootState;
  const shotCategoriesReadStatus = state.shotCategories.readStatus;
  const lang = state.app.language;
  // Get shot categories
  let shotCategories = ShotCategorySelector.selectAll(state);
  if (shotCategoriesReadStatus === FetchingStatus.NULL)
    shotCategories = (await dispatch(ShotCategoryActions.read()))
      .payload as ShotCategory[];

  return { scenario, shotCategories, context: { lang } };
});

export const duplicate = createAsyncThunk<
  { scenario: ScenarioDTO; shotCategories: ShotCategory[]; context: object },
  Scenario,
  { dispatch: AppDispatch }
>(GtvActionsTypes.DUPLICATE, async (scenario, { getState, dispatch }) => {
  const state = getState() as RootState;
  const shotCategoriesReadStatus = state.shotCategories.readStatus;
  const lang = state.app.language;
  // Get shot categories
  let shotCategories = ShotCategorySelector.selectAll(state);
  if (shotCategoriesReadStatus === FetchingStatus.NULL)
    shotCategories = (await dispatch(ShotCategoryActions.read()))
      .payload as ShotCategory[];

  return { scenario, shotCategories, context: { lang } };
});

const GtvSlice = createMySlice({
  name: "gtv",
  initialState: GtvInitialState,
  reducers: {
    updateScenario: (
      state,
      action: PayloadAction<DeepPartial<ScenarioDTO>>
    ) => {
      state.scenario.values = Object.assign(
        state.scenario.values,
        action.payload
      );
      if (!action.payload.wantAudioMix) {
        state.audioMix.values = undefined;
      }
      if (action.payload.wantAudioMix && !state.audioMix) {
        state.audioMix = AudioMixInitialState;
      }
      state.scenario.isValid = true;
    },
    updateScenarioIsValid: (state, action: PayloadAction<boolean>) => {
      state.scenario.isValid = action.payload;
    },
    updateAudioMix: (
      state,
      action: PayloadAction<DeepPartial<AudioMixCreateDto>>
    ) => {
      state.audioMix.values = Object.assign(
        state.audioMix.values || {},
        action.payload
      );
      state.audioMix.isValid = true;
    },
    updateAudioMixIsValid: (state, action: PayloadAction<boolean>) => {
      state.audioMix.isValid = action.payload;
    },

    // Story
    addStory: state => {
      state.stories.push({
        ...StoryInitialState,
        values: { ...StoryInitialState.values, _key: uuid.v4() }
      });
    },
    duplicateStory: (
      state,
      action: PayloadAction<{ index: number; context?: object }>
    ) => {
      const { index } = action.payload;
      const story = state.stories[index];
      story.open = false;
      state.stories.splice(index + 1, 0, {
        ...story,
        open: true,
        values: {
          ...story.values,
          id: undefined,
          _key: uuid.v4()
        },
        shots: story.shots.map(s => ({
          ...s,
          open: false,
          values: {
            ...s.values,
            id: undefined,
            _key: uuid.v4()
          }
        }))
      });
    },
    updateStory: (
      state,
      action: PayloadAction<{
        index: number;
        story: DeepPartial<StoryDTO & { _storyCategoryId: string }>;
        firstShotIsBroll?: { brollChildId?: string };
        close?: boolean;
      }>
    ) => {
      const { index, story, close, firstShotIsBroll } = action.payload;
      state.stories[index].values = Object.assign(
        state.stories[index].values,
        story
      );
      state.stories[index].isValid = true;

      // Set the new category if different from story category
      if (firstShotIsBroll && firstShotIsBroll.brollChildId) {
        state.stories[index].shots.forEach((shot, index) => {
          if (index === 0) shot.values.shotCategoryId = story._storyCategoryId;
          else shot.values.shotCategoryId = firstShotIsBroll.brollChildId;
        });
      } else {
        state.stories[index].shots
          .filter(shot => shot.values.shotCategoryId !== story._storyCategoryId)
          .forEach(shot => {
            shot.values.shotCategoryId = story._storyCategoryId;
          });
      }

      if (close) state.stories[index].open = false;
    },
    updateStoryShotsType: (
      state,
      action: PayloadAction<{
        index: number;
        storyCategoryId: string;
        firstShotIsBroll?: { brollChildId?: string };
      }>
    ) => {
      const { index, firstShotIsBroll, storyCategoryId } = action.payload;
      // Set the new category if different from story category
      if (firstShotIsBroll && firstShotIsBroll.brollChildId) {
        state.stories[index].shots.forEach((shot, index) => {
          if (index === 0) shot.values.shotCategoryId = storyCategoryId;
          else shot.values.shotCategoryId = firstShotIsBroll.brollChildId;
        });
      } else {
        state.stories[index].shots
          .filter(shot => shot.values.shotCategoryId !== storyCategoryId)
          .forEach(shot => {
            shot.values.shotCategoryId = storyCategoryId;
          });
      }
    },
    removeStory: (state, action: PayloadAction<number>) => {
      state.stories.splice(action.payload, 1);
    },
    openStory: (
      state,
      action: PayloadAction<{ index: number; open: boolean }>
    ) => {
      state.stories[action.payload.index].open = action.payload.open;
    },
    updateStoryIsValid: (
      state,
      action: PayloadAction<{
        index: number;
        isValid: boolean;
      }>
    ) => {
      state.stories[action.payload.index].isValid = action.payload.isValid;
    },

    // Shot
    addShot: (state, action: PayloadAction<number>) => {
      state.stories[action.payload].shots.push({
        ...ShotInitialState,
        values: { ...ShotInitialState.values, _key: uuid.v4() }
      });
    },
    duplicateShot: (
      state,
      action: PayloadAction<{
        storyIndex: number;
        index: number;
        context?: object;
      }>
    ) => {
      const { storyIndex, index } = action.payload;
      const shot = state.stories[storyIndex].shots[index];
      state.stories[storyIndex].shots.splice(index + 1, 0, {
        ...shot,
        values: { ...shot.values, id: undefined, _key: uuid.v4() },
        open: true
      });
    },
    updateShot: (
      state,
      action: PayloadAction<{
        storyIndex: number;
        index: number;
        shot: DeepPartial<ShotDTO>;
        close?: boolean;
      }>
    ) => {
      const { storyIndex, index, shot, close } = action.payload;
      const shotState = state.stories[storyIndex].shots[index];
      shotState.values = Object.assign(shotState.values, shot);
      shotState.isValid = true;
      if (index === 0)
        state.stories[storyIndex].values._storyCategoryId = shot.shotCategoryId;
      if (close) shotState.open = false;
    },
    removeShot: (
      state,
      action: PayloadAction<{ storyIndex: number; index: number }>
    ) => {
      const { storyIndex, index } = action.payload;
      state.stories[storyIndex].shots.splice(index, 1);
    },
    openShot: (
      state,
      action: PayloadAction<{
        storyIndex: number;
        index: number;
        open: boolean;
      }>
    ) => {
      const { storyIndex, index, open } = action.payload;
      state.stories[storyIndex].shots[index].open = open;
    },
    updateShotIsValid: (
      state,
      action: PayloadAction<{
        storyIndex: number;
        index: number;
        isValid: boolean;
      }>
    ) => {
      const { storyIndex, index, isValid } = action.payload;
      state.stories[storyIndex].shots[index].isValid = isValid;
    },
    setValidating: (state, action: PayloadAction<boolean>) => {
      state.isValidating = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(submit.fulfilled, state => {
      if (!state.isSubmitted) state.isSubmitted = true;
    });
    builder.addCase(submit.rejected, state => {
      if (!state.isSubmitted) state.isSubmitted = true;
    });
    builder.addCase(validateForm.pending, state => {
      state.isValidating = true;
    });
    builder.addCase(validateForm.fulfilled, (state, action) => {
      state.isValidating = false;
      merge(state, action.payload.validation);
    });
    builder.addCase(validateForm.rejected, state => {
      state.isValidating = false;
    });
    builder.addCase(edit.fulfilled, (state, action) => {
      state.edit = true;
      const {
        lockProperties,
        description,
        enabled,
        id,
        tagIds,
        label,
        wantAudioMix,
        audioMix,
        posterId,
        availableFormats,
        stories
      } = action.payload.scenario;
      state.scenario.values = {
        lockProperties,
        description,
        enabled,
        id,
        label,
        wantAudioMix,
        posterId,
        availableFormats,
        tagIds
      };
      state.scenario.isValid = validateSchema(
        ScenarioSchema,
        state.scenario.values,
        action.payload.context
      ).isValid;

      if (wantAudioMix) {
        state.audioMix.values = audioMix;
        state.audioMix.isValid = validateSchema(AudioMixSchema, audioMix, {
          ...action.payload.context,
          wantAudioMix
        }).isValid;
      } else {
        state.audioMix.isValid = true;
        state.scenario.values.wantAudioMix = false;
      }
      sortBy(stories, "order").forEach((story, index) => {
        const {
          shots,
          scenarioId,
          label,
          description,
          id,
          lockProperties,
          enabled,
          order,
          audioId,
          endEffectId,
          startEffectId,
          endEffectDuration,
          startEffectDuration
        } = story;
        state.stories[index] = {
          isValid: validateSchema(StorySchema, story, action.payload.context)
            .isValid,
          open: false,
          values: {
            _key: uuid.v4(),
            scenarioId,
            label,
            description,
            id,
            lockProperties,
            enabled,
            order,
            audioId,
            endEffectId,
            startEffectId,
            endEffectDuration,
            startEffectDuration,
            _storyCategoryId: shots[0]?.shotCategoryId
          },
          shots: []
        };
        sortBy(shots, "order").forEach((shot, shotIndex) => {
          const shotCategory = action.payload.shotCategories.find(
            s => s.id === shot.shotCategoryId
          );
          const {
            id,
            storyId,
            label,
            description,
            order,
            enabled,
            lockProperties,
            posterId,
            type,
            videoId,
            recommendedDuration,
            instruction,
            shotCategoryId,
            musicVolume,
            musicVolumeWithAudioMix,
            videoVolume,
            videoVolumeWithAudioMix
          } = shot;
          const recommendedDurationInSecond = recommendedDuration / 1000;
          const defaultRecommendedDurationInSecond =
            shotCategory?.defaultRecommendedDuration! / 1000;
          state.stories[index].shots[shotIndex] = {
            storyIndex: index,
            open: false,
            isValid: validateSchema(ShotSchema, shot, action.payload.context)
              .isValid,
            values: {
              _key: uuid.v4(),
              id,
              storyId,
              label,
              description,
              order,
              enabled,
              lockProperties,
              posterId,
              type,
              videoId,
              recommendedDuration:
                recommendedDurationInSecond ||
                defaultRecommendedDurationInSecond,
              instruction,
              shotCategory,
              shotCategoryId,
              musicVolume: musicVolume || shotCategory?.defaultMusicVolume,
              musicVolumeWithAudioMix:
                musicVolumeWithAudioMix ||
                shotCategory?.defaultMusicVolumeWithAudioMix,
              videoVolume: videoVolume || shotCategory?.defaultVideoVolume,
              videoVolumeWithAudioMix:
                videoVolumeWithAudioMix ||
                shotCategory?.defaultVideoVolumeWithAudioMix
            }
          };
        });
      });
    });
    builder.addCase(duplicate.fulfilled, (state, action) => {
      state.isDuplicated = true;
      const {
        lockProperties,
        label,
        description,
        availableFormats,
        enabled,
        posterId,
        tagIds,
        wantAudioMix,
        audioMix,
        stories
      } = action.payload.scenario;
      state.scenario.values = {
        lockProperties,
        label,
        description,
        availableFormats,
        enabled,
        posterId,
        tagIds,
        wantAudioMix,
        audioMix,
        stories
      };
      state.scenario.isValid = validateSchema(
        ScenarioSchema,
        state.scenario.values,
        action.payload.context
      ).isValid;
      if (wantAudioMix) {
        const lockProperties = action.payload.scenario.audioMix?.lockProperties;
        const audioId = action.payload.scenario.audioMix?.audioId;
        const playOnGenerics = action.payload.scenario.audioMix?.playOnGenerics;
        state.audioMix.values = { audioId, lockProperties, playOnGenerics };
        state.audioMix.isValid = validateSchema(AudioMixSchema, audioMix, {
          ...action.payload.context,
          wantAudioMix
        }).isValid;
      } else {
        state.audioMix.isValid = true;
        state.scenario.values.wantAudioMix = false;
      }
      sortBy(stories, "order").forEach((story, index) => {
        const {
          lockProperties,
          audioId,
          label,
          description,
          order,
          enabled,
          startEffectId,
          startEffectDuration,
          endEffectId,
          endEffectDuration,
          shots
        } = story;
        state.stories[index] = {
          isValid: validateSchema(StorySchema, story, action.payload.context)
            .isValid,
          open: false,
          values: {
            lockProperties,
            audioId,
            label,
            description,
            order,
            enabled,
            startEffectId,
            startEffectDuration,
            endEffectId,
            endEffectDuration,
            shots,
            _storyCategoryId: shots[0]?.shotCategoryId
          },
          shots: []
        };
        sortBy(shots, "order").forEach((shot, shotIndex) => {
          const shotCategory = action.payload.shotCategories.find(
            s => s.id === shot.shotCategoryId
          );
          const {
            lockProperties,
            label,
            description,
            instruction,
            order,
            recommendedDuration,
            videoId,
            type,
            musicVolumeWithAudioMix,
            videoVolumeWithAudioMix,
            musicVolume,
            videoVolume,
            enabled,
            shotCategoryId,
            videoTutorialId,
            posterId
          } = shot;
          const recommendedDurationInSecond = recommendedDuration / 1000;
          const defaultRecommendedDurationInSecond =
            shotCategory?.defaultRecommendedDuration! / 1000;
          state.stories[index].shots[shotIndex] = {
            storyIndex: index,
            open: false,
            isValid: validateSchema(ShotSchema, shot, action.payload.context)
              .isValid,
            values: {
              lockProperties,
              label,
              description,
              instruction,
              order,
              recommendedDuration:
                recommendedDurationInSecond ||
                defaultRecommendedDurationInSecond,
              videoId,
              type,
              musicVolumeWithAudioMix:
                musicVolumeWithAudioMix ||
                shotCategory?.defaultMusicVolumeWithAudioMix,
              videoVolumeWithAudioMix:
                videoVolumeWithAudioMix ||
                shotCategory?.defaultVideoVolumeWithAudioMix,
              musicVolume: musicVolume || shotCategory?.defaultMusicVolume,
              videoVolume: videoVolume || shotCategory?.defaultVideoVolume,
              enabled,
              shotCategory,
              shotCategoryId,
              videoTutorialId,
              posterId
            }
          };
        });
      });
    });
  }
});

export const GtvReducer = GtvSlice.reducer;
export const GtvActions = {
  ...GtvSlice.actions,
  submit,
  edit,
  duplicate,
  validateForm
};
