import {
  Avatar,
  Box,
  BoxProps,
  ButtonBase,
  Card,
  Paper
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ShotCategoryIcon } from "../../../assets/icons";
import { ShotFormState } from "../../../reducers/gtv/reducer";
import { ShotCategorySelector } from "../../../reducers/shot-category/selector";
import { DotStatus } from "../DotStatus";
import { MyTypography } from "../MyTypography";

interface ShotTimelineProps extends BoxProps {
  storyIndex: number;
  index: number;
  shot: ShotFormState;
}

export const ShotTimeline: React.FC<ShotTimelineProps> = ({
  // storyIndex,
  index,
  shot,
  ...rest
}) => {
  const shotCategory = useSelector(
    ShotCategorySelector.getShotcategoryById(shot.values.shotCategoryId)
  );
  return (
    <Box
      clone
      borderRadius={10}
      display="flex"
      margin="0 auto"
      flexDirection="column"
      minWidth={80}
      {...rest}
    >
      <ButtonBase
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box
          mb={0.5}
          component={Card}
          height={20}
          width={20}
          borderRadius="100%"
          bgcolor="white"
        >
          <MyTypography color="primary">{index + 1}</MyTypography>
        </Box>
        {shotCategory && (
          <Box
            clone
            display="flex"
            height={40}
            width={40}
            borderRadius="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Paper elevation={2}>
              <Avatar src={ShotCategoryIcon[shotCategory.name]} />
            </Paper>
          </Box>
        )}
        <DotStatus mt={1} size={10} isValid={shot.isValid} />
      </ButtonBase>
    </Box>
  );
};
