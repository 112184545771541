import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ShotCategoryActionsTypes } from "./action";
import * as Api from "./api";
import { ShotCategory } from "./entity";

export interface ShotCategoryState extends EntityState<ShotCategory> {
  readStatus: FetchingStatus;
}

export const ShotCategoryInitialState: ShotCategoryState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL
};

export const ShotCategoryAdapter = createEntityAdapter<ShotCategory>();

const ShotCategoryAdapterState = ShotCategoryAdapter.getInitialState(
  ShotCategoryInitialState
);

export const read = createMyAsyncThunk(
  ShotCategoryActionsTypes.SHOT_CATEGORY_READ,
  Api.read
);

const ShotCategorySlice = createMySlice({
  name: "shot-cotegories",
  initialState: ShotCategoryAdapterState,
  adapter: ShotCategoryAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ShotCategoryAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const ShotCategoryReducer = ShotCategorySlice.reducer;
export const ShotCategoryActions = { ...ShotCategorySlice.actions, read };
