import { yupResolver } from "@hookform/resolvers";
import { Box, Collapse } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import set from "lodash/set";
import React, { memo, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AssetsType } from "../../../entities/asset";
import { CategoryNames } from "../../../entities/category";
import { ShotDTO, ShotType } from "../../../entities/shot";
import { AppSelector } from "../../../reducers/app/selector";
import { GtvSelector } from "../../../reducers/gtv/selector";
import { ShotCategoryName } from "../../../reducers/shot-category/entity";
import { ShotCategorySelector } from "../../../reducers/shot-category/selector";
import { ShotSchema } from "../../Gtv/schema";
import { MyButton } from "../MyButton";
import { MyForm } from "../MyForm2";
import { MyGalleryField } from "../MyForm2/MyGalleryField";
import { MyJsonTranslatorField } from "../MyForm2/MyJsonTranslatorField";
import { MyLockField } from "../MyForm2/MyLockField";
import { MySelectField } from "../MyForm2/MySelectField";
import { MySliderField } from "../MyForm2/MySliderField";
import { MySwitchField } from "../MyForm2/MySwitchField";
import { MyTextField } from "../MyForm2/MyTextField";
import { MyToggleButtonField } from "../MyForm2/MyToggleButtonField";
import { GtvContext } from "./Form";

interface ShotFormProps {
  index: number;
  storyIndex: number;
}

export const ShotForm: React.FC<ShotFormProps> = memo(
  ({ index, storyIndex }) => {
    const { t } = useTranslation("gtv");
    const { t: tCommon } = useTranslation("common");
    const { language: lang } = useSelector(AppSelector.getState);
    const {
      methodsRef,
      images,
      videos,
      onShotSubmit,
      edit,
      isSubmitted
    } = useContext(GtvContext);
    const [advancedOptions, setAdvancedOptions] = useState<boolean>(false);
    const [videoCategoryFilter, setVideoCategoryFilter] = useState<
      CategoryNames | undefined
    >();
    const shotCategoriesOptions = useSelector(
      ShotCategorySelector.getShotCategoriesOptions
    );
    const shot = useSelector(GtvSelector.getShotState(storyIndex, index));
    const typeOptions = [
      { label: t("shot-type.close"), value: ShotType.CLOSE },
      { label: t("shot-type.medium"), value: ShotType.MEDIUM },
      { label: t("shot-type.wide"), value: ShotType.WIDE }
    ];
    const methods = useForm({
      defaultValues: shot.values,
      mode: "all",
      context: { lang },
      resolver: yupResolver(ShotSchema)
    });
    const {
      control,
      setValue,
      formState: { isValid, isDirty },
      trigger,
      reset
    } = methods;

    const shotCategoryId = useWatch<string>({
      name: "shotCategoryId",
      control
    });
    const shotCategory = useSelector(
      ShotCategorySelector.getShotcategoryById(shotCategoryId)
    );
    const [myShot, setMyShot] = useState<any>();

    const getVideoCategoryFilter = (
      shotCategory: ShotCategoryName
    ): CategoryNames => {
      if (shotCategory === ShotCategoryName.INTERTITLE)
        return CategoryNames.INTERTITLE;
      else if (shotCategory === ShotCategoryName.GENERIC)
        return CategoryNames.GENERICS;
      else return CategoryNames.THEMES;
    };

    useEffect(() => {
      const defaultRecommendedDurationInSecond =
        shotCategory?.defaultRecommendedDuration! / 1000;
      if (shotCategory) {
        setValue("videoVolume", shotCategory.defaultVideoVolume);
        setValue("musicVolume", shotCategory.defaultMusicVolume);
        setValue(
          "videoVolumeWithAudioMix",
          shotCategory.defaultVideoVolumeWithAudioMix
        );
        setValue(
          "musicVolumeWithAudioMix",
          shotCategory.defaultMusicVolumeWithAudioMix
        );
        setValue("recommendedDuration", defaultRecommendedDurationInSecond);
        setVideoCategoryFilter(getVideoCategoryFilter(shotCategory.name));
      }
    }, [setValue, shotCategory]);

    useEffect(() => {
      if (advancedOptions) {
        if (shotCategory) {
          setValue("videoVolume", shotCategory.defaultVideoVolume);
          setValue("musicVolume", shotCategory.defaultMusicVolume);
          setValue(
            "videoVolumeWithAudioMix",
            shotCategory.defaultVideoVolumeWithAudioMix
          );
          setValue(
            "musicVolumeWithAudioMix",
            shotCategory.defaultMusicVolumeWithAudioMix
          );
        }
      }
    }, [setValue, shotCategory, advancedOptions]);

    useEffect(() => {
      if (methodsRef?.current) {
        set(
          methodsRef.current,
          `stories[${storyIndex}].shots[${index}].methods`,
          methods
        );
      }
    }, [index, methods, methodsRef, storyIndex]);

    useEffect(() => {
      if (!isDirty && !shot.isValid && (edit || isSubmitted)) {
        trigger();
      }
    }, [edit, isDirty, isSubmitted, shot.isValid, trigger]);

    useEffect(() => {
      if (
        shotCategory &&
        (shotCategory.name === ShotCategoryName.GENERIC ||
          shotCategory.name === ShotCategoryName.INTERTITLE)
      ) {
        setValue("type", undefined, {
          shouldValidate: true,
          shouldDirty: true
        });
        setValue("posterId", undefined, {
          shouldValidate: true,
          shouldDirty: true
        });
        trigger();
      }
    }, [setValue, shotCategory, trigger]);

    useEffect(() => {
      reset(Object.assign({}, shot.values));
    }, [index, reset, shot.values, storyIndex]);

    useEffect(() => {
      setMyShot(shot.values);
    }, [shot]);

    return (
      <MyForm component={Box} methods={methods}>
        <Box>
          <input
            hidden
            type="number"
            name={`order`}
            ref={methods.register}
            defaultValue={index}
          />
          <MyJsonTranslatorField
            // defaultValue={field.label}
            name={`label`}
            textFieldProps={{ label: t("form.label") }}
            defaultValue={myShot?.label || null}
            setValue={(name: string, value: any) => {
              setValue(name, value);
            }}
          />
          <MyJsonTranslatorField
            // defaultValue={field.description}
            name={`description`}
            textFieldProps={{ label: t("form.description") }}
            defaultValue={myShot?.description || null}
            multiline={true}
            setValue={(name: string, value: any) => {
              setValue(name, value);
            }}
          />
          <MyJsonTranslatorField
            // defaultValue={field.instruction}
            name={`instruction`}
            textFieldProps={{
              label: t("form.instruction"),
              // multiline: true,
              // rows: 2,
              // rowsMax: 4
            }}
            defaultValue={myShot?.instruction || null}
            setValue={(name: string, value: any) => {
              setValue(name, value);
            }}
          />
          <MySelectField
            formControlProps={{ hidden: true }}
            label={t("form.shot-category")}
            options={shotCategoriesOptions}
            name={`shotCategoryId`}
          />
          <MyToggleButtonField
            formControlProps={{
              hidden:
                shotCategory &&
                [
                  ShotCategoryName.GENERIC,
                  ShotCategoryName.INTERTITLE
                ].includes(shotCategory.name)
            }}
            size="small"
            name="type"
            label={t("form.shot-type")}
            options={typeOptions}
          />

          <MyLockField
            name={`lockProperties`}
            lockProperty="recommendedDuration"
          >
            <MyTextField
              label={t("form.recommended-duration")}
              type="number"
              name={`recommendedDuration`}
            />
          </MyLockField>
          <MyGalleryField
            // defaultValue={field.posterId}
            formControlProps={{
              hidden:
                shotCategory &&
                [
                  ShotCategoryName.GENERIC,
                  ShotCategoryName.INTERTITLE
                ].includes(shotCategory.name)
            }}
            label={t("form.select-poster")}
            buttonLabel={t("form.show-image-gallery")}
            name={`posterId`}
            type={AssetsType.IMAGE}
            category={CategoryNames.PLANS}
            assets={images}
          />
          <MyLockField name={`lockProperties`} lockProperty="videoId">
            <MyGalleryField
              // defaultValue={field.videoId}
              label={t("form.select-video")}
              buttonLabel={t("form.show-video-gallery")}
              name={`videoId`}
              type={AssetsType.VIDEO}
              category={videoCategoryFilter}
              assets={videos}
            />
          </MyLockField>
          <MySwitchField
            // defaultChecked={field.enabled}
            label={tCommon("enabled")}
            name={`enabled`}
          />
          <MyButton
            boxProps={{ my: 1, justifyContent: "space-between" }}
            color="primary"
            fullWidth
            variant="outlined"
            onClick={() => setAdvancedOptions(prev => !prev)}
            rightIcon={
              advancedOptions ? (
                <ExpandLessIcon color="primary" />
              ) : (
                <ExpandMoreIcon color="primary" />
              )
            }
          >
            {tCommon("advanced-options")}
          </MyButton>
          <Collapse in={advancedOptions}>
            <MyLockField name={`lockProperties`} lockProperty="videoVolume">
              <MySliderField
                //defaultValue={shotCategory?.defaultVideoVolume}
                label={t("form.video-volume")}
                name={`videoVolume`}
                percentageDisplay
                leftIcon={<VolumeDown />}
                rightIcon={<VolumeUp />}
              />
            </MyLockField>
            <MyLockField name={`lockProperties`} lockProperty="musicVolume">
              <MySliderField
                // defaultValue={field.musicVolume}
                //defaultValue={shotCategory?.defaultMusicVolume}
                label={t("form.music-volume")}
                name={`musicVolume`}
                percentageDisplay
                leftIcon={<VolumeDown />}
                rightIcon={<VolumeUp />}
              />
            </MyLockField>
            <MyLockField
              name={`lockProperties`}
              lockProperty="videoVolumeWithAudioMix"
            >
              <MySliderField
                // defaultValue={field.videoVolumeWithAudioMix}
                //defaultValue={shotCategory?.defaultVideoVolumeWithAudioMix}
                label={t("form.video-volume-with-audio-mix")}
                name={`videoVolumeWithAudioMix`}
                percentageDisplay
                leftIcon={<VolumeDown />}
                rightIcon={<VolumeUp />}
              />
            </MyLockField>
            <MyLockField
              name={`lockProperties`}
              lockProperty="musicVolumeWithAudioMix"
            >
              <MySliderField
                // defaultValue={field.musicVolumeWithAudioMix}
                //defaultValue={shotCategory?.defaultMusicVolumeWithAudioMix}
                label={t("form.music-volume-with-audio-mix")}
                name={`musicVolumeWithAudioMix`}
                percentageDisplay
                leftIcon={<VolumeDown />}
                rightIcon={<VolumeUp />}
              />
            </MyLockField>
          </Collapse>
          <MyButton
            color={
              (!shot.isValid && isValid) || isDirty ? "secondary" : "primary"
            }
            onClick={e =>
              methods.handleSubmit(values =>
                onShotSubmit(storyIndex, index, values as ShotDTO)
              )(e)
            }
            size="large"
            fullWidth
            variant={isValid ? "contained" : "outlined"}
          >
            {(!shot.isValid && isValid) || isDirty
              ? t("form.update-shot")
              : t("form.save-shot")}
          </MyButton>
        </Box>
      </MyForm>
    );
  }
);
