import memoize from "lodash/memoize";
import { createSelector } from "reselect";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { AppSelector } from "../app/selector";
import { ShotCategoryName } from "../shot-category/entity";
import { ShotCategorySelector } from "../shot-category/selector";
import { RootState } from "../store";

export const getState = createSelector(
  (state: RootState) => state,
  memoize(state => state.gtv)
);

export const getEdit = createSelector(getState, state => state.edit);
export const getDuplicate = createSelector(
  getState,
  state => state.isDuplicated
);
export const getIsSubmitted = createSelector(
  getState,
  state => state.isSubmitted
);
export const getIsValidating = createSelector(
  getState,
  state => state.isValidating
);

export const getScenarioState = createSelector(
  (state: RootState) => state.gtv,
  gtv => gtv.scenario
);

export const getAudioMixState = createSelector(
  (state: RootState) => state.gtv,
  gtv => gtv.audioMix
);

export const getStoriesState = createSelector(getState, gtv => gtv.stories);
export const getStoryState = (index: number) =>
  createSelector(getStoriesState, stories => stories[index]);

export const getShotsState = (storyIndex: number) =>
  createSelector(getState, gtv => gtv.stories[storyIndex].shots);
export const getShotState = (storyIndex: number, index: number) =>
  createSelector(getShotsState(storyIndex), shots => shots[index]);

export const getStoryCategoriesOptions = createSelector(
  ShotCategorySelector.selectAll,
  AppSelector.getState,
  (shotCategories, { language }) => {
    return shotCategories
      .filter(sc => sc.name !== ShotCategoryName.B_ROLL_CHILD)
      .map(sc => ({ value: sc.id, label: jsonTranslator(sc.label, language) }));
  }
);

export const GtvSelector = {
  getState,
  getEdit,
  getDuplicate,
  getIsValidating,
  getIsSubmitted,
  getScenarioState,
  getAudioMixState,
  getStoriesState,
  getStoryCategoriesOptions,
  getStoryState,
  getShotsState,
  getShotState
};
