import { Project, ProjectUpdateDTO, StatusUpdateDTO } from "./entity";
import { Image } from "../images/entity";
import { Request } from "../../utils/request";

export const readProjects = () => Request({ withToken: true }).get("/projects");

export const readOneProjects = (id: string) =>
  Request({ withToken: true }).get(`/projects/${id}`);

export const removeOneProject = (id: string) =>
  Request({ withToken: true }).delete(`/projects/${id}`);

export const updateProject = ({
  id,
  project
}: {
  id: string;
  project: ProjectUpdateDTO;
}) => {
  return Request({ withToken: true }).patch(`/projects/${id}`, project);
};

export const updateVideoStatus = ({
  id,
  videoId,
  status
}: {
  id: string;
  videoId: string;
  status: StatusUpdateDTO;
}) => {
  return Request({ withToken: true }).patch(
    `/projects/${id}/videos/${videoId}`,
    { status: status.status, comment: status.comment }
  );
};

export const uploadVideo = ({
  project,
  formData
}: {
  project: Project;
  formData: FormData;
}) =>
  Request({ withToken: true }).post<Image[]>(
    `/projects/${project.id}/videos`,
    formData,
    {
      headers: {
        "Content-rype": "multipart/form-data"
      }
    }
  );
