import { Box } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Input, { InputProps } from "@material-ui/core/Input";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import get from "lodash/get";
import React from "react";
import { ChromePicker } from "react-color";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";

export interface MyTextFieldProps extends InputProps, FormProps {
  showValidationIcon?: boolean;
  myFormControlProps?: Omit<MyFormControlProps, "label">;
  defaultValues?: unknown;
  name: string;
  multiline?: boolean;
  onTextChange?: (text: string) => void;
}

export const MyTextField: React.FC<MyTextFieldProps> = props => {
  const {
    name,
    defaultValue,
    label,
    helperText,
    myFormControlProps,
    formControlProps,
    formLabelProps,
    formHelperTextProps,
    showValidationIcon,
    onTextChange,
    multiline,
    ...inputProps
  } = props;
  const { errors, control } = useFormContext();

  const labelRef = React.useRef<HTMLLabelElement>(null);
  const errorField = get(errors, name)?.message;
  // const touchField = get(formState.touched, name);

  const myFormControl: MyFormControlProps = {
    errorField,
    label,
    labelRef,
    helperText,
    // add custone error message 

      formLabelProps: { ...formLabelProps, required: inputProps.required, onError: () => {console.log("error")}},
    formHelperTextProps,
    ...formControlProps,
    ...myFormControlProps
  };

  const endAdornment =
    inputProps.endAdornment || showValidationIcon ? (
      !showValidationIcon ? (
        inputProps.endAdornment
      ) : errorField ? (
        <ErrorIcon color="error" />
      ) : (
        <CheckIcon style={{ color: green[400] }} />
      )
    ) : null;
  return (
    <MyFormControl {...myFormControl} isAnInputLabel={true}>
      <Controller
        control={control}
        defaultValue={defaultValue || ""}
        name={name}
        render={({ onBlur, onChange, value }) =>
          inputProps.type === "color" ? (
            <ChromePicker
              color={value}
              styles={{
                default: {
                  picker: {
                    width: 180,
                    marginTop: 42,
                    marginRight: 8,
                    marginBottom: 8
                  }
                }
              }}
              onChangeComplete={color => {
                onBlur();
                onChange(color.hex);
              }}
            />
          ) : (
            <Input
              margin="dense"
              error={!!errorField}
              onChange={(event) => {
                onChange(event);
                if (onTextChange && typeof onTextChange === "function") {
                  onTextChange(event.target.value);
                }
              }}
            onError={(err) => {
              console.log("Error ", err);
            }}
              onBlur={onBlur}
              value={
                /*(inputProps.type === "number" && value >= 1000) ? value / 1000 :*/ value
              }
              endAdornment={endAdornment}
              disabled={inputProps.disabled}
              {...inputProps}
              multiline={multiline ? multiline : false}
              startAdornment={
                inputProps.startAdornment ? (
                  <Box mr={1}>{inputProps.startAdornment}</Box>
                ) : null
              }
            />
          )
        }
      />
    </MyFormControl>
  );
};
