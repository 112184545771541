import { AssetsType } from "../../entities/asset";
import { User } from "../../entities/user";
import { Request } from "../../utils/request";
import { ScenarioDTO } from "../scenarios/entity";
import { Company, CompanyCreateDTO } from "./entity";
import { Image, ImageGrouped } from "../images/entity";
import { Watermark } from "../../entities/watermark";
import { VideoGrouped } from "../videos/entity";
import { Project, ProjectCompanyCreateDTO } from "../projects/entity";

export const read = () => Request({ withToken: true }).get("/companies");

export const readOneCompany = (id: string) =>
  Request({ withToken: true }).get(`/companies/${id}`);

export const createOne = (userId: string, company: CompanyCreateDTO) =>
  Request({ withToken: true }).post(`/users/${userId}/companies`, company);

// Company
export const removeOne = (company: Company) =>
  Request({ withToken: true }).delete(`/companies/${company.id}`);

// Projects
export const readProjects = (company: Company) =>
  Request({ withToken: true }).get<Project[]>(
    `/companies/${company.id}/projects`
  );

export const createProject = ({
  project,
  company
}: {
  company: Company;
  project: ProjectCompanyCreateDTO;
}) => {
  //TODO to be removed
  const { name, scenarioId }: { name: string; scenarioId: string } = project;
  // ------
  return Request({ withToken: true }).post<Project>(
    `/companies/${company.id}/projects`,
    { name, scenarioId }
  );
};

// Collaborators
export const readCollaborators = (company: Company) =>
  Request({ withToken: true }).get<User[]>(
    `/companies/${company.id}/collaborators`
  );

export const removeCollaborator = ({
  user,
  company
}: {
  user: User;
  company: Company;
}) =>
  Request({ withToken: true }).delete(
    `/companies/${company.id}/users/${user.id}/collaborator`
  );

export const addCollaborator = ({
  user,
  company
}: {
  user: User;
  company: Company;
}) =>
  Request({ withToken: true }).put(
    `/companies/${company.id}/users/${user.id}/collaborator`
  );

// Assets

export const readImages = (company: Company) =>
  Request({ withToken: true }).get<ImageGrouped>(
    `/companies/${company.id}/images/group`
  );
export const uploadImage = ({
  company,
  formData
}: {
  company: Company;
  formData: FormData;
}) =>
  Request({ withToken: true }).post<Image[]>(
    `/companies/${company.id}/images/upload`,
    formData,
    {
      headers: {
        "Content-rype": "multipart/form-data"
      }
    }
  );
export const uploadWatermark = ({
  company,
  formData
}: {
  company: Company;
  formData: FormData;
}) =>
  Request({ withToken: true }).post<Watermark>(
    `/companies/${company.id}/watermarks/upload`,
    formData,
    {
      headers: {
        "Content-rype": "multipart/form-data"
      }
    }
  );
export const removeWatermark = ({ id }: { companyId: string; id: string }) =>
  Request({ withToken: true }).delete(`/watermarks/${id}`);

export const readVideos = (company: Company) =>
  Request({ withToken: true }).get<VideoGrouped>(
    `/companies/${company.id}/videos/group`
  );
export const uploadVideo = ({
  company,
  formData
}: {
  company: Company;
  formData: FormData;
}) =>
  Request({ withToken: true }).post<Image[]>(
    `/companies/${company.id}/videos/upload`,
    formData,
    {
      headers: {
        "Content-rype": "multipart/form-data"
      }
    }
  );

export const readAudios = (company: Company) =>
  Request({ withToken: true }).get(`/companies/${company.id}/audios`);
export const uploadAudio = ({
  company,
  formData
}: {
  company: Company;
  formData: FormData;
}) =>
  Request({ withToken: true }).post<Image[]>(
    `/companies/${company.id}/audios/upload`,
    formData,
    {
      headers: {
        "Content-rype": "multipart/form-data"
      }
    }
  );

export const readAssets = (company: Company, assetType?: AssetsType) =>
  Request({ withToken: true }).get(
    `/companies/${company.id}/${assetType ? `${assetType}s` : ""}`
  );

export const readScenarios = (company: Company) =>
  Request({ withToken: true }).get(`/companies/${company.id}/scenarios`);

export const createScenario = (args: {
  company: Company;
  scenario: ScenarioDTO;
}) =>
  Request({ withToken: true }).post(
    `/companies/${args.company.id}/scenarios`,
    args.scenario
  );

export const updateScenario = (args: {
  scenarioId: string;
  companyId: string;
  scenario: ScenarioDTO;
}) =>
  Request({ withToken: true }).put(
    `/companies/${args.companyId}/scenarios/${args.scenarioId}`,
    args.scenario
  );
