import {
  AppBar,
  Container,
  createStyles,
  Drawer,
  Grid,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import LeftArrowIcon from "@material-ui/icons/ArrowBack";
import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ScenarioCategory } from "../../../entities/scenario-category";
import { AppSelector } from "../../../reducers/app/selector";
import { Scenario } from "../../../reducers/scenarios/entity";
import { ScenarioSelector } from "../../../reducers/scenarios/selector";
import {
  jsonTranslator,
  Languages
} from "../../../utils/function/jsonTranslator";
import { MyIconButton } from "../MyIconButton";
import { ScenarioCategoryItem } from "../ScenarioCategoryItem";
import { MyScenarioList } from "../ScenarioList";

export interface MyGalleryScenarioProps {
  own: Scenario[];
  shared: Scenario[];
  activationCode: Scenario[];
  others: Scenario[];
  onScenarioSelected?: (
    scenario: Scenario,
    closeScenarioList: Function
  ) => void;
}

interface GalleryScenarioContextProps {
  scenarioSelected?: Scenario;
  onScenarioSelected?: (scenario: Scenario) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    drawer: {
      maxWidth: theme.breakpoints.values["md"],
      width: "100%"
    }
  })
);
export const GalleryScenarioContext = createContext<
  GalleryScenarioContextProps
>({
  scenarioSelected: undefined,
  onScenarioSelected: () => null
});

export const MyGalleryScenario: React.FC<MyGalleryScenarioProps> = ({
  own,
  shared,
  activationCode,
  others,
  onScenarioSelected
}) => {
  const { language: lang } = useSelector(AppSelector.getState);

  const [premiumCategory, setPremiumCategory] = useState<
    Array<ScenarioCategory & { poster?: string; scenarios: Scenario[] }>
  >([]);
  const [categorySelected, setCategorySelected] = useState<ScenarioCategory>();
  const [openScenarioList, setOpenScenarioList] = useState<boolean>(false);
  const scenarioSelected = useSelector(ScenarioSelector.getScenarioSelected);
  const classes = useStyles();

  useEffect(() => {
    const premiumCategoryById = others.reduce((prev, curr) => {
      curr.categories.forEach(c => {
        prev[c.id] = {
          ...prev[c.id],
          ...c,
          poster: prev[c.id]?.poster || curr.poster?.url,
          scenarios: [...(prev[c.id]?.scenarios || []), curr]
        };
      });
      return prev;
    }, {} as { [categoryIndex: string]: ScenarioCategory & { poster?: string; scenarios: Scenario[] } });
    const premiumCategoryCreated = Object.keys(premiumCategoryById).map(
      key => premiumCategoryById[key]
    );
    setPremiumCategory(premiumCategoryCreated);
  }, [others]);

  const onScenarioCategoryClick = (scenarioCategory: ScenarioCategory) => {
    setCategorySelected(scenarioCategory);
    setOpenScenarioList(true);
  };
  const closeScenarioList = () => setOpenScenarioList(false);

  return (
    <GalleryScenarioContext.Provider
      value={{
        onScenarioSelected: (s: Scenario) => {
          onScenarioSelected && onScenarioSelected(s, closeScenarioList);
        },
        scenarioSelected
      }}
    >
      <Drawer
        classes={{ paper: classes.drawer }}
        open={openScenarioList}
        anchor="right"
        variant="temporary"
        onClose={() => {
          setOpenScenarioList(false);
        }}
      >
        <AppBar position="sticky" color="primary" elevation={0}>
          <Toolbar variant="dense" disableGutters>
            <MyIconButton
              style={{ color: "white" }}
              onMouseDown={() => setOpenScenarioList(false)}
            >
              <LeftArrowIcon />
            </MyIconButton>
            {categorySelected && (
              <Typography variant="h6">
                {jsonTranslator(categorySelected.label, lang)}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        {categorySelected && (
          <Container maxWidth="md" disableGutters style={{ padding: 0 }}>
            <MyScenarioList scenarios={categorySelected.scenarios || []} />
          </Container>
        )}
      </Drawer>
      <Grid container>
        {own.length > 0 && (
          <Grid item xs={12} lg={6}>
            <ScenarioCategoryItem
              onScenarioCategoryClick={onScenarioCategoryClick}
              scenarioCategory={{
                id: "own",
                label: {
                  [Languages.FR]: "Vos scénarios",
                  [Languages.EN]: "Your scenarios"
                },
                description: {
                  [Languages.FR]: "Retrouvez vos scénarios",
                  [Languages.EN]: "Get your scenarios"
                },
                poster: own[0].poster?.url,
                scenarios: own
              }}
            />
          </Grid>
        )}
        {shared.length > 0 && (
          <Grid item xs={12} lg={6}>
            <ScenarioCategoryItem
              onScenarioCategoryClick={onScenarioCategoryClick}
              scenarioCategory={{
                id: "shared",
                label: {
                  [Languages.FR]: "Vos scénarios partagés",
                  [Languages.EN]: "Your shared scenarios"
                },
                description: {
                  [Languages.FR]:
                    "Retrouvez et consultez tous vos scénarios partagés",
                  [Languages.EN]: "Find and get all of your shared scenarios"
                },
                poster: shared[0].poster?.url,
                scenarios: shared
              }}
            />
          </Grid>
        )}
        {activationCode.length > 0 && (
          <Grid item xs={12} lg={6}>
            <ScenarioCategoryItem
              onScenarioCategoryClick={onScenarioCategoryClick}
              scenarioCategory={{
                id: "activationCode",
                label: {
                  [Languages.FR]:
                    "Vos scénarios partagés par vos codes d'activation",
                  [Languages.EN]:
                    "Your shared scenario from your activation codes"
                },
                description: {
                  [Languages.FR]:
                    "Retrouvez et consultez vos scénarios partagés par vos codes d'activation",
                  [Languages.EN]:
                    "Find and get all of your shared scenarios by activation codes"
                },
                poster: activationCode[0].poster?.url,
                scenarios: activationCode
              }}
            />
          </Grid>
        )}
        {premiumCategory &&
          premiumCategory
            .sort((a, b) => a.label[lang].localeCompare(b.label[lang]))
            .map(p => (
              <Grid item xs={12} lg={6} key={p.id}>
                <ScenarioCategoryItem
                  onScenarioCategoryClick={onScenarioCategoryClick}
                  key={p.id}
                  scenarioCategory={p}
                />
              </Grid>
            ))}
      </Grid>
    </GalleryScenarioContext.Provider>
  );
};
