import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { ScenarioCategory } from "../../entities/scenario-category";
import { AppSelector } from "../../reducers/app/selector";
import {
  jsonTranslator,
  JsonTranslator
} from "../../utils/function/jsonTranslator";

interface ScenarioCategoryItemProps extends CardProps {
  onScenarioCategoryClick?: (scenarioCategory: ScenarioCategory) => void;
  scenarioCategory: DeepPartial<ScenarioCategory> & {
    label: JsonTranslator;
    poster?: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative"
    },
    overlay: {
      width: "100%",
      position: "absolute",
      top: 0,
      backgroundColor: "rgba(15, 114, 184, 0.55)"
    },
    poster: {
      height: 180,
      [theme.breakpoints.down("sm")]: {
        height: 90
      }
    },
    description: {
      height: 44,
      [theme.breakpoints.down("sm")]: {
        height: "auto"
      }
    },
    name: {
      fontSize: theme.typography.h5.fontSize,
      position: "absolute",
      bottom: 0,
      color: "white"
    }
  })
);
export const ScenarioCategoryItem: React.FC<ScenarioCategoryItemProps> = ({
  scenarioCategory,
  onScenarioCategoryClick,
  ...rest
}) => {
  const { label, description, poster } = scenarioCategory;
  const classes = useStyles();
  const { language: lang } = useSelector(AppSelector.getState);

  const descriptionLang = description ? jsonTranslator(description, lang) : "";
  const descriptionCut =
    descriptionLang.length > 145
      ? descriptionLang.substr(0, 145) + "..."
      : descriptionLang;

  return (
    <Box clone m={1 / 2}>
      <Card
        className={classes.root}
        {...rest}
        onClick={() =>
          onScenarioCategoryClick &&
          onScenarioCategoryClick(scenarioCategory as ScenarioCategory)
        }
      >
        <CardActionArea>
          <Box position="relative">
            <CardMedia image={poster} className={classes.poster} />
            <div className={clsx(classes.overlay, classes.poster)} />
            <Box clone pl={{ xs: 1, sm: 2 }}>
              <Typography className={classes.name}>
                {jsonTranslator(label, lang)}
              </Typography>
            </Box>
          </Box>
          {descriptionCut.length && (
            <CardContent>
              <Typography className={classes.description}>
                {descriptionCut}
              </Typography>
            </CardContent>
          )}
        </CardActionArea>
      </Card>
    </Box>
  );
};
