import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import { AppSelector } from "../../reducers/app/selector";
import { Scenario } from "../../reducers/scenarios/entity";
import { jsonTranslator } from "../../utils/function/jsonTranslator";

interface ScenarioItemProps extends CardProps {
  onClick?: () => void;
  scenario: Scenario;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    overlay: {
      width: "100%",
      position: "absolute",
      top: 0,
      background: "linear-gradient(to top,rgba(0,0,0,0.4), rgba(0,0,0,0) 54px)",
      [theme.breakpoints.down("sm")]: {
        background:
          "linear-gradient(to top,rgba(0,0,0,0.4), rgba(0,0,0,0) 34px)"
      }
    },
    poster: {
      height: 260,
      [theme.breakpoints.down("sm")]: {
        height: 180
      }
    },
    description: {
      height: 44,
      [theme.breakpoints.down("sm")]: {
        height: "auto"
      }
    },
    name: {
      fontSize: theme.typography.h4.fontSize,
      position: "absolute",
      bottom: 0,
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.h5.fontSize
      }
    }
  })
);
export const ScenarioItem: React.FC<ScenarioItemProps> = ({
  scenario: { label, poster, description },
  onClick,
  ...rest
}) => {
  const classes = useStyles();
  const { language: lang } = useSelector(AppSelector.getState);

  const descriptionLang = description ? jsonTranslator(description, lang) : "";
  const descriptionCut =
    descriptionLang.length > 145
      ? descriptionLang.substr(0, 145) + "..."
      : descriptionLang;

  return (
    <Box clone m={1 / 2}>
      <Card className={classes.root} {...rest} onClick={onClick}>
        <CardActionArea>
          <Box position="relative">
            <CardMedia image={poster?.url} className={classes.poster} />
            <div className={clsx(classes.overlay, classes.poster)} />
            <Box className="contain_title" clone pl={{ xs: 1, sm: 2 }} style={{ width: "100%", padding: 10, backgroundColor:"#00000061" }}>
              <Typography className={classes.name}>
                {jsonTranslator(label, lang)}
              </Typography>
            </Box>
          </Box>
          {descriptionCut && (
            <CardContent>
              <Typography className={classes.description}>
                {descriptionCut}
              </Typography>
            </CardContent>
          )}
        </CardActionArea>
      </Card>
    </Box>
  );
};
