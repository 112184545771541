import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthenticationActions } from "../../../../reducers/authentication/reducer";
import { MyButton, MyButtonProps } from "../../MyButton";

export const Logout: React.FC<{
  onLogout?: Function;
  myButtonProps?: MyButtonProps;
}> = ({ onLogout, myButtonProps }) => {
  const dispatch = useDispatch();
  const logout = () => dispatch(AuthenticationActions.logOut());
  const { t } = useTranslation("components");
  return (
    <MyButton
      color="inherit"
      leftIcon={<ExitToAppIcon />}
      onClick={() => {
        logout();
        onLogout && onLogout();
      }}
      {...myButtonProps}
    >
      {t("my-topbar.logout")}
    </MyButton>
  );
};
