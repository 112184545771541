import {
  AppBar,
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import LeftArrowIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ExtraCompanyProps } from "..";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { MyGalleryScenario } from "../../../components/common/gallery/MyGalleryScenario";
import { GtvCompany } from "../../../components/common/Gtv";
import { MyButton } from "../../../components/common/MyButton";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { Page } from "../../../components/common/MyPage";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { CompanyActions } from "../../../reducers/companies/reducer";
import { CompanySelector } from "../../../reducers/companies/selector";
import { GtvActions } from "../../../reducers/gtv/reducer";
import { RoleNames } from "../../../reducers/roles/entity";
import { Scenario, ScenarioDTO } from "../../../reducers/scenarios/entity";
import { ScenarioActions } from "../../../reducers/scenarios/reducer";
import { ScenarioSelector } from "../../../reducers/scenarios/selector";
import { ScenarioDetail } from "../../Scenario/ScenarioDetail";
import { jsonTranslator } from "../../../utils/function/jsonTranslator";
import { AppSelector } from "../../../reducers/app/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { TransferScenario } from "./components/TransferScenario";
import { MyDialog } from "../../../components/common/MyDialog";
import { MyForm } from "../../../components/common/MyForm2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { object, string } from "yup";
import { ProjectCompanyCreateDTO } from "../../../reducers/projects/entity";
import { push } from "connected-react-router";
import { MyTextField } from "../../../components/common/MyForm2/MyTextField";
import { MyAutocompleteField } from "../../../components/common/MyForm2/MyAutocompleteField";
import { RootState } from "../../../reducers/store";

interface ScenarioPageProps extends ExtraCompanyProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appbar: {
      background: "transparent"
    },
    drawer: {
      maxWidth: theme.breakpoints.values["md"],
      width: "100%"
    }
  })
);

export const ScenarioPage: React.FC<ScenarioPageProps> = ({ company }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");
  const { t: tCompany } = useTranslation("company");

  const classes = useStyles();
  const { language: lang } = useSelector(AppSelector.getState);
  const currentUser = useSelector(getCurrentUser);
  const { readScenariosStatus, updateScenarioStatus } = useSelector(
    CompanySelector.getState
  );

  const { transferScenarioStatus } = useSelector(ScenarioSelector.getState);
  const scenariosPremium = useSelector(ScenarioSelector.getScenariosPremium);
  const scenariosPublic = useSelector(ScenarioSelector.getScenariosPublic);
  const companyScenarios = useSelector(
    ScenarioSelector.getCompanyScenarios(company)
  );
  const companySharedScenarios = useSelector(
    ScenarioSelector.getCompanySharedScenarios(company)
  );

  const {
    scenarios,
    createProjectStatus
  } = useSelector((state: RootState) => ({
    scenarios: ScenarioSelector.selectAll(state),
    scenarioStatus: state.companies.readScenariosStatus,
    createProjectStatus: state.companies.createProjectStatus
  }));
  const scenarioCompany = useMemo(
    () => scenarios.filter(s => s.companyId === company.id),
    [company.id, scenarios]
  );
  const scenarioOptions = useMemo(
    () =>
      scenarioCompany.map(s => ({
        value: s.id,
        label: jsonTranslator(s.label, lang)
      })),
    [lang, scenarioCompany]
  );

  const scenarioSelected = useSelector(ScenarioSelector.getScenarioSelected);
  const [openScenarioDetail, setOpenScenarioDetail] = useState<boolean>(false);
  const [openScenarioEdit, setOpenScenarioEdit] = useState<boolean>(false);
  const [duplicateScenario, setDuplicateScenario] = useState<boolean>(false);
  const [openTransferButton, setOpenTransferButton] = useState<boolean>(false);
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const closeScenarioListRef = useRef<Function>();

  const onScenarioSelected = (
    scenario: Scenario,
    closeScenarioList: Function
  ) => {
    closeScenarioListRef.current = closeScenarioList;
    dispatch(ScenarioActions.selected(scenario));
    setOpenScenarioDetail(true);
  };

  const onScenarioDeleteClick = () => {
    closeScenarioListRef.current && closeScenarioListRef.current();
    setOpenScenarioDetail(false);
    if (scenarioSelected) {
      dispatch(ScenarioActions.async.removeOneScenario(scenarioSelected.id));
    }
  };

  const methods = useForm({
    defaultValues: { scenarioId: "", name: "" },
    resolver: yupResolver(
      object({
        name: string()
          .required()
          .min(1),

        scenarioId: string().required()
      })
    )
  });

  const onCreateProject = async (project: ProjectCompanyCreateDTO) => {
    const { payload } = await dispatch<any>(
      CompanyActions.createCompanyProject({ company, project })
    );
    if (payload?.id)
      dispatch(push(`/company/${company.id}/projects/${payload.id}`));
  };


  const onEditScenario = (scenario: ScenarioDTO) => {
    closeScenarioListRef.current && closeScenarioListRef.current();
    setOpenScenarioEdit(false);
    if (scenarioSelected) {
      dispatch(
        CompanyActions.updateScenario({
          companyId: company.id,
          scenarioId: scenarioSelected.id,
          scenario
        })
      );
    }
  };

  const onScenarioEditClick = () => {
    if (scenarioSelected) {
      /* setOpenScenarioDetail(false); */
      setOpenScenarioEdit(true);
      dispatch(GtvActions.edit(scenarioSelected));
    }
  };

  const onStartProjectClick = () => {
    if (scenarioSelected) {
      /* setOpenScenarioDetail(false); */
      // setDuplicateScenario(true);
      // setOpenScenarioEdit(true);
      // dispatch(GtvActions.duplicate(scenarioSelected));
      setOpenCreateProject(true);
    }
  };

  const onValidDuplicatedScenario = (scenario: ScenarioDTO) => {
    closeScenarioListRef.current && closeScenarioListRef.current();
    setOpenScenarioEdit(false);
    if (scenarioSelected) {
      dispatch(CompanyActions.createScenario({ company, scenario }));
    }
    setDuplicateScenario(false);
  };

  const onScenarioTransferClick = () => {
    closeScenarioListRef.current && closeScenarioListRef.current();
    setOpenScenarioDetail(false);
    if (scenarioSelected) {
      setOpenTransferButton(true);
    }
  };

  useEffect(() => {
    if (transferScenarioStatus === FetchingStatus.SUCCESS) {
      setOpenTransferButton(false);
    }
  }, [transferScenarioStatus]);

  return (
    <Page>
      <DynamicElement
        actions={[
          {
            action: CompanyActions.readScenarios(company),
            status: readScenariosStatus
          }
        ]}
        data={[
          ...scenariosPremium,
          ...companyScenarios,
          ...companySharedScenarios,
          ...scenariosPublic
        ]}
        showCondition={
          [
            ...scenariosPremium,
            ...companyScenarios,
            ...companySharedScenarios,
            ...scenariosPublic
          ].length > 0
        }
      >
        {() => (
          <>
            <MyGalleryScenario
              onScenarioSelected={onScenarioSelected}
              own={companyScenarios}
              shared={companySharedScenarios}
              activationCode={[]}
              others={[...scenariosPremium, ...scenariosPublic]}
            />
            <Drawer
              classes={{ paper: classes.drawer }}
              open={openScenarioDetail}
              anchor="right"
              variant="temporary"
              onClose={() => setOpenScenarioDetail(false)}
            >
              <AppBar
                position="absolute"
                className={classes.appbar}
                elevation={0}
              >
                <Toolbar variant="dense" disableGutters>
                  <MyIconButton
                    style={{ color: "white", background: "rgba(0,0,0,0.2)" }}
                    onMouseDown={() => setOpenScenarioDetail(false)}
                  >
                    <LeftArrowIcon />
                  </MyIconButton>
                </Toolbar>
              </AppBar>
              {scenarioSelected && (
                <ScenarioDetail
                  id={scenarioSelected.id}
                  actions={
                    <>
                      <>
                        {((currentUser.role &&
                          [
                            RoleNames.ADMIN,
                            RoleNames.GOD,
                            RoleNames.DEV
                          ].includes(currentUser.role.name)) ||
                          (currentUser.id === company.userId &&
                            scenarioSelected.companyId === company.id)) && (
                          <MyButton
                            loading={
                              updateScenarioStatus === FetchingStatus.PENDING
                            }
                            fullWidth
                            color="secondary"
                            variant="contained"
                            onClick={onScenarioEditClick}
                          >
                            {t("scenarios.edit-scenario")}
                          </MyButton>
                        )}
                      </>
                      <>
                        {((currentUser.role &&
                          [
                            RoleNames.ADMIN,
                            RoleNames.GOD,
                            RoleNames.DEV
                          ].includes(currentUser.role.name)) ||
                          (currentUser.id === company.userId &&
                            scenarioSelected.companyId === company.id)) && (
                          <MyButton
                            fullWidth
                            color="secondary"
                            variant="contained"
                            onClick={onScenarioDeleteClick}
                          >
                            {t("scenarios.delete-scenario")}
                          </MyButton>
                        )}
                      </>
                      <MyButton
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={onStartProjectClick}
                      >
                        {t("scenarios.start-project")} 
                      </MyButton>
                      <>
                        {((currentUser.role &&
                          [
                            RoleNames.ADMIN,
                            RoleNames.GOD,
                            RoleNames.DEV
                          ].includes(currentUser.role.name)) ||
                          (currentUser.id === company.userId &&
                            scenarioSelected.companyId === company.id)) && (
                          <MyButton
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={onScenarioTransferClick}
                          >
                            {t("scenarios.transfer")}
                          </MyButton>
                        )}
                      </>
                    </>
                  }
                />
              )}
            </Drawer>
            <Dialog
              fullScreen
              open={openScenarioEdit}
              onClose={() => setOpenScenarioEdit(false)}
            >
              <AppBar
                color="inherit"
                variant="elevation"
                position="sticky"
                elevation={1}
              >
                <Toolbar>
                  {scenarioSelected && scenarioSelected.label && (
                    <Typography variant="subtitle1">
                      {jsonTranslator(scenarioSelected.label, lang)}
                    </Typography>
                  )}
                  <Box display="flex" flexGrow={1} />
                  <Box flexGrow={1} />
                  <MyIconButton onClick={async () => {
                    setOpenScenarioEdit(false);
                    await dispatch(GtvActions.clear());
                  }}>
                    <CloseIcon />
                  </MyIconButton>
                </Toolbar>
              </AppBar>
              {duplicateScenario ? (
                <GtvCompany
                  onSubmit={onValidDuplicatedScenario}
                  company={company}
                />
              ) : (
                <GtvCompany onSubmit={onEditScenario} company={company} />
              )}
            </Dialog>

            <MyDialog
              open={openCreateProject}
              onClose={() => setOpenCreateProject(false)}
            >
              <MyForm methods={methods} onSubmit={onCreateProject}>
                <DialogTitle>{tCompany("projects.create")}</DialogTitle>
                <DialogContent>
                  <MyTextField label={tCommon("name")} name="name" />
                  <MyAutocompleteField
                    name="scenarioId"
                    label={tCommon("scenario")}
                    options={scenarioOptions.filter(scenario => scenario.value === scenarioSelected?.id)}
                    // disabled={true}
                    value={scenarioSelected?.id}
                    defaultValue={scenarioSelected?.id}
                  />
                </DialogContent>
                <DialogActions>
                  <MyButton
                    loading={createProjectStatus === FetchingStatus.PENDING}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    {tCommon("create")}
                  </MyButton>
                </DialogActions>
              </MyForm>
            </MyDialog>

            <TransferScenario
              open={openTransferButton}
              onClose={() => setOpenTransferButton(false)}
            />
          </>
        )}
      </DynamicElement>
    </Page>
  );
};
