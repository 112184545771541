import {
  ShotCategoryCreateDTO,
  ShotCategoryUpdateDTO,
  ShotCategory
} from "./entity";
import { Request } from "../../utils/request";

export const read = () =>
  Request({ withToken: true }).get<ShotCategory[]>("/shot-categories");

export const readOne = (id: string) =>
  Request({ withToken: true }).get<ShotCategory>(`/shot-categories/${id}`);

export const createOne = (shotCategory: ShotCategoryCreateDTO) =>
  Request({ withToken: true }).post<ShotCategory>(
    "/shot-categories",
    shotCategory
  );

export const updateOne = ({ id, ...dto }: ShotCategoryUpdateDTO) =>
  Request({ withToken: true }).patch<ShotCategory>(
    `/shot-categories/${id}`,
    dto
  );

export const deleteOne = (id: string) =>
  Request({ withToken: true }).delete(`/shot-categories/${id}`);
