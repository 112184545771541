import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { ConditionFailComponent } from "./ConditionFail";
import { ErrorComponent } from "./ErrorComponent";
import { LoaderComponent } from "./LoaderComponent";
import { NoDataComponent } from "./NoDataComponent";

interface Action {
  action: AnyAction;
  status: FetchingStatus;
  refetch?: boolean;
}

export interface DynamicElementProps<T> {
  // TODO any is any async thunk function
  actions: Action[] | Action | any;
  showCondition?: boolean;
  data?: T;
  errorComponent?: ReactElement;
  noDataComponent?: ReactElement;
  conditionFailComponent?: ReactElement;
  loaderComponent?: ReactElement;
  children: (props: NonNullable<T>) => ReactElement;
}

const MINIMUM_TIME_BEFORE_SHOW_LOADER = 1000;

const DynamicElementComponents = {
  loader: LoaderComponent,
  noData: NoDataComponent,
  error: ErrorComponent,
  conditionFail: ConditionFailComponent
};

// TODO OPTIMIZE CODE PLEASE
export function DynamicElement<T>({
  data,
  actions,
  noDataComponent,
  errorComponent,
  loaderComponent,
  conditionFailComponent,
  showCondition = true,
  children
}: DynamicElementProps<T>) {
  const [showLoader, setShowLoader] = useState(false);
  const [status, setStatus] = useState({
    success: false,
    failed: false,
    pending: false
  });
  const dispatch = useDispatch();
  const init = useRef<boolean>(false);

  const showLoaderFunc = debounce(
    () => setShowLoader(true),
    MINIMUM_TIME_BEFORE_SHOW_LOADER
  );

  useEffect(() => {
    if (!init.current) {
      if (Array.isArray(actions)) {
        actions
          .filter(
            a =>
              a.refetch || a.status !== FetchingStatus.SUCCESS || !showCondition
          )
          .forEach(a => {
            dispatch(a.action);
          });
      } else {
        if (actions.status !== FetchingStatus.SUCCESS || !showCondition)
          dispatch(actions.action);
      }
      showLoaderFunc();
      init.current = true;
    }
  }, [actions, dispatch, showCondition, showLoaderFunc]);

  useEffect(() => {
    const allStatus = {
      success: Array.isArray(actions)
        ? actions.every(a => a.status === FetchingStatus.SUCCESS)
        : actions.status === FetchingStatus.SUCCESS,
      failed: Array.isArray(actions)
        ? actions.some(a => a.status === FetchingStatus.FAILED)
        : actions.status === FetchingStatus.FAILED,
      pending: Array.isArray(actions)
        ? actions.some(a => a.status === FetchingStatus.PENDING)
        : actions.status === FetchingStatus.PENDING,
      null: Array.isArray(actions)
        ? actions.some(a => a.status === FetchingStatus.NULL)
        : actions.status === FetchingStatus.NULL
    };
    if (!isEqual(status, allStatus)) {
      setStatus(allStatus);
    }
  }, [actions, status]);

  if (status.pending) {
    if (showLoader) {
      return (
        loaderComponent || React.createElement(DynamicElementComponents.loader)
      );
    } else {
      return null;
    }
  }
  if (status.success) {
    if (showCondition && data) {
      return children(data as NonNullable<T>);
    } else {
      return (
        conditionFailComponent ||
        React.createElement(DynamicElementComponents.conditionFail)
      );
    }
  }
  if (status.failed) {
    if (
      (Array.isArray(actions) &&
        actions.some(action => action.status === FetchingStatus.FAILED)) ||
      (!Array.isArray(actions) && actions.status === FetchingStatus.FAILED)
    ) {
      return (
        errorComponent || React.createElement(DynamicElementComponents.error)
      );
    } else {
      return (
        noDataComponent || React.createElement(DynamicElementComponents.noData)
      );
    }
  }
  return null;
}

/* console.log("actions", isEqual(prev.actions, curr.actions)); */
/* console.log("data", isEqual(prev.data, curr.data)); */
/* console.log("showCondition", isEqual(prev.showCondition, curr.showCondition)); */
/* export function useDynamicElement<T>({ */
/*   data, */
/*   actions, */
/*   noDataComponent, */
/*   errorComponent, */
/*   loaderComponent, */
/*   conditionFailComponent, */
/*   showCondition */
/* }: Omit<DynamicElementProps<T>, "children">): [ */
/*   NonNullable<T> | null, */
/*   ReactElement | null */
/* ] { */
/*   const [showLoader, setShowLoader] = useState(false); */
/*   const [fetchTry, setFetchTry] = useState(0); */
/*   const dispatch = useDispatch(); */

/*   const showLoaderFunc = debounce( */
/*     () => setShowLoader(true), */
/*     MINIMUM_TIME_BEFORE_SHOW_LOADER */
/*   ); */

/*   useEffect(() => { */
/*     if (Array.isArray(actions)) { */
/*       if ( */
/*         !showCondition && */
/*         actions.every(action => action.status !== FetchingStatus.PENDING) && */
/*         fetchTry < MAX_FETCH_TRY */
/*       ) { */
/*         actions.forEach(a => { */
/*           if (a.status !== FetchingStatus.PENDING) { */
/*             dispatch(a.action); */
/*           } */
/*         }); */
/*         setFetchTry(fetchTry + 1); */
/*       } else { */
/*         showLoaderFunc(); */
/*       } */
/*     } else { */
/*       if ( */
/*         !showCondition && */
/*         actions.status !== FetchingStatus.PENDING && */
/*         fetchTry < MAX_FETCH_TRY */
/*       ) { */
/*         dispatch(actions.action); */
/*         setFetchTry(fetchTry + 1); */
/*       } else { */
/*         showLoaderFunc(); */
/*       } */
/*     } */
/*   }, [actions, dispatch, fetchTry, showCondition, showLoaderFunc]); */

/*   if ( */
/*     (Array.isArray(actions) && */
/*       actions.some(action => action.status === FetchingStatus.PENDING)) || */
/*     (!Array.isArray(actions) && actions.status === FetchingStatus.PENDING) */
/*   ) { */
/*     return [ */
/*       null, */
/*       showLoader */
/*         ? loaderComponent || */
/*           React.createElement(DynamicElementComponents.loader) */
/*         : null */
/*     ]; */
/*   } else if ( */
/*     (Array.isArray(actions) && */
/*       actions.some(action => action.status === FetchingStatus.FAILED)) || */
/*     (!Array.isArray(actions) && actions.status === FetchingStatus.FAILED) */
/*   ) { */
/*     return [ */
/*       null, */
/*       errorComponent || React.createElement(DynamicElementComponents.error) */
/*     ]; */
/*   } else if (!data) { */
/*     return [ */
/*       null, */
/*       noDataComponent || React.createElement(DynamicElementComponents.noData) */
/*     ]; */
/*   } else if (!showCondition) { */
/*     return [ */
/*       null, */
/*       conditionFailComponent || */
/*         React.createElement(DynamicElementComponents.conditionFail) */
/*     ]; */
/*   } else { */
/*     return [data as NonNullable<T>, null]; */
/*   } */
/* } */
