import { yupResolver } from "@hookform/resolvers";
import { Box } from "@material-ui/core";
import PasswordIcon from "@material-ui/icons/VpnKeyOutlined";
import queryString from "query-string";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MyButton } from "../../../components/common/MyButton";
import { MyForm } from "../../../components/common/MyForm2";
import { MyTextField } from "../../../components/common/MyForm2/MyTextField";
import { AuthenticationActions } from "../../../reducers/authentication/reducer";
import { getAuthenticationState } from "../../../reducers/authentication/selector";
import { RootState, useAppDispatch } from "../../../reducers/store";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import {
  ChangePasswordSchema,
  ChangePasswordValues
} from "./ChangePassword.schema";

export interface SignInProps {
  onTabAuthenticationChange: (
    e: React.ChangeEvent<any> | null,
    newValue: number
  ) => void;
}

export const ChangePassword: React.FC<SignInProps> = ({
  onTabAuthenticationChange
}) => {
  const { t } = useTranslation("authentication");
  const dispatch = useAppDispatch();
  const { changePasswordStatus } = useSelector(getAuthenticationState);
  const location = useSelector((state: RootState) => state.router.location);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(ChangePasswordSchema)
  });

  const onSubmit = (values: ChangePasswordValues) => {
    const token = queryString.parse(location.search).token as string;
    dispatch(
      AuthenticationActions.changePassword({ ...values, token })
    ).then(() => onTabAuthenticationChange(null, 0));
  };

  return (
    <>
      <MyForm onSubmit={onSubmit} methods={methods}>
        <MyTextField
          type="password"
          placeholder={t("change-password.form.password-placeholder")}
          startAdornment={<PasswordIcon />}
          label={t("change-password.form.password")}
          name="password"
        />
        <MyTextField
          showValidationIcon={!!methods.getValues().password}
          type="password"
          placeholder={t("change-password.form.password-confirm-placeholder")}
          startAdornment={<PasswordIcon />}
          label={t("change-password.form.password-confirm")}
          name="passwordConfirm"
        />
        <Box clone mt={1}>
          <MyButton
            capitalize
            loading={changePasswordStatus === FetchingStatus.PENDING}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            {t("change-password.form.change-password")}
          </MyButton>
        </Box>
      </MyForm>
    </>
  );
};
