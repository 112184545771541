import { EntityId } from "@reduxjs/toolkit";
import { schema } from "normalizr";
import { Asset } from "../../entities/asset";
import { AudioMix, AudioMixCreateDto } from "../../entities/audio-mix";
import { ScenarioCategory } from "../../entities/scenario-category";
import { Story, StoryDTO } from "../../entities/story";
import { Tag } from "../../entities/tag";
import { User } from "../../entities/user";
import { JsonTranslator } from "../../utils/function/jsonTranslator";
import { ActivationCode } from "../activation-code/entity";
import { Company } from "../companies/entity";
import { Image } from "../images/entity";
import { ScenarioUserShared } from "../scenarios-users-shared/entity";

export type ScenarioLockProperties = keyof Scenario;

export enum Format {
  "1/1" = "1/1",
  "9/16" = "9/16",
  "16/9" = "16/9",
  "16/10" = "16/10"
}

export interface Rule {
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface ScenarioCompanyShared {
  id: string;
  rule: Rule;
  scenarioId: string;
  scenario?: Scenario;
  companyId: string;
  company?: Company;
}

export interface Scenario extends Asset {
  id: string;
  legacyId: string;
  lockProperties: ScenarioLockProperties[];
  wantAudioMix: boolean;
  availableFormats: Format[];
  createdAt: Date;
  updatedAt: Date;
  enabled: boolean;
  posterId: string;
  _uncompleted: boolean;
  tagIds?: string[];
  tags?: Tag[];
  poster?: Image;
  audioMix?: AudioMix;
  stories: Story[];
  categories: ScenarioCategory[];
  sharedUsers?: ScenarioUserShared[];
  activationCodes?: ActivationCode[];
  sharedCompanies?: ScenarioCompanyShared[];
}

export interface ScenarioDTO {
  id?: string;
  lockProperties: ScenarioLockProperties[];
  label: JsonTranslator;
  description?: JsonTranslator;
  availableFormats: Format[];
  enabled: boolean;
  posterId: string;
  tagIds?: string[];
  wantAudioMix: boolean;
  audioMix?: AudioMixCreateDto;
  stories: StoryDTO[];
}

export interface ScenarioUpdateDTO {
  id: string;
}

export type TransferScenarioDTO = {
  id: string;
};

export interface ScenarioNormalized {
  sharedUsers?: Record<EntityId, User>;
  sharedCompanies?: Record<EntityId, User>;
  scenarios: Record<EntityId, Scenario>;
  activationCodes: Record<EntityId, ActivationCode>;
}
export const ScenarioEntity = new schema.Entity<Scenario>("scenarios", {
  sharedUsers: [new schema.Entity<ScenarioUserShared>("sharedUsers")],
  sharedCompanies: [
    new schema.Entity<ScenarioCompanyShared>("sharedCompanies")
  ],
  activationCodes: [new schema.Entity<ActivationCode[]>("activationCodes")]
});
