import { Yup } from "../../../utils/yup";
import { string } from "yup";

export interface ForgetPasswordValues {
  email: string;
}

export const ForgetPasswordSchema = Yup.object().shape({
  email: string()
    .email()
    .required()
});
