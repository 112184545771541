import { yupResolver } from "@hookform/resolvers";
import { Avatar, Box, BoxProps, useTheme } from "@material-ui/core";
import AudioIcon from "@material-ui/icons/Audiotrack";
import AudiotrackIcon from "@material-ui/icons/AudiotrackOutlined";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import React, { memo, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AssetsType } from "../../../entities/asset";
import { AppSelector } from "../../../reducers/app/selector";
import { GtvActions } from "../../../reducers/gtv/reducer";
import { GtvSelector } from "../../../reducers/gtv/selector";
import { AudioMixSchema } from "../../Gtv/schema";
import { MyButton } from "../MyButton";
import { MyForm } from "../MyForm2";
import { MyGalleryField } from "../MyForm2/MyGalleryField";
import { MySwitchField } from "../MyForm2/MySwitchField";
import { MyTypography } from "../MyTypography";
import { GtvContext } from "./Form";

interface AudioMixFormProps extends BoxProps {}

export const AudioMixForm: React.FC<AudioMixFormProps> = memo(props => {
  const dispatch = useDispatch();
  const { language: lang } = useSelector(AppSelector.getState);
  const audioMix = useSelector(GtvSelector.getAudioMixState);
  const {
    values: { wantAudioMix }
  } = useSelector(GtvSelector.getScenarioState);
  const { t } = useTranslation("gtv");
  const theme = useTheme();
  const {
    edit,
    audios,
    isSubmitted,
    methodsRef,
    onAudioMixSubmit
  } = useContext(GtvContext);

  const methods = useForm({
    defaultValues: audioMix.values,
    mode: "all",
    context: { lang, wantAudioMix },
    resolver: yupResolver(AudioMixSchema)
  });
  const {
    formState: { isValid, isDirty },
    trigger,
    reset
  } = methods;

  const handleAudioMix = () => {
    dispatch(GtvActions.updateScenario({ wantAudioMix: !wantAudioMix }));
  };

  useEffect(() => {
    if (methodsRef.current)
      set(methodsRef.current, "audioMix.methods", methods);
  }, [methods, methodsRef]);

  useEffect(() => {
    if (!isDirty && !audioMix.isValid && (edit || isSubmitted)) {
      trigger();
    }
  }, [edit, isSubmitted, audioMix.isValid, trigger, isDirty]);

  useEffect(() => {
    reset(Object.assign({}, audioMix.values));
  }, [reset, audioMix.values]);

  return (
    <Box {...props}>
      <MyForm methods={methods} onSubmit={onAudioMixSubmit}>
        <Box mb={{ xs: 2, md: 4 }}>
          <MyTypography
            color="primary"
            leftIcon={
              <Avatar
                style={{
                  background: theme.palette.primary.main,
                  color: "white",
                  height: 50,
                  width: 50
                }}
              >
                <AudiotrackIcon fontSize="large" />
              </Avatar>
            }
            variant="h4"
          >
            {t("audio-mix")}
          </MyTypography>
        </Box>
        {wantAudioMix && (
          <>
            <MySwitchField
              label={t("form.audio-mix-play-on-generics")}
              name="playOnGenerics"
            />
            <MyGalleryField
              label={t("form.select-audio")}
              buttonLabel={t("form.show-audio-gallery")}
              name="audioId"
              type={AssetsType.AUDIO}
              assets={audios}
            />
          </>
        )}
        <MyButton
          leftIcon={<AudioIcon />}
          variant="contained"
          size="large"
          color={!wantAudioMix ? "primary" : "default"}
          onClick={handleAudioMix}
        >
          {!wantAudioMix ? t("add-audio-mix") : t("remove-audio-mix")}
        </MyButton>
        <Box display="flex" justifyContent="flex-end">
          <MyButton
            type="submit"
            size="large"
            variant={isValid ? "contained" : "outlined"}
            color={
              (!audioMix.isValid && isValid) || isDirty
                ? "secondary"
                : "primary"
            }
          >
            {t("validate-step")}
          </MyButton>
        </Box>
      </MyForm>
    </Box>
  );
}, isEqual);
