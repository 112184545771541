import { yupResolver } from "@hookform/resolvers";
import { Avatar, Box, BoxProps, useTheme } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import React, { memo, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AssetsType } from "../../../entities/asset";
import { CategoryNames } from "../../../entities/category";
import { AppSelector } from "../../../reducers/app/selector";
import { GtvSelector } from "../../../reducers/gtv/selector";
import { Format } from "../../../reducers/scenarios/entity";
import { baseURL } from "../../../utils/request";
import { ScenarioSchema } from "../../Gtv/schema";
import { MyButton } from "../MyButton";
import { MyForm } from "../MyForm2";
import {
  MyAutocompleteField,
  SelectOption
} from "../MyForm2/MyAutocompleteField";
import { MyGalleryField } from "../MyForm2/MyGalleryField";
import { MyJsonTranslatorField } from "../MyForm2/MyJsonTranslatorField";
import { MySwitchField } from "../MyForm2/MySwitchField";
import { MyToggleButtonField } from "../MyForm2/MyToggleButtonField";
import { MyTypography } from "../MyTypography";
import { GtvContext } from "./Form";

export interface ScenarioFormProps extends BoxProps {}

export const ScenarioForm: React.FC<ScenarioFormProps> = memo(props => {
  const { t } = useTranslation("gtv");
  const { t: tCommon } = useTranslation("common");
  const { language: lang } = useSelector(AppSelector.getState);
  const [myScenario, setMyScenario] = useState<any>();
  const scenario = useSelector(GtvSelector.getScenarioState);
  const theme = useTheme();
  const { language } = useSelector(AppSelector.getState);
  const {
    edit,
    images,
    isSubmitted,
    methodsRef,
    onScenarioSubmit
  } = useContext(GtvContext);

  const [defaultLang, setDefaultLang] = useState(lang);

  const formatOptions: SelectOption[] = [
    { label: Format["16/9"], value: Format["16/9"] },
    { label: Format["1/1"], value: Format["1/1"] },
    { label: Format["9/16"], value: Format["9/16"] },
    { label: Format["16/10"], value: Format["16/10"] }
  ];

  const methods = useForm({
    defaultValues: scenario.values,
    mode: "all",
    context: { lang: defaultLang },
    resolver: yupResolver(ScenarioSchema)
  });

  const {
    register,
    getValues,
    formState: { isValid, isDirty },
    trigger,
    reset,
    clearErrors,
    setValue
  } = methods;

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (methodsRef.current) {
      set(methodsRef.current, "scenario.methods", methods);
    }
  }, [methods, methodsRef]);

  useEffect(() => {
    if (!isDirty && !scenario.isValid && (edit || isSubmitted)) {
      trigger();
    }
  }, [edit, isDirty, isSubmitted, scenario.isValid, trigger]);

  useEffect(() => {
    reset(Object.assign({}, scenario.values));
  }, [getValues, reset, scenario.values]); 

  useEffect(() => {
    setMyScenario(scenario.values);
  }, [scenario]);

  useEffect(() => {
    clearErrors();
  }, [lang]);

  return (
    <Box {...props}>
      <MyForm methods={methods} onSubmit={onScenarioSubmit}>
        <Box mb={{ xs: 2, md: 4 }}>
          <MyTypography
            color="primary"
            leftIcon={
              <Avatar
                style={{
                  background: theme.palette.primary.main,
                  color: "white",
                  height: 50,
                  width: 50
                }}
              >
                <SettingsIcon fontSize="large" />
              </Avatar>
            }
            variant="h4"
          > 
            {t("scenario-info")}
          </MyTypography>
        </Box>
        <input hidden name="wantAudioMix" ref={register} />
        <MyJsonTranslatorField
          name="label"
          textFieldProps={{ label: t("form.label") }}
          defaultValue={myScenario?.label || null}
          setValue={(name: string, value: any) => {
            setValue(name, value);
          }}
        />
        <MyJsonTranslatorField
          name="description"
          textFieldProps={{ label: t("form.description")}}
          defaultValue={myScenario?.description || null}
          multiline={true}
          setValue={(name: string, value: any) => {
            setValue(name, value);
          }}
        />
        <MyToggleButtonField
          size="small"
          multiple
          name="availableFormats"
          label={t("form.availableFormats")}
          options={formatOptions}
        />
        <MyAutocompleteField<true, false, false>
          multiple
          name="tagIds"
          label={t("form.tags")}
          filterSelectedOptions
          disableCloseOnSelect
          async={() => {
            return {
              url: baseURL + `/tags`,
              method: "get",
              withToken: true,
              label: `label['${language}']`,
              value: "id"
            };
          }}
        />
        <MyGalleryField
          label={t("form.select-poster")}
          buttonLabel={t("form.show-image-gallery")}
          name="posterId"
          category={CategoryNames.THEMES}
          type={AssetsType.IMAGE}
          assets={images}
        />
        <MySwitchField label={tCommon("enabled")} name="enabled" />
        <Box display="flex" justifyContent="flex-end">
          <MyButton
            color={
              (!scenario.isValid && isValid) || isDirty
                ? "secondary"
                : "primary"
            }
            onClick={goToTop}
            type="submit"
            size="large"
            variant={isValid ? "contained" : "outlined"}
          >
            {t("validate-step")}
          </MyButton>
        </Box>
      </MyForm>
    </Box>
  );
}, isEqual);
