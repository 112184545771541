import FormHelperText, {
  FormHelperTextProps
} from "@material-ui/core/FormHelperText";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppSelector } from "../../../../reducers/app/selector";
import { Languages } from "../../../../utils/function/jsonTranslator";

interface MyFormHelperTextProps extends FormHelperTextProps {
  errorField?: any;
}

export const MyFormHelperText: React.FC<MyFormHelperTextProps> = ({
  children,
  errorField,
  ...formHelperTextProps
}) => {

  const { language } = useSelector(AppSelector.getState);

  return (
    <FormHelperText
      error={!!errorField}
      style={{ marginLeft: 0, whiteSpace: "nowrap", marginTop: 4 }}
      {...formHelperTextProps}
    >
      {errorField ? ((errorField === 'Required' || errorField === 'Requis') ? (language === Languages.FR ? 'Requis' : 'Required') : errorField) : children}
    </FormHelperText>
  );
};
