import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import {
  AsyncActionMethods,
  createMySlice
} from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ScenarioActions } from "../scenarios/reducer";
import { Video } from "../videos/entity";
import { VideoActions } from "../videos/reducer";
import { ProjectActionsTypes } from "./action";
import * as Api from "./api";
import {
  Project,
  ProjectEntity,
  ProjectNormalized,
  ProjectUpdateDTO,
  StatusUpdateDTO
} from "./entity";

export interface ProjectState extends EntityState<Project> {
  selected?: Project;
  readOneStatus: FetchingStatus;
  readStatus: FetchingStatus;
  removeOneStatus: FetchingStatus;
  updateOneStatus: FetchingStatus;
  updateVideoStatus: FetchingStatus;
  uploadVideoStatus: FetchingStatus;
}

export const ProjectInitialState: ProjectState = {
  ids: [],
  entities: {},
  selected: undefined,
  readOneStatus: FetchingStatus.NULL,
  readStatus: FetchingStatus.NULL,
  removeOneStatus: FetchingStatus.NULL,
  updateOneStatus: FetchingStatus.NULL,
  updateVideoStatus: FetchingStatus.NULL,
  uploadVideoStatus: FetchingStatus.NULL
};

export const ProjectAdapter = createEntityAdapter<Project>();

const ProjectAdapterState = ProjectAdapter.getInitialState(ProjectInitialState);

const read = createMyAsyncThunk<Project[]>(
  ProjectActionsTypes.PROJECT_READ,
  Api.readProjects,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const {
        entities: { scenarios }
      } = normalize<Project, ProjectNormalized>(result, ProjectEntity);
      dispatch(ScenarioActions.upsertMany(scenarios));
      return result;
    }
  }
);

const readOne = createMyAsyncThunk<Project, string>(
  ProjectActionsTypes.PROJECT_READ_ONE,
  Api.readOneProjects,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const { entities } = normalize<Project, ProjectNormalized>(
        result,
        ProjectEntity
      );
      dispatch(VideoActions.upsertMany(entities.videos));
      dispatch(ScenarioActions.upsertMany(entities.scenarios));
      return result;
    }
  }
);

const removeOne = createMyAsyncThunk(
  ProjectActionsTypes.PROJECT_REMOVE_ONE,
  Api.removeOneProject,
  { onSuccess: ({ values }) => values, onSuccessMessage: "saga:delete-success" }
);

const updateOne = createMyAsyncThunk<
  Project,
  { id: string; project: ProjectUpdateDTO }
>(ProjectActionsTypes.PROJECT_UPDATE, Api.updateProject, {
  onSuccessMessage: "saga:update-success"
});

const updateVideoStatus = createMyAsyncThunk<
  Video,
  { id: string; videoId: string; status: StatusUpdateDTO }
>(ProjectActionsTypes.PROJECT_UPDATE_STATUS_VIDEO, Api.updateVideoStatus, {
  onSuccessMessage: "saga:update-success",
  onSuccess: ({ result, thunkApi: { dispatch } }) => {
    dispatch(VideoActions.upsertOne(result));
  }
});

const uploadVideo = createMyAsyncThunk(
  ProjectActionsTypes.PROJECT_UPLOAD_VIDEO,
  Api.uploadVideo,
  {
    onSuccessMessage: "saga:upload-sucess",
    onFailedMessage: "saga:upload-failed",
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(VideoActions.addOne(result));
    }
  }
);

const ProjectSlice = createMySlice({
  name: "projects",
  initialState: ProjectAdapterState,
  adapter: ProjectAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus"
    },
    {
      action: readOne,
      statusName: "readOneStatus",
      onSuccess: (state, action) => {
        ProjectAdapter.upsertOne(state, action);
      }
    },
    {
      action: removeOne,
      statusName: "removeOneStatus",
      method: AsyncActionMethods.DELETE
    },
    {
      action: updateOne,
      statusName: "updateOneStatus",
      method: AsyncActionMethods.UPDATE
    },
    {
      action: updateVideoStatus,
      statusName: "updateVideoStatus"
    },
    {
      action: uploadVideo,
      statusName: "uploadVideoStatus"
    }
  ],
  reducers: {}
});

export const ProjectReducer = ProjectSlice.reducer;

export const ProjectActions = {
  ...ProjectSlice.actions,
  async: {
    read,
    readOne,
    removeOne,
    updateOne,
    updateVideoStatus,
    uploadVideo
  }
};
