import React, { createRef } from "react";

export interface VideoItemProps
  extends React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  > {
  playOnHover?: boolean;
}
export const VideoItem: React.FC<VideoItemProps> = ({
  playOnHover,
  ...props
}) => {
  const videoRef = createRef<HTMLVideoElement>();
  return (
    <video
      ref={videoRef}
      onMouseEnter={() => playOnHover && videoRef.current?.play()}
      onMouseLeave={() => {
        if (videoRef.current && playOnHover) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      }}
      onEnded={e => e.currentTarget.load()}
      {...props}
    />
  );
};
