import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardContent,
  useTheme
} from "@material-ui/core";
import * as date from "date-fns";
import React from "react";
import { StoryFormState } from "../../../reducers/gtv/reducer";
import { getUniqChainColorByNumber } from "../../../utils/yup/random-color";
import { MyTypography } from "../MyTypography";
import { ShotTimeline } from "./ShotTimeline";

interface StoryTimelineProps extends BoxProps {
  story: StoryFormState;
  index: number;
}

export const StoryTimeline: React.FC<StoryTimelineProps> = ({
  story,
  index
}) => {
  const theme = useTheme();
  return (
    <Box height="100%" p={0.2}>
      <Box
        clone
        height="100%"
        color="white"
        borderRadius={10}
        bgcolor={getUniqChainColorByNumber(index)}
      >
        <Card elevation={2}>
          <CardActionArea
            style={{
              height: "100%",
              color: theme.palette.augmentColor({
                main: getUniqChainColorByNumber(index)
              }).dark
            }}
          >
            <Box clone height="100%" color="white">
              <CardContent
                style={{ padding: 0 }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Box
                  height="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <MyTypography variant="h3" color="inherit">
                    S{index + 1}
                  </MyTypography>
                  <MyTypography variant="subtitle1" color="textPrimary">
                    {date.format(
                      date.addSeconds(
                        new Date(0),
                        story.shots.reduce(
                          (prev, curr) =>
                            (prev += curr.values.recommendedDuration || 0),
                          0
                        )
                      ),
                      `m"ss''`
                    )}
                  </MyTypography>
                  <Box
                    mt={0.5}
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    flexWrap="nowrap"
                    overflow="auto"
                  >
                    {story.shots.map((shot, shotIndex) => (
                      <ShotTimeline
                        key={shotIndex}
                        storyIndex={index}
                        index={shotIndex}
                        shot={shot}
                      />
                    ))}
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </CardActionArea>
        </Card>
      </Box>
    </Box>
  );
};
