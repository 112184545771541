import {
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  TextField
} from "@material-ui/core";
import { green, grey, red } from "@material-ui/core/colors";
import UserIcon from "@material-ui/icons/AccountCircleOutlined";
import AdjustIcon from "@material-ui/icons/Adjust";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ExtraCompanyProps } from ".";
import { DynamicElement } from "../../components/common/DynamicElement";
import { Page } from "../../components/common/MyPage";
import { MyVideo } from "../../components/common/MyVideo";
import { AssetsStatus } from "../../entities/asset";
import { Project } from "../../reducers/projects/entity";
import { ProjectActions } from "../../reducers/projects/reducer";
import { ProjectSelector } from "../../reducers/projects/selector";
import { ScenarioSelector } from "../../reducers/scenarios/selector";
import { RootState, useAppDispatch } from "../../reducers/store";
import { VideoSelector } from "../../reducers/videos/selector";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CompanyActions } from "../../reducers/companies/reducer";
import { Video } from "../../reducers/videos/entity";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  }
}));
export interface CompanyProjectProps extends ExtraCompanyProps {}
export const CompanyProjectVideo = ({ company }: CompanyProjectProps) => {
  const { t: tCommon } = useTranslation("common");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id: projectId } = useParams<{ id: string }>();
  const { videoId } = useParams<{ videoId: string }>();
  const { projectStatus, project, videos, scenarioStatus } = useSelector(
    (state: RootState) => ({
      projectStatus: state.projects.readOneStatus,
      project: ProjectSelector.getProjectById(projectId)(state),
      videos: VideoSelector.selectAll(state),
      scenarios: ScenarioSelector.getCompanyScenarios(company)(state),
      scenarioStatus: state.companies.readScenariosStatus
    })
  );

  const videoList = useMemo(
    () => videos.filter(v => v.projectId && v.id === videoId),
    [videoId, videos]
  );
  const [filterValue, setFilterValue] = useState<Video[]>(videoList);
  console.log(
    "🚀 ~ file: Video.tsx:65 ~ CompanyProjectVideo ~ setFilterValue:",
    setFilterValue
  );
  const [statusfilter, setStatusFilter] = useState<string[]>([
    "",
    "VALIDATED",
    "REFUSED"
  ]);
  console.log(
    "🚀 ~ file: Video.tsx:71 ~ CompanyProjectVideo ~ setStatusFilter:",
    setStatusFilter
  );

  const onVideoStatusChange = (videoId: string, status: AssetsStatus) => {
    dispatch(
      ProjectActions.async.updateVideoStatus({
        id: projectId,
        videoId,
        status: { status }
      })
    );
  };

  const statusOptions = useMemo(
    () => [
      {
        value: "",
        label: "NO STATUS",
        style: {
          backgroundColor: grey[400],
          color: "white"
        }
      },
      {
        value: AssetsStatus.VALIDATED,
        label: AssetsStatus.VALIDATED,
        style: {
          backgroundColor: green[300],
          color: "white"
        }
      },
      {
        value: AssetsStatus.REFUSED,
        label: AssetsStatus.REFUSED,
        style: {
          backgroundColor: red[300],
          color: "white"
        }
      }
    ],
    []
  );

  return (
    <Page>
      <DynamicElement<Project>
        data={project}
        actions={[
          {
            action: ProjectActions.async.readOne(projectId),
            status: projectStatus,
            refetch: true
          },
          {
            action: CompanyActions.readScenarios(company),
            status: scenarioStatus
          }
        ]}
        showCondition={[scenarioStatus, projectStatus].every(
          s => s === FetchingStatus.SUCCESS
        )}
      >
        {() => (
          <Page>
            {/* <Box mb={{ xs: 1, sm: 2 }} display="flex" alignItems="center">
                <>
                  <MyIconButton
                    onClick={() => projectNameInputRef.current?.focus()}
                  >
                    <EditOutlinedIcon fontSize="large" color="action" />
                  </MyIconButton>
                  <MyIconButton
                    menuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                      },
                      transformOrigin: {
                        horizontal: "right",
                        vertical: "top"
                      }
                    }}
                    menus={({ onClose }) => (
                      <MenuItem
                        dense={true}
                        onClick={() => {
                          onClose();
                          onDeleteProject();
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 36 }}>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <Typography color="error">{tCommon("delete")}</Typography>
                      </MenuItem>
                    )}
                  >
                    <MoreVertIcon fontSize="large" color="action" />
                  </MyIconButton>
                </>
              </Box> */}
            {/* <Box mb={{ xs: 1, sm: 2 }}>
                <ToggleButtonGroup onChange={handleStatus}>
                  {statusOptions.map(s => (
                    <ToggleButton key={s.value} value={s.value} style={s.style}>
                      {s.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box> */}
            <Typography variant="subtitle1" paragraph>
              {tCommon("video")} {videoList[0].name}
            </Typography>
            <Grid container spacing={2}>
              {[
                ...(filterValue.length > 0
                  ? filterValue
                  : videoList || []
                ).filter(v =>
                  statusfilter.length > 0 && v.status
                    ? statusfilter.includes(v.status)
                    : v.id
                )
              ]
                .sort((a, b) =>
                  new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1
                )
                .map(v => {
                  return (
                    <Grid key={v.id} item xs={12} sm={6} lg={4}>
                      <MyVideo
                        disableLabel
                        video={v}
                        height={280}
                        width="100%"
                      />
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <UserIcon />
                          </ListItemIcon>
                          <ListItemText primary={v.user?.email} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AdjustIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <FormControl variant="outlined" size="small">
                                <Select
                                  className={classes.root}
                                  displayEmpty
                                  value={v.status || ""}
                                  onChange={e =>
                                    onVideoStatusChange(
                                      v.id,
                                      e.target.value as AssetsStatus
                                    )
                                  }
                                  style={{
                                    ...(statusOptions.find(
                                      s => s.value === v.status
                                    )?.style || statusOptions[0].style)
                                  }}
                                >
                                  {statusOptions.map(s => (
                                    <MenuItem
                                      dense
                                      value={s.value}
                                      style={s.style}
                                      key={s.value}
                                    >
                                      {s.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  );
                })}
            </Grid>
          </Page>
        )}
      </DynamicElement>
      <Typography variant="subtitle1">Comments</Typography>
      <TextField
        id="filled-multiline-static"
        multiline
        rows={4}
        placeholder="your comment here"
      />
    </Page>
  );
};
