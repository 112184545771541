import { yupResolver } from "@hookform/resolvers";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { push } from "connected-react-router";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { object, string } from "yup";
import { ExtraCompanyProps } from ".";
import { DynamicElement } from "../../components/common/DynamicElement";
import { MyButton } from "../../components/common/MyButton";
import { MyDialog } from "../../components/common/MyDialog";
import { MyForm } from "../../components/common/MyForm2";
import { MyAutocompleteField } from "../../components/common/MyForm2/MyAutocompleteField";
import { MyTextField } from "../../components/common/MyForm2/MyTextField";
import { MyIconButton } from "../../components/common/MyIconButton";
import { Page } from "../../components/common/MyPage";
import { MyTable } from "../../components/common/MyTable";
import { AppSelector } from "../../reducers/app/selector";
import { CompanyActions } from "../../reducers/companies/reducer";
import {
  Project,
  ProjectCompanyCreateDTO
} from "../../reducers/projects/entity";
import { ProjectSelector } from "../../reducers/projects/selector";
import { ScenarioSelector } from "../../reducers/scenarios/selector";
import { RootState, useAppDispatch } from "../../reducers/store";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

interface CompanyProjectListProps extends ExtraCompanyProps {}
export const CompanyProjectList = ({ company }: CompanyProjectListProps) => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("company");
  const { language: lang } = useSelector(AppSelector.getState);
  const dispatch = useAppDispatch();
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const methods = useForm({
    defaultValues: { scenarioId: "", name: "" },
    resolver: yupResolver(
      object({
        name: string()
          .required()
          .min(1),

        scenarioId: string().required()
      })
    )
  });
  const {
    projectStatus,
    projects,
    scenarios,
    scenarioStatus,
    createProjectStatus
  } = useSelector((state: RootState) => ({
    projectStatus: state.companies.readProjectsStatus,
    projects: ProjectSelector.selectAll(state),
    scenarios: ScenarioSelector.selectAll(state),
    scenarioStatus: state.companies.readScenariosStatus,
    createProjectStatus: state.companies.createProjectStatus
  }));
  const scenarioCompany = useMemo(
    () => scenarios.filter(s => s.companyId === company.id),
    [company.id, scenarios]
  );
  const scenarioOptions = useMemo(
    () =>
      scenarioCompany.map(s => ({
        value: s.id,
        label: jsonTranslator(s.label, lang)
      })),
    [lang, scenarioCompany]
  );
  const projectsData = useMemo(() => {
    return projects.map(p => ({
      ...p,
      scenario: scenarios.find(s => s.id === p.scenarioId)
    }));
  }, [projects, scenarios]);

  const onCreateProject = async (project: ProjectCompanyCreateDTO) => {
    const { payload } = await dispatch<any>(
      CompanyActions.createCompanyProject({ company, project })
    );
    if (payload?.id)
      dispatch(push(`/company/${company.id}/projects/${payload.id}`));
  };

  return (
    <Page>
      <DynamicElement<Project[]>
        data={projectsData}
        actions={[
          {
            action: CompanyActions.readCompanyProjects(company),
            status: projectStatus,
            refetch: true
          },
          {
            action: CompanyActions.readScenarios(company),
            status: scenarioStatus
          }
        ]}
      >
        {data => (
          <>
            <MyDialog
              open={openCreateProject}
              onClose={() => setOpenCreateProject(false)}
            >
              <MyForm methods={methods} onSubmit={onCreateProject}>
                <DialogTitle>{t("projects.create")}</DialogTitle>
                <DialogContent>
                  <MyTextField label={tCommon("name")} name="name" />
                  <MyAutocompleteField
                    name="scenarioId"
                    label={tCommon("scenario")}
                    options={scenarioOptions}
                  />
                </DialogContent>
                <DialogActions>
                  <MyButton
                    loading={createProjectStatus === FetchingStatus.PENDING}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    {tCommon("create")}
                  </MyButton>
                </DialogActions>
              </MyForm>
            </MyDialog>
            <MyButton
              color="primary"
              variant="contained"
              onClick={() => setOpenCreateProject(true)}
            >
              {t("projects.create")}
            </MyButton>
            <MyTable
              data={data.map(d => ({
                id: d.id,
                name: d.name,
                createdAt: new Date(d.createdAt).toLocaleDateString(lang, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit"
                }),
                createdBy: d.user?.email,
                scenarioName: d.scenario
                  ? jsonTranslator(d.scenario.label, lang)
                  : ""
              }))}
              columns={[
                {
                  hidden: true,
                  field: "id"
                },
                {
                  title: tCommon("name"),
                  field: "name"
                },
                {
                  title: tCommon("created-at"),
                  field: "createdAt"
                },
                {
                  title: tCommon("created-by"),
                  field: "createdBy"
                },
                {
                  title: "Scenario",
                  field: "scenarioName"
                },
                {
                  render: project => (
                    <MyIconButton
                      to={`/company/${company.id}/projects/${project.id}`}
                    >
                      <VisibilityIcon />
                    </MyIconButton>
                  )
                }
              ]}
            />
          </>
        )}
      </DynamicElement>
    </Page>
  );
};
