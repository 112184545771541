import { createSelector } from "reselect";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { AppSelector } from "../app/selector";
import { RootState } from "../store";
import { VideoEffectAdapter } from "./reducer";

const AdapterSelector = VideoEffectAdapter.getSelectors(
  (state: RootState) => state.videoEffects
);

const getState = createSelector(
  (state: RootState) => state.videoEffects,
  state => state
);

const getVideoEffectOptions = createSelector(
  AdapterSelector.selectAll,
  AppSelector.getState,
  (videoEffects, { language }) =>
    videoEffects.map(ve => ({
      value: ve.id,
      label: jsonTranslator(ve.label, language),
      name: ve.name
    }))
);

export const VideoEffectSelector = {
  ...AdapterSelector,
  getVideoEffectOptions,
  getState
};
