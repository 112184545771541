import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "../../locales/en";
import fr from "../../locales/fr";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { "en-US": en, "fr-FR": fr },
    fallbackLng: "en-US",
    debug: process.env.NODE_ENV === "development",
    react: {
      useSuspense: false
    }
  });

export default i18n;
