import { yupResolver } from "@hookform/resolvers";
import React, { useEffect } from "react";
import { Theme, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { MyButton } from "../../../components/common/MyButton";
import { MyForm } from "../../../components/common/MyForm2";
import { MyTextField } from "../../../components/common/MyForm2/MyTextField";
import { User } from "../../../entities/user";
import { AuthenticationActions } from "../../../reducers/authentication/reducer";
import { getAuthenticationState } from "../../../reducers/authentication/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { SignUpSchema, SignUpValues } from "./SignUp.schema";
import { useTranslation } from "react-i18next";
import { AppActions } from "../../../reducers/app/reducer";
import { AppSelector } from "../../../reducers/app/selector";
import { Languages } from "../../../utils/function/jsonTranslator";

export interface SignUpProps {
  onTabAuthenticationChange: (
    e: React.ChangeEvent<{}> | null,
    newValue: number
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typographySpacer: { 
      marginTop: theme.spacing(4),
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  })
);

export const SignUp: React.FC<SignUpProps> = ({
  onTabAuthenticationChange
}) => {
  const dispatch = useDispatch();
  const { signUpStatus } = useSelector(getAuthenticationState);
  const { t } = useTranslation("authentication");
  const classes = useStyles();
  const { language: lang } = useSelector(AppSelector.getState);

  const methods = useForm({ 
    mode: "all",
    context: { lang },
    resolver: yupResolver(SignUpSchema) 
  });

  useEffect(() => {
    const langStorage = localStorage.getItem("i18nextLng");
    dispatch(AppActions.setLanguage(langStorage as Languages || lang));
  }, []);
  
  const onSubmit = (values: SignUpValues) => {
    const user: DeepPartial<User> = {
      username: values.email,
      email: values.email,
      password: values.password,
      profile: {
        companyName: values.companyName,
        phoneNumber: values.phoneNumber,
        firstname: values.firstname,
        lastname: values.lastname
      }
    };
    dispatch(AuthenticationActions.signUp(user));
  };

  const onSignInClick = (e: React.MouseEvent<HTMLElement>) =>
  onTabAuthenticationChange(e, 0);

  return (
    <MyForm methods={methods} onSubmit={onSubmit}>
      <MyTextField label={t("sign-up.form.lastname")} name="lastname" />
      <MyTextField label={t("sign-up.form.firstname")} name="firstname" />
      <MyTextField label={t("sign-up.form.company-name")} name="companyName" />
      <MyTextField label={t("sign-up.form.phone-number")} name="phoneNumber" />
      <MyTextField label={t("sign-up.form.email")} name="email" />
      <MyTextField
        label={t("sign-up.form.password")}
        name="password"
        type="password"
      />
      <MyButton
        capitalize
        loading={signUpStatus === FetchingStatus.PENDING}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        {t("sign-up.signup-submit")}
      </MyButton>

      <Typography
        align="center"
        paragraph
        color="textSecondary"
        className={classes.typographySpacer}
        onClick={onSignInClick}
      >
        {t("sign-in.already-account")}
      </Typography>
    </MyForm>
  );
};
