import { JsonTranslator } from "../utils/function/jsonTranslator";

export enum CategoryNames {
  GENERICS = "GENERICS",
  INTERTITLE = "INTERTITLES",
  WATERMARKS = "WATERMARKS",
  PLANS = "PLANS",
  THEMES = "THEMES"
}

export class Category {
  id!: string;
  name!: CategoryNames;
  description?: string;
  label!: JsonTranslator;
  public!: boolean;
}
