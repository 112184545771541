import { Box, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import get from "lodash/get";
import React, { ReactElement, useEffect, useState, FocusEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AssetsType } from "../../../entities/asset";
import { MyGalleryFieldDialog } from "../gallery/MyGalleryFieldDialog";
import { MyAudio } from "../MyAudio";
import { MyAudioList } from "../MyAudioList";
import { MyButton } from "../MyButton";
import { MyIconButton } from "../MyIconButton";
import { MyImage } from "../MyImage";
import { MyImageList } from "../MyImageList";
import {
  MyFormControl,
  MyFormControlProps,
  FormProps
} from "./common/MyFormControl";
import { MyVideoList } from "../MyVideoList";
import { MyVideo } from "../MyVideo";
import { CategoryNames } from "../../../entities/category";

export type AssetOption = any & { id: string; url: string };
export type IsArrayAssetOptions<T> = T extends true
  ? AssetOption[]
  : AssetOption;

export interface MyMultipleGalleryFieldProps extends FormProps {
  type: AssetsType;
  category?: CategoryNames;
  name: string;
  defaultValue?: unknown;
  required?: boolean;
  label: string;
  buttonLabel?: string;
  assets: AssetOption[];
  preview?: (
    items: AssetOption[],
    remove: (id: string) => void
  ) => ReactElement;
  multiple?: false;
}

export interface MySingleGalleryFieldProps extends FormProps {
  type: AssetsType;
  category?: CategoryNames;
  defaultValue?: unknown;
  name: string;
  required?: boolean;
  label: string;
  buttonLabel?: string;
  assets: AssetOption[];
  preview?: (
    items: AssetOption[],
    remove: (id: string) => void,
    onBlur: (e: FocusEvent<any>) => void
  ) => ReactElement;
  multiple: true;
}
export const MyGalleryField: React.FC<
  MyMultipleGalleryFieldProps | MySingleGalleryFieldProps
> = ({
  name,
  defaultValue,
  type,
  assets,
  required,
  multiple,
  label,
  buttonLabel,
  helperText,
  formControlProps,
  formLabelProps,
  formHelperTextProps,
  category,
  preview
}) => {
  const { errors, control, setValue, getValues } = useFormContext();
  const [posterModal, setPosterModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<any[] | any>();
  const theme = useTheme();

  const errorField = get(errors, name)?.message;

  const myFormControlProps: MyFormControlProps = {
    errorField,
    label,
    helperText,
    formLabelProps: { ...formLabelProps, required },
    formHelperTextProps
  };

  const removeItem = async (id: string) => {
    const newItemSelected =
      multiple && Array.isArray(itemSelected)
        ? [...itemSelected.filter((i: AssetOption) => i.id !== id)]
        : undefined;
    setValue(name, newItemSelected, {
      shouldDirty: true,
      shouldValidate: true
    });
    setItemSelected(newItemSelected);
  };

  const onSelectChange = (items: AssetOption | AssetOption, onChange: any) => {
    setItemSelected(items);
    onChange(
      multiple && Array.isArray(items) ? items.map(i => i.id) : items.id
    );
  };

  useEffect(() => {
    if (!itemSelected && assets.length && !!get(getValues(), name)) {
      setItemSelected(
        multiple
          ? assets.filter(a =>
              ((get(getValues(), name) || []) as AssetOption[]).includes(a.id)
            )
          : assets.find(a => a.id === get(getValues(), name))
      );
    }
    if (!get(getValues(), name)) {
      setItemSelected(undefined);
    }
  }, [assets, getValues, itemSelected, multiple, name]);

  const onCancel = (onChange: any) => {
    setItemSelected(null);
    onChange(multiple);
  }

  const getList = (type: AssetsType, onChange: (...event: any[]) => void) => {
    switch (type) {
      case AssetsType.IMAGE:
        return {
          component: (
            <MyImageList
              category={category}
              title={label}
              images={assets}
              multiple={multiple}
              imagesSelected={itemSelected}
              onImagesSelected={items => onSelectChange(items, onChange)}
            />
          ),
          preview: (
            items: IsArrayAssetOptions<typeof multiple> | undefined,
            remove: (id: string) => void,
            onBlur: (e: FocusEvent<any>) => void
          ) => {
            if (!items) {
              return null;
            }
            return (
              <>
                {(multiple && Array.isArray(items) ? items : [items]).map(
                  (item: AssetOption) => (
                    <Box
                      mt={2}
                      key={item.id}
                      position="relative"
                      width={240}
                      onBlur={onBlur}
                    >
                      <MyImage height="100%" image={item} />
                      <MyIconButton
                        onClick={e => {
                          onBlur(e);
                          remove(item.id);
                        }}
                        size="small"
                        color="primary"
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          background: theme.palette.primary.main,
                          color: "white"
                        }}
                      >
                        <CloseIcon color="inherit" fontSize="small" />
                      </MyIconButton>
                    </Box>
                  )
                )}
              </>
            );
          }
        };
      case AssetsType.AUDIO:
        return {
          component: (
            <MyAudioList
              title={label}
              audios={assets}
              multiple={multiple}
              audiosSelected={itemSelected}
              onAudiosSelected={items => onSelectChange(items, onChange)}
            />
          ),
          preview: (
            items: IsArrayAssetOptions<typeof multiple>,
            remove: (id: string) => void,
            onBlur: (e: FocusEvent<any>) => void
          ) => {
            if (!items) {
              return null;
            }
            return (
              <>
                {(multiple && Array.isArray(items) ? items : [items]).map(
                  (item: AssetOption) => (
                    <Box
                      mt={2}
                      key={item.id}
                      position="relative"
                      width={240}
                      onBlur={onBlur}
                    >
                      <MyAudio audio={item} />
                      <MyIconButton
                        onClick={e => {
                          onBlur(e);
                          remove(item.id);
                        }}
                        size="small"
                        color="primary"
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          background: theme.palette.primary.main,
                          color: "white"
                        }}
                      >
                        <CloseIcon color="inherit" fontSize="small" />
                      </MyIconButton>
                    </Box>
                  )
                )}
              </>
            );
          }
        };
      case AssetsType.VIDEO:
        return {
          component: (
            <MyVideoList
              category={category}
              title={label}
              videos={assets}
              multiple={multiple}
              videosSelected={itemSelected}
              onVideosSelected={items => onSelectChange(items, onChange)}
            />
          ),
          preview: (
            items: IsArrayAssetOptions<typeof multiple>,
            remove: (id: string) => void,
            onBlur: (e: FocusEvent<any>) => void
          ) => {
            if (!items) {
              return null;
            }
            return (
              <>
                {(multiple && Array.isArray(items) ? items : [items]).map(
                  (item: AssetOption) => (
                    <Box
                      mt={2}
                      key={item.id}
                      position="relative"
                      width={240}
                      onBlur={onBlur}
                    >
                      <MyVideo video={item} height={140} width="100%" />
                      <MyIconButton
                        onClick={e => {
                          onBlur(e);
                          remove(item.id);
                        }}
                        size="small"
                        color="primary"
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          background: theme.palette.primary.main,
                          color: "white"
                        }}
                      >
                        <CloseIcon color="inherit" fontSize="small" />
                      </MyIconButton>
                    </Box>
                  )
                )}
              </>
            );
          }
        };
      default:
        throw new Error(
          `My gallery field need a known type. Type ${type} is unknown`
        );
    }
  };

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      render={({ onBlur, onChange, value }) => {
        const components = getList(type, onChange);
        return (
          <MyFormControl
            {...formControlProps}
            {...myFormControlProps}
            hiddenLabel
            onBlur={onBlur}
          >
            <MyGalleryFieldDialog
              title={label}
              open={posterModal}
              value={value}
              onClose={() => {
                setPosterModal(false);
                onBlur();
              }}
              onCancel={() => {
                onCancel(onChange);
              }}
            >
              {components.component}
            </MyGalleryFieldDialog>
            {!!value && preview
              ? preview(itemSelected, removeItem, onBlur)
              : components.preview(itemSelected, removeItem, onBlur)}
            <Box my={1}>
              <MyButton
                variant="contained"
                color={errorField ? "primary" : "default"}
                onClick={() => setPosterModal(true)}
                onBlur={onBlur}
              >
                {buttonLabel || label}
              </MyButton>
            </Box>
          </MyFormControl>
        );
      }}
    />
  );
};
