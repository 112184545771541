import { yupResolver } from "@hookform/resolvers";
import { Avatar, Box, Container, Grid, Theme } from "@material-ui/core";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";
import {
  default as NameIcon,
  default as ProfilIcon
} from "@material-ui/icons/AssignmentIndOutlined";
import CompanyIcon from "@material-ui/icons/BusinessOutlined";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import PasswordIcon from "@material-ui/icons/VpnKeyOutlined";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DeepPartial } from "redux";
import DefaultAvatar from "../../../assets/avatars/default_avatar.png";
import { MyButton } from "../../../components/common/MyButton";
import { MyForm } from "../../../components/common/MyForm2";
import { MyTextField } from "../../../components/common/MyForm2/MyTextField";
import { Page } from "../../../components/common/MyPage";
import { User } from "../../../entities/user";
import { AppBarActions } from "../../../reducers/appbar/reducer";
import { AuthenticationActions } from "../../../reducers/authentication/reducer";
import {
  getAuthenticationState,
  getCurrentUser,
  getUserDataForProfilForm
} from "../../../reducers/authentication/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { ProfilSchema, ProfilValues } from "./schema";

interface ProfilProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      // border: `6px solid ${theme.palette.common.white}`,
      width: 120,
      height: 120,
      marginBottom: theme.spacing(2)
    }
  })
);

export const Profil: React.FC<ProfilProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("profile");
  const user = useSelector(getCurrentUser);
  const { updateAccountStatus } = useSelector(getAuthenticationState);

  const onUpdateAccount = (values: ProfilValues) => {
    const newUserData: DeepPartial<User> = {
      username: values.username,
      email: values.email,
      password: values.password,
      profile: {
        firstname: values.firstname,
        lastname: values.lastname,
        companyName: values.companyName,
        newsletter: values.newsletter
      }
    };
    dispatch(AuthenticationActions.updateAccount(newUserData));
  };

  const initialValues = useSelector(getUserDataForProfilForm);
  const methods = useForm({
    resolver: yupResolver(ProfilSchema),
    defaultValues: {
      ...initialValues,
      companyName: initialValues.companyName || "",
    }
  });

  useEffect(() => {
    dispatch(AppBarActions.setState({ title: t("title") }));
    return () => {
      dispatch(AppBarActions.setState());
    };
  });

  return (
    <Page>
      <Container maxWidth="sm">
        <Box p={{ xs: 1, sm: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box clone mx="auto">
                <Avatar
                  alt="avatar"
                  src={
                    (user.profile && user.profile.avatarUrl) || DefaultAvatar
                  }
                  className={classes.avatar}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <MyForm methods={methods} onSubmit={onUpdateAccount}>
                <MyTextField
                  startAdornment={<AccountIcon />}
                  label={t("form.username")}
                  name="username"
                />
                <MyTextField
                  startAdornment={<EmailIcon />}
                  label={t("form.email")}
                  name="email"
                />
                <MyTextField
                  startAdornment={<ProfilIcon />}
                  label={t("form.firstname")}
                  name="firstname"
                />
                <MyTextField
                  startAdornment={<NameIcon />}
                  label={t("form.lastname")}
                  name="lastname"
                />
                <MyTextField
                  startAdornment={<CompanyIcon />}
                  label={t("form.company-name")}
                  name="companyName"
                />
                <Box mt={2}>
                  <MyTextField
                    type="password"
                    placeholder={t("form.password-placeholder")}
                    startAdornment={<PasswordIcon />}
                    label={t("form.password")}
                    name="password"
                  />
                  {methods.getValues().password && (
                    <MyTextField
                      showValidationIcon
                      type="password"
                      placeholder={t("form.password-confirm-placeholder")}
                      startAdornment={<PasswordIcon />}
                      label={t("form.password-confirm")}
                      name="passwordConfirm"
                    />
                  )}
                </Box>
                <MyButton
                  capitalize
                  loading={updateAccountStatus === FetchingStatus.PENDING}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  {t("form.update-account-submit")}
                </MyButton>
              </MyForm>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};
