import {
  Box,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import InfiniteIcon from "@material-ui/icons/AllInclusiveOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
//import BatteryCharging20Icon from "@material-ui/icons/BatteryCharging20";
//import BatteryCharging60Icon from "@material-ui/icons/BatteryCharging60";
import BatteryCharging100Icon from "@material-ui/icons/BatteryChargingFull";
import CheckIcon from "@material-ui/icons/Check";
import StarIcon from "@material-ui/icons/StarOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyButton } from "../../../components/common/MyButton";
import { Page } from "../../../components/common/MyPage";
import { AppSelector } from "../../../reducers/app/selector";
import { AppBarActions } from "../../../reducers/appbar/reducer";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { SubscriptionNames } from "../../../utils/constant";
import { jsonTranslator } from "../../../utils/function/jsonTranslator";

export interface SubscriptionPageProps {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardActive: {
      boxShadow: `0px 0px 4px 2px ${theme.palette.success.main}`
      // transform: "translateY(-20px)"
    },
    cardExpired: {
      boxShadow: `0px 0px 4px 2px ${theme.palette.warning.dark}`
      // transform: "translateY(-20px)"
    },
    card: {
      maxWidth: 300,
      flexGrow: 1,
      margin: theme.spacing(),
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        boxShadow: "2px 2px 8px 4px grey",
        transform: "translateY(-10px)"
      }
    },
    cardContent: {
      padding: 0,
      height: 700,
      [theme.breakpoints.down("sm")]: {
        height: "auto"
      }
    },
    cardContentTitle: {
      fontWeight: "bold"
    },
    cardActions: {},
    cardActionsButtonActive: {
      height: 80,
      background: theme.palette.success.main
    },
    cardActionsButtonExpired: {
      height: 80,
      background: theme.palette.warning.dark
    },
    cardActionsButton: {
      height: 80,
      color: "white",
      background: theme.palette.primary.main
    },
    header: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      textTransform: "uppercase"
    },
    headerActive: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      textTransform: "uppercase"
    },
    headerExpired: {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.common.white,
      textTransform: "uppercase"
    },
    listIcon: {
      minWidth: 30
    },
    arrowIcon: {
      minWidth: 30,
      fontSize: 13
    },
    listText: {
      color: theme.palette.text.secondary
    },
    icon: { transform: "rotate(90deg)", width: 40, height: 40 }
  })
);

interface SubscriptionOffer {
  name: SubscriptionNames;
  icon: React.ReactNode;
  title: string;
  count?: number;
  content: {
    icon?: React.ReactElement;
    title: string;
  }[];
  custom?: {
    title: string;
    count?: number;
  }[];
  option?: boolean;
}

export const SubscriptionPage: React.FC<SubscriptionPageProps> = () => {
  const { t } = useTranslation("backoffice");
  const classes = useStyles();
  const {
    userSubscription,
    userSubscriptionOptions,
    hasValidSubscription
  } = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const { language: lang } = useSelector(AppSelector.getState);

  useEffect(() => {
    dispatch(AppBarActions.setState({ title: t("subscription.title") }));
    return () => {
      dispatch(AppBarActions.setState());
    };
  });

  const subscriptions: SubscriptionOffer[] = [
    // {
    //   name: SubscriptionNames.STARTER,
    //   icon: <BatteryCharging20Icon className={classes.icon} />,
    //   title: t("subscription.offer1"),
    //   content: [
    //     {
    //       icon: <InfiniteIcon color="primary" />,
    //       title: t("subscription.unlimited-video")
    //     },
    //     {
    //       icon: (
    //         <Typography align="center" variant="h4" color="primary">
    //           6
    //         </Typography>
    //       ),
    //       title: t("subscription.offer1-template")
    //     },
    //     {
    //       title: t("subscription.makidoo-watermark")
    //     },
    //     {
    //       title: t("subscription.export-sd")
    //     }
    //   ]
    // },
    // {
    //   name: SubscriptionNames.BUSINESS,
    //   icon: <BatteryCharging60Icon className={classes.icon} />,
    //   title: t("subscription.offer2"),
    //   count: 750,
    //   content: [
    //     {
    //       icon: <InfiniteIcon color="primary" />,
    //       title: t("subscription.unlimited-video")
    //     },
    //     {
    //       icon: <InfiniteIcon color="primary" />,
    //       title: t("subscription.support")
    //     },
    //     {
    //       icon: <InfiniteIcon color="primary" />,
    //       title: t("subscription.all-templates")
    //     }
    //   ],
    //   custom: [
    //     { title: t("subscription.generic"), count: 1 },
    //     { title: "Watermark", count: 1 }
    //   ],
    //   option: true
    // },
    {
      name: SubscriptionNames.ON_BOARDING,
      icon: <BatteryCharging100Icon className={classes.icon} />,
      title: t("subscription.offer3"),
      count: 1000,
      content: [
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.unlimited-video")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.support")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.all-templates")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.meeting")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.follow-up")
        }
      ],
      custom: [
        { title: t("subscription.generic"), count: 1 },
        { title: t("subscription.inter-title"), count: 1 },
        { title: "Watermark", count: 1 },
        { title: t("subscription.graphic-integration") }
      ],
      option: true
    },
    {
      name: SubscriptionNames.PREMIUM,
      icon: <StarIcon className={classes.icon} />,
      title: t("subscription.offer5"),
      count: 2000,
      content: [
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.unlimited-video")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.support")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.all-templates")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.meeting")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.follow-up")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.web-access")
        }
      ],
      option: true
    },
    {
      name: SubscriptionNames.CODE,
      icon: <VpnKeyOutlinedIcon style={{ width: 40, height: 40 }} />,
      title: t("subscription.offer4"),
      count: 3500,
      content: [
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.unlimited-video")
        },
        {
          icon: <InfiniteIcon color="primary" />,
          title: t("subscription.unlimited-users")
        },
        {
          icon: (
            <Typography align="center" variant="h4" color="primary">
              3
            </Typography>
          ),
          title: t("subscription.custom-template")
        }
      ],
      custom: [
        { title: t("subscription.generic"), count: 1 },
        { title: t("subscription.inter-title"), count: 1 },
        { title: "Watermark", count: 1 },
        { title: t("subscription.graphic-integration") },
        { title: t("subscription.video-tutorial") }
      ],
      option: true
    }
  ];
  return (
    <Page>
      <Box my={1}>
        <Typography>{t("subscription.options")}</Typography>
        <Box display="flex">
          {userSubscriptionOptions?.map(uso => (
            <Box width="auto">
              <ListItem dense>
                <ListItemIcon>
                  <Box color="success.main">
                    <CheckIcon fontSize="small" />
                  </Box>
                </ListItemIcon>
                <ListItemText>
                  {jsonTranslator(uso.subscriptionOption.label, lang)}
                </ListItemText>
              </ListItem>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {subscriptions.map(subs => {
          const subscriptionActivate =
            userSubscription &&
            subs.name === userSubscription.subscription.name;
          return (
            <Card
              key={subs.name}
              className={clsx(classes.card, {
                [classes.cardActive]: subscriptionActivate,
                [classes.cardExpired]:
                  subscriptionActivate && !hasValidSubscription
              })}
            >
              <CardContent classes={{ root: classes.cardContent }}>
                <Box
                  p={2}
                  className={clsx(classes.header, {
                    [classes.headerActive]: subscriptionActivate,
                    [classes.headerExpired]:
                      subscriptionActivate && !hasValidSubscription
                  })}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  {subs.icon}
                  <Typography className={classes.cardContentTitle} variant="h5">
                    {subs.title}
                  </Typography>
                </Box>
                <Box p={1}>
                  <List>
                    {subs.content.map((ct, index) => (
                      <ListItem key={index}>
                        {ct.icon && (
                          <ListItemIcon className={classes.listIcon}>
                            {ct.icon}
                          </ListItemIcon>
                        )}
                        <ListItemText className={classes.listText}>
                          {ct.title}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                  {subs.custom && (
                    <>
                      <Divider />
                      <List
                        subheader={
                          <ListSubheader>
                            {t("subscription.custom")}
                          </ListSubheader>
                        }
                      >
                        {subs.custom.map((custom, index) => (
                          <ListItem key={index} dense disableGutters>
                            <ListItemIcon className={classes.listIcon}>
                              <ArrowForwardIcon
                                color="primary"
                                className={classes.arrowIcon}
                              />
                            </ListItemIcon>
                            <ListItemText className={classes.listText}>
                              {custom.count && (
                                <Typography
                                  color="primary"
                                  component="span"
                                  variant="h6"
                                >
                                  {custom.count}{" "}
                                </Typography>
                              )}
                              {custom.title}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                  {subs.option && (
                    <ListItem>
                      <ListItemText className={classes.listText}>
                        {t("subscription.sub-title-traduction")}
                      </ListItemText>
                    </ListItem>
                  )}
                </Box>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <MyButton
                  fullWidth
                  capitalize
                  size="large"
                  color="primary"
                  variant="contained"
                  className={clsx({
                    [classes.cardActionsButton]: !subscriptionActivate,
                    [classes.cardActionsButtonActive]: subscriptionActivate,
                    [classes.cardActionsButtonExpired]:
                      subscriptionActivate && !hasValidSubscription
                  })}
                  style={{}}
                >
                  {subscriptionActivate
                    ? !hasValidSubscription
                      ? t("subscription.expired")
                      : t("subscription.activate")
                    : subs.count
                    ? t("subscription.estimate", {
                        count: subs.count,
                        unit: lang === "fr-FR" ? "E" : "$"
                      })
                    : t("subscription.free")}
                </MyButton>
              </CardActions>
            </Card>
          );
        })}
      </Box>
    </Page>
  );
};
