import { createSelector } from "reselect";
import { RootState } from "../store";
import { FontConfigAdapter } from "./reducer";
import { User } from "../../entities/user";

const AdapterSelector = FontConfigAdapter.getSelectors(
  (state: RootState) => state.fontConfigs
);

const getFontConfigState = createSelector(
  (state: RootState) => state,
  state => state.fontConfigs
);

const getFontConfigByUser = (user: User) =>
  createSelector(AdapterSelector.selectAll, fontConfigs =>
    fontConfigs.filter(fc => fc.userId === user.id)
  );

export const FontConfigSelector = {
  ...AdapterSelector,
  getFontConfigState,
  getFontConfigByUser
};
