import { Yup } from "../../../utils/yup";

export interface SignUpValues {
  lastname: string;
  firstname: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

export const SignUpSchema = Yup.object().shape({
  lastname: Yup.string().required(),
  firstname: Yup.string().required(),
  companyName: Yup.string(),
  phoneNumber: Yup.string(),
  email: Yup.string()
    .email()
    .lowercase()
    .required(),
  password: Yup.string().required()
});
