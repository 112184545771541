import {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal
} from "@material-ui/core/colors";

const colors = [
  red[200],
  blue[200],
  teal[200],
  orange[200],
  pink[200],
  purple[200],
  indigo[200],
  cyan[200],
  green[200],
  lime[200],
  amber[200],
  deepOrange[200],
  brown[200]
];

export const getUniqChainColorByNumber = (number: number) => {
  let correctNumber = number;
  if (correctNumber > colors.length - 1)
    while (correctNumber > colors.length - 1) correctNumber -= colors.length;

  if (correctNumber === number - 1) {
    correctNumber = number;
  }
  return colors[correctNumber];
};
