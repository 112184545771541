import { ActivationCode } from "../activation-code/entity";
import { Company } from "../companies/entity";
import { Video, VideoEntity } from "../videos/entity";
import { Languages } from "../../utils/function/jsonTranslator";
import { User } from "../../entities/user";
import { EntityId } from "@reduxjs/toolkit";
import { schema } from "normalizr";
import { Scenario, ScenarioEntity } from "../scenarios/entity";

export interface Project {
  id: string;
  legacyId?: string;
  name: string;
  transcriptionId?: string;
  exportId?: string;
  subtitled?: boolean;
  userId?: string;
  companyId?: string;
  scenarioId?: string;
  languageId?: string;
  codeId?: string;
  createdAt: string;
  updatedAt: string;
  videoIds?: string[];
  user?: User;
  company?: Company;
  language?: Languages;
  scenario?: Scenario;
  videos?: Video[];
  activationCode?: ActivationCode;
}

export interface ProjectDTO {
  id: string;
  name: string;
  userId?: string;
  companyId?: string;
  scenarioId?: string;
}

export type ProjectUpdateDTO = {
  name?: string;
  scenarioId?: string;
};
export type ProjectCompanyCreateDTO = {
  name: string;
  scenarioId: string;
};

export type StatusUpdateDTO = {
  status?: string;
  comment?: string;
};

export interface ProjectNormalized {
  projects: Record<EntityId, Project>;
  scenarios: Record<EntityId, Scenario>;
  users: Record<EntityId, User>;
  companies: Record<EntityId, Company>;
  activationCodes: Record<EntityId, ActivationCode>;
  videos: Record<EntityId, Video>;
}

export const ProjectEntity = new schema.Entity<Project>("projects", {
  videos: [VideoEntity],
  scenario: ScenarioEntity
});
