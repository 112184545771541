import { ShotCategoryName } from "../../reducers/shot-category/entity";
import GenericIcon from "./generic.png";
import ClipIcon from "./clip.png";
import IntertitleIcon from "./intertitle.png";
import InterviewIcon from "./interview.png";
import BrollChildIcon from "./broll-child.png";
import BrollIcon from "./broll.png";

export const ShotCategoryIcon: Record<ShotCategoryName, string> = {
  [ShotCategoryName.GENERIC]: GenericIcon,
  [ShotCategoryName.CLIP]: ClipIcon,
  [ShotCategoryName.INTERTITLE]: IntertitleIcon,
  [ShotCategoryName.INTERVIEW]: InterviewIcon,
  [ShotCategoryName.B_ROLL]: BrollIcon,
  [ShotCategoryName.B_ROLL_CHILD]: BrollChildIcon
};
