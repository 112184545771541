import { createSelector } from "reselect";
import { User } from "../../entities/user";
import { ActivationCodeSelector } from "../activation-code/selector";
import { Company } from "../companies/entity";
import { ScenarioCompanySharedSelector } from "../scenarios-companies-shared/selector";
import { ScenarioUserSharedSelector } from "../scenarios-users-shared/selector";
import { RootState } from "../store";
import { Scenario } from "./entity";
import { ScenarioAdapter } from "./reducer";

export const AdapterSelector = ScenarioAdapter.getSelectors(
  (state: RootState) => state.scenarios
);

export const getState = createSelector(
  (state: RootState) => state.scenarios,
  state => state
);

export const getScenarios = AdapterSelector.selectAll;

export const getScenariosReadOneStatus = createSelector(
  getState,
  state => state.readOneStatus
);

export const getCompanyScenarios = (company: Company) =>
  createSelector(getScenarios, scenarios =>
    scenarios.filter(s => s.companyId === company.id)
  );

export const getUserScenarios = (user: User) =>
  createSelector(getScenarios, scenarios =>
    scenarios.filter(s => s.userId === user.id)
  );

export const getUserSharedScenarios = (user: User) =>
  createSelector(
    ScenarioUserSharedSelector.selectAll,
    AdapterSelector.selectEntities,
    (scenarioUserShareds, scenarios) => {
      return scenarioUserShareds
        .filter(sus => sus.userId === user.id && scenarios[sus.scenarioId])
        .map(sus => scenarios[sus.scenarioId]) as Scenario[];
    }
  );

export const getUserActivationCodeScenarios = (user: User) =>
  createSelector(
    AdapterSelector.selectAll,
    ActivationCodeSelector.selectAll,
    (scenarios, activationCodes) =>
      activationCodes
        .map(ac =>
          scenarios.filter(
            s =>
              ac.scenarioIds.includes(s.id) &&
              ac.userIds.includes(user.id) &&
              new Date() < new Date(ac.end) &&
              new Date() > new Date(ac.start)
          )
        )
        .flat()
  );

export const getCompanySharedScenarios = (company: Company) =>
  createSelector(
    ScenarioCompanySharedSelector.selectAll,
    AdapterSelector.selectEntities,
    (scenarioCompanyShareds, scenarios) => {
      return scenarioCompanyShareds
        .filter(
          scs => scs.companyId === company.id && scenarios[scs.scenarioId]
        )
        .map(sus => scenarios[sus.scenarioId]) as Scenario[];
    }
  );

export const getScenariosPremium = createSelector(getScenarios, scenarios =>
  scenarios.filter(s => s.premium)
);
export const getScenariosPublic = createSelector(getScenarios, scenarios =>
  scenarios.filter(s => s.public)
);

export const getScenarioSelected = createSelector(
  getState,
  state => state.selected
);

export const getScenarioById = (id: string) =>
  createSelector(getScenarios, scenario => scenario.find(s => s.id === id));

export const ScenarioSelector = {
  ...AdapterSelector,
  getState,
  getScenarioSelected,
  getScenarios,
  getCompanyScenarios,
  getUserScenarios,
  getUserSharedScenarios,
  getCompanySharedScenarios,
  getScenariosPremium,
  getScenariosPublic,
  getUserActivationCodeScenarios
};
