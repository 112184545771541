import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BoxAnimated,
  BoxAnimatedProps
} from "../../../components/common/BoxAnimated";
import { MyButton } from "../../../components/common/MyButton";
import { VideoItem } from "../../../components/common/VideoItem";

export interface OnBoardingVideoListProps extends BoxAnimatedProps {
  onSelectVideo: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    video: {
      width: "100%",
      maxWidth: 280,
      margin: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  })
);

export const OnBoardingVideoList: React.FC<OnBoardingVideoListProps> = ({
  onSelectVideo,
  ...props
}) => {
  const classes = useStyles();
  const { t: tCommon } = useTranslation("common");

  return (
    <BoxAnimated {...props}>
      <Grid container justify="flex-start">
        {Array.from(Array(9)).map((_, index) => {
          return (
            <Grid item key={index} className={classes.video}>
              <VideoItem
                controls={false}
                playOnHover
                width="100%"
                poster={`${process.env.REACT_APP_HOST ||
                  ""}/public/videos/onboarding-${index + 1}.png`}
                src={`${process.env.REACT_APP_HOST ||
                  ""}/public/videos/onboarding-${index + 1}.m4v`}
                muted
              />
              <Box clone mt={0.5}>
                <MyButton
                  leftIcon={
                    <ReplyIcon
                      fontSize="small"
                      style={{ transform: "rotateY(180deg)" }}
                    />
                  }
                  size="small"
                  variant="contained"
                  onClick={() => onSelectVideo(index + 1)}
                >
                  {tCommon("select")}
                </MyButton>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </BoxAnimated>
  );
};
