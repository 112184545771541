import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { AppBarActions } from "../../reducers/appbar/reducer";
import { getCurrentUser } from "../../reducers/authentication/selector";

export const HomePage: React.FC<{}> = () => {
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const { t } = useTranslation("home");

  useEffect(() => {
    dispatch(AppBarActions.setState({ title: t("home") }));
    return () => {
      dispatch(AppBarActions.setState());
    };
  }, [dispatch, t]);

  if (!user || !user.isActive || !user.token) {
    return <Redirect to="/authentication" />;
  }

  return <Redirect to="/my-space" />;
};
