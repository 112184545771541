import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { Role } from "./entity";

export interface RoleState extends EntityState<Role> {}

export const RoleInitialState: RoleState = {
  ids: [],
  entities: {}
};

export const RoleAdapter = createEntityAdapter<Role>();

const RoleAdapterState = RoleAdapter.getInitialState(RoleInitialState);

export const RoleSlice = createMySlice({
  name: "role",
  initialState: RoleAdapterState,
  adapter: RoleAdapter,
  asyncActions: [],
  reducers: {}
});

export const RoleReducer = RoleSlice.reducer;
export const RoleActions = RoleSlice.actions;
