import { Tab, Tabs, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { TabPanel } from "../../../components/common/TabPanel";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { ForgetPassword } from "./ForgetPassword";
import { ChangePassword } from "./ChangePassword";

export interface AuthenticationProps {
  authTab: number;
  onTabAuthenticationChange: (newValue: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      width: "100%"
    },
    tabPaper: {
      height: "100%",
      padding: theme.spacing(2)
    },
    tabs: {},
    tabsIndicator: {
      height: 4
    },
    tab: {
      minWidth: 100,
      textTransform: "none"
    }
  })
);

const AuthenticationComponent: React.FC<AuthenticationProps> = ({
  authTab,
  onTabAuthenticationChange
}) => {
  const { t } = useTranslation("authentication");
  const classes = useStyles();

  const onTabChange = (_: React.ChangeEvent<{}> | null, newValue: number) =>
    onTabAuthenticationChange(newValue);

  return (
    <div className={classes.background}>
      <Tabs
        centered
        className={classes.tabs}
        classes={{ indicator: classes.tabsIndicator }}
        value={authTab}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
      >

        {
          <Tab
            data-testid="signin-tab"
            className={classes.tab}
            label={
              <Typography variant="subtitle1">
                {t("sign-in.tab-title")}
              </Typography>
            }
          />
        }
        {
          authTab === 1 &&
          <Tab
            data-testid="signup-tab"
            className={classes.tab}
            label={
              <Typography variant="subtitle1">
                {t("sign-up.tab-title")}
              </Typography>
            }
          />
        }
      </Tabs>
      <TabPanel value={authTab} index={0}>
        <SignIn onTabAuthenticationChange={onTabChange} />
      </TabPanel>
      <TabPanel value={authTab} index={1}>
        <SignUp onTabAuthenticationChange={onTabChange}  />
      </TabPanel>
      <TabPanel value={authTab} index={2}>
        <ForgetPassword onTabAuthenticationChange={onTabChange} />
      </TabPanel>
      <TabPanel value={authTab} index={3}>
        <ChangePassword onTabAuthenticationChange={onTabChange} />
      </TabPanel>
    </div>
  );
};

export const Authentication = AuthenticationComponent;
