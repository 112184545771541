import * as React from "react";

import { CssBaseline } from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes
} from "@material-ui/core/styles";

import { AppState } from "../../reducers/app/reducer";
import { commonThemeOptions } from "./common";
import { useSelector } from "react-redux";
import { getUserDomain } from "../../reducers/domains/selector";

export interface AppThemeProps {
  theme: AppState["theme"];
}

type Props = AppThemeProps;

export const AppTheme: React.FC<Props> = props => {
  const { children } = props;

  const domainSettings = useSelector(getUserDomain);
  let theme = createMuiTheme(commonThemeOptions);
  theme = responsiveFontSizes(theme, { factor: 1.1 });
  if (domainSettings) {
    theme.palette.primary.main = domainSettings.primaryColor;
    theme.palette.secondary.main = domainSettings.secondaryColor;
  }

  return (
    <MuiThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
