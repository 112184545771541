import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import LogoWhite from "../../../assets/logo-white.png";

export interface OnBoardingLoaderProps {}

export const OnBoardingLoader: React.FC<OnBoardingLoaderProps> = () => {
  const { t } = useTranslation("onBoarding");
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minWidth={360}
      display="flex"
    >
      <Box position="relative" height={320} width={200}>
        <Box clone position="absolute" left={-60} top={-30}>
          <CircularProgress
            thickness={1}
            size={320}
            color="primary"
            variant="indeterminate"
          />
        </Box>
        <img alt="enterprise-logo" src={LogoWhite} width="100%" />
      </Box>
      <Box mt={1} borderRadius={24} bgcolor="white" px={4}>
        <Typography variant="h6">{t("generating-video")}</Typography>
      </Box>
    </Box>
  );
};
