import {
  AppBar,
  AppBarProps,
  createStyles,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
  ToolbarProps,
  Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/MenuRounded";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../../reducers/app/reducer";
import { AppSelector } from "../../../reducers/app/selector";
import { getAppBarState } from "../../../reducers/appbar/selector";
import { DRAWER_WIDTH } from "../../../utils/constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      [theme.breakpoints.up("md")]: {
        color: "initial"
      }
    },
    appbar: {
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: "none",
      [theme.breakpoints.up("md")]: {
        backgroundColor: theme.palette.common.white
      }
    },
    appBarWithMargin: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${DRAWER_WIDTH}px - 1px)`,
        marginLeft: DRAWER_WIDTH
      }
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 180
      }
    }
  })
);

export interface MyTopBarProps {
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  hideMenu?: boolean;
  applyMarginLeft?: boolean;
  appBarProps?: AppBarProps;
  toolbarProps?: ToolbarProps;
}

export const MyTopBar: React.FC<MyTopBarProps> = ({
  rightActions,
  hideMenu,
  leftActions,
  applyMarginLeft,
  appBarProps,
  toolbarProps
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { openMenu } = useSelector(AppSelector.getState);
  const { title, style } = useSelector(getAppBarState);
  const handleOpenMenu = () => dispatch(AppActions.openMenu(!openMenu));

  useEffect(() => {
    dispatch(AppActions.getUseLegacy());
  }, [dispatch]);

  return (
    <AppBar
      position="fixed"
      color="primary"
      classes={{
        root: clsx(classes.appbar, {
          [classes.appBarWithMargin]: applyMarginLeft
        })
      }}
      {...appBarProps}
      style={style}
    >
      <Toolbar classes={{ root: classes.toolbar }} {...toolbarProps}>
        {!hideMenu && (
          <Hidden mdUp>
            <IconButton
              data-testid="menu-button"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenMenu}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
        <Typography className={classes.title} variant="h6" noWrap>
          {title}
        </Typography>
        {leftActions}
        <div className={classes.grow} />
        {rightActions}
      </Toolbar>
    </AppBar>
  );
};
