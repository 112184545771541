import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

export interface TutorialsCardProps {
  tuto: {
    title: string;
    category: string;
    link: string;
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 340,
      maxWidth: 341
    },
    media: {
      minHeight: 300
    },
    avatar: {
      backgroundColor: red[500]
    }
  })
);

export const TutorialsCard: React.FC<TutorialsCardProps> = ({
  tuto
}: TutorialsCardProps) => {
  const classes = useStyles();

  return (
    // className={classes.root}
    <Card>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Tuto
          </Avatar>
        }
        title={tuto.title}
        subheader={tuto.category}
      />
      <iframe
        width="350"
        height="330"
        title="video"
        id="video"
        src={`https://www.youtube.com/embed/${tuto.link}`}
        frameBorder="0"
        allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Card>
  );
};
