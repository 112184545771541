import { createSelector } from "reselect";
import { User } from "../../entities/user";
import { ActivationCodeSelector } from "../activation-code/selector";
import { RootState } from "../store";
import { AudioAdapter } from "./reducer";

const AdapterSelector = AudioAdapter.getSelectors(
  (state: RootState) => state.audios
);

const getState = createSelector(
  (state: RootState) => state,
  state => state.audios
);

const getPremium = createSelector(AdapterSelector.selectAll, audios =>
  audios.filter(a => a.premium)
);

const getUserAudios = (user: User) =>
  createSelector(AdapterSelector.selectAll, audios =>
    audios.filter(v => v.userId === user.id)
  );
const getUserAudiosFromActivationCode = (user: User) =>
  createSelector(
    AdapterSelector.selectAll,
    ActivationCodeSelector.selectAll,
    (audios, activationCodes) =>
      activationCodes
        .map(ac =>
          audios.filter(
            a =>
              ac.audioIds.includes(a.id) &&
              ac.userIds.includes(user.id) &&
              new Date() < new Date(ac.end) &&
              new Date() > new Date(ac.start)
          )
        )
        .flat()
  );

const getUserAudiosByGroup = (user: User) =>
  createSelector(
    getUserAudios(user),
    getUserAudiosFromActivationCode(user),
    (owned, activationCode) => ({ owned, activationCode })
  );

export const AudioSelector = {
  ...AdapterSelector,
  getState,
  getPremium,
  getUserAudiosByGroup
};
