import {
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
  createStyles,
  makeStyles,
  Theme
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { MyLink } from "../../../../components/common/MyLink";
import { Company } from "../../../../reducers/companies/entity";
import NoPictureIcon from "@material-ui/icons/PanoramaOutlined";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    card: {
      height: 272,
      backgroundColor: grey[100]
    },
    cardDisabled: {
      backgroundColor: grey[400],
      opacity: 0.4,
      pointerEvents: "none"
    },
    images: {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    },
    avatar: {
      backgroundColor: theme.palette.primary.main
    }
  })
);

export interface CompanyItemInterface {
  company: Company;
  cardActions?: ReactNode;
  disabled?: boolean;
}

export const CompanyItem: React.FC<CompanyItemInterface> = ({
  company,
  cardActions,
  disabled
}) => {
  const { t: tCommon } = useTranslation("common");
  const classes = useStyles();
  return (
    <Card className={clsx(classes.card, { [classes.cardDisabled]: disabled })}>
      {/* <Card className={clsx(classes.card, { [classes.cardDisabled]: disabled })}> */}
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {company.name.substr(0, 2).toUpperCase()}
          </Avatar>
        }
        action={cardActions}
        title={company.name.toUpperCase()}
        subheader={`${tCommon("created-at")} ${new Date(
          company.createdAt
        ).toLocaleDateString()}`}
      />
      <CardActionArea style={{ height: 200 }}>
        <MyLink to={`/company/${company.id}`}>
          {company.watermarks &&
          company.watermarks.length &&
          company.watermarks[0].image ? (
            <img
              alt="logo"
              src={company.watermarks[0].image.url}
              className={classes.images}
            />
          ) : (
            <NoPictureIcon
              className={classes.images}
              style={{ color: company.setting?.primaryColor || grey[300] }}
            />
          )}
        </MyLink>
      </CardActionArea>
    </Card>
  );
};
