import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import LogoWhite from "../../../assets/logo-white.png";
//import Logo from "../../../assets/logo.svg";

interface LoaderComponentProps {
  title?: React.ReactNode | string;
  whiteLogo?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    progress: {
      width: 140,
      height: 140
      // border: 1,
      // position: "absolute",
      // top: `calc(50% - 70px)`,
      // left: `calc(50% - 70px)`
    },
    logo: {
      width: 120,
      display: "block",
      margin: "0 auto"
    }
  })
);

export const LoaderComponent: React.FC<LoaderComponentProps> = ({
  title,
  whiteLogo
}) => {
  const { t } = useTranslation("components");
  const classes = useStyles();
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minWidth={360}
      display="flex"
    >
      <Box position="relative" height={140} width={140}>
        <Box clone position="absolute">
          <CircularProgress
            thickness={1}
            size={140}
            color="primary"
            variant="indeterminate"
          />
        </Box>
        <img
          alt="enterprise-logo"
          src={whiteLogo ? LogoWhite : LogoWhite}
          className={classes.logo}
        />
      </Box>
      <Box mt={1}>
        {title ? (
          typeof title === "string" ? (
            <Typography>{title}</Typography>
          ) : (
            title
          )
        ) : (
          <Typography>{t("dynamicElement.loaderComponent.title")}</Typography>
        )}
      </Box>
    </Box>
  );
};
