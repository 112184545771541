import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { MyTable } from "../../../components/common/MyTable";
import { AppSelector } from "../../../reducers/app/selector";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { FontConfig } from "../../../reducers/fonts-config/entity";
import { FontConfigSelector } from "../../../reducers/fonts-config/selector";
import { ItemSelector } from "../../../reducers/items/selector";
import { UserActions } from "../../../reducers/users/reducer";

export interface FontListProps {
  fontConfigs: FontConfig[];
  customText: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    colorIcon: {
      width: 20,
      height: 21,
      marginRight: theme.spacing(),
      borderRadius: 6,
      border: `1px solid grey`
    },
    fontPreview: {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      wordBreak: "break-word",
      overflow: "auto"
    }
  })
);

export const FontList: React.FC<FontListProps> = memo(({ customText }) => {
  const classes = useStyles();
  const { t: tCommon } = useTranslation("common");
  const { language: lang } = useSelector(AppSelector.getState);
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const fontConfigs = useSelector(FontConfigSelector.selectAll);
  const userFontConfigRemovedPending = useSelector(
    ItemSelector.getItemsPendingByAction(
      UserActions.removeFontConfig.typePrefix
    )
  );

  const onRemoveFontConfig = (fontConfig: FontConfig) => () =>
    dispatch(UserActions.removeFontConfig({ user: currentUser, fontConfig }));

  return (
    <>
      <MyTable
        data={
          fontConfigs.length > 0
            ? fontConfigs.map(fontConfig => ({
                id: fontConfig.id,
                color: fontConfig.color,
                name: fontConfig.font.name,
                size: fontConfig.size,
                weight: fontConfig.weight,
                backgroundColor: fontConfig.backgroundColor,
                createdAt: fontConfig.createdAt
              }))
            : []
        }
        detailPanel={[
          {
            disabled: false,
            icon: "visibility",
            openIcon: "keyboard_arrow_down",
            render: fontConfig => {
              return (
                <Box p={2} bgcolor="grey.200">
                  <Paper elevation={2}>
                    <Typography
                      key={fontConfig.id}
                      className={classes.fontPreview}
                      component="pre"
                      style={{
                        fontFamily: fontConfig.name,
                        fontSize: fontConfig.size,
                        fontWeight: fontConfig.weight,
                        fontStyle: fontConfig.style,
                        backgroundColor: `#${fontConfig.backgroundColor}`,
                        color: `#${fontConfig.color}`
                      }}
                    >
                      {customText}
                    </Typography>
                  </Paper>
                </Box>
              );
            }
          }
        ]}
        columns={[
          {
            hidden: true,
            field: "id"
          },
          {
            title: tCommon("name"),
            field: "name"
          },
          {
            title: tCommon("color"),
            field: "color",
            render: rowData => (
              <Box display="flex" alignItems="center">
                <div
                  className={classes.colorIcon}
                  style={{ backgroundColor: `#${rowData.color}` }}
                />
                <Typography>#{rowData.color}</Typography>
              </Box>
            )
          },
          {
            title: tCommon("background-color"),
            field: "backgroundColor",
            render: rowData => (
              <Box display="flex" alignItems="center">
                <div
                  className={classes.colorIcon}
                  style={{
                    backgroundColor: `#${rowData.backgroundColor}`
                  }}
                />
                <Typography>#{rowData.backgroundColor}</Typography>
              </Box>
            )
          },
          {
            title: tCommon("size"),
            field: "size"
          },
          // {
          //   title: tCommon("weight"),
          //   field: "weight",
          //   type: "numeric"
          // },
          {
            title: tCommon("created-at"),
            field: "createdAt",
            render: rowData => {
              return (
                <Typography>
                  {new Date(rowData.createdAt).toLocaleString(lang)}
                </Typography>
              );
            }
          },
          {
            title: "",
            render: fontConfig => (
              <MyIconButton
                loading={userFontConfigRemovedPending.includes(fontConfig.id)}
                onClick={onRemoveFontConfig(fontConfig)}
              >
                <DeleteIcon />
              </MyIconButton>
            )
          }
        ]}
      />
    </>
  );
});
