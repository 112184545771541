import {
  Grid,
  MenuItem,
  ListItemIcon,
  Typography,
  Box
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { Page } from "../../../components/common/MyPage";
import { AppBarActions } from "../../../reducers/appbar/reducer";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { Company } from "../../../reducers/companies/entity";
import { UserActions } from "../../../reducers/users/reducer";
import { UserSelector } from "../../../reducers/users/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { CompanyItem } from "./components/CompanyItem";
import { SearchCompanyOpportunity } from "./components/SearchCompany";
import { MyIconButton } from "../../../components/common/MyIconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import partition from "lodash/partition";

export interface MyCollaborationsProps {}

// const useStyles = makeStyles(() =>
//   createStyles({
//     root: {}
//   })
// );

export const MyCollaborations: React.FC<MyCollaborationsProps> = () => {
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");
  const dispatch = useDispatch();
  const [openSearchCompany, setOpenSearchCompany] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const workingCompanies = useSelector(
    UserSelector.getCollaborations(currentUser)
  );
  const { readCollaborationsStatus } = useSelector(UserSelector.getState);

  useEffect(() => {
    dispatch(AppBarActions.setState({ title: t("menus.collaborations") }));
    return () => {
      dispatch(AppBarActions.setState());
    };
  }, [dispatch, t]);

  const onLeave = (company: Company) =>
    dispatch(UserActions.leaveCompany(company));

  return (
    <Page>
      <DynamicElement<Company[]>
        data={workingCompanies}
        actions={{
          action: UserActions.readCollaborations(currentUser),
          status: readCollaborationsStatus
        }}
        showCondition={readCollaborationsStatus === FetchingStatus.SUCCESS}
      >
        {workingCompanies => (
          <>
            <SearchCompanyOpportunity
              open={openSearchCompany}
              onClose={() => setOpenSearchCompany(false)}
            />
            <Box>
              {partition(workingCompanies, c => c.hasValidSubscription)[0]
                .length > 0 && (
                <Typography variant="subtitle1" paragraph>
                  {tCommon("active")}
                </Typography>
              )}
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                spacing={2}
              >
                {partition(
                  workingCompanies,
                  c => c.hasValidSubscription
                )[0].map(workingCompany => {
                  return (
                    <Grid item key={workingCompany.id} xs={12} md={4}>
                      <CompanyItem
                        company={workingCompany}
                        cardActions={
                          <MyIconButton
                            aria-label="collaboration-settings"
                            menus={({ onClose }) => (
                              <MenuItem
                                dense={true}
                                onClick={() => {
                                  onLeave(workingCompany);
                                  onClose();
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                  <ExitToAppIcon color="primary" />
                                </ListItemIcon>
                                <Typography color="primary">
                                  {t("collaborations.actions.leave")}
                                </Typography>
                              </MenuItem>
                            )}
                          >
                            <MoreVertIcon />
                          </MyIconButton>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <Box mt={2}>
              {partition(workingCompanies, c => c.hasValidSubscription)[1]
                .length > 0 && (
                <Typography variant="subtitle1" paragraph>
                  {tCommon("inactive")}
                </Typography>
              )}
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                spacing={2}
              >
                {partition(
                  workingCompanies,
                  c => c.hasValidSubscription
                )[1].map(workingCompany => {
                  return (
                    <Grid item key={workingCompany.id} xs={12} md={4}>
                      <CompanyItem company={workingCompany} disabled />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </>
        )}
      </DynamicElement>
    </Page>
  );
};
