export const DRAWER_WIDTH = 260;

export enum SubscriptionNames {
  STARTER = "Starter",
  SOLO = "Solo",
  CODE = "Code",
  BUSINESS = "Business",
  ON_BOARDING = "Onboarding",
  PREMIUM = "Premium"
}

export enum SubscriptionHistoryActions {
  CREATED_FROM_LEGACY = "CREATED_FROM_LEGACY",
  INCREASE_LICENCES = "INCREASE_LICENSES",
  DECREASE_LICENCES = "DECREASE_LICENSES"
}
