import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { AppActions } from "../../reducers/app/reducer";
import { AppDispatch, RootState } from "../../reducers/store";
import i18n from "../i18n";

interface ThunkApi {
  dispatch: AppDispatch;
  getState: () => RootState;
  extra: any;
  requestId: string;
  signal: AbortSignal;
  rejectWithValue: any;
}

interface CreateMyAsyncThunkOptions<Returned, ThunkArg, State> {
  onSuccess?: ({
    result,
    values,
    thunkApi
  }: {
    result: Returned;
    values: ThunkArg;
    thunkApi: ThunkApi;
  }) => any;
  onSuccessMessage?: string;
  onFailed?: ({
    error,
    values,
    thunkApi
  }: {
    error: any;
    values: ThunkArg;
    thunkApi: ThunkApi;
  }) => void;
  onFailedMessage?: string;
}

export function createMyAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  request: (
    values: ThunkArg,
    thunkApi: ThunkApi
  ) => Promise<AxiosResponse<Returned>>,
  options?: CreateMyAsyncThunkOptions<Returned, ThunkArg, RootState>
) {
  return createAsyncThunk<
    Returned,
    ThunkArg,
    { state: RootState; dispatch: AppDispatch }
  >(typePrefix, async (values: any, thunkApi: ThunkApi) => {
    const dispatch = thunkApi.dispatch;
    try {
      const result = await request(values, thunkApi);

      if (options?.onSuccessMessage) {
        dispatch(
          AppActions.enqueueSnacbar({
            message: i18n.t(options?.onSuccessMessage),
            variant: "success"
          })
        );
      }
      if (options?.onSuccess) {
        const returnSuccess = options.onSuccess({
          result: result.data,
          values,
          thunkApi
        });
        if (!!returnSuccess) {
          return returnSuccess;
        }
      }

      return result.data;
    } catch (error) {
      console.warn(JSON.stringify(error, null, 2));
      if (options?.onFailedMessage) {
        dispatch(
          AppActions.enqueueSnacbar({
            message: i18n.t(options.onFailedMessage),
            variant: "error"
          })
        );
      } else {
        dispatch(
          AppActions.enqueueSnacbar({
            message: `${i18n.t("saga:failed")}: ${
              error?.response?.data?.message
            }`,
            variant: "error"
          })
        );
      }
      if (options?.onFailed) {
        options.onFailed({
          error: error?.message,
          values,
          thunkApi
        });
      }
      return thunkApi.rejectWithValue(error.message);
    }
  });
}
