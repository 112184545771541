import React from "react";

import Box, { BoxProps } from "@material-ui/core/Box";

interface TabPanelProps {
  boxProps?: BoxProps;
  value: number;
  index: number;
  className?: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  boxProps,
  ...other
}) => {
  return (
    <Box
      {...boxProps}
      style={{ height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {children}
    </Box>
  );
};
