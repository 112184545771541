import { createSelector } from "reselect";
import { RootState } from "../store";
import { ProjectAdapter } from "./reducer";

const AdapterSelector = ProjectAdapter.getSelectors(
  (state: RootState) => state.projects
);

const getState = createSelector(
  (state: RootState) => state,
  state => state.projects
);

const getProjects = AdapterSelector.selectAll;

export const getProjectById = (id: string) =>
  createSelector(getProjects, projects => projects.find(p => p.id === id));

export const getProjectByCompany = (companyId: string) =>
  createSelector(AdapterSelector.selectAll, projects =>
    projects.filter(p => p.companyId === companyId)
  );

export const ProjectSelector = {
  getState,
  getProjects,
  getProjectById,
  getProjectByCompany,
  ...AdapterSelector
};
