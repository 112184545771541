import { Box, TextField, TextFieldProps } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { Field, FieldProps } from "formik";
import { get } from "lodash";
import React from "react";
import { ChromePicker, ChromePickerProps } from "react-color";

export type MyTextFieldProps = TextFieldProps & {
  showValidationIcon?: boolean;
  colorPickerProps?: ChromePickerProps;
};

export const MyTextField: React.FC<MyTextFieldProps> = props => {
  const {
    label,
    helperText,
    value: textFieldValue,
    showValidationIcon,
    colorPickerProps,
    ...inputProps
  } = props;

  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps<any>) => {
        const errorField = get(form.errors, field.name);
        const touchField = get(form.touched, field.name);
        let value: any;

        if (textFieldValue !== undefined) {
          value = textFieldValue;
        } else if (field.value !== undefined) {
          value = field.value;
        } else {
          value = "";
        }

        const endAdornment =
          inputProps.InputProps?.endAdornment || showValidationIcon ? (
            !showValidationIcon ? (
              inputProps.InputProps?.endAdornment
            ) : touchField ? (
              errorField ? (
                <ErrorIcon color="error" />
              ) : (
                <CheckIcon style={{ color: green[400] }} />
              )
            ) : null
          ) : null;

        const textField =
          // <MyFormControl
          //   {...formControlProps}
          //   {...myFormControlProps}
          //   margin="dense"
          //   isAnInputLabel={true}
          // >
          inputProps.type === "color" ? (
            <ChromePicker
              styles={{
                default: {
                  picker: {
                    width: 180
                  }
                }
              }}
              {...colorPickerProps}
              color={field.value}
              onChangeComplete={color => {
                const e = {
                  target: { value: color.hex, name: field.name }
                };
                field.onChange(e);
              }}
            />
          ) : (
            <TextField
              style={{ marginTop: 8 }}
              label={label}
              fullWidth
              helperText={
                touchField && errorField ? errorField : helperText || null
              }
              margin="none"
              // labelWidth={labelWidth}
              error={!!errorField && !!touchField}
              {...field}
              {...inputProps}
              InputProps={{
                startAdornment: inputProps.InputProps?.startAdornment ? (
                  <Box clone mr={1}>
                    {inputProps.InputProps?.startAdornment}
                  </Box>
                ) : null,
                endAdornment
              }}
              value={value}
              disabled={inputProps.disabled}
            />
          );
        // </MyFormControl>
        return textField;
      }}
    </Field>
  );
};
