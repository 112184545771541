import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { AudioActionsTypes } from "./action";
import { Audio } from "./entity";
import * as Api from "./api";

export interface AudioState extends EntityState<Audio> {
  readStatus: FetchingStatus;
  readPremiumStatus: FetchingStatus;
  removeOneStatus: FetchingStatus;
}

export const AudioInitialState: AudioState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  readPremiumStatus: FetchingStatus.NULL,
  removeOneStatus: FetchingStatus.NULL
};

export const AudioAdapter = createEntityAdapter<Audio>();

const AudioAdapterState = AudioAdapter.getInitialState(AudioInitialState);

export const read = createMyAsyncThunk<Audio[]>(
  AudioActionsTypes.AUDIO_READ,
  Api.read
);

export const readPremium = createMyAsyncThunk<Audio[]>(
  AudioActionsTypes.AUDIO_READ_PREMIUM,
  Api.readPremium
);

export const removeOne = createMyAsyncThunk(
  AudioActionsTypes.AUDIO_REMOVE_ONE,
  Api.removeOne,
  { onSuccess: ({ values }) => values, onSuccessMessage: "saga:delete-success" }
);

const AudioSlice = createMySlice({
  name: "audios",
  initialState: AudioAdapterState,
  adapter: AudioAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: AudioAdapter.upsertMany
    },
    {
      action: readPremium,
      statusName: "readPremiumStatus",
      onSuccess: AudioAdapter.upsertMany
    },
    {
      action: removeOne,
      statusName: "removeOneStatus",
      onSuccess: AudioAdapter.removeOne
    }
  ],
  reducers: {}
});

export const AudioReducer = AudioSlice.reducer;
export const AudioActions = {
  ...AudioSlice.actions,
  read,
  readPremium,
  async: { read, readPremium, removeOne }
};
