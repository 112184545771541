import { createSelector } from "@reduxjs/toolkit";
import { User } from "../../entities/user";
import { CompanySelector } from "../companies/selector";
import { ProjectSelector } from "../projects/selector";
import { RootState } from "../store";
import { UserAdapter } from "./reducer";

const AdapterSelector = UserAdapter.getSelectors(
  (state: RootState) => state.users
);

const getState = createSelector(
  (state: RootState) => state,
  state => state.users
);

export const getUsers = AdapterSelector.selectAll;

export const getUserById = (id: string) =>
  createSelector(getUsers, user => user.find(u => u.id === id));

const getCompanies = () => CompanySelector.selectAll;

const getCollaborations = (user: User) =>
  createSelector(CompanySelector.selectAll, companies =>
    companies.filter(
      company =>
        company.collaboratorsIds && company.collaboratorsIds.includes(user.id)
    )
  );

const getProjects = (user: User) =>
  createSelector(ProjectSelector.selectAll, projects => {
    projects.filter(project => project.userId === user.id);
  });

export const UserSelector = {
  ...AdapterSelector,
  getState,
  getProjects,
  getCompanies,
  getCollaborations
};
