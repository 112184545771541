import { Select } from "@material-ui/core";
import Input, { InputProps } from "@material-ui/core/Input";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import { SelectProps } from "@material-ui/core/Select";
import get from "lodash/get";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";

export interface SelectOption {
  value: any;
  label: React.ReactNode | string;
  menuItemProps?: MenuItemProps;
}
interface MySelectFieldProps extends FormProps {
  options: SelectOption[];
  name: string;
  inputProps?: InputProps;
  defaultValue?: unknown;
}
type Props = MySelectFieldProps & SelectProps & FormProps;

export const MySelectField: React.FC<Props> = props => {
  const {
    name,
    defaultValue,
    label,
    options,
    inputProps,
    helperText,
    formControlProps,
    formLabelProps,
    formHelperTextProps,
    ...selectProps
  } = props;
  const { errors, formState, control } = useFormContext();
  const labelRef = React.useRef<HTMLLabelElement>(null);

  const errorField = get(errors, name)?.message;
  const touchField = get(formState.touched, name);
  const myFormControlProps: MyFormControlProps = {
    errorField,
    label,
    labelRef,
    helperText,
    formLabelProps,
    formHelperTextProps,
    ...formControlProps
  };
  return (
    <MyFormControl
      {...myFormControlProps}
      disabled={selectProps.disabled}
      isAnInputLabel
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || (selectProps.multiple ? [] : "")}
        as={
          <Select
            error={!!errorField && !!touchField}
            // margin="none"
            input={<Input required={selectProps.required} {...inputProps} />}
            {...selectProps}
          >
            {options.map(option => (
              <MenuItem
                {...option.menuItemProps}
                key={option.value}
                value={option.value}
                button={true}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        }
      />
    </MyFormControl>
  );
};
