import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { ScenarioItem } from "./ScenarioItem";
import { Scenario } from "../../reducers/scenarios/entity";
import { GalleryScenarioContext } from "./gallery/MyGalleryScenario";
import { useSelector } from "react-redux";
import { AppSelector } from "../../reducers/app/selector";

interface ScenarioListProps {
  scenarios: Scenario[];
}
export const MyScenarioList: React.FC<ScenarioListProps> = ({ scenarios }) => {
  const { onScenarioSelected } = useContext(GalleryScenarioContext);
  const { language } = useSelector(AppSelector.getState);
  return (
    <Grid container>
      {scenarios
        .sort((a, b) => a.label[language].localeCompare(b.label[language]))
        .map(s => (
          <Grid item xs={12} lg={6} key={s.id}>
            <ScenarioItem
              onClick={() => onScenarioSelected && onScenarioSelected(s)}
              scenario={s}
            />
          </Grid>
        ))}
    </Grid>
  );
};
