import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  createStyles,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from "@material-ui/core";
import { lightGreen } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PictureIcon from "@material-ui/icons/PanoramaOutlined";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ExtraCompanyProps } from "../..";
import { DynamicElement } from "../../../../components/common/DynamicElement";
import { MyIconButton } from "../../../../components/common/MyIconButton";
import { Page } from "../../../../components/common/MyPage";
import { UploadAssetButton } from "../../../../components/common/UploadAssetButton";
import { UploadWatermarkButton } from "../../../../components/common/UploadWatermarkButton";
import { AssetsType } from "../../../../entities/asset";
import { AppSelector } from "../../../../reducers/app/selector";
import { Company } from "../../../../reducers/companies/entity";
import { CompanyActions } from "../../../../reducers/companies/reducer";
import { CompanySelector } from "../../../../reducers/companies/selector";
import { Image } from "../../../../reducers/images/entity";
import { ImageActions } from "../../../../reducers/images/reducer";
import { useAppDispatch } from "../../../../reducers/store";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { RoleNames } from "../../../../reducers/roles/entity";
import { useSnackbar } from "notistack";

interface ListProps {
  images: Image[];
  company: Company;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    imageCard: {
      margin: theme.spacing(2)
    },
    images: {
      width: "100%",
      height: 220,
      objectFit: "cover"
    },
    listItem: {
      width: 160
    },
    pngImages: {
      backgroundColor: lightGreen[400]
    }
  })
);

export const ImageList: React.FC<ExtraCompanyProps> = ({ company }) => {
  const { readImagesTransferedStatus } = useSelector(CompanySelector.getState);
  const { t } = useTranslation("company");
  const { t: gtv } = useTranslation("gtv");
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { uploadImageStatus, uploadWatermarkStatus } = useSelector(
    CompanySelector.getState
  );
  const images = useSelector(CompanySelector.getImages(company));
  const currentUser = useSelector(getCurrentUser);

const uploadimageAssets = async (type: string, formData: FormData, filesName: string[]) => {
  // check if filesName list string is present on images list name
  const isStringPresent = filesName.some(filename => {
    const lastDotIndex = filename.lastIndexOf('.');
    const nameWithoutExtension = filename.substring(0, lastDotIndex);
    return images.some(image => image.name === nameWithoutExtension);
  });

  if(isStringPresent) {
    enqueueSnackbar(gtv("form.name-exist"), { variant: "error" });
    return false;
  }

  if (type === "image") {
    await dispatch(CompanyActions.uploadImage({ company, formData }))
  } else if (type === "watermark") {
    await dispatch(CompanyActions.uploadWatermark({ company, formData }))
  }
}

  useEffect(() => {
    dispatch(CompanyActions.readImagesTransfered(company));
  }, [company, dispatch]);

  return (
    <>
      {(company.userId === currentUser.id ||
        (currentUser.role &&
          [RoleNames.ADMIN, RoleNames.GOD].includes(
            currentUser.role.name
          ))) && (
            <UploadAssetButton
            variant="contained"
            color="primary"
            assetType={AssetsType.IMAGE}
            loadingStatus={uploadImageStatus}
            onSubmitAsset={async (formData: FormData, fileNames?: string[]) =>
              await uploadimageAssets("image", formData, fileNames || [])
            }
          >
            {t("assets.image-upload")}
          </UploadAssetButton>
      )}

      {(company.userId === currentUser.id ||
        (currentUser.role &&
          [RoleNames.ADMIN, RoleNames.GOD].includes(
            currentUser.role.name
          ))) && (
            <UploadWatermarkButton
            variant="contained"
            color="secondary"
            assetType={AssetsType.IMAGE}
            loadingStatus={uploadWatermarkStatus}
            onSubmitAsset={async (formData: FormData, fileNames?: string[]) =>
              await uploadimageAssets("watermark", formData, fileNames || [])
            }
          >
            {t("assets.watermark-upload")}
          </UploadWatermarkButton>
      )}

      <DynamicElement<Image[]>
        actions={{
          action: CompanyActions.readImagesTransfered(company),
          status: readImagesTransferedStatus
        }}
        data={images}
        showCondition={
          images.length > 0 ||
          (company.watermarks && company.watermarks.length > 0)
        }
      >
        {images => {
          return <List images={images} company={company} />;
        }}
      </DynamicElement>
    </>
  );
};

export const List: React.FC<ListProps> = ({ images, company }) => {
  const classes = useStyles();
  const { language: lang } = useSelector(AppSelector.getState);
  const { t: tCommon } = useTranslation("common");
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const onDelete = (id: string) => {
    dispatch(ImageActions.async.removeOne(id));
  };
  const onDeleteWatermark = (id: string) => {
    dispatch(CompanyActions.removeWatermark({ id, companyId: company.id }));
  };
  return (
    <Page>
      {company.watermarks && company.watermarks.length > 0 && (
        <>
          <Typography variant="subtitle1">Watermarks</Typography>
          <Box display="flex" flexWrap="wrap">
            {company.watermarks.map(w => (
              <Box clone key={w.id} maxWidth={400} m={1} flex="auto">
                <Card className={classes.imageCard}>
                  <CardHeader
                    avatar={<PictureIcon />}
                    action={(company.userId === currentUser.id ||
                      (currentUser.role &&
                        [RoleNames.ADMIN, RoleNames.GOD].includes(
                          currentUser.role.name
                        ))) ?
                        <MyIconButton
                        menus={({ onClose }) => (
                          <MenuItem
                            dense={true}
                            onClick={() => {
                              onClose();
                              onDeleteWatermark(w.id);
                            }}
                          >
                            <ListItemIcon style={{ minWidth: 36 }}>
                              <DeleteIcon color="error" />
                            </ListItemIcon>
                            <Typography color="error">
                              {tCommon("delete")}
                            </Typography>
                          </MenuItem>
                        )}
                      >
                        <MoreVertIcon />
                      </MyIconButton> : null
                    }
                    title={w.image.name}
                    subheader={`${tCommon("created-at")} ${new Date(
                      w.image.createdAt
                    ).toLocaleDateString(lang)}`}
                  />
                  <CardMedia
                    className={clsx(classes.images, {
                      [classes.pngImages]: w.image?.format === "png"
                    })}
                    image={w.image?.url}
                  />
                </Card>
              </Box>
            ))}
          </Box>
        </>
      )}

      <Typography variant="subtitle1">{tCommon("owned")}</Typography>
      <Box display="flex" flexWrap="wrap">
        {images.map(i => (
          <Box clone key={i.id} maxWidth={400} m={1} flex="auto">
            <Card className={classes.imageCard}>
              <CardHeader
                avatar={<PictureIcon />}
                action={(company.userId === currentUser.id ||
                  (currentUser.role &&
                    [RoleNames.ADMIN, RoleNames.GOD].includes(
                      currentUser.role.name
                    ))) ?
                  <MyIconButton
                    menus={({ onClose }) => (
                      <MenuItem
                        dense={true}
                        onClick={() => {
                          onClose();
                          onDelete(i.id);
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 36 }}>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <Typography color="error">
                          {tCommon("delete")}
                        </Typography>
                      </MenuItem>
                    )}
                  >
                    <MoreVertIcon />
                  </MyIconButton> : null
                }
                title={i.name}
                subheader={`${tCommon("created-at")} ${new Date(
                  i.createdAt
                ).toLocaleDateString(lang)}`}
              />
              <CardMedia
                className={clsx(classes.images, {
                  [classes.pngImages]: i.format === "png"
                })}
                image={i.url}
              />
            </Card>
          </Box>
        ))}
      </Box>
    </Page>
  );
};
