import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FontConfig } from "./entity";

export interface FontConfigState extends EntityState<FontConfig> {}

export const FontConfigInitialState: FontConfigState = {
  ids: [],
  entities: {}
};

export const FontConfigAdapter = createEntityAdapter<FontConfig>();

const FontConfigAdapterState = FontConfigAdapter.getInitialState(
  FontConfigInitialState
);

const FontConfigSlice = createMySlice({
  name: "font-configs",
  initialState: FontConfigAdapterState,
  adapter: FontConfigAdapter,
  asyncActions: [],
  reducers: {}
});

export const FontConfigReducer = FontConfigSlice.reducer;
export const FontConfigActions = { ...FontConfigSlice.actions };
