export enum AuthenticationActionsTypes {
  SIGNIN_STATUS = "@@CURRENT_USER signin status",
  SIGNIN_FETCH = "@@CURRENT_USER signin fetch",
  SIGNUP_STATUS = "@@CURRENT_USER sign up status",
  SIGNUP_FETCH = "@@CURRENT_USER sign up fetch",
  RETRY_CONNECTION = "@@CURRENT_USER retry connection",
  RETRY_CONNECTION_STATUS = "@@CURRENT_USER retry connection status",
  LOG_OUT_STATUS = "@@CURRENT_USER log out status",
  LOG_OUT_FETCH = "@@CURRENT_USER log out fetch",
  UPDATE_ONBOARDING = "@@CURRENT_USER update on boarding",
  UPDATE_ACCOUNT_FETCH = "@@CURRENT_USER update account fetch",
  UPDATE_ACCOUNT_STATUS = "@@CURRENT_USER update account status",
  LEAVE_COMPANY = "@@CURRENT_USER leave company",
  LEAVE_COMPANY_STATUS = "@@CURRENT_USER leave company status",
  READ_SCENARIO = "@@CURRENT_USER read scenario",
  READ_SCENARIO_STATUS = "@@CURRENT_USER read scenario status",
  READ_SHARED_SCENARIO = "@@CURRENT_USER read shared scenario",
  READ_SHARED_SCENARIO_STATUS = "@@CURRENT_USER read shared scenario status",
  SHARE_SCENARIO_TO_USER_COMPANIES = "@@CURRENT_USER share scenarios to user's companies",
  READ_VIDEOS = "@@CURRENT_USER read videos",
  READ_FONTCONFIGS = "@@CURRENT_USER read font configs",
  REMOVE_FONTCONFIG = "@@CURRENT_USER remove font config",
  FORGET_PASSWORD = "@@CURRENT_USER forget password",
  FORGET_PASSWORD_STATUS = "@@CURRENT_USER forget password status",
  CHANGE_PASSWORD = "@@CURRENT_USER change password",
  CHANGE_PASSWORD_STATUS = "@@CURRENT_USER change password status"
}
