import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import RootApplication from "./pages";
import { store } from "./reducers/store";
import * as serviceWorker from "./serviceWorker";
import "./utils/i18n";

export const WebApp: React.FunctionComponent<{}> = () => {
  return (
    <Provider store={store}>
      <RootApplication />
    </Provider>
  );
};

render(<WebApp />, document.getElementById("root"));

serviceWorker.unregister();
