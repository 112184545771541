import { Yup } from "../../../../utils/yup";
import { string, object } from "yup";

export const createCompanySchema = Yup.object({
  name: string()
    .required()
    .min(1),
  setting: object({
    primaryColor: string().required(),
    secondaryColor: string().required(),
    tertiaryColor: string().notRequired()
  })
});
