import {
  createStyles,
  IconButton,
  makeStyles,
  StepConnector,
  StepConnectorProps,
  StepIconProps,
  Theme
} from "@material-ui/core";
import AudiotrackIcon from "@material-ui/icons/AudiotrackOutlined";
import MovieIcon from "@material-ui/icons/MovieOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import clsx from "clsx";
import React from "react";

export interface GtvStepIconProps extends StepIconProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
      zIndex: 1
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.primary.light
      }
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.error.light
      }
    }
  })
);

export function GtvStepIconButton(props: GtvStepIconProps) {
  const classes = useStyles();
  const { active, completed, icon, error } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <AudiotrackIcon />,
    3: <MovieIcon />
  };

  return (
    <IconButton
      disabled={!completed && !active}
      size="medium"
      className={clsx(classes.root, {
        [classes.active]: active || completed,
        [classes.error]: !!error
        // [classes.completed]: completed
      })}
    >
      {icons[String(icon)]}
    </IconButton>
  );
}

const useConnectorStyles = makeStyles((theme: Theme) =>
  createStyles({
    alternativeLabel: {
      top: 22
    },
    active: {
      "& $line": {
        background: theme.palette.primary.main
      }
    },
    completed: {
      "& $line": {
        background: theme.palette.primary.main
      }
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1
    }
  })
);

export function GtvStepConnector(props: StepConnectorProps) {
  const classes = useConnectorStyles();
  return <StepConnector {...props} classes={classes} />;
}
