import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import { lightGreen } from "@material-ui/core/colors";
import PictureIcon from "@material-ui/icons/PanoramaOutlined";
import clsx from "clsx";
import React from "react";
import { Image } from "../../reducers/images/entity";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    images: {
      width: "100%",
      height: 220,
      objectFit: "cover"
    },
    listItem: {
      width: 160
    },
    pngImages: {
      backgroundColor: lightGreen[400]
    }
  })
);
export interface ImageProps extends BoxProps {
  image: Image;
}

export const ImageItem: React.FC<ImageProps> = ({ image, ...props }) => {
  const classes = useStyles();
  // const { language: lang } = useSelector(AppSelector.getState);
  // const { t: tCommon } = useTranslation("common");
  return (
    <Box {...props}>
      <Card>
        <CardActionArea>
          <img
            className={clsx(classes.images, {
              [classes.pngImages]: image.format === "png"
            })}
            src={image.url}
            alt={image.name}
          />
          <ListItem>
            <ListItemIcon>
              <PictureIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: "subtitle1" }}>
              {image.name}
            </ListItemText>
          </ListItem>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
          ></Typography>
        </CardActionArea>
      </Card>
    </Box>
  );
};
