export enum AppActionTypes {
  ENQUEUE_SNACKBAR = "@@App enqueue snackbar",
  CLOSE_SNACKBAR = "@@App close snackbar",
  REMOVE_SNACKBAR = "@@App remove snackbar",
  CHANGE_THEME = "@@App change theme",
  SET_LANGUAGE = "@@App set language",
  OPEN_MENU = "@@App open drawer",
  IS_ON_BACKOFFICE = "@@App is on backoffice",
  IS_ON_BOARDING = "@@App is on boarding",
  SET_USE_LEGACY_FETCH = "@App set use legacy fetch",
  SET_USE_LEGACY_STATUS = "@App set use legacy status",
  GET_USE_LEGACY_FETCH = "@App get use legacy fetch",
  GET_USE_LEGACY_STATUS = "@App get use legacy status"
}
