export enum ProjectActionsTypes {
  PROJECT_READ = "@@PROJECT read project",
  PROJECT_READ_ONE = "@@PROJECT read one project",
  PROJECT_REMOVE_ONE = "@@PROJECT remove one project",
  PROJECT_REMOVE_ONE_STATUS = "@@PROJECT remove one project status",
  PROJECT_UPDATE = "@@PROJECT update project",
  PROJECT_UPDATE_STATUS = "@@PROJECT update project status",
  PROJECT_CREATE = "@@PROJECT create project",
  PROJECT_CREATE_STATUS = "@@PROJECT create project status",
  PROJECT_UPDATE_STATUS_VIDEO = "@@PROJECT update status video",
  PROJECT_UPDATE_STATUS_VIDEO_STATUS = "@@PROJECT update status video status",
  PROJECT_UPLOAD_VIDEO = "@@PROJECT upload video"
}
