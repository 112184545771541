import { createSelector } from "reselect";
import { CompanySetting } from "../../entities/company-setting";
import { RootState } from "../store";
import { DomainAdapter } from "./reducer";

export const DomainSelector = DomainAdapter.getSelectors(
  (state: RootState) => state.domains
);

export const getState = createSelector(
  (state: RootState) => state,
  state => state.domains
);

export const getUserDomain = createSelector(
  getState,
  domain => domain.settings as CompanySetting
);
