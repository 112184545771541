import React, { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { Box, Grid } from "@material-ui/core";
import {
  MyGalleryAppBar,
  MyGalleryAppBarProps
} from "./gallery/MyGalleryAppBar";
import { MyAudio } from "./MyAudio";
import { Audio } from "../../reducers/audios/entity";

export interface MyAudioListProps {
  title?: string;
  audios: Audio[];
  multiple?: boolean;
  onAudiosSelected?: (audios: Audio | Audio[]) => void;
  audiosSelected?: Audio | Audio[];
  myGalleryAppBarProps?: MyGalleryAppBarProps;
}
export const MyAudioList: React.FC<MyAudioListProps> = ({
  audios,
  onAudiosSelected,
  audiosSelected,
  multiple,
  myGalleryAppBarProps
}) => {
  const [searchAudio, setSearchAudio] = useState(audios);
  const [search, setSearch] = useState("");

  const filterAudio = useCallback(
    debounce((search: string) => {
      if (!search) {
        return setSearchAudio(audios);
      }
      setSearchAudio(
        audios.filter(i => i.name.toLowerCase().includes(search.toLowerCase()))
      );
    }, 200),
    [audios]
  );

  const onSearchChange = (search: string) => {
    setSearch(search);
  };

  const onSelection = (audio: Audio) => {
    if (onAudiosSelected) {
      if (!audiosSelected) {
        return onAudiosSelected(multiple ? [audio] : audio);
      }
      if (multiple && Array.isArray(audiosSelected)) {
        const audioExistIndex = (audiosSelected || []).findIndex(
          i => i.id === audio.id
        );
        if (audioExistIndex === -1)
          onAudiosSelected([...(audiosSelected || []), audio]);
        else
          onAudiosSelected(
            (audiosSelected || []).filter(i => i.id !== audio.id)
          );
      } else {
        onAudiosSelected(audio);
      }
    }
  };

  useEffect(() => {
    if (!search) {
      setSearchAudio(audios);
    } else {
      filterAudio(search);
    }
  }, [filterAudio, audios, search]);

  return (
    <Box>
      <MyGalleryAppBar
        mySearchInputProps={{ onChange: onSearchChange, value: search }}
        toolbarProps={{ style: { marginRight: 28 } }}
        {...myGalleryAppBarProps}
      />
      <Grid container>
        {searchAudio.map(audio => {
          const isSelected =
            multiple && Array.isArray(audiosSelected)
              ? audiosSelected.map(i => i.id).includes(audio.id)
              : audiosSelected
              ? (audiosSelected as Audio).id === audio.id
              : false;

          return (
            <Grid item key={audio.id} xs={12} sm={4} md={3}>
              <MyAudio
                audio={audio}
                isSelected={isSelected}
                onClick={() => onSelection(audio)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
