import { PayloadAction } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ReducerMethods } from "../../utils/reducers/methods";
import { CompanyActions } from "../companies/reducer";
import { UserActions } from "../users/reducer";

export interface ItemState {
  [k: string]: {
    [FetchingStatus.PENDING]: any[];
  };
}

export const ItemInitialState: ItemState = {
  [CompanyActions.removeCollaborator.typePrefix]: {
    [FetchingStatus.PENDING]: []
  },
  [CompanyActions.addCollaborator.typePrefix]: {
    [FetchingStatus.PENDING]: []
  },
  [UserActions.removeFontConfig.typePrefix]: {
    [FetchingStatus.PENDING]: []
  }
};

const addItem = <T>(state: ItemState, name: string, item: string) => {
  state[name][FetchingStatus.PENDING].push(item);
};
const removeItem = <T>(state: ItemState, name: string, item: string) => {
  ReducerMethods.removeByIdMutate(state[name][FetchingStatus.PENDING], item);
};

const ItemSlice = createMySlice({
  name: "items",
  initialState: ItemInitialState,
  reducers: {
    init: (state, action: PayloadAction<string>) => {
      if (!state[action.payload]) {
        state[action.payload] = {
          [FetchingStatus.PENDING]: []
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(
      CompanyActions.removeCollaborator.pending,
      (state, action) =>
        addItem(
          state,
          CompanyActions.removeCollaborator.typePrefix,
          action.meta.arg.user.id
        )
    );
    builder.addCase(
      CompanyActions.removeCollaborator.fulfilled,
      (state, action) =>
        removeItem(
          state,
          CompanyActions.removeCollaborator.typePrefix,
          action.meta.arg.user.id
        )
    );
    builder.addCase(
      CompanyActions.removeCollaborator.rejected,
      (state, action) =>
        removeItem(
          state,
          CompanyActions.removeCollaborator.typePrefix,
          action.meta.arg.user.id
        )
    );
    builder.addCase(CompanyActions.addCollaborator.pending, (state, action) =>
      addItem(
        state,
        CompanyActions.addCollaborator.typePrefix,
        action.meta.arg.user.id
      )
    );
    builder.addCase(CompanyActions.addCollaborator.fulfilled, (state, action) =>
      removeItem(
        state,
        CompanyActions.addCollaborator.typePrefix,
        action.meta.arg.user.id
      )
    );
    builder.addCase(CompanyActions.addCollaborator.rejected, (state, action) =>
      removeItem(
        state,
        CompanyActions.addCollaborator.typePrefix,
        action.meta.arg.user.id
      )
    );
    builder.addCase(UserActions.removeFontConfig.pending, (state, action) =>
      addItem(
        state,
        UserActions.removeFontConfig.typePrefix,
        action.meta.arg.fontConfig.id
      )
    );
    builder.addCase(UserActions.removeFontConfig.fulfilled, (state, action) =>
      removeItem(
        state,
        UserActions.removeFontConfig.typePrefix,
        action.meta.arg.fontConfig.id
      )
    );
    builder.addCase(UserActions.removeFontConfig.rejected, (state, action) =>
      removeItem(
        state,
        UserActions.removeFontConfig.typePrefix,
        action.meta.arg.fontConfig.id
      )
    );
  }
});

export const ItemReducer = ItemSlice.reducer;
export const ItemActions = ItemSlice.actions;
