import { CircularProgress, FormControlLabel, Switch } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../../../reducers/app/reducer";
import { AppSelector } from "../../../../reducers/app/selector";
import { FetchingStatus } from "../../../../utils/reducers/fetchingStatus";

export const UseLegacy: React.FC<{ onLegacyChange?: Function }> = ({
  onLegacyChange
}) => {
  const { useLegacy, setUseLegacyStatus, getUseLegacyStatus } = useSelector(
    AppSelector.getState
  );
  const dispatch = useDispatch();
  const handleUseLegacy = () => {
    dispatch(AppActions.setUseLegacy(!useLegacy));
    onLegacyChange && onLegacyChange();
  };
  const isLoading =
    setUseLegacyStatus === FetchingStatus.PENDING ||
    getUseLegacyStatus === FetchingStatus.PENDING;
  return (
    <FormControlLabel
      control={
        <div style={{ position: "relative" }}>
          <Switch
            checked={useLegacy}
            onChange={handleUseLegacy}
            disabled={isLoading}
            value={useLegacy}
          />
          {isLoading && (
            <CircularProgress
              size={24}
              thickness={4}
              color="primary"
              style={{
                animationDuration: "500ms",
                position: "absolute",
                top: 8,
                right: useLegacy ? 7 : 27
              }}
            />
          )}
        </div>
      }
      label="Legacy"
    />
  );
};
