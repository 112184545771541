import {
  Dialog,
  DialogProps,
  useMediaQuery,
  useTheme,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
  Slide
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    closeButton: {
      zIndex: theme.zIndex.modal,
      position: "absolute",
      right: 0,
      top: 0
    }
  })
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export interface MyDialogProps extends DialogProps {}
export const MyDialog: React.FC<MyDialogProps> = ({ onClose, ...props }) => {
  const [showChildren, setShowChildren] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <Dialog
      TransitionComponent={Transition}
      onEntering={() => setShowChildren(true)}
      onExit={() => setShowChildren(false)}
      fullScreen={fullScreen}
      maxWidth="md"
      {...props}
      onClose={onClose}
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => onClose && onClose({}, "escapeKeyDown")}
      >
        <CloseIcon />
      </IconButton>
      {showChildren ? props.children : null}
    </Dialog>
  );
};
