import { object, string, StringSchema } from "yup";
import { Languages } from "../function/jsonTranslator";
export const JsonTranslatorSchema = (required?: boolean) =>
  object({
    "fr-FR": string()
      .ensure()
      .when("$lang", (lang: string, schema: StringSchema) => {
        if (lang === Languages.FR && required) return schema.required();
        else if (required) return schema.required();
        return schema;
      }),
    "en-US": string()
      .ensure()
      .when("$lang", (lang: string, schema: StringSchema) => {
        if (lang === Languages.EN && required) return schema.required();
        else if (required) return schema.required();
        return schema;
      })
  }).transform(val => (!val ? { "fr-FR": "", "en-US": "" } : val));
