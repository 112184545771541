import React from "react";
import FlagIconFactory, { FlagIconProps } from "react-flag-icon-css";
import { Languages } from "../../utils/function/jsonTranslator";
import { Box } from "@material-ui/core";

export const Flag = FlagIconFactory(React, { useCssModules: false });

interface MyFlagProps extends FlagIconProps {
  code: Languages;
}

export const MyFlag: React.FC<MyFlagProps> = ({ code, ...rest }) => {
  const flagCode = {
    [Languages.EN]: "us",
    [Languages.FR]: "fr"
  };
  return (
    <Box clone maxHeight={20}>
      <Flag code={flagCode[code]} {...rest} />
    </Box>
  );
};
