import { yupResolver } from "@hookform/resolvers";
import { Box } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MyButton } from "../../../components/common/MyButton";
import { MyForm } from "../../../components/common/MyForm2";
import { MyTextField } from "../../../components/common/MyForm2/MyTextField";
import { AuthenticationActions } from "../../../reducers/authentication/reducer";
import { getAuthenticationState } from "../../../reducers/authentication/selector";
import { useAppDispatch } from "../../../reducers/store";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import {
  ForgetPasswordSchema,
  ForgetPasswordValues
} from "./ForgetPassword.schema";

export interface SignInProps {
  onTabAuthenticationChange: (
    e: React.ChangeEvent<any> | null,
    newValue: number
  ) => void;
}

export const ForgetPassword: React.FC<SignInProps> = ({
  onTabAuthenticationChange
}) => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const { forgetPasswordStatus } = useSelector(getAuthenticationState);
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(ForgetPasswordSchema)
  });

  const onSubmit = (values: ForgetPasswordValues) => {
    dispatch(AuthenticationActions.forgetPassword(values)).then(() =>
      onTabAuthenticationChange(null, 0)
    );
  };

  return (
    <>
      <MyForm onSubmit={onSubmit} methods={methods}>
        <MyTextField
          required
          autoComplete="email"
          label="Email"
          autoFocus
          name="email"
        />
        <Box clone mt={1}>
          <MyButton
            capitalize
            loading={forgetPasswordStatus === FetchingStatus.PENDING}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            {t("send")}
          </MyButton>
        </Box>
      </MyForm>
    </>
  );
};
