import { createSelector } from "reselect";
import { RootState } from "../store";
import { ProfilValues } from "../../pages/MySpace/Profil/schema";
import { User } from "../../entities/user";

export const getAuthenticationState = createSelector(
  (state: RootState) => state,
  state => state.authentication
);

export const getCurrentUser = createSelector(
  getAuthenticationState,
  auth => auth.user as User
);

export const getUserDataForProfilForm = createSelector(
  getCurrentUser,
  user =>
    ({
      username: user.username,
      email: user.email,
      firstname: user.profile?.firstname,
      lastname: user.profile?.lastname,
      companyName: user.profile?.companyName,
      newsletter: user.profile?.newsletter
    } as ProfilValues)
);
