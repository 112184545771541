import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface ConditionFailComponentProps {}

export const ConditionFailComponent: React.FC<ConditionFailComponentProps> = () => {
  const { t } = useTranslation("components");
  return (
    <Typography style={{ marginTop: 5, textAlign: "center", fontSize: "15px" }}>
      {t("dynamicElement.conditionFailComponent.title")}
    </Typography>
  );
};
