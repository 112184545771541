import { Box, Typography } from "@material-ui/core";
import React from "react";
import { TimelineState } from "./Timeline";

export interface GridTimelineProps {
  timeline: TimelineState;
}

export const GridTimeline: React.FC<GridTimelineProps> = ({ timeline }) => {
  return (
    <>
      {Array.from(new Array(Math.round(timeline.totalDuration / 1000))).map(
        (_, i) => (
          <Box
            borderLeft="0.3px dashed lightgrey"
            borderRight="0.3px dashed lightgrey"
            key={i}
            position="absolute"
            bgcolor="transparent"
            zIndex={-1}
            bottom={0}
            left={i * timeline.ratio * 1000}
            height={200}
            width={timeline.ratio * 1000}
          >
            <Box
              clone
              position="relative"
              top={186}
              right={4}
              textAlign="right"
            >
              <Typography color="textSecondary" variant="body2">
                {i + 1}s
              </Typography>
            </Box>
          </Box>
        )
      )}
    </>
  );
};
