import { Box, BoxProps, createStyles, makeStyles } from "@material-ui/core";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoryFormState } from "../../../reducers/gtv/reducer";
import { GtvSelector } from "../../../reducers/gtv/selector";
import { GridTimeline } from "./GridTimeline";
import { StoryTimeline } from "./StoryTimeline";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      overflowX: "auto",
      overflowY: "hidden",
      height: 210,
      backgroundColor: "white"
    },
    background: {}
  })
);

export interface TimelineProps extends BoxProps {}

export interface TimelineState {
  width?: number;
  totalDuration: number;
  ratio: number;
}

export const Timeline: React.FC<TimelineProps> = ({ ...rest }) => {
  const classes = useStyles();
  const timelineBoxRef = createRef<HTMLDivElement>();
  const [timeline, setTimeline] = useState<TimelineState | undefined>();
  const state = useSelector(GtvSelector.getState);

  const getTotalDuration = useCallback(() => {
    return state.stories.reduce(
      (prev, curr) =>
        (prev +=
          curr.shots && curr.shots.length
            ? curr.shots.reduce(
                (prev, curr) => (prev += curr.values.recommendedDuration || 0),
                0
              )
            : 0),
      0
    );
  }, [state.stories]);

  const getStoryDuration = (story: StoryFormState) => {
    return story.shots && story.shots.length
      ? story.shots.reduce(
          (prev, curr) => (prev += curr.values.recommendedDuration || 0),
          0
        )
      : 0;
  };

  const getTimelineWidth = useCallback(() => {
    if (timelineBoxRef.current) {
      const totalDuration = getTotalDuration();
      const width = timelineBoxRef.current.clientWidth;
      const ratio = width / totalDuration;
      if (totalDuration !== timeline?.totalDuration || width !== timeline.width)
        setTimeline({
          width,
          totalDuration,
          ratio: ratio > 0.05 ? ratio : 0.05
        });
    }
  }, [getTotalDuration, timeline, timelineBoxRef]);

  useEffect(() => {
    getTimelineWidth();
  }, [getTimelineWidth]);

  return (
    <Box clone py="14px" {...rest}>
      <div className={classes.root} ref={timelineBoxRef}>
        {timeline && (
          <>
            <GridTimeline timeline={timeline} />
            <Box display="inline-flex" height={170} flexWrap="nowrap">
              {state.stories.map((story, index) => (
                <StoryTimeline
                  story={story}
                  index={index}
                  key={index}
                  width={timeline.ratio * getStoryDuration(story)}
                />
              ))}
            </Box>
          </>
        )}
      </div>
    </Box>
  );
};
