import { Typography, createStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "./MyPage";
import { makeStyles } from "@material-ui/styles";
import Logo from "../../assets/logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: 350,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 250
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 200
      }
    }
  })
);

export const MyConstructionPage: React.FC = () => {
  const { t } = useTranslation("components");
  const classes = useStyles();
  return (
    <Page center>
      <img alt="enterprise-logo" src={Logo} className={classes.logo} />
      <Typography color="textSecondary" align="center">
        {t("my-construction-page.title")}
      </Typography>
    </Page>
  );
};
