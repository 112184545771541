import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import map from "lodash/map";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { mixed, object, string } from "yup";
import { AssetsType } from "../../../entities/asset";
import { WatermarkPosition } from "../../../entities/watermark";
import { AppSelector } from "../../../reducers/app/selector";
import { jsonTranslator } from "../../../utils/function/jsonTranslator";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { MyButton } from "../MyButton";
import { MyDialog } from "../MyDialog";
import { MyForm } from "../MyForm2";
import { MyAutocompleteField } from "../MyForm2/MyAutocompleteField";

export interface UploadCompanyWatermarkButtonProps extends ButtonProps {
  assetType: AssetsType;
  loadingStatus?: FetchingStatus;
  onSubmitAsset: (value: FormData, filesArray?: string[]) => void;
}

const AcceptExtension = {
  [AssetsType.IMAGE]: ".png,.jpg,.jpeg,.gif",
  [AssetsType.VIDEO]: ".mp4,.m4v",
  [AssetsType.AUDIO]: ".mp3,.m4a"
};
const LabelByType = {
  [AssetsType.IMAGE]: "UploadAssetButton.watermark-upload",
  [AssetsType.VIDEO]: "UploadAssetButton.video-upload",
  [AssetsType.AUDIO]: "UploadAssetButton.audio-upload"
}; 

export const UploadWatermarkButton: React.FC<UploadCompanyWatermarkButtonProps> = ({
  assetType,
  onSubmitAsset,
  loadingStatus,
  ...buttonProps
}) => {
  const { t } = useTranslation("components");
  const { t: tYup } = useTranslation("yup");
  const { t: tCommon } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const { language } = useSelector(AppSelector.getState);
  const methods = useForm<{
    position: WatermarkPosition;
    files: FileList | null;
  }>({
    resolver: yupResolver(
      object({
        position: string()
          .oneOf([
            WatermarkPosition.TOP_RIGHT,
            WatermarkPosition.TOP_LEFT,
            WatermarkPosition.BOTTOM_RIGHT,
            WatermarkPosition.BOTTOM_LEFT
          ])
          .required(),
        files: mixed()
          .required()
          .test("minFiles", tYup("test.min-files"), val => val.length > 0)
      })
    )
  });

  const watermarkPositionOptions = [
    {
      value: WatermarkPosition.TOP_RIGHT,
      label: jsonTranslator(
        { "fr-FR": "En haut à droite", "en-US": "Top right" },
        language
      )
    },
    {
      value: WatermarkPosition.TOP_LEFT,
      label: jsonTranslator(
        { "fr-FR": "En haut à gauche", "en-US": "Top left" },
        language
      )
    },
    {
      value: WatermarkPosition.BOTTOM_RIGHT,
      label: jsonTranslator(
        { "fr-FR": "En bas à droite", "en-US": "Bottom right" },
        language
      )
    },
    {
      value: WatermarkPosition.BOTTOM_LEFT,
      label: jsonTranslator(
        { "fr-FR": "En bas à gauche", "en-US": "Bottom left" },
        language
      )
    }
  ];

  const onSubmit = (v: {
    position: WatermarkPosition;
    files: FileList | null;
  }) => {
    if (!v.files || !v.files.length) {
      return;
    }
    const formData = new FormData();
    if (v.position) formData.append("position", v.position);
    if (v.files) formData.append("watermark", v.files[0]);
    onSubmitAsset(formData, fileName.split(", "));
  };

  const onCloseDialog = () => {
    setFileName(map(methods.getValues().files, f => f.name).join(", "));
  };

  useEffect(() => {
    if (loadingStatus === FetchingStatus.SUCCESS) {
      setOpen(false);
      setFileName('');
    }
  }, [loadingStatus]);

  return (
    <>
      <MyButton onClick={() => setOpen(true)} {...buttonProps} />
      <MyDialog open={open} 
      onClose={() => {
        setOpen(false);
        setFileName('');
      }}>
        <MyForm methods={methods} onSubmit={onSubmit}>
          <DialogTitle>{t(LabelByType[assetType])}</DialogTitle>
          <DialogContent onInput={onCloseDialog} style={{ minWidth: 300 }}>
            <input
              hidden
              name="files"
              type="file"
              ref={methods.register}
              accept={AcceptExtension[AssetsType.IMAGE]}
              id="upload-file"
              multiple={false}
            />
            <Box alignItems="center" display="flex">
              <label htmlFor="upload-file">
                <Button variant="contained" component="span">
                  {tCommon("upload-file")}
                </Button>
              </label>
              {methods.getValues().files && (
                <Box ml={1}>
                  <Typography>{fileName}</Typography>
                </Box>
              )}
            </Box>
            <ErrorMessage
              name="files"
              errors={methods.errors}
              render={({ message }) => (
                <Typography color="error">{message}</Typography>
              )}
            />
            <MyAutocompleteField
              name="position"
              label="Position"
              options={watermarkPositionOptions}
            />
          </DialogContent>
          <DialogActions>
            <MyButton
              fullWidth
              color="primary"
              loading={loadingStatus === FetchingStatus.PENDING}
              type="submit"
              variant="contained"
              size="large"
            >
              {tCommon("upload")}
            </MyButton>
          </DialogActions>
        </MyForm>
      </MyDialog>
    </>
  );
};
