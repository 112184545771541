import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ScenarioUserSharedTypes } from "./action";
import * as Api from "./api";
import { ScenarioUserShared } from "./entity";

export const ScenarioUserSharedAdapter = createEntityAdapter<
  ScenarioUserShared
>();

export interface ScenarioUserSharedState
  extends EntityState<ScenarioUserShared> {
  deleteOne: FetchingStatus;
}

export const ScenarioUserSharedInitialState: ScenarioUserSharedState = {
  entities: {},
  ids: [],
  deleteOne: FetchingStatus.NULL
};

const ScenarioUserSharedState = ScenarioUserSharedAdapter.getInitialState(
  ScenarioUserSharedInitialState
);

export const deleteOne = createMyAsyncThunk<
  ScenarioUserShared,
  { scenarioId: string; userId: string }
>(ScenarioUserSharedTypes.DELETE_ONE, Api.deleteOne, {
  onFailedMessage: "saga:scenario-user-shared.delete-one"
});

const ScenarioUserSharedSlice = createMySlice({
  name: "scenario-user-shared",
  initialState: ScenarioUserSharedState,
  adapter: ScenarioUserSharedAdapter,
  asyncActions: [
    {
      action: deleteOne,
      statusName: "deleteOne",
      onSuccess: ScenarioUserSharedAdapter.removeOne
    }
  ],
  reducers: {}
});

export const ScenarioUserSharedReducer = ScenarioUserSharedSlice.reducer;
export const ScenarioUserSharedActions = {
  deleteOne,
  ...ScenarioUserSharedSlice.actions
};
