export const saveToken = (token: string) => {
  try {
    localStorage.setItem("token", token);
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e);
  }
};

export const removeToken = () => {
  try {
    localStorage.removeItem("token");
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e);
  }
};

export const getToken = () => {
  try {
    const token = localStorage.getItem("token");
    return token || undefined;
  } catch (e) {
    return undefined;
  }
};
