import MaterialTable, { MaterialTableProps } from "material-table";
import React from "react";
import { useTranslation } from "react-i18next";
import { MyTableToolbar } from "./components/Toolbar";

export interface MyTableProps extends MaterialTableProps<any> {}

export const MyTable: React.FC<MyTableProps> = props => {
  const { t } = useTranslation("components");

  return (
    <MaterialTable
      options={{
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        emptyRowsWhenPaging: false,
        paginationType: "stepped",
        headerStyle: { backgroundColor: "transparent" }
      }}
      style={{ width: "100%" }}
      components={{
        Toolbar: MyTableToolbar
      }}
      localization={{
        body: {
          emptyDataSourceMessage: t(
            "myMaterialTable.body.emptyDataSourceMessage"
          )
        },
        toolbar: {
          searchPlaceholder: t("myMaterialTable.toolbar.searchPlaceholder")
        },
        pagination: {
          labelRowsSelect: t("myMaterialTable.pagination.labelRowsSelect")
        }
      }}
      {...props}
    />
  );
};
