import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

interface ErrorComponentProps {}

export const ErrorComponent: React.FC<ErrorComponentProps> = () => {
  const { t } = useTranslation("components");
  return <Typography>{t("dynamicElement.errorComponent.title")}</Typography>;
};
