import { RootState } from "../store";
import { VideoAdapter } from "./reducer";
import { createSelector } from "reselect";
import { User } from "../../entities/user";
import { ActivationCodeSelector } from "../activation-code/selector";

const AdapterSelector = VideoAdapter.getSelectors(
  (state: RootState) => state.videos
);

const getState = createSelector(
  (state: RootState) => state,
  state => state.videos
);

const getPremium = createSelector(AdapterSelector.selectAll, videos =>
  videos.filter(i => i.premium)
);

const getUserVideos = (user: User) =>
  createSelector(AdapterSelector.selectAll, videos =>
    videos.filter(v => v.userId === user.id)
  );
const getUserVideosFromActivationCode = (user: User) =>
  createSelector(
    AdapterSelector.selectAll,
    ActivationCodeSelector.selectAll,
    (videos, activationCodes) =>
      activationCodes
        .map(ac =>
          videos.filter(
            v =>
              ac.videoIds.includes(v.id) &&
              ac.userIds.includes(user.id) &&
              new Date() < new Date(ac.end) &&
              new Date() > new Date(ac.start)
          )
        )
        .flat()
  );

const getUserVideosByGroup = (user: User) =>
  createSelector(
    getUserVideos(user),
    getUserVideosFromActivationCode(user),
    (owned, activationCode) => ({ owned, activationCode })
  );

export const VideoSelector = {
  ...AdapterSelector,
  getState,
  getPremium,
  getUserVideosByGroup
};
