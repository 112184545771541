import RemoveIcon from "@material-ui/icons/HighlightOff";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExtraCompanyProps } from "..";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { MyTable } from "../../../components/common/MyTable";
import { User } from "../../../entities/user";
// import { getCurrentUser } from "../../../reducers/authentication/selector";
import { CompanyActions } from "../../../reducers/companies/reducer";
import { CompanySelector } from "../../../reducers/companies/selector";
import { ItemSelector } from "../../../reducers/items/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { CompanySearchCollaborator } from "./SearchCollaborator";
import { RoleNames } from "../../../reducers/roles/entity";
import { MyButton } from "../../../components/common/MyButton";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { useTranslation } from "react-i18next";

export interface CompanyCollaboratorsProps extends ExtraCompanyProps {}

export const CompanyCollaborators: React.FC<CompanyCollaboratorsProps> = ({
  company,
  isOwner
}) => {
  // const { t } = useTranslation("company");
  // const currentUser = useSelector(getCurrentUser);
  const companyRemoveCollaboratorPending = useSelector(
    ItemSelector.getItemsPendingByAction(
      CompanyActions.removeCollaborator.typePrefix
    )
  );
  const dispatch = useDispatch();
  const [openAddCollaborator, setOpenAddCollaborator] = useState(false);
  const { readCollaboratorsStatus } = useSelector(CompanySelector.getState);
  let collaborators: User[] = [];
  const currentUser = useSelector(getCurrentUser);
  const { t } = useTranslation("company");

  // if (company.user) {
  //   collaborators.push(company.user);
  // }

  collaborators = collaborators.concat(
    useSelector(CompanySelector.getCollaborators(company))
  );
  const removeCollaborator = (user: User) => {
    dispatch(CompanyActions.removeCollaborator({ user, company }));
  };

  return (
    <>
      <CompanySearchCollaborator
        company={company}
        open={openAddCollaborator}
        onClose={() => setOpenAddCollaborator(false)}
      />
      {(isOwner ||
        (currentUser.role &&
          [RoleNames.ADMIN, RoleNames.GOD].includes(
            currentUser.role.name
          ))) && (
        <MyButton
          color="primary"
          variant="contained"
          onClick={() => setOpenAddCollaborator(true)}
        >
          {t("add-collaborator.title")}
        </MyButton>
      )}
      <DynamicElement<User[]>
        data={collaborators}
        actions={{
          status: readCollaboratorsStatus,
          action: CompanyActions.readCollaborators(company)
        }}
        showCondition={
          collaborators.length > 0 &&
          readCollaboratorsStatus === FetchingStatus.SUCCESS
        }
      >
        {users => {
          const data = users.map(c => ({
            id: c.id,
            // username: c.username,
            email: c.email
          }));
          return (
            <MyTable
              data={data}
              columns={[
                {
                  hidden: true,
                  field: "id"
                },
                // {
                //   title: tCommon("username"),
                //   field: "username"
                // },
                {
                  title: "Email",
                  field: "email"
                },
                {
                  render: user => {
                    const loading = companyRemoveCollaboratorPending.includes(
                      user.id
                    );
                    return (isOwner || (currentUser.role && [RoleNames.ADMIN, RoleNames.GOD].includes(currentUser.role.name))) ? 
                    ( 
                      <MyIconButton
                        loading={loading}
                        onClick={() => removeCollaborator(user)}
                      >
                        <RemoveIcon />
                      </MyIconButton>
                    ) : null;
                  }
                }
              ]}
            />
          );
        }}
      </DynamicElement>
    </>
  );
};
