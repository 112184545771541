import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  ButtonGroup,
  Container,
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider
} from "@material-ui/core";
import { default as ProfilIcon } from "@material-ui/icons/AccountCircleOutlined";
import ReplyIcon from "@material-ui/icons/Reply";
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyButton } from "../../components/common/MyButton";
import { MyTopBar } from "../../components/common/MyTopBar";
import { Language } from "../../components/common/MyTopBar/components/Language";
import { AppActions } from "../../reducers/app/reducer";
import { AppBarActions } from "../../reducers/appbar/reducer";
import { getCurrentUser } from "../../reducers/authentication/selector";
import { OnBoardingActions } from "../../reducers/on-boarding/reducer";
import { getOnBoardingState } from "../../reducers/on-boarding/selector";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { HOST } from "../../utils/request";
import Logo from "./assets/makidoo-logo.png";
import { OnBoardingCarousel } from "./components/Carousel";
import { OnBoardingLoader } from "./components/Loader";
import { LogoFrame } from "./components/LogoFrame";
import { OnBoardingVideoList } from "./components/VideoList";
import { OnBoardingVideoLogoPlacement } from "./components/VideoLogoPlacement";
import { OnBoardingSchema } from "./schema";
interface OnBoardingPageProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#37aae1"
    },
    container: {
      minHeight: "100%",
      // paddingTop: 64 + 100,
      [theme.breakpoints.down("sm")]: {
        // paddingTop: 56
      }
    },
    header: {
      height: 140,
      paddingLeft: 200,
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 340
      },
      [theme.breakpoints.down("lg")]: {
        paddingLeft: 240
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 190
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0
      }
    },
    title: {
      width: "100%",
      marginLeft: theme.spacing(8),
      textAlign: "left",
      fontWeight: "bold",
      [theme.breakpoints.down("lg")]: {
        marginLeft: 80
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 90
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        marginLeft: 0
      }
    },
    videoList: {},
    backgroundColor: {
      width: "100%",
      height: "100vh",
      position: "fixed",
      zIndex: -2,
      top: 0
    }
  })
);

export const OnBoardingPage: React.FC<OnBoardingPageProps> = () => {
  const user = useSelector(getCurrentUser);
  const classes = useStyles();
  const { t } = useTranslation("onBoarding");
  const { t: tCommon } = useTranslation("common");
  const dispatch = useDispatch();
  const methods = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: { email: user.email || "" },
    resolver: yupResolver(OnBoardingSchema)
  });

  const {
    logoPath,
    logoWidth,
    videoNumber,
    generateStatus,
    videoUrl,
    step
  } = useSelector(getOnBoardingState);
  const onBoardingIsNotValid =
    !logoWidth ||
    !!!videoNumber ||
    !logoPath ||
    !!videoUrl ||
    (user.id ? false : !methods.formState.isValid) ||
    generateStatus === FetchingStatus.PENDING;

  const reset = useCallback(async () => {
    dispatch(OnBoardingActions.reset());
  }, [dispatch]);

  useEffect(() => {
    if (generateStatus === FetchingStatus.FAILED) {
      reset();
    }
  }, [generateStatus, reset]);

  useEffect(() => {
    dispatch(
      AppBarActions.setState({
        style: { backgroundColor: "transparent" }
      })
    );
    dispatch(AppActions.isOnBoarding(true));
    return () => {
      dispatch(AppActions.isOnBoarding(false));
    };
  }, [dispatch]);

  const onDropLogo = async (logo: File) => {
    dispatch(OnBoardingActions.setLogoPath(window.URL.createObjectURL(logo)));
    dispatch(OnBoardingActions.setStep(1));
  };

  const onSelectVideo = async (id: number) => {
    dispatch(OnBoardingActions.setVideoNumber(id));
    dispatch(OnBoardingActions.setStep(2));
  };

  const onSubmit = ({ email }: { email?: string }) => {
    if (!logoPath) {
      dispatch(
        AppActions.enqueueSnacbar({ message: t("no-file"), variant: "warning" })
      );
      return;
    }
    dispatch(OnBoardingActions.generateVideo(email));
  };

  return (
    <Box display="flex" minHeight="100%" className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <ThemeProvider
          theme={createMuiTheme({
            typography: {
              h4: { lineHeight: "54px" }
            },
            palette: {
              type: "dark" as "dark",
              primary: {
                main: "#ffffff"
              },
              secondary: {
                main: "#e16f37"
              }
            }
          })}
        >
          <MyTopBar
            leftActions={<img alt="logo" src={Logo} width={200} />}
            hideMenu
            rightActions={
              user.id || user.token ? (
                <ButtonGroup variant="contained" size="large" color="primary">
                  <Language />
                  <MyButton
                    variant="contained"
                    leftIcon={<ProfilIcon />}
                    to="/my-space"
                  >
                    {t("go-my-space")}
                  </MyButton>
                </ButtonGroup>
              ) : (
                <>
                  <ButtonGroup variant="contained" size="large" color="primary">
                    <MyButton
                      // rightIcon={<LaunchIcon />}
                      onClick={() => window.open("https://makidoo.io")}
                    >
                      {t("go-website")}
                    </MyButton>
                    <Language />
                    <MyButton
                      // leftIcon={<SignUpIcon />}
                      onClick={() =>
                        window.location.replace(
                          `${HOST}/authentication?authTab=1`
                        )
                      }
                    >
                      {t("go-sign-up")}
                    </MyButton>
                    <MyButton
                      variant="contained"
                      // leftIcon={<SignInIcon />}
                      onClick={() =>
                        window.location.replace(`${HOST}/authentication`)
                      }
                    >
                      {t("go-sign-in")}
                    </MyButton>
                  </ButtonGroup>
                </>
              )
            }
          />
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Box width={{ sm: "100%", md: "25%", xl: "18%" }}>
              <Box p={2} pl={4} maxWidth={320}>
                <LogoFrame
                  onBoardingIsNotValid={onBoardingIsNotValid}
                  formMethods={methods}
                  onGenerate={onSubmit}
                  onLogoDrop={onDropLogo}
                />
              </Box>
            </Box>
            <Box
              height={{ sm: "auto", md: 620 }}
              display="flex"
              width={{ sm: "100%", md: "75%" }}
              alignItems="center"
              justifyContent="center"
            >
              {generateStatus === FetchingStatus.PENDING ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <OnBoardingLoader />
                </Box>
              ) : videoUrl ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box clone maxWidth={1024} width="100%">
                    <video src={videoUrl} controls autoPlay />
                  </Box>
                  <Box clone mt={2}>
                    <MyButton
                      leftIcon={
                        <ReplyIcon
                          fontSize="large"
                          style={{ transform: "rotateY(180deg)" }}
                        />
                      }
                      variant="contained"
                      size="large"
                      href={videoUrl}
                    >
                      {tCommon("download")}
                    </MyButton>
                  </Box>
                </Box>
              ) : (
                <Box p={2} display="flex" justifyContent="center">
                  {step === 0 && <OnBoardingCarousel height={300} />}
                  {step === 1 && (
                    <OnBoardingVideoList
                      onSelectVideo={onSelectVideo}
                      width={870}
                    />
                  )}
                  {step === 2 && videoNumber && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <OnBoardingVideoLogoPlacement
                        minHeight={450}
                        videoIndexSelected={videoNumber}
                        logoPath={logoPath}
                      />
                      <Box clone mt={2}>
                        <MyButton
                          leftIcon={
                            <ReplyIcon
                              fontSize="large"
                              style={{ transform: "rotateY(180deg)" }}
                            />
                          }
                          variant="contained"
                          size="large"
                          onClick={async () => {
                            if (!logoWidth) {
                              dispatch(OnBoardingActions.setLogoWidth(200));
                            }
                            user.id
                              ? onSubmit({ email: undefined })
                              : await methods.handleSubmit(onSubmit)();
                          }}
                        >
                          {t("generate-video")}
                        </MyButton>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </Container>
    </Box>
  );
};
