import { RootState } from "../store";
import { FontAdapter } from "./reducer";
import { createSelector } from "reselect";

const AdapterSelector = FontAdapter.getSelectors(
  (state: RootState) => state.fonts
);

const getPublic = createSelector(AdapterSelector.selectAll, fonts =>
  fonts.filter(f => f.public)
);

export const FontSelector = { ...AdapterSelector, getPublic };
