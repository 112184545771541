import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ActivationCodeActionsTypes } from "./action";
import * as Api from "./api";
import { ActivationCode } from "./entity";

export interface ActivationCodeState extends EntityState<ActivationCode> {
  readStatus: FetchingStatus;
}

export const ActivationCodeInitialState: ActivationCodeState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL
};

export const ActivationCodeAdapter = createEntityAdapter<ActivationCode>();

const ActivationCodeAdapterState = ActivationCodeAdapter.getInitialState(
  ActivationCodeInitialState
);

export const read = createMyAsyncThunk<ActivationCode[]>(
  ActivationCodeActionsTypes.ACTIVATION_CODE_READ_ONE,
  Api.read
);

export const readOne = createMyAsyncThunk<ActivationCode, string>(
  ActivationCodeActionsTypes.ACTIVATION_CODE_READ_ONE,
  Api.readOneActivationCode
);

// Projects
const ActivationCodeSlice = createMySlice({
  name: "activation-code",
  initialState: ActivationCodeAdapterState,
  adapter: ActivationCodeAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ActivationCodeAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const ActivationCodeReducer = ActivationCodeSlice.reducer;
export const ActivationCodeActions = {
  ...ActivationCodeSlice.actions,
  read
};
