import {
  Card,
  CardHeader,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  Box,
  Divider
} from "@material-ui/core";
// import { green, grey, red } from "@material-ui/core/colors";
import UserIcon from "@material-ui/icons/AccountCircleOutlined";
import ProjectIcon from "@material-ui/icons/CameraRollOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Publish } from "@material-ui/icons";
import { debounce } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ExtraCompanyProps } from ".";
import { DynamicElement } from "../../components/common/DynamicElement";
import { MyIconButton } from "../../components/common/MyIconButton";
import { Page } from "../../components/common/MyPage";
import { MyTypography } from "../../components/common/MyTypography";
import { MyVideo } from "../../components/common/MyVideo";
import { AssetsType } from "../../entities/asset";
import { AppSelector } from "../../reducers/app/selector";
import { Project } from "../../reducers/projects/entity";
import { ProjectActions } from "../../reducers/projects/reducer";
import { ProjectSelector } from "../../reducers/projects/selector";
import { ScenarioSelector } from "../../reducers/scenarios/selector";
import { RootState, useAppDispatch } from "../../reducers/store";
import { VideoActions } from "../../reducers/videos/reducer";
import { VideoSelector } from "../../reducers/videos/selector";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CompanyActions } from "../../reducers/companies/reducer";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { push } from "connected-react-router";
import { KeyOption, matchSorter, rankings } from "match-sorter";
import { Video } from "../../reducers/videos/entity";
import { UploadVideoIconButton } from "../../components/common/UploadVideoIconButton";

export interface CompanyProjectProps extends ExtraCompanyProps {}
export const CompanyProject = ({ company }: CompanyProjectProps) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tProject } = useTranslation("project");
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { language: lang } = useSelector(AppSelector.getState);
  const { id: projectId } = useParams<{ id: string }>();
  const {
    projectStatus,
    project,
    videos,
    scenarios,
    scenarioStatus,
    uploadVideoStatus
  } = useSelector((state: RootState) => ({
    projectStatus: state.projects.readOneStatus,
    project: ProjectSelector.getProjectById(projectId)(state),
    videos: VideoSelector.selectAll(state),
    scenarios: ScenarioSelector.getCompanyScenarios(company)(state),
    scenarioStatus: state.companies.readScenariosStatus,
    uploadVideoStatus: state.projects.uploadVideoStatus
  }));
  const projectNameInputRef = useRef<HTMLInputElement>();
  const videoList = useMemo(
    () => videos.filter(v => v.projectId === project?.id),
    [project, videos]
  );
  const [filterValue, setFilterValue] = useState<Video[]>(videoList);
  const [statusfilter, setStatusFilter] = useState<string[]>([
    "",
    "VALIDATED",
    "REFUSED"
  ]);
  console.log(
    "🚀 ~ file: Project.tsx:87 ~ CompanyProject ~ setStatusFilter:",
    setStatusFilter
  );

  const SearchMutipleWords = (
    rows: Video[],
    filterValue: string[],
    keys: KeyOption<Video>[]
  ) => {
    if (!filterValue || !filterValue.length) {
      return rows;
    }

    return filterValue.reduceRight(
      (results, filterValue) =>
        matchSorter(results, filterValue, {
          keys,
          threshold: rankings.CONTAINS,
          keepDiacritics: true
        }),
      rows
    );
  };

  // const handleStatus = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newStatus: string[]
  // ) => {
  //   console.log(newStatus);
  //   setStatusFilter(newStatus);
  // };

  const scenarioOptions = useMemo(
    () =>
      scenarios.map(s => ({
        value: s.id,
        label: jsonTranslator(s.label, lang)
      })),
    [lang, scenarios]
  );

  const onProjectChange = useCallback(
    debounce((project: { name?: string; scenarioId?: string }) => {
      dispatch(
        ProjectActions.async.updateOne({
          id: projectId,
          project
        })
      );
    }, 1000),
    []
  );

  // const onVideoStatusChange = (videoId: string, status: AssetsStatus) => {
  //   dispatch(
  //     ProjectActions.async.updateVideoStatus({
  //       id: projectId,
  //       videoId,
  //       status: { status }
  //     })
  //   );
  // };

  const onDeleteVideo = (videoId: string) => {
    dispatch(VideoActions.async.removeOne(videoId));
  };

  const onDeleteProject = async () => {
    await dispatch(ProjectActions.async.removeOne(projectId));
    dispatch(push(`/company/${company.id}/projects`));
  };

  const filterOptions = createFilterOptions({
    stringify: (option: Video) =>
      option.user?.email + option.name + option.status // make it one for it
  });

  // const statusOptions = useMemo(
  //   () => [
  //     {
  //       value: "",
  //       label: "NO STATUS",
  //       style: {
  //         backgroundColor: grey[400],
  //         color: "white"
  //       }
  //     },
  //     {
  //       value: AssetsStatus.VALIDATED,
  //       label: AssetsStatus.VALIDATED,
  //       style: {
  //         backgroundColor: green[300],
  //         color: "white"
  //       }
  //     },
  //     {
  //       value: AssetsStatus.REFUSED,
  //       label: AssetsStatus.REFUSED,
  //       style: {
  //         backgroundColor: red[300],
  //         color: "white"
  //       }
  //     }
  //   ],
  //   []
  // );

  return (
    <Page>
      <DynamicElement<Project>
        data={project}
        actions={[
          {
            action: ProjectActions.async.readOne(projectId),
            status: projectStatus,
            refetch: true
          },
          {
            action: CompanyActions.readScenarios(company),
            status: scenarioStatus
          }
        ]}
        showCondition={[scenarioStatus, projectStatus].every(
          s => s === FetchingStatus.SUCCESS
        )}
      >
        {project => (
          <Page>
            <Box mb={{ xs: 1, sm: 2 }} display="flex" alignItems="center">
              <InputBase
                inputRef={projectNameInputRef}
                name="name"
                defaultValue={project.name}
                fullWidth
                onChange={e => onProjectChange({ name: e.target.value })}
                inputProps={{
                  style: {
                    fontSize: theme.typography.h4.fontSize
                  }
                }}
              />
              <>
                <MyIconButton
                  onClick={() => projectNameInputRef.current?.focus()}
                >
                  <EditOutlinedIcon fontSize="large" color="action" />
                </MyIconButton>
                <MyIconButton
                  menuProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right"
                    },
                    transformOrigin: {
                      horizontal: "right",
                      vertical: "top"
                    }
                  }}
                  menus={({ onClose }) => (
                    <MenuItem
                      dense={true}
                      onClick={() => {
                        onClose();
                        onDeleteProject();
                      }}
                    >
                      <ListItemIcon style={{ minWidth: 36 }}>
                        <DeleteIcon color="error" />
                      </ListItemIcon>
                      <Typography color="error">{tCommon("delete")}</Typography>
                    </MenuItem>
                  )}
                >
                  <MoreVertIcon fontSize="large" color="action" />
                </MyIconButton>
              </>
              <Box alignSelf="right">
                <UploadVideoIconButton
                  color="primary"
                  assetType={AssetsType.VIDEO}
                  loadingStatus={uploadVideoStatus}
                  onSubmitAsset={formData =>
                    dispatch(
                      ProjectActions.async.uploadVideo({ project, formData })
                    )
                  }
                >
                  <Publish fontSize="large" color="action" />
                </UploadVideoIconButton>
              </Box>
            </Box>
            <>
              <Box mb={{ xs: 1, sm: 2 }}>
                <Typography variant="subtitle1" paragraph>
                  {tCommon("scenario")}
                </Typography>
                <Autocomplete
                  defaultValue={scenarioOptions.find(
                    s => s.value === project.scenarioId
                  )}
                  onChange={(_, value) =>
                    onProjectChange({ scenarioId: value?.value })
                  }
                  getOptionLabel={option => option.label}
                  options={scenarioOptions}
                  style={{ width: 340 }}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
              <Box mb={{ xs: 1, sm: 2 }}>
                <Typography variant="subtitle1" paragraph>
                  {tProject("search")}
                </Typography>
                <Autocomplete
                  id="search-bar"
                  autoComplete
                  freeSolo
                  multiple
                  style={{ width: 460 }}
                  filterOptions={filterOptions}
                  getOptionLabel={option => {
                    if (typeof option === "string") return option;
                    return option.name;
                  }}
                  options={videoList}
                  onChange={(event, value) => {
                    const filter: string[] = [];
                    const videos: Video[] = [];

                    value.forEach(v => {
                      if (typeof v === "string") filter.push(v);
                      else videos.push(v);
                    });
                    const filteredArray: Video[] = SearchMutipleWords(
                      videoList,
                      filter,
                      ["name", "status", "user.email"]
                    );
                    setFilterValue(
                      filteredArray
                        .filter(a => !videos.includes(a))
                        .concat(videos)
                    );
                  }}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
              <Typography variant="subtitle1" paragraph>
                {tCommon("video")}
              </Typography>
              <Grid container spacing={2}>
                {[
                  ...(filterValue.length > 0
                    ? filterValue
                    : videoList || []
                  ).filter(v =>
                    statusfilter.length > 0
                      ? v.status
                        ? statusfilter.includes(v.status)
                        : statusfilter.includes("")
                      : v.id
                  )
                ]
                  .sort((a, b) =>
                    new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1
                  )
                  .map(v => {
                    return (
                      <Grid key={v.id} item xs={12} sm={6} lg={4}>
                        <Card style={{ height: 430 }}>
                          <CardHeader
                            action={
                              <MyIconButton
                                menuProps={{
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right"
                                  },
                                  transformOrigin: {
                                    horizontal: "right",
                                    vertical: "top"
                                  }
                                }}
                                menus={({ onClose }) => (
                                  <MenuItem
                                    dense={true}
                                    onClick={() => {
                                      onClose();
                                      onDeleteVideo(v.id);
                                    }}
                                  >
                                    <ListItemIcon style={{ minWidth: 36 }}>
                                      <DeleteIcon color="error" />
                                    </ListItemIcon>
                                    <Typography color="error">
                                      {tCommon("delete")}
                                    </Typography>
                                  </MenuItem>
                                )}
                              >
                                <MoreVertIcon />
                              </MyIconButton>
                            }
                            title={
                              <MyTypography
                                variant="h6"
                                leftIcon={<ProjectIcon />}
                              >
                                {v.name}
                              </MyTypography>
                            }
                            subheader={
                              <Typography color="textSecondary">
                                {new Date(v.createdAt).toLocaleDateString(
                                  lang,
                                  {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit"
                                  }
                                )}
                              </Typography>
                            }
                          />
                          <MyVideo
                            disableLabel
                            video={v}
                            height={280}
                            width="100%"
                          />
                          <List>
                            <ListItem>
                              <ListItemIcon>
                                <UserIcon />
                              </ListItemIcon>
                              <ListItemText primary={v.user?.email} />
                            </ListItem>
                          </List>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </>
            <Divider />
          </Page>
        )}
      </DynamicElement>
    </Page>
  );
};
