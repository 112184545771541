import React from "react";
import { RouteProps } from "react-router";
import { AuthenticationPage } from "../pages/Authentication";
import { CompanyPage } from "../pages/Company";
import { TutorialsPage } from "../pages/Tutorials";
import { HomePage } from "../pages/Home";
import { MySpace } from "../pages/MySpace";
import { OnBoardingPage } from "../pages/OnBoarding";

export interface MyRouteProps extends RouteProps {
  extraProps?: any;
  options?: {
    authenticated?: boolean;
    roles?: string[];
    toolbar?: {
      actions: React.ReactNode;
    };
    sidenav?: {
      show?: boolean;
      icon?: React.ReactElement;
      label?: React.ReactNode;
    };
    redirect?: string;
  };
  childRoutes?: MyRouteProps[];
  name: string;
}

export const RootRoutes: MyRouteProps[] = [
  {
    name: "home",
    path: "/",
    exact: true,
    component: HomePage
  },
  {
    name: "on-boarding",
    path: "/on-boarding",
    exact: true,
    component: OnBoardingPage
  },
  {
    name: "myspace",
    path: "/my-space",
    component: MySpace
  },
  {
    name: "authentication",
    path: "/authentication",
    exact: true,
    component: AuthenticationPage
  },
  {
    name: "company",
    path: "/company/:id",
    component: CompanyPage
  },
  {
    name: "tutorials",
    path: "/tutorials",
    exact: true,
    component: TutorialsPage
  }
];
