import { JsonTranslator } from "../utils/function/jsonTranslator";
import { Video } from "../reducers/videos/entity";
import { Image } from "../reducers/images/entity";
import { Story } from "./story";
import { ShotCategory } from "../reducers/shot-category/entity";

export type ShotLockProperties = keyof Shot;

export enum ShotType {
  WIDE = "WIDE",
  MEDIUM = "MEDIUM",
  CLOSE = "CLOSE"
}

export interface Shot {
  id: string;
  legacyId?: string;
  lockProperties: ShotLockProperties[];
  label: JsonTranslator;
  description?: JsonTranslator;
  instruction?: JsonTranslator;
  order: number;
  recommendedDuration: number;
  videoId?: string;
  video?: Video;
  type: ShotType;
  musicVolumeWithAudioMix?: number;
  videoVolumeWithAudioMix?: number;
  musicVolume?: number;
  videoVolume?: number;
  enabled: boolean;
  videoTutorialId?: string;
  storyId: string;
  shotCategoryId: string;
  shotCategory?: ShotCategory;
  videoTutorial?: Video;
  posterId?: string;
  poster?: Image;
  story?: Story;
}

export interface ShotDTO {
  id?: string;
  storyId?: string;
  lockProperties: ShotLockProperties[];
  label: JsonTranslator;
  description?: JsonTranslator;
  instruction?: JsonTranslator;
  order: number;
  recommendedDuration: number;
  videoId?: string;
  type?: ShotType;
  musicVolumeWithAudioMix?: number;
  videoVolumeWithAudioMix?: number;
  musicVolume?: number;
  videoVolume?: number;
  enabled: boolean;
  shotCategory?: ShotCategory;
  shotCategoryId: string;
  videoTutorialId?: string;
  posterId?: string;
}
