import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { MyDialog } from "../../../../components/common/MyDialog";
export interface SearchCompanyOpportunityProps extends DialogProps {}

export const SearchCompanyOpportunity: React.FC<SearchCompanyOpportunityProps> = ({
  ...props
}) => {
  const { t } = useTranslation("backoffice");
  return (
    <MyDialog {...props}>
      <DialogTitle>{t("collaboration.search")}</DialogTitle>
      <DialogContent>
        <DialogContentText>Opportunity</DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </MyDialog>
  );
};
