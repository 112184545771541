import { JsonTranslator } from "../../utils/function/jsonTranslator";

export enum RoleNames {
  GOD = "GOD",
  ADMIN = "ADMIN",
  SALES = "SALES",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE",
  PRODUCTION = "PRODUCTION",
  DIRECTION = "DIRECTION",
  DEV = "DEV"
}

export class Role {
  id!: string;
  name!: RoleNames;
  label!: JsonTranslator;
  description!: string;
}
