import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import map from "lodash/map";
import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { mixed, object, string } from "yup";
import { AssetsType } from "../../../entities/asset";
import { Category, CategoryNames } from "../../../entities/category";
import { AppSelector } from "../../../reducers/app/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { baseURL } from "../../../utils/request";
import { MyButton } from "../MyButton";
import { MyDialog } from "../MyDialog";
import { MyForm } from "../MyForm2";
import { MyAutocompleteField } from "../MyForm2/MyAutocompleteField";

export interface UploadCompanyAssetButtonProps extends ButtonProps {
  assetType: AssetsType;
  loadingStatus?: FetchingStatus;
  onSubmitAsset: (value: FormData, filesArray?: string[]) => void;
}

const AcceptExtension = {
  [AssetsType.IMAGE]: ".png,.jpg,.jpeg,.gif",
  [AssetsType.VIDEO]: ".mp4,.m4v",
  [AssetsType.AUDIO]: ".mp3,.m4a"
};
const LabelByType = {
  [AssetsType.IMAGE]: "UploadAssetButton.image-upload",
  [AssetsType.VIDEO]: "UploadAssetButton.video-upload",
  [AssetsType.AUDIO]: "UploadAssetButton.audio-upload"
};

export const UploadAssetButton: React.FC<UploadCompanyAssetButtonProps> = ({
  assetType,
  onSubmitAsset,
  loadingStatus,
  ...buttonProps
}) => {
  const { t } = useTranslation("components");
  const { t: tYup } = useTranslation("yup");
  const { t: tCommon } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const { language } = useSelector(AppSelector.getState);
  const [categoryRequired, setCategoryRequired] = useState<boolean>(false);
  const methods = useForm<{ categoryId: string; files: FileList | null }>(
    categoryRequired
      ? {
          resolver: yupResolver(
            object({
              categoryId: string().required(),
              files: mixed()
                .required()
                .test("minFiles", tYup("test.min-files"), val => val.length > 0)
            })
          )
        }
      : {
          resolver: yupResolver(
            object({
              categoryId: string(),
              files: mixed()
                .required()
                .test("minFiles", tYup("test.min-files"), val => val.length > 0)
            })
          )
        }
  );

  const getCategoryOptions = useMemo(() => {
    return {
      url: baseURL + "/categories",
      label: `label['${language}']`,
      value: "id",
      method: "get",
      withToken: true,
      handleOptions: (data: Category[]) =>
        data.filter(c => {
          switch (assetType) {
            case AssetsType.IMAGE:
              return [CategoryNames.PLANS, CategoryNames.THEMES].includes(
                c.name
              );
            case AssetsType.VIDEO:
              return [
                CategoryNames.GENERICS,
                CategoryNames.INTERTITLE,
                CategoryNames.THEMES
              ].includes(c.name);
            default:
              return c;
          }
        })
    };
  }, [assetType, language]);

  const onSubmit = (v: { categoryId: string; files: FileList | null }) => {
    if (!v.files || !v.files.length) {
      return;
    }
    const formData = new FormData();
    if (v.categoryId) formData.append("categoryId", v.categoryId);
    if (v.files) formData.append(assetType, v.files[0]);
    console.log();
    onSubmitAsset(formData, fileName.split(", "));
  };

  const onCloseDialog = () => {
    setFileName(map(methods.getValues().files, f => f.name).join(", "));
  };

  useEffect(() => {
    if (assetType === AssetsType.VIDEO || assetType === AssetsType.IMAGE) {
      setCategoryRequired(true);
    }
  }, [assetType]);

  useEffect(() => {
    if (loadingStatus === FetchingStatus.SUCCESS) {
      setOpen(false);
      setFileName('');
    }
  }, [loadingStatus]);

  return (
    <>
      <MyButton onClick={() => setOpen(true)} {...buttonProps} />
      <MyDialog open={open} onClose={() => {
        setOpen(false);
        setFileName('');
      }}>
        <MyForm methods={methods} onSubmit={onSubmit}>
          <DialogTitle>{t(LabelByType[assetType])}</DialogTitle>
          <DialogContent onInput={onCloseDialog} style={{ minWidth: 300 }}>
            <input
              hidden
              name="files"
              type="file"
              ref={methods.register}
              accept={AcceptExtension[assetType]}
              id="upload-file"
              multiple={false}
            />
            <Box alignItems="center" display="flex">
              <label htmlFor="upload-file">
                <Button variant="contained" component="span">
                  {tCommon("upload-file")}
                </Button>
              </label>
              {methods.getValues().files && (
                <Box ml={1}>
                  <Typography>{fileName}</Typography>
                </Box>
              )}
            </Box>
            <ErrorMessage
              name="files"
              errors={methods.errors}
              render={({ message }) => (
                <Typography color="error">{message}</Typography>
              )}
            />
            {assetType === AssetsType.VIDEO ||
            assetType === AssetsType.IMAGE ? (
              <MyAutocompleteField
                name="categoryId"
                label={tCommon("category")}
                async={() => getCategoryOptions}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <MyButton
              fullWidth
              color="primary"
              loading={loadingStatus === FetchingStatus.PENDING}
              type="submit"
              variant="contained"
              size="large"
            >
              {tCommon("upload")}
            </MyButton>
          </DialogActions>
        </MyForm>
      </MyDialog>
    </>
  );
};
