import { yupResolver } from "@hookform/resolvers";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle
} from "@material-ui/core";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyButton } from "../../../../components/common/MyButton";
import { MyDialog } from "../../../../components/common/MyDialog";
import { MyForm } from "../../../../components/common/MyForm2";
// import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { Company } from "../../../../reducers/companies/entity";
import { UserSelector } from "../../../../reducers/users/selector";
import { FetchingStatus } from "../../../../utils/reducers/fetchingStatus";
import { ScenarioActions } from "../../../../reducers/scenarios/reducer";
import { object, string } from "yup";
import { ScenarioSelector } from "../../../../reducers/scenarios/selector";
import { RootState } from "../../../../reducers/store";
import { MyAutocompleteField } from "../../../../components/common/MyForm2/MyAutocompleteField";
import { CompanyActions } from "../../../../reducers/companies/reducer";
export interface TransferScenarioProps extends DialogProps {}

export const TransferScenario: React.FC<TransferScenarioProps> = ({
  ...props
}) => {
  const { t } = useTranslation("backoffice");
  const dispatch = useDispatch();

  const scenarioSelected = useSelector(ScenarioSelector.getScenarioSelected);
  // const user = useSelector(getCurrentUser);
  const { transferScenarioStatus, companyList } = useSelector(
    (state: RootState) => ({
      transferScenarioStatus: state.scenarios.transferScenarioStatus,
      companyList: UserSelector.getCompanies()(state)
    })
  );
  const onSubmit = async (company: Company) => {
    if (scenarioSelected) {
      await dispatch(
        ScenarioActions.async.setScenarioOwnerToCompany({
          id: scenarioSelected.id,
          companyId: company.id
        })
      );
      console.log("dispatch ", company);
      await dispatch(CompanyActions.readImages(company));
      await dispatch(CompanyActions.readVideos(company));
      await dispatch(CompanyActions.readAudios(company));
    }
  };
  const methods = useForm({
    defaultValues: { id: "" },
    resolver: yupResolver(
      object({
        id: string()
          .required()
          .min(1)
      })
    )
  });

  const companyOptions = useMemo(
    () =>
      companyList
        .filter(company => company.id !== scenarioSelected?.companyId)
        .map(c => ({
          value: c.id,
          label: c.name
        })),
    [companyList, scenarioSelected]
  );

  return (
    <MyDialog {...props}>
      <MyForm methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{"Transfert Scénarios"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{}</DialogContentText>

          <MyAutocompleteField<false, false, true>
            name="id"
            options={companyOptions}
            getOptionLabel={option => option.label}
            style={{ width: 300 }}
            label={t("scenarios.select-company")}
          />
        </DialogContent>
        <DialogActions>
          <MyButton
            loading={transferScenarioStatus === FetchingStatus.PENDING}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {t("scenarios.transfer")}
          </MyButton>
        </DialogActions>
      </MyForm>
    </MyDialog>
  );
};
