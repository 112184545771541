import {
  Box,
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  useTheme
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
//import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import BackMySpaceIcon from "@material-ui/icons/KeyboardBackspace";
import MovieIcon from "@material-ui/icons/MovieOutlined";
import MusicIcon from "@material-ui/icons/MusicNote";
import PaletteIcon from "@material-ui/icons/PaletteOutlined";
import ImageIcon from "@material-ui/icons/PanoramaOutlined";
import ScenarioIcon from "@material-ui/icons/TheatersOutlined";
import GtvIcon from "@material-ui/icons/VideoCallOutlined";
import ProjectIcon from "@material-ui/icons/CameraRollOutlined";
import { makeStyles } from "@material-ui/styles";
import { mdiHandshake } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ExtraCompanyProps } from "..";
import { MyLink } from "../../../components/common/MyLink";
import { AppActions } from "../../../reducers/app/reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    avatarBox: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
      position: "relative",
      overflow: "hidden"
    },
    avatarBoxBackground: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    avatar: {
      border: `2px solid ${theme.palette.common.white}`,
      width: 80,
      height: 80,
      [theme.breakpoints.down("sm")]: {
        width: 60,
        height: 60
      }
    },
    menuActive: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    nestedMenu: {
      paddingLeft: theme.spacing(4)
    },
    backToMySpace: {
      position: "absolute",
      bottom: 0
    },
    menuList: {
      height: "100%",
      position: "relative"
    }
  })
);
export interface DrawerMenuProps {
  icon: React.ReactElement;
  label: string;
  path?: string;
  hidden?: boolean;
  inProgress?: boolean;
  children?: DrawerMenuProps[];
}
export interface MenusProps extends ExtraCompanyProps {}

export const CompanyMenus: React.FC<MenusProps> = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { t } = useTranslation("company");
  const [collapseMenu, setCollapseMenu] = useState<{
    [name: string]: boolean;
  }>({});
  const theme = useTheme();
  // const lang = useSelector(getLanguage);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menus: DrawerMenuProps[] = [
    // {
    //   icon: <HomeOutlinedIcon />,
    //   label: t("menus.home"),
    //   path: `${url}`,
    //   inProgress: true
    // },
    {
      icon: (
        <Icon path={mdiHandshake} size={1} fill={theme.palette.primary.main} />
      ),
      label: t("menus.collaborators"),
      path: `${url}/collaborators`
    },
    //{
    //  icon: <WorkIcon fontSize="default" />,
    //  label: t("menus.projects"),
    // path: `${url}/projects`
    //inProgress: true
    // },
    {
      icon: <PaletteIcon />,
      label: t("menus.assets"),
      path: `${url}/assets`,
      children: [
        {
          icon: <MovieIcon />,
          label: t("menus.videos"),
          path: `${url}/assets/videos`
        },
        {
          icon: <MusicIcon />,
          label: t("menus.musics"),
          path: `${url}/assets/musics`
        },
        {
          icon: <ImageIcon />,
          label: t("menus.images"),
          path: `${url}/assets/images`
        }
      ]
    },
    // {
    //   icon: <GalleryIcon fontSize="default" />,
    //   label: t("menus.gallery"),
    //   path: `${url}/gallery`
    // },
    {
      icon: <ScenarioIcon fontSize="default" />,
      label: t("menus.scenarios"),
      path: `${url}/scenarios`
    },
    {
      icon: <GtvIcon fontSize="default" />,
      label: t("menus.gtv"),
      path: `${url}/gtv`
    },
    {
      icon: <ProjectIcon fontSize="default" />,
      label: t("menus.projects"),
      path: `${url}/projects`,
      hidden: false
    }
  ];

  const onMenuClick = (path: string) => () => {
    history.push(path);
    dispatch(AppActions.openMenu(false));
  };

  const onCollapseMenuClick = (name: string) =>
    setCollapseMenu({ ...collapseMenu, [name]: !collapseMenu[name] });

  const generateMenus = (menus: DrawerMenuProps[], isNested = false) => {
    return menus.map((menu: DrawerMenuProps, index) => {
      const isActive = menu.path === location.pathname;
      if (menu.hidden) return null;
      if (menu.children) {
        return (
          <React.Fragment key={index}>
            <ListItem button onClick={() => onCollapseMenuClick(menu.label)}>
              <ListItemIcon>
                {React.cloneElement(menu.icon, {
                  color: "primary"
                })}
              </ListItemIcon>
              <ListItemText primary={menu.label} />
              {collapseMenu[menu.label] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={collapseMenu[menu.label]}
              timeout="auto"
              unmountOnExit
            >
              <List>{generateMenus(menu.children, true)}</List>
            </Collapse>
          </React.Fragment>
        );
      }
      return (
        <ListItem
          key={menu.path}
          button
          onClick={onMenuClick(menu.path as string)}
          className={clsx({
            [classes.menuActive]: isActive,
            [classes.nestedMenu]: isNested
          })}
        >
          <ListItemIcon>
            {React.cloneElement(menu.icon, {
              color: "primary"
            })}
          </ListItemIcon>
          <ListItemText primary={menu.label} />
          {menu.inProgress && (
            <ListItemSecondaryAction>
              <Box fontStyle="italic">
                <Typography variant="caption">
                  {t("menus.in-progress")}
                </Typography>
              </Box>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      );
    });
  };

  return (
    <>
      {/* <Box clone p={3} display="block"> */}
      {/*   <ButtonBase */}
      {/*     onClick={() => history.push("/my-space/profile")} */}
      {/*     className={classes.avatarBox} */}
      {/*   > */}
      {/*     <Avatar */}
      {/*       alt="avatar" */}
      {/*       src={(user.profile && user.profile.avatarUrl) || DefaultAvatar} */}
      {/*       className={classes.avatar} */}
      {/*       variant="rounded" */}
      {/*     /> */}
      {/*     <Box display="flex"> */}
      {/*       <img */}
      {/*         alt="avatar-background" */}
      {/*         src={BackgroundAvatar} */}
      {/*         className={classes.avatarBoxBackground} */}
      {/*       /> */}
      {/*       <Typography align="left"> */}
      {/*         <b>{company.name}</b> */}
      {/*       </Typography> */}
      {/*     </Box> */}
      {/*   </ButtonBase> */}
      {/* </Box> */}

      <List className={classes.menuList}>
        {generateMenus(menus)}
        <MyLink to="/my-space">
          <ListItem key="my-space" button className={classes.backToMySpace}>
            <ListItemIcon>
              <BackMySpaceIcon />
            </ListItemIcon>
            <ListItemText primary={t("menus.back-to-myspace")} />
          </ListItem>
        </MyLink>
      </List>
    </>
  );
};
