import { schema } from "normalizr";
import { User } from "../../entities/user";
import { Font } from "../fonts/entity";
import { EntityId } from "@reduxjs/toolkit";

export class FontConfig {
  id!: string;
  size?: number;
  color?: string;
  weight?: number;
  style?: string;
  backgroundColor?: string;
  createdAt!: string;
  updatedAt!: string;
  userId!: string;
  user!: User;
  fontId!: string;
  font!: Font;
}

export interface FontConfigNormalized {
  fontConfig: Record<EntityId, FontConfig>;
  user: Record<EntityId, User>;
  font: Record<EntityId, Font>;
}
export const FontConfigSchema = new schema.Entity<FontConfig>("fontConfig", {
  user: new schema.Entity("user"),
  font: new schema.Entity("font")
});
