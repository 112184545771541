import { yupResolver } from "@hookform/resolvers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Collapse,
  Hidden,
  Typography,
  useTheme,
  Tooltip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import MovieIcon from "@material-ui/icons/LocalMovies";
import SaveIcon from "@material-ui/icons/Save";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import React, {
  memo,
  useContext,
  useEffect,
  useState,
  useCallback
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ShotCategoryIcon } from "../../../assets/icons";
import { AssetsType } from "../../../entities/asset";
import { AppSelector } from "../../../reducers/app/selector";
import { GtvSelector } from "../../../reducers/gtv/selector";
import { ShotCategorySelector } from "../../../reducers/shot-category/selector";
import { VideoEffectSelector } from "../../../reducers/video-effect/selector";
import {
  JsonTranslator,
  jsonTranslator
} from "../../../utils/function/jsonTranslator";
import { StorySchema } from "../../Gtv/schema";
import { DotStatus } from "../DotStatus";
import { MyButton } from "../MyButton";
import { MyForm } from "../MyForm2";
import { MyGalleryField } from "../MyForm2/MyGalleryField";
import { MyJsonTranslatorField } from "../MyForm2/MyJsonTranslatorField";
import { MyLockField } from "../MyForm2/MyLockField";
import { MySelectField } from "../MyForm2/MySelectField";
import { MySwitchField } from "../MyForm2/MySwitchField";
import { MyToggleButtonField } from "../MyForm2/MyToggleButtonField";
import { MyIconButton } from "../MyIconButton";
import { MyTypography } from "../MyTypography";
import { GtvContext } from "./Form";
import { ShotForm } from "./ShotForm";
import { ShotCategoryName } from "../../../reducers/shot-category/entity";
import { GtvActions } from "../../../reducers/gtv/reducer";
import { SelectOption } from "../MyForm2/MyAutocompleteField";
import { VideoEffectType } from "../../../reducers/video-effect/entity";

interface StoryFormProps {
  index: number;
}
export enum StoryCategoryName {
  GENERIC = "GENERIC",
  INTERTITLE = "INTERTITLE",
  INTERVIEW = "INTERVIEW",
  CLIP = "CLIP",
  B_ROLL = "B_ROLL"
}
export const StoryForm = memo(({ index }: StoryFormProps) => {
  const { language: lang } = useSelector(AppSelector.getState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation("gtv");
  const { t: tCommon } = useTranslation("common");
  const { t: tYup } = useTranslation("yup");
  const story = useSelector(GtvSelector.getStoryState(index));
  const {
    edit,
    audios,
    methodsRef,
    isSubmitted,
    onStorySubmit,
    onShotDuplicate,
    onShotRemove,
    onShotOpen,
    onShotAdd,
    onShotSubmit
  } = useContext(GtvContext);

  const [advancedOptions, setAdvancedOptions] = useState<boolean>(false);
  const videoEffectOptions = useSelector(
    VideoEffectSelector.getVideoEffectOptions
  );
  const startEffectOptions = videoEffectOptions.filter(ve =>
    ve.name.includes(VideoEffectType.IN)
  );
  const endEffectOptions = videoEffectOptions.filter(ve =>
    ve.name.includes(VideoEffectType.OUT)
  );

  const durationOptions: SelectOption[] = [
    { label: "0,5 sec", value: 500 },
    { label: "1 sec", value: 1000 }
  ];

  const storyCategoriesOptions = useSelector(
    GtvSelector.getStoryCategoriesOptions
  );

  const shotCategories = useSelector(ShotCategorySelector.selectAll);

  const [myStory, setMyStory] = useState<any>();

  const methods = useForm({
    defaultValues: story.values,
    context: { lang },
    mode: "all",
    resolver: yupResolver(StorySchema)
  });
  const {
    formState: { isValid, isDirty },
    reset,
    register,
    trigger,
    control,
    getValues,
    setError,
    setValue
  } = methods;

  const shotCategoryId = useWatch<string>({
    control,
    name: "_storyCategoryId"
  });

  const onStoryTypeChange = useCallback(
    shotCategoryId => {
      const firstShotIsBroll =
        shotCategories.find(
          sc => sc.id === story?.shots[0]?.values?.shotCategoryId
        )?.name === ShotCategoryName.B_ROLL;
      const brollChildId = shotCategories.find(
        sc => sc.name === ShotCategoryName.B_ROLL_CHILD
      )?.id;
      dispatch(
        GtvActions.updateStoryShotsType({
          index,
          storyCategoryId: shotCategoryId,
          firstShotIsBroll: firstShotIsBroll ? { brollChildId } : undefined
        })
      );
    },
    [dispatch, index, shotCategories, story.shots]
  );

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (methodsRef.current) {
      set(methodsRef.current, `stories[${index}].methods`, methods);
    }
  }, [index, methods, methodsRef]);

  useEffect(() => {
    if (!isDirty && !story.isValid && (edit || isSubmitted)) {
      trigger();
    }
  }, [edit, isDirty, isSubmitted, story.isValid, trigger]);

  useEffect(() => {
    onStoryTypeChange(shotCategoryId);
  }, [onStoryTypeChange, shotCategoryId]);

  useEffect(() => {
    reset(Object.assign({}, story.values));
  }, [reset, story.values]);

  useEffect(() => {
    setMyStory(story.values);
  }, [story]);

  return (
    <MyForm methods={methods} onSubmit={values => onStorySubmit(index, values)}>
      <input
        hidden
        type="number"
        name="order"
        defaultValue={index}
        ref={register}
      />
      <MyJsonTranslatorField
        name="label"
        textFieldProps={{ label: t("form.label") }}
        defaultValue={myStory?.label || null}
        setValue={(name: string, value: any) => {
          setValue(name, value);
        }}
      />
      <MyJsonTranslatorField
        name="description"
        textFieldProps={{ label: t("form.description") }}
        defaultValue={myStory?.description || null}
        multiline={true}
        setValue={(name: string, value: any) => {
          setValue(name, value);
        }}
      />
      <MySelectField
        label={t("form.story-category")}
        options={storyCategoriesOptions}
        name="_storyCategoryId"
      />
      <MyLockField name={`lockProperties`} lockProperty="audioId">
        <MyGalleryField
          // defaultValue={field.audioId}
          label={t("form.select-audio")}
          buttonLabel={t("form.show-audio-gallery")}
          name="audioId"
          type={AssetsType.AUDIO}
          assets={audios}
        />
      </MyLockField>

      <MySwitchField
        // defaultChecked={field.enabled}
        label={tCommon("enabled")}
        name="enabled"
      />
      <MyButton
        boxProps={{ my: 1, justifyContent: "space-between" }}
        fullWidth
        color="primary"
        variant="outlined"
        onClick={() => setAdvancedOptions(prev => !prev)}
        rightIcon={
          advancedOptions ? (
            <ExpandLessIcon color="primary" />
          ) : (
            <ExpandMoreIcon color="primary" />
          )
        }
      >
        {tCommon("advanced-options")}
      </MyButton>
      <Collapse in={advancedOptions}>
        <MyToggleButtonField
          size="small"
          label={t("form.start-video-effect")}
          options={startEffectOptions}
          name="startEffectId"
        />
        <MyToggleButtonField
          size="small"
          label={t("form.start-video-effect-duration")}
          options={durationOptions}
          name="startEffectDuration"
          //defaultValue={}
        />
        <MyToggleButtonField
          size="small"
          label={t("form.end-video-effect")}
          options={endEffectOptions}
          name="endEffectId"
        />
        <MyToggleButtonField
          size="small"
          label={t("form.end-video-effect-duration")}
          options={durationOptions}
          name="endEffectDuration"
          //defaultValue={}
        />
      </Collapse>
      <MyTypography
        boxProps={{ my: { xs: 1, sm: 2 } }}
        color="primary"
        variant="h4"
        leftIcon={
          <Avatar
            style={{
              background: theme.palette.primary.main,
              color: "white",
              height: 50,
              width: 50
            }}
          >
            <MovieIcon fontSize="large" />
          </Avatar>
        }
      >
        {t("shots")}
      </MyTypography>
      {story.shots.map((shot, shotIndex) => {
        const shotCategory = shotCategories.find(
          sc => sc.id === shot?.values?.shotCategoryId
        );
        const shotCategoryIcon = shotCategory
          ? ShotCategoryIcon[shotCategory.name]
          : null;
        return (
          <Box
            bgcolor="#F0F8FC"
            key={myStory?._key}
            clone
            my={{ xs: 1, sm: 2 }}
            p={{ xs: 0, sm: 1 }}
          >
            <Accordion
              expanded={shot.open}
              TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
              onChange={() => {
                if (!getValues()._storyCategoryId) {
                  return setError("_storyCategoryId", {
                    type: "Required",
                    message: tYup("mixed.required")
                  });
                }
                onShotOpen(index, shotIndex, true);
              }}
            >
              <AccordionSummary
                IconButtonProps={{
                  onClick: e => {
                    if (shot.open && methodsRef.current) {
                      methodsRef.current.stories[index].shots[
                        shotIndex
                      ].methods.handleSubmit(values =>
                        onShotSubmit(index, shotIndex, values)
                      )(e);
                    }
                  }
                }}
                expandIcon={
                  shot.open ? (
                    <Tooltip
                      arrow
                      title={<Typography>{t("save-shot")}</Typography>}
                    >
                      <SaveIcon
                        style={{ transform: "rotate(180deg)" }}
                        color="secondary"
                      />
                    </Tooltip>
                  ) : (
                    <ExpandMoreIcon color="primary" />
                  )
                }
              >
                <Box display="flex" alignItems="center" width="100%">
                  <Box
                    display="flex"
                    alignItems="center"
                    height="100%"
                    flex={1}
                  >
                    <MyTypography
                      IconBoxProps={{ mr: 1 }}
                      leftIcon={
                        shotCategoryIcon ? (
                          <Avatar src={shotCategoryIcon} />
                        ) : null
                      }
                      color="primary"
                      variant="h4"
                    >
                      Shot {shotIndex + 1}
                    </MyTypography>
                    <DotStatus ml={2} size={10} isValid={shot.isValid} />
                    <Hidden xsDown>
                      {shot?.values?.label && (
                        <>
                          <Box
                            mx={{ xs: 1, sm: 2, md: 4 }}
                            width={2}
                            height="100%"
                            bgcolor="white"
                          />
                          <Typography variant="h5">
                            {jsonTranslator(
                              shot.values.label as JsonTranslator,
                              lang
                            )}
                          </Typography>
                        </>
                      )}
                    </Hidden>
                  </Box>
                  <MyIconButton
                    tooltipProps={{
                      title: <Typography>{t("duplicate-shot")}</Typography>
                    }}
                    boxProps={{
                      bgcolor: "primary.main",
                      color: "white",
                      mx: 0.5
                    }}
                    bgColorHover={theme.palette.primary.light}
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      onShotDuplicate(index, shotIndex);
                    }}
                  >
                    <FileCopyIcon fontSize="small" />
                  </MyIconButton>
                  {story.shots.length > 1 && (
                    <MyIconButton
                      tooltipProps={{
                        title: <Typography>{t("remove-shot")}</Typography>
                      }}
                      boxProps={{
                        bgcolor: "primary.main",
                        color: "white",
                        mx: 0.5
                      }}
                      size="small"
                      bgColorHover={theme.palette.primary.light}
                      onClick={e => {
                        e.stopPropagation();
                        onShotRemove(index, shotIndex);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </MyIconButton>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ShotForm
                  key={shot?.values?._key}
                  index={shotIndex}
                  storyIndex={index}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
      <MyButton
        boxProps={{
          bgcolor: "#F0F8FC",
          height: { xs: 60, sm: 80 },
          justifyContent: "flex-start"
        }}
        fullWidth
        color="primary"
        leftIcon={<AddIcon color="primary" fontSize="large" />}
        onClick={() => onShotAdd(index)}
      >
        <Typography variant="h4">{t("add-shot")}</Typography>
      </MyButton>
      <MyButton
        boxProps={{ my: 1 }}
        color={(!story.isValid && isValid) || isDirty ? "secondary" : "primary"}
        type="submit"
        size="large"
        variant={isValid ? "contained" : "outlined"}
        onClick={goToTop}
      >
        {(!story.isValid && isValid) || isDirty
          ? t("update-story")
          : t("save-story")}
      </MyButton>
    </MyForm>
  );
}, isEqual);
