/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { useLocation } from "react-router-dom";
import { Reconnection } from "../pages/Reconnection";
import { AppActions } from "../reducers/app/reducer";
import { AppSelector } from "../reducers/app/selector";
import {
  AuthenticationActions,
  AuthenticationState
} from "../reducers/authentication/reducer";
import { getCurrentUser } from "../reducers/authentication/selector";
import { RootState, store } from "../reducers/store";
import { getToken } from "../utils/reconnection";
import { FetchingStatus } from "../utils/reducers/fetchingStatus";
import { MyRouteProps } from "./";

interface RouterManagerProps {
  routes: MyRouteProps[];
  prefix?: string;
}

interface SecureRouteProps extends Partial<MyRouteProps> {
  options: MyRouteProps["options"];
  user: AuthenticationState["user"];
  component: any;
}

const ReturnNotAuthenticated: React.SFC<{ returnPath: string }> = ({
  returnPath
}) => {
  store.dispatch(
    AppActions.enqueueSnacbar({
      message: i18n.t("routes:notAuthenticated"),
      variant: "warning"
    })
  );
  return <Redirect to={returnPath} />;
};

const ReturnNoPermission: React.SFC<{ returnPath: string }> = ({
  returnPath
}) => {
  store.dispatch(
    AppActions.enqueueSnacbar({
      message: "Vous n'avez pas les droits pour accéder à cette page",
      variant: "warning"
    })
  );
  return <Redirect to={returnPath} />;
};

const PrivateRoute: React.SFC<SecureRouteProps> = ({
  component: Component,
  options,
  ...rest
}) => {
  const user = useSelector(getCurrentUser);
  const returnPath = options
    ? options.redirect || "/authentication"
    : "/authentication";
  return (
    <Route
      {...rest}
      render={props => {
        return user.isActive && user.token ? (
          Component ? (
            <Component {...props} />
          ) : (
            rest.render && rest.render(props)
          )
        ) : (
          <ReturnNotAuthenticated returnPath={returnPath} />
        );
      }}
    />
  );
};

export const RoleRoute: React.SFC<SecureRouteProps> = ({
  component: Component,
  options,
  ...rest
}) => {
  const user = useSelector(getCurrentUser);
  const returnPath = options
    ? options.redirect || "/authentication"
    : "/authentication";
  const hasRole =
    options &&
    options.roles &&
    user.role &&
    options.roles.includes(user.role.name);
  const isActive = user.isActive;
  return (
    <Route
      {...rest}
      render={props =>
        hasRole && isActive ? (
          <Component {...props} />
        ) : (
          <ReturnNoPermission returnPath={returnPath} />
        )
      }
    />
  );
};

const createRoute = (
  allRoutes: MyRouteProps[],
  path?: string
): MyRouteProps[] => {
  return allRoutes.reduce((prevRoutes: any, route: any) => {
    const { options, childRoutes, name, ...routeProps } = route;
    const routePath = path ? path + route.path : route.path;
    routeProps.path = routePath;
    if (childRoutes) {
      prevRoutes = [...prevRoutes, ...createRoute(childRoutes, routePath)];
    }
    if (options && (options.roles || options.authenticated)) {
      if (options.roles) {
        prevRoutes = [
          ...prevRoutes,
          <RoleRoute key={name} {...routeProps} options={options} />
        ];
      } else if (options.authenticated) {
        prevRoutes = [
          ...prevRoutes,
          <PrivateRoute key={name} {...routeProps} options={options} />
        ];
      } else {
        prevRoutes = [...prevRoutes, <Route key={name} {...routeProps} />];
      }
    } else {
      prevRoutes = [...prevRoutes, <Route key={name} {...routeProps} />];
    }
    return prevRoutes;
  }, []);
};

export const RoutesManager: React.FC<RouterManagerProps> = ({
  routes,
  prefix
}) => {
  const [allRoutes, setAllRoutes] = useState<MyRouteProps[]>([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const { isOnBackOffice: appIsOnBackOffice } = useSelector(
    AppSelector.getState
  );
  const { user, retryConnectionStatus, signInStatus } = useSelector(
    (state: RootState) => state.authentication
  );
  const token = getToken();
  useEffect(() => {
    if (!user?.token && token && signInStatus !== FetchingStatus.PENDING) {
      dispatch(AuthenticationActions.retryConnection(token));
    }
  }, [dispatch, signInStatus, token, user]);

  useEffect(() => {
    const isOnBackOffice =
      !location.pathname.includes("authentication") &&
      user?.token &&
      !location.pathname.includes("on-boarding");
    if (isOnBackOffice && !appIsOnBackOffice) {
      dispatch(AppActions.isOnBackOffice(true));
    }
    if (!isOnBackOffice && appIsOnBackOffice) {
      dispatch(AppActions.isOnBackOffice(false));
    }
  }, [appIsOnBackOffice, dispatch, location.pathname, user]);

  useEffect(() => {
    if (retryConnectionStatus === FetchingStatus.PENDING) {
      return;
    }
    setAllRoutes(createRoute(routes, prefix));
  }, [retryConnectionStatus, routes, prefix]);

  const showReconnection = retryConnectionStatus === FetchingStatus.PENDING;

  return showReconnection || (!user?.token && token) ? (
    <Reconnection />
  ) : (
    <>{allRoutes}</>
  );
};
