import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButtonProps,
  Typography
} from "@material-ui/core";
import map from "lodash/map";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { mixed, object, string } from "yup";
import { AssetsType } from "../../../entities/asset";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { MyButton } from "../MyButton";
import { MyDialog } from "../MyDialog";
import { MyForm } from "../MyForm2";
import { MyIconButton } from "../MyIconButton";

export interface UploadVideoIconButtonProps extends IconButtonProps {
  assetType: AssetsType;
  loadingStatus?: FetchingStatus;
  onSubmitAsset: (value: FormData) => void;
}

const AcceptExtension = {
  [AssetsType.IMAGE]: ".png,.jpg,.jpeg,.gif",
  [AssetsType.VIDEO]: ".mp4,.m4v",
  [AssetsType.AUDIO]: ".mp3,.m4a"
};
const LabelByType = {
  [AssetsType.IMAGE]: "UploadAssetButton.image-upload",
  [AssetsType.VIDEO]: "UploadAssetButton.video-upload",
  [AssetsType.AUDIO]: "UploadAssetButton.audio-upload"
};

export const UploadVideoIconButton: React.FC<UploadVideoIconButtonProps> = ({
  assetType,
  onSubmitAsset,
  loadingStatus,
  children,
  ...iconButtonProps
}) => {
  const { t } = useTranslation("components");
  const { t: tYup } = useTranslation("yup");
  const { t: tCommon } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const methods = useForm<{ categoryId: string; files: FileList | null }>({
    resolver: yupResolver(
      object({
        categoryId: string(),
        files: mixed()
          .required()
          .test("minFiles", tYup("test.min-files"), val => val.length > 0)
      })
    )
  });

  const onSubmit = (v: { categoryId: string; files: FileList | null }) => {
    if (!v.files || !v.files.length) {
      return;
    }
    const formData = new FormData();
    if (v.files) formData.append(assetType, v.files[0]);
    onSubmitAsset(formData);
  };

  const onCloseDialog = () => {
    setFileName(map(methods.getValues().files, f => f.name).join(", "));
  };

  useEffect(() => {
    if (loadingStatus === FetchingStatus.SUCCESS) {
      setOpen(false);
      setFileName('');
    }
  }, [loadingStatus]);

  return (
    <>
      <MyIconButton onClick={() => setOpen(true)} {...iconButtonProps}>
        {children}
      </MyIconButton>
      <MyDialog open={open} onClose={() => {
        setOpen(false);
        setFileName('');
      }}>
        <MyForm methods={methods} onSubmit={onSubmit}>
          <DialogTitle>{t(LabelByType[assetType])}</DialogTitle>
          <DialogContent onInput={onCloseDialog} style={{ minWidth: 300 }}>
            <input
              hidden
              name="files"
              type="file"
              ref={methods.register}
              accept={AcceptExtension[assetType]}
              id="upload-file"
              multiple
            />
            <Box alignItems="center" display="flex">
              <label htmlFor="upload-file">
                <Button variant="contained" component="span">
                  {tCommon("upload-file")}
                </Button>
              </label>
              {methods.getValues().files && (
                <Box ml={1}>
                  <Typography>{fileName}</Typography>
                </Box>
              )}
            </Box>
            <ErrorMessage
              name="files"
              errors={methods.errors}
              render={({ message }) => (
                <Typography color="error">{message}</Typography>
              )}
            />
          </DialogContent>
          <DialogActions>
            <MyButton
              fullWidth
              color="primary"
              loading={loadingStatus === FetchingStatus.PENDING}
              type="submit"
              variant="contained"
              size="large"
            >
              {tCommon("upload")}
            </MyButton>
          </DialogActions>
        </MyForm>
      </MyDialog>
    </>
  );
};
