import { Typography, createStyles, Theme, Grid } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../components/common/MyPage";
import { makeStyles } from "@material-ui/styles";
import Logo from "../assets/logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    logo: {
      maxWidth: 350,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 250
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 200
      }
    }
  })
);

export const Reconnection: React.FC = () => {
  const { t } = useTranslation("reconnection");
  const classes = useStyles();
  return (
    <Page pt={{ xs: 4, sm: 6, md: 8 }} height="100%" className={classes.root}>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <img alt="enterprise-logo" src={Logo} className={classes.logo} />
        </Grid>
        <Grid item>
          <Typography color="textSecondary" align="center">
            {t("title")}
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
};
