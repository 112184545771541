import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { blue, green, orange, red } from "@material-ui/core/colors";
import CloseRounded from "@material-ui/icons/CloseRounded";
import clsx from "clsx";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MyPanel } from "../components/common/MyPanel";
import { Notifier } from "../components/common/Notifier";
import { ScrollToTop } from "../components/common/ScrollToTop";
import { AppTheme } from "../components/theme/AppTheme";
import { AppActions } from "../reducers/app/reducer";
import { AppSelector } from "../reducers/app/selector";
import { history, RootState } from "../reducers/store";
import { RootRoutes } from "../routes";
import { DRAWER_WIDTH } from "../utils/constant";
import { Languages } from "../utils/function/jsonTranslator";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      width: "auto",
      marginRight: theme.spacing(1)
    },
    main: {
      height: "100%",
      paddingTop: 64,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 56
      }
    },
    mainDisablePadding: {
      paddingTop: 0
    },
    mainWithMargin: {
      [theme.breakpoints.up("md")]: {
        marginLeft: DRAWER_WIDTH
      }
    },
    toolbarSpacer: theme.mixins.toolbar,
    notificationRoot: {
      marginBottom: "0px"
    },
    notificationDefault: {
      background: "white",
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      paddingTop: 0,
      paddingBottom: 0
    },
    notificationSuccess: {
      border: `1px solid ${green[400]}`,
      color: green[400]
    },
    notificationError: {
      border: `1px solid ${red[400]}`,
      color: red[400]
    },
    notificationWarning: {
      border: `1px solid ${orange[400]}`,
      color: orange[400]
    },
    notificationInfo: {
      border: `1px solid ${blue[400]}`,
      color: blue[400]
    },
    closeIcon: {}
  })
);

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOnBackOffice, isOnBoarding } = useSelector(AppSelector.getState);
  const { theme } = useSelector((state: RootState) => state.app);

  const initializeLanguage = useCallback(() => {
    try {
      let language = localStorage.getItem("i18nextLng");
      console.log('language storage *** ', language);
      if (!language || language.startsWith("en")) {
        language = Languages.EN;
      } else if (language.startsWith("fr")) {
        language = Languages.FR;
      } else {
        language = Languages.EN;
      }
      dispatch(AppActions.setLanguage(language as Languages));
    } catch (error) {
      dispatch(
        AppActions.enqueueSnacbar({
          message: "Cannot load the current browser language",
          variant: "warning"
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const ll = localStorage.getItem("i18nextLng");
    console.log('language *** storage *** ', ll);
    initializeLanguage();
  }, [initializeLanguage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppTheme theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate={true}
        classes={{
          root: classes.notificationRoot,
          variantSuccess: clsx(
            classes.notificationSuccess,
            classes.notificationDefault
          ),
          variantError: clsx(
            classes.notificationError,
            classes.notificationDefault
          ),
          variantWarning: clsx(
            classes.notificationWarning,
            classes.notificationDefault
          ),
          variantInfo: clsx(
            classes.notificationInfo,
            classes.notificationDefault
          )
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        action={key => (
          <IconButton
            key="close_notification"
            color="inherit"
            onClick={() => dispatch(AppActions.closeSnackbar(key as string))}
          >
            <CloseRounded className={classes.closeIcon} />
          </IconButton>
        )}
      >
        <ConnectedRouter history={history}>
          <>
            <ScrollToTop />
            <Notifier />
            <main
              className={clsx(classes.main, {
                [classes.mainWithMargin]: isOnBackOffice,
                [classes.mainDisablePadding]: isOnBoarding
              })}
            >
              <MyPanel routes={RootRoutes} />
            </main>
          </>
        </ConnectedRouter>
      </SnackbarProvider>
    </AppTheme>
  );
};
