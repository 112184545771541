import {
  createSlice,
  CreateSliceOptions,
  Draft,
  EntityAdapter,
  EntityState,
  PayloadAction,
  SliceCaseReducers
} from "@reduxjs/toolkit";
import { FetchingStatus } from "./fetchingStatus";

export enum AsyncActionMethods {
  CREATE = "CREATE",
  CREATE_MANY = "CREATE_MANY",
  READ = "READ",
  READ_ONE = "READ_ONE",
  UPDATE = "UPDATE",
  UPDATE_MANY = "UPDATE_MANY",
  DELETE = "DELETE",
  DELETE_MANY = "DELETE_MANY"
}

export interface AsyncAnyActions<T> {
  action: any;
  statusName: keyof T;
  method?: AsyncActionMethods;
  onSuccess?: (state: T, action: PayloadAction<any>) => any;
  onPending?: (state: T, action: PayloadAction<any>) => any;
  onFailed?: (state: T, action: PayloadAction<any>) => any;
}

const executeNormalizrMethod = <T>(
  adapter: EntityAdapter<T>,
  method: AsyncActionMethods,
  state: EntityState<T>,
  action: PayloadAction<any>
) => {
  switch (method) {
    case AsyncActionMethods.CREATE:
      return adapter.addOne(state, action.payload);
    case AsyncActionMethods.CREATE_MANY:
      return adapter.addMany(state, action.payload);
    case AsyncActionMethods.READ:
      return adapter.addMany(state, action.payload);
    case AsyncActionMethods.READ_ONE:
      return adapter.upsertOne(state, action.payload);
    case AsyncActionMethods.UPDATE:
      return adapter.upsertOne(state, action.payload);
    case AsyncActionMethods.UPDATE_MANY:
      return adapter.upsertMany(state, action.payload);
    case AsyncActionMethods.DELETE:
      return adapter.removeOne(state, action.payload);
    case AsyncActionMethods.DELETE_MANY:
      return adapter.removeMany(state, action.payload);
  }
};

export function createMySlice<
  Entity,
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string
>({
  name,
  initialState,
  reducers,
  adapter,
  asyncActions,
  extraReducers
}: CreateSliceOptions<State, CaseReducers, Name> & {
  asyncActions?: Array<AsyncAnyActions<State>>;
  adapter?: EntityAdapter<Entity>;
}) {
  return createSlice({
    name,
    initialState,
    reducers: {
      clear: () => initialState,
      addOne: (state: EntityState<Entity>, action) => {
        if (action.payload) adapter?.addOne(state, action.payload);
      },
      addMany: (state: EntityState<Entity>, action) => {
        if (action.payload) adapter?.addMany(state, action.payload);
      },
      removeOne: (state: EntityState<Entity>, action) => {
        if (action.payload) adapter?.removeOne(state, action.payload);
      },
      upsertOne: (state: EntityState<Entity>, action) => {
        if (action.payload) adapter?.upsertOne(state, action.payload);
      },
      upsertMany: (state: EntityState<Entity>, action) => {
        if (action.payload) adapter?.upsertMany(state, action.payload);
      },
      setAll: (state: EntityState<Entity>, action) => {
        if (action.payload) adapter?.setAll(state, action.payload);
      },
      ...reducers
    },
    extraReducers: builder => {
      if (asyncActions && asyncActions.length) {
        for (const asyncAction of asyncActions) {
          builder.addCase(
            asyncAction.action.fulfilled,
            (state: any, action: PayloadAction<any>) => {
              state[asyncAction.statusName] = FetchingStatus.SUCCESS;
              if (asyncAction.method) {
                if (!adapter) {
                  throw new Error(
                    `${asyncAction.statusName} cannot process without the entity adaptater`
                  );
                }
                executeNormalizrMethod<Entity>(
                  adapter,
                  asyncAction.method,
                  state,
                  action
                );
              }
              asyncAction.onSuccess && asyncAction.onSuccess(state, action);
            }
          );
          builder.addCase(
            asyncAction.action.pending,
            (state: Draft<any>, action) => {
              state[asyncAction.statusName] = FetchingStatus.PENDING;
              asyncAction.onPending && asyncAction.onPending(state, action);
            }
          );
          builder.addCase(
            asyncAction.action.rejected,
            (state: Draft<any>, action) => {
              state[asyncAction.statusName] = FetchingStatus.FAILED;
              asyncAction.onFailed && asyncAction.onFailed(state, action);
            }
          );
        }
      }
      if (extraReducers) {
        return (extraReducers as Function)(builder);
      }
    }
  });
}
