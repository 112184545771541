import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import AddBoxIcon from "@material-ui/icons/AddBox";
import BusinessIcon from "@material-ui/icons/Business";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NoPictureIcon from "@material-ui/icons/PanoramaOutlined";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DynamicElement } from "../../../components/common/DynamicElement";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { MyLink } from "../../../components/common/MyLink";
import { Page } from "../../../components/common/MyPage";
import { AppBarActions } from "../../../reducers/appbar/reducer";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { Company } from "../../../reducers/companies/entity";
import { CompanyActions } from "../../../reducers/companies/reducer";
import { UserActions } from "../../../reducers/users/reducer";
import { UserSelector } from "../../../reducers/users/selector";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { CreateCompany } from "./components/CreateCompany";

export interface MyCompaniesProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    card: {
      height: 272
    },
    cardCreateCompany: {
      border: `5px dashed ${grey[300]}`
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    avatar: {
      backgroundColor: red[500]
    },
    createCompanyContent: {
      position: "relative"
    },
    iconContainer: {},
    businessIcon: {
      fontSize: 180
    },
    addIcon: {
      color: theme.palette.success.light,
      fontSize: 80,
      position: "absolute",
      top: 0,
      right: 0
    },
    images: {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  })
);

export const MyCompanies: React.FC<MyCompaniesProps> = () => {
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  console.log(user);
  const companies = useSelector(UserSelector.getCompanies());

  const { readCompaniesStatus, createCompanyStatus } = useSelector(
    UserSelector.getState
  );
  const [openCreateCompanyDialog, setOpenCreateCompanyDialog] = useState(false);

  useEffect(() => {
    dispatch(AppBarActions.setState({ title: t("menus.my-companies") }));
    dispatch(UserActions.readCompanies(user));
    return () => {
      dispatch(AppBarActions.setState());
    };
  }, [dispatch, t, user]);

  useEffect(() => {
    if (createCompanyStatus === FetchingStatus.SUCCESS) {
      setOpenCreateCompanyDialog(false);
    }
  }, [createCompanyStatus]);

  const onDeleteCompany = (company: Company) =>
    dispatch(CompanyActions.removeOne(company));

  return (
    <Page>
      <DynamicElement<Company[]>
        data={companies}
        actions={{
          action: UserActions.readCompanies(user),
          status: readCompaniesStatus
        }}
        showCondition={readCompaniesStatus === FetchingStatus.SUCCESS}
      >
        {companies => (
          <>
            <CreateCompany
              open={openCreateCompanyDialog}
              onClose={() => setOpenCreateCompanyDialog(false)}
            />
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              {companies.map(company => {
                return (
                  <Grid item key={company.id} xs={12} md={4}>
                    <Card className={classes.card}>
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="recipe"
                            className={classes.avatar}
                          >
                            {company.name.substr(0, 2).toUpperCase()}
                          </Avatar>
                        }
                        action={
                          <MyIconButton
                            aria-label="company-settings"
                            menus={({ onClose }) => (
                              <MenuItem
                                dense={true}
                                onClick={() => {
                                  onDeleteCompany(company);
                                  onClose();
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                  <DeleteIcon color="error" />
                                </ListItemIcon>
                                <Typography color="error">
                                  {t("my-companies.actions.delete")}
                                </Typography>
                              </MenuItem>
                            )}
                          >
                            <MoreVertIcon />
                          </MyIconButton>
                        }
                        title={company.name.toUpperCase()}
                        subheader={`${tCommon("created-at")} ${new Date(
                          company.createdAt
                        ).toLocaleDateString()}`}
                      />
                      <CardActionArea style={{ height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MyLink to={`/company/${company.id}`} style={{ height: '100%', backgroundColor: 'red' }}>
                          {company.watermarks &&
                          company.watermarks.length &&
                          company.watermarks[0].image ? (
                            <img
                              alt="logo"
                              src={company.watermarks[0].image.url}
                              className={classes.images}
                            />
                          ) : (
                            <NoPictureIcon
                              className={classes.images}
                              style={{
                                color:
                                  company.setting?.primaryColor || grey[300]
                              }}
                            />
                          )}

                          {/* <CardContent>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              This impressive paella is a perfect party dish and
                              a fun meal to cook together with your guests. Add
                              1 cup of frozen peas along with the mussels, if
                              you like.
                            </Typography>
                          </CardContent> */}
                        </MyLink>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
              <Grid item>
                <CardActionArea
                  onClick={() => setOpenCreateCompanyDialog(true)}
                >
                  <Card
                    className={clsx(classes.card, classes.cardCreateCompany)}
                    elevation={0}
                  >
                    <CardHeader
                      style={{ height: 40 }}
                      action={
                        <AddBoxIcon
                          className={classes.addIcon}
                          color="disabled"
                        />
                      }
                    />
                    <CardContent>
                      <Box display="block" mx="auto" clone>
                        <BusinessIcon
                          className={classes.businessIcon}
                          color="disabled"
                        />
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Box width="100%" clone>
                        <Typography
                          align="center"
                          variant="h6"
                          color="textSecondary"
                          component="p"
                        >
                          {companies.length
                            ? t("my-companies.create-new-company")
                            : t("my-companies.create-company")}
                        </Typography>
                      </Box>
                    </CardActions>
                  </Card>
                </CardActionArea>
              </Grid>
            </Grid>
          </>
        )}
      </DynamicElement>
    </Page>
  );
};
