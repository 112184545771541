import {
  createAsyncThunk,
  createEntityAdapter,
  EntityState
} from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { User } from "../../entities/user";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { Request } from "../../utils/request";
import { ActivationCodeActions } from "../activation-code/reducer";
import { Audio, AudioEntity, AudioNormalized } from "../audios/entity";
import { AudioActions } from "../audios/reducer";
import { Company, CompanyCreateDTO } from "../companies/entity";
import { CompanyActions } from "../companies/reducer";
import {
  FontConfig,
  FontConfigNormalized,
  FontConfigSchema
} from "../fonts-config/entity";
import { FontConfigActions } from "../fonts-config/reducer";
import { FontActions } from "../fonts/reducer";
import { Image, ImageEntity, ImageNormalized } from "../images/entity";
import { ImageActions } from "../images/reducer";
import { Project } from "../projects/entity";
import { ScenarioCompanySharedActions } from "../scenarios-companies-shared/reducer";
import { ScenarioUserSharedActions } from "../scenarios-users-shared/reducer";
import {
  Scenario,
  ScenarioEntity,
  ScenarioNormalized
} from "../scenarios/entity";
import { ScenarioActions } from "../scenarios/reducer";
import { AppDispatch } from "../store";
import { Video, VideoEntity, VideoNormalized } from "../videos/entity";
import { VideoActions } from "../videos/reducer";
import { UserActionsTypes } from "./action";
import * as Api from "./api";

export interface UserState extends EntityState<User> {
  readCompaniesStatus: FetchingStatus;
  createCompanyStatus: FetchingStatus;
  leaveCompanyStatus: FetchingStatus;
  readCollaborationsStatus: FetchingStatus;
  readScenarioStatus: FetchingStatus;
  readSharedScenarioStatus: FetchingStatus;
  updateOnBoardingStatus: FetchingStatus;
  readVideosStatus: FetchingStatus;
  readImagesStatus: FetchingStatus;
  readAudiosStatus: FetchingStatus;
  readProjectsStatus: FetchingStatus;
  readFontConfigsStatus: FetchingStatus;
  removeFontConfigStatus: FetchingStatus;
  readOneUserStatus: FetchingStatus;
}

export const UserInitialState: UserState = {
  ids: [],
  entities: {},
  readCompaniesStatus: FetchingStatus.NULL,
  createCompanyStatus: FetchingStatus.NULL,
  leaveCompanyStatus: FetchingStatus.NULL,
  readCollaborationsStatus: FetchingStatus.NULL,
  readScenarioStatus: FetchingStatus.NULL,
  readSharedScenarioStatus: FetchingStatus.NULL,
  updateOnBoardingStatus: FetchingStatus.NULL,
  readVideosStatus: FetchingStatus.NULL,
  readImagesStatus: FetchingStatus.NULL,
  readAudiosStatus: FetchingStatus.NULL,
  readProjectsStatus: FetchingStatus.NULL,
  readFontConfigsStatus: FetchingStatus.NULL,
  removeFontConfigStatus: FetchingStatus.NULL,
  readOneUserStatus: FetchingStatus.NULL
};

const readOneUser = createMyAsyncThunk<User, string>(
  UserActionsTypes.READ_ONE_USER,
  Api.readOneUser
);

const readScenarios = createMyAsyncThunk<Scenario[], void>(
  UserActionsTypes.READ_SCENARIO,
  (_, { getState }) =>
    Request({ withToken: true }).get(
      `/users/${getState().authentication.user?.id}/scenarios`
    ),
  {
    onFailedMessage: "saga.read-failed",
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const normalized = normalize<Scenario, ScenarioNormalized>(result, [
        ScenarioEntity
      ]);
      dispatch(
        ScenarioUserSharedActions.upsertMany(
          normalized.entities.sharedUsers || []
        )
      );
      dispatch(ScenarioActions.setAll(normalized.entities.scenarios || []));
      dispatch(
        ActivationCodeActions.upsertMany(
          normalized.entities.activationCodes || []
        )
      );
    }
  }
);

const shareScenarioToUserCompanies = createAsyncThunk<
  void,
  { scenario: Scenario; user: User },
  { dispatch: AppDispatch }
>(
  UserActionsTypes.SHARE_SCENARIO_TO_USER_COMPANIES,
  async ({ scenario, user }, { dispatch }) => {
    const { data: userCompanies } = await Api.readCompanies(user);
    CompanyActions.addMany(userCompanies);
    for (const company of userCompanies) {
      dispatch(
        ScenarioCompanySharedActions.createOne({
          companyId: company.id,
          scenarioId: scenario.id
        })
      );
    }
  }
);

const readSharedScenarios = createMyAsyncThunk<Scenario[], void>(
  UserActionsTypes.READ_SHARED_SCENARIO,
  (_, { getState }) =>
    Request({ withToken: true }).get(
      `/users/${getState().authentication.user?.id}/scenarios/shared`
    ),
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(ScenarioActions.addMany(result));
      return result;
    }
  }
);

const updateOnBoarding = createMyAsyncThunk<void, string>(
  UserActionsTypes.UPDATE_ONBOARDING,
  userId => Request({ withToken: true }).put(`/users/${userId}/on-boarding`)
);

const readVideos = createMyAsyncThunk<Video[], User>(
  UserActionsTypes.READ_VIDEOS,
  Api.readVideos,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const {
        entities: { activationCodes, videos }
      } = normalize<Video, VideoNormalized>(result, [VideoEntity]);
      dispatch(VideoActions.upsertMany(videos || []));
      dispatch(ActivationCodeActions.upsertMany(activationCodes || []));
    }
  }
);
const readImages = createMyAsyncThunk<Image[], User>(
  UserActionsTypes.READ_IMAGES,
  Api.readImages,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const {
        entities: { activationCodes, images }
      } = normalize<Image, ImageNormalized>(result, [ImageEntity]);
      dispatch(ImageActions.upsertMany(images || []));
      dispatch(ActivationCodeActions.upsertMany(activationCodes || []));
    }
  }
);
const readAudios = createMyAsyncThunk<Audio[], User>(
  UserActionsTypes.READ_AUDIOS,
  Api.readAudios,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const {
        entities: { activationCodes, audios }
      } = normalize<Audio, AudioNormalized>(result, [AudioEntity]);
      dispatch(AudioActions.upsertMany(audios || []));
      dispatch(ActivationCodeActions.upsertMany(activationCodes || []));
    }
  }
);

const readFontConfigs = createMyAsyncThunk<FontConfig[], User>(
  UserActionsTypes.READ_FONTCONFIGS,
  Api.readFontConfigs,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      const normalized = normalize<FontConfig, FontConfigNormalized>(result, [
        FontConfigSchema
      ]);
      dispatch(FontConfigActions.upsertMany(normalized.entities.fontConfig));
      if (normalized.entities.font)
        dispatch(FontActions.upsertMany(normalized.entities.font));
    }
  }
);

const removeFontConfig = createMyAsyncThunk<
  void,
  { user: User; fontConfig: FontConfig }
>(UserActionsTypes.REMOVE_FONTCONFIG, Api.removeFontConfig, {
  onSuccess: ({ thunkApi: { dispatch }, values }) => {
    dispatch(FontConfigActions.removeOne(values.fontConfig.id));
  }
});

// Projects
const readUserProjects = createMyAsyncThunk<Project[], User>(
  UserActionsTypes.READ_PROJECTS,
  Api.readUserProjects
);

// Companies
const createCompany = createMyAsyncThunk<
  Company,
  { user: User; company: CompanyCreateDTO }
>(UserActionsTypes.CREATE_COMPANY, Api.createCompany, {
  onSuccess: ({ result, thunkApi: { dispatch } }) => {
    dispatch(CompanyActions.addOne(result));
  }
});

const readCompanies = createMyAsyncThunk<Company[], User>(
  UserActionsTypes.READ_COMPANIES,
  Api.readCompanies,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(CompanyActions.upsertMany(result));
    }
  }
);

const readCollaborations = createMyAsyncThunk(
  UserActionsTypes.READ_COLLABORATIONS,
  Api.readCollaborations,
  {
    onSuccess: ({ result, thunkApi: { dispatch } }) => {
      dispatch(CompanyActions.upsertMany(result));
    }
  }
);

const leaveCompany = createMyAsyncThunk<void, Company>(
  UserActionsTypes.LEAVE_COMPANY,
  (company, { getState }) =>
    Request({ withToken: true }).delete(
      `/users/${getState().authentication.user?.id}/companies/${
        company.id
      }/leave`
    ),
  {
    onSuccess: ({ values: company, thunkApi: { dispatch } }) => {
      dispatch(CompanyActions.removeOne(company));
      return company;
    },
    onSuccessMessage: "saga:users.leave-company-success",
    onFailedMessage: "saga:delete-failed"
  }
);

export const UserAdapter = createEntityAdapter<User>();

const UserAdapterState = UserAdapter.getInitialState(UserInitialState);

const UserSlice = createMySlice({
  name: "users",
  initialState: UserAdapterState,
  adapter: UserAdapter,
  asyncActions: [
    { action: readScenarios, statusName: "readScenarioStatus" },
    { action: readSharedScenarios, statusName: "readSharedScenarioStatus" },
    { action: updateOnBoarding, statusName: "updateOnBoardingStatus" },
    { action: readVideos, statusName: "readVideosStatus" },
    { action: readImages, statusName: "readImagesStatus" },
    { action: readAudios, statusName: "readAudiosStatus" },
    { action: readFontConfigs, statusName: "readFontConfigsStatus" },
    { action: removeFontConfig, statusName: "removeFontConfigStatus" },
    { action: readCompanies, statusName: "readCompaniesStatus" },
    { action: readUserProjects, statusName: "readProjectsStatus" },
    { action: readCollaborations, statusName: "readCollaborationsStatus" },
    { action: createCompany, statusName: "createCompanyStatus" },
    { action: leaveCompany, statusName: "leaveCompanyStatus" },
    { action: readOneUser, statusName: "readOneUserStatus" }
  ],
  reducers: {}
});

export const UserReducer = UserSlice.reducer;
export const UserActions = {
  readCompanies,
  readCollaborations,
  createCompany,
  leaveCompany,
  readScenarios,
  readSharedScenarios,
  shareScenarioToUserCompanies,
  updateOnBoarding,
  readVideos,
  readImages,
  readAudios,
  readFontConfigs,
  removeFontConfig,
  readOneUser,
  readUserProjects,
  ...UserSlice.actions
};
