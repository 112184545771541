import {
  Box,
  BoxProps,
  createStyles,
  Hidden,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowDrop from "../assets/ArrowDrop.png";
import MakidooLogo from "../assets/makidoo-logo.png";

interface OnBoardingCarouselProps extends BoxProps {}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    arrowDrop: {
      transform: "rotate(-20deg)",
      width: 320,
      left: -60,
      top: -150
    }
  })
);

export const OnBoardingCarousel: React.FC<OnBoardingCarouselProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation("onBoarding");
  return (
    <Box {...props}>
      <Box position="relative" pl={{ sm: 0, md: "200px" }}>
        <Hidden smDown>
          <Box clone position="absolute" top={0} left={0}>
            <img
              alt="arrow-drop"
              key="arrow-drop"
              src={ArrowDrop}
              className={classes.arrowDrop}
            />
          </Box>
        </Hidden>
        <Typography variant="h4" color="textPrimary">
          {t("title-drop-logo")}
        </Typography>
      </Box>
      <Box clone pl={{ md: 0, lg: "180px" }}>
        <img
          alt="makidoo logo"
          key="makidoo-logo"
          src={MakidooLogo}
          width="100%"
        />
      </Box>
    </Box>
  );
};
