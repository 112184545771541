import { connectRouter } from "connected-react-router";
import { History } from "history";
import { Action, combineReducers } from "redux";
import { AppReducer } from "./app/reducer";
import { AppBarReducer } from "./appbar/reducer";
import { AudioReducer } from "./audios/reducer";
import { AuthenticationReducer } from "./authentication/reducer";
import { CompanyReducer } from "./companies/reducer";
import { FontConfigReducer } from "./fonts-config/reducer";
import { FontReducer } from "./fonts/reducer";
import { ImageReducer } from "./images/reducer";
import { ItemReducer } from "./items/reducer";
import { OnBoardingReducer } from "./on-boarding/reducer";
import { RoleReducer } from "./roles/reducer";
import { ScenarioCompanySharedReducer } from "./scenarios-companies-shared/reducer";
import { ScenarioUserSharedReducer } from "./scenarios-users-shared/reducer";
import { ScenarioReducer } from "./scenarios/reducer";
import { ShotCategoryReducer } from "./shot-category/reducer";
import { UserReducer } from "./users/reducer";
import { VideoEffectReducer } from "./video-effect/reducer";
import { VideoReducer } from "./videos/reducer";
import { GtvReducer } from "./gtv/reducer";
import { ActivationCodeReducer } from "./activation-code/reducer";
import { ProjectReducer } from "./projects/reducer";
import { DomainReducer } from "./domains/reducer";

export const RootReducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    items: ItemReducer,
    app: AppReducer,
    appbar: AppBarReducer,
    authentication: AuthenticationReducer,
    users: UserReducer,
    roles: RoleReducer,
    fonts: FontReducer,
    fontConfigs: FontConfigReducer,
    companies: CompanyReducer,
    scenarios: ScenarioReducer,
    scenarioUserShared: ScenarioUserSharedReducer,
    scenarioCompanyShared: ScenarioCompanySharedReducer,
    activationCodes: ActivationCodeReducer,
    images: ImageReducer,
    videos: VideoReducer,
    audios: AudioReducer,
    videoEffects: VideoEffectReducer,
    shotCategories: ShotCategoryReducer,
    onBoarding: OnBoardingReducer,
    gtv: GtvReducer,
    projects: ProjectReducer,
    domains: DomainReducer
  });

enum RootActionTypes {
  CLEAR = "CLEAR"
}

export class RootClear {
  readonly type = RootActionTypes.CLEAR;
}

export const RootReducer = (history: History) => (
  state: any,
  action: Action<any>
) => {
  if (action.type === RootActionTypes.CLEAR) {
    state = undefined;
  }
  return RootReducers(history)(state, action);
};
