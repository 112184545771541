import { Box, Grid } from "@material-ui/core";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import { CategoryNames } from "../../entities/category";
import { Image } from "../../reducers/images/entity";
import {
  MyGalleryAppBar,
  MyGalleryAppBarProps
} from "./gallery/MyGalleryAppBar";
import { MyImage } from "./MyImage";

export interface MyImageListProps {
  title?: string;
  images: Image[];
  category?: CategoryNames;
  multiple?: boolean;
  onImagesSelected?: (images: Image | Image[]) => void;
  imagesSelected?: Image | Image[];
  myGalleryAppBarProps?: MyGalleryAppBarProps;
}
export const MyImageList: React.FC<MyImageListProps> = ({
  title,
  myGalleryAppBarProps,
  images,
  onImagesSelected,
  imagesSelected,
  multiple,
  category
}) => {
  const [searchImage, setSearchImage] = useState(
    category ? images.filter(i => i.category?.name === category) : images
  );
  const [search, setSearch] = useState("");

  const filterImage = useCallback(
    debounce((search: string) => {
      if (!search) {
        return setSearchImage(images);
      }
      setSearchImage(
        images.filter(i => i.name.toLowerCase().includes(search.toLowerCase()))
      );
    }, 200),
    [images]
  );

  const onSearchChange = (search: string) => {
    setSearch(search);
  };

  const onSelection = (image: Image) => {
    if (onImagesSelected) {
      if (!imagesSelected) {
        return onImagesSelected(multiple ? [image] : image);
      }
      if (multiple && Array.isArray(imagesSelected)) {
        const imageExistIndex = (imagesSelected || []).findIndex(
          i => i.id === image.id
        );
        if (imageExistIndex === -1)
          onImagesSelected([...(imagesSelected || []), image]);
        else
          onImagesSelected(
            (imagesSelected || []).filter(i => i.id !== image.id)
          );
      } else {
        onImagesSelected(image);
      }
    }
  };

  useEffect(() => {
    if (!search) {
      setSearchImage(
        category ? images.filter(i => i.category?.name === category) : images
      );
    } else {
      filterImage(search);
    }
  }, [category, filterImage, images, search]);

  return (
    <Box>
      <MyGalleryAppBar
        title={title}
        mySearchInputProps={{ onChange: onSearchChange, value: search }}
        toolbarProps={{ style: { marginRight: 28 } }}
        {...myGalleryAppBarProps}
      />
      <Grid container>
        {searchImage.map(image => {
          const isSelected =
            multiple && Array.isArray(imagesSelected)
              ? imagesSelected.map(i => i.id).includes(image.id)
              : imagesSelected
              ? (imagesSelected as Image).id === image.id
              : false;

          return (
            <Grid item key={image.id} xs={12} sm={4} md={3}>
              <MyImage
                image={image}
                isSelected={isSelected}
                onClick={() => onSelection(image)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

// <ListItem>
//               <ListItemIcon>
//                 <PictureIcon />
//               </ListItemIcon>
//               <ListItemText primaryTypographyProps={{ variant: "subtitle1" }}>
//                 {image.name}
//               </ListItemText>
//             </ListItem>
//             <Grid container>
//               <ListItem dense>
//                 <ListItemIcon>
//                   <CreatedAtIcon />
//                 </ListItemIcon>
//                 <ListItemText style={{ textTransform: "capitalize" }}>
//                   {tCommon("created-at")}{" "}
//                   {new Date(image.createdAt).toLocaleString(lang, {
//                     month: "long",
//                     day: "numeric",
//                     year: "numeric"
//                   })}
//                 </ListItemText>
//               </ListItem>
//               <ListItem className={classes.listItem} dense>
//                 <ListItemIcon>
//                   <FormatIcon />
//                 </ListItemIcon>
//                 <ListItemText style={{ textTransform: "capitalize" }}>
//                   {image.format}
//                 </ListItemText>
//               </ListItem>
//               {image.description && (
//                 <ListItem className={classes.listItem}>
//                   <ListItemText>{image.description}</ListItemText>
//                 </ListItem>
//               )}
//               <ListItem className={classes.listItem} dense>
//                 <ListItemIcon>
//                   <SizeIcon />
//                 </ListItemIcon>
//                 <ListItemText>
//                   {image.width} x {image.height} px
//                 </ListItemText>
//               </ListItem>
//               <ListItem className={classes.listItem} dense>
//                 <ListItemIcon>
//                   <StorageIcon />
//                 </ListItemIcon>
//                 <ListItemText>{image.size} Mo</ListItemText>
//               </ListItem>
//             </Grid>
//             <Typography
//               variant="body2"
//               color="textSecondary"
//               component="p"
//             ></Typography>
//           </CardActionArea>
//         </Card>
