import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Slider,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import CustomTextIcon from "@material-ui/icons/CreateOutlined";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DynamicElement } from "../../components/common/DynamicElement";
import { Page } from "../../components/common/MyPage";
import { AppBarActions } from "../../reducers/appbar/reducer";
import { getCurrentUser } from "../../reducers/authentication/selector";
import { FontConfig } from "../../reducers/fonts-config/entity";
import { FontConfigSelector } from "../../reducers/fonts-config/selector";
import { FontSelector } from "../../reducers/fonts/selector";
import { UserActions } from "../../reducers/users/reducer";
import { UserSelector } from "../../reducers/users/selector";
import { FontList } from "./components/List";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export interface FontProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    fontPreview: {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      wordBreak: "break-word",
      overflow: "auto"
    }
  })
);

export const Fonts: React.FC<FontProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("font");
  const [customText, setCustomText] = useState(
    "The quick brown fox jumps over the lazy dog"
  );
  const [fontExampleSize, setFontExampleSize] = useState<{
    [k: string]: number | number[];
  }>({});

  const currentUser = useSelector(getCurrentUser);
  const userFontConfig = useSelector(
    FontConfigSelector.getFontConfigByUser(currentUser)
  );
  const fontsPublic = useSelector(FontSelector.getPublic);
  const { readFontConfigsStatus } = useSelector(UserSelector.getState);

  useEffect(() => {
    dispatch(AppBarActions.setState({ title: t("title") }));
  });

  const onCustomTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomText(e.target.value);
  };

  const onFontExampleSizeChange = (name: string) => (
    e: React.ChangeEvent<{}>,
    value: number | number[]
  ) => setFontExampleSize({ ...fontExampleSize, [name]: value });

  return (
    <Page>
      <Container maxWidth="xl">
        <Grid container justify="space-between" spacing={4}>
          <Grid item xs={12} lg={8}>
            <DynamicElement<FontConfig[]>
              data={userFontConfig}
              actions={{
                status: readFontConfigsStatus,
                action: UserActions.readFontConfigs(currentUser)
              }}
              showCondition={
                (userFontConfig.length > 0 || fontsPublic.length > 0) &&
                readFontConfigsStatus === FetchingStatus.SUCCESS
              }
            >
              {fontConfigs => (
                <>
                  <Box mb={2}>
                    <TextField
                      onChange={onCustomTextChange}
                      value={customText}
                      multiline
                      rowsMax={4}
                      name="custom-text"
                      fullWidth
                      placeholder={t("custom-text-placeholder")}
                      InputProps={{
                        startAdornment: (
                          <Box mr={1} clone>
                            <CustomTextIcon />
                          </Box>
                        )
                      }}
                    />
                  </Box>
                  <FontList fontConfigs={fontConfigs} customText={customText} />
                </>
              )}
            </DynamicElement>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="h5">{t("font-example")}</Typography>
            <List dense>
              {[
                "Barlow",
                "Caviar Dreams",
                "Gill Sans",
                "Great Vibes",
                "Gruppo",
                "Nanum Gothic Coding",
                "Nixie One",
                "Open Sans",
                "Open Sans Condensed",
                "Pinyon Script",
                "Playball",
                "Poiret One",
                "PT Mono",
                "SF Pro",
                "Tajawal",
                "Tangerine"
              ].map(font => (
                <ListItem key={font} disableGutters>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center" flexWrap="nowrap">
                        <Typography style={{ flex: 1 }}>{font}</Typography>
                        <Slider
                          style={{ width: 100 }}
                          valueLabelDisplay="auto"
                          value={fontExampleSize[font] || 16}
                          min={8}
                          max={40}
                          step={1}
                          onChange={onFontExampleSizeChange(font)}
                          aria-labelledby="font-example-size"
                        />
                      </Box>
                    }
                    secondary={
                      <Typography
                        component="pre"
                        style={{
                          fontFamily: font,
                          fontSize: (fontExampleSize[font] as number) || 16
                        }}
                        className={classes.fontPreview}
                      >
                        {customText}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
